import classes from "classnames";
import { getAvatar } from "@Utils";
import { LiveChatMessage } from "@Models/LiveChat";
import "./style.scss";

type Props = {
  item: Omit<LiveChatMessage, "message">;
};

const LiveChatAvatar: React.FC<Props> = ({ item }) => {
  const { avatar, name, host } = item;
  const avatarAlt = getAvatar(name);

  return (
    <div className="wrapper-live-chat-avatar">
      {avatar ? (
        <div>image</div>
      ) : (
        <span
          className="live-chat-avatar"
          style={{
            backgroundColor: avatarAlt.color
          }}
        >
          <span className="live-chat-message__avatar-name-letters">{avatarAlt.shortLetters}</span>
        </span>
      )}

      <span
        className={classes("live-chat-avatar-name", {
          "live-chat-avatar-name__host": host
        })}
      >
        {host ? (
          <>
            Host: <span className="live-chat-avatar-name__host-bold">{name}</span>
            <i className="icon-check-validate live-chat-avatar-name__icon" />
          </>
        ) : (
          name
        )}
      </span>
    </div>
  );
};

export default LiveChatAvatar;
