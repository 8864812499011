import { SyntheticEvent, useEffect, useState } from "react";
import { isEmpty } from "ramda";
import classes from "classnames";
import { Row } from "react-flexbox-grid";
import { FilterChooser, FilterEdit } from "@Components/Filters";
import { Filters } from "@Models/Filters";
import { FilterComponent } from "@Models/FilterComponent";
import "./style.scss";

type Props = {
  isActive?: boolean;
  isPublic?: boolean;
  onClose: () => void;
  onFiltersUpdate: (filters: Filters) => void;
  initialFilters?: Filters;
};

const FiltersBar = ({
  isActive = false,
  onClose,
  onFiltersUpdate,
  initialFilters,
  isPublic
}: Props) => {
  const [showChooser, setShowChooser] = useState(false);
  const [activeFilters, setActiveFilters] = useState(initialFilters || ({} as Filters));
  const [showEditFilter, setShowEditFilter] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState({} as FilterComponent);

  useEffect(() => {
    if (!isEmpty(activeFilters)) {
      onFiltersUpdate(activeFilters);
    }
  }, [activeFilters]);

  const toggleAddFilter = () => {
    setShowChooser(!showChooser);
  };

  const toggleEditFilter = () => {
    setShowEditFilter(!showEditFilter);
  };

  const onFilterApplied = (filter: FilterComponent) => {
    setActiveFilters({
      ...activeFilters,
      [filter.id]: filter
    });
  };

  const onRemoveFromFilter = (event: SyntheticEvent, index: number) => {
    event.stopPropagation();
    const filtered = { ...activeFilters };
    delete filtered[Object.keys(activeFilters)[index]];
    setActiveFilters(filtered);
    onFiltersUpdate(filtered);
    setSelectedFilter({} as FilterComponent);
  };

  const onEditFormFilter = (item: FilterComponent) => {
    setSelectedFilter(item);
    setShowEditFilter(!showEditFilter);
  };

  return (
    <>
      {isActive && (
        <Row>
          <div className="app-filter-bar">
            <span className="bar-label">Filter</span>
            <ul className="filters-list">
              {!isEmpty(activeFilters) &&
                Object.values(activeFilters).map((filter, i) => (
                  <div className="edit-filter-item" key={i}>
                    <li
                      className={classes("selected-filter-item", {
                        "hide-selected-filter-item":
                          showEditFilter && selectedFilter.id === filter.id,
                        "opaticy-selected-filter-item":
                          showEditFilter && selectedFilter.id !== filter.id
                      })}
                      onClick={() => onEditFormFilter(filter)}
                    >
                      {`${filter.label}${filter.name}`}
                      <i
                        className="icon-close-rounded"
                        onClick={event => {
                          onRemoveFromFilter(event, i);
                        }}
                      />
                    </li>

                    {showEditFilter && selectedFilter.id === filter.id && (
                      <FilterEdit
                        filter={selectedFilter}
                        onClickOutside={toggleEditFilter}
                        onFilterApply={onFilterApplied}
                      />
                    )}
                  </div>
                ))}
              <li className="chooser-coll">
                {showChooser ? (
                  <FilterChooser
                    activeList={Object.keys(activeFilters)}
                    isPublic={isPublic}
                    onClickOutside={toggleAddFilter}
                    onFilterApply={onFilterApplied}
                  />
                ) : (
                  Object.keys(activeFilters).length !== 9 && (
                    <div className="add-filter" onClick={toggleAddFilter}>
                      <i className="icon-rounded-plus" />
                    </div>
                  )
                )}
              </li>
            </ul>
            <i className="icon-close-rounded" onClick={onClose} />
            <div
              className="reset-all-filters"
              onClick={() => {
                setActiveFilters({});
                onFiltersUpdate({});
                setSelectedFilter({} as FilterComponent);
              }}
            >
              <i className="icon-refresh-arrow" />
              Reset all
            </div>
          </div>
        </Row>
      )}
    </>
  );
};

export default FiltersBar;
