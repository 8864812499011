import { Fragment, forwardRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import UseKey from "react-use/lib/component/UseKey";
import { Row } from "react-flexbox-grid";
import { getSidebarData, hideSidebar, setSidebarDirectory } from "@Store/actions/sidebar";
import { selectSidebarVisibility, selectSidebarSelectedDirectory } from "@Store/selectors/sidebar";
import { Directory as DirectoryType } from "@Store/reducers/sidebar";
import { LogoWhite } from "@Assets";
import Overlay from "./Overlay";
import Directory from "./Directory";
import AppSearchInput from "../Layout/components/AppSearchInput";
import "./style.scss";

interface IProps {
  offsetTop: number;
}

const Sidebar = forwardRef<HTMLDivElement, IProps>(({ offsetTop }, ref): JSX.Element => {
  const dispatch = useDispatch();
  const isVisible = useSelector(selectSidebarVisibility);
  const selectedDirectory = useSelector(selectSidebarSelectedDirectory);
  const className = cn("discovery-menu-root", { "dm-visible": isVisible });

  const handleCloseSidebar = useCallback(() => dispatch(hideSidebar()), []);
  const handleSelectDirectory = useCallback(
    (directory: DirectoryType) => dispatch(setSidebarDirectory(directory)),
    []
  );

  useEffect(() => {
    dispatch(getSidebarData());
  }, []);

  return (
    <Fragment>
      <Overlay offsetTop={offsetTop} visible={isVisible} onClick={handleCloseSidebar} />
      <div className={className} ref={ref} style={{ height: `calc(100vh - ${offsetTop}px)` }}>
        <div className="buttons-close">
          <i className="icon-chevron-left" onClick={handleCloseSidebar} />
          <i className="icon-close-rounded" onClick={handleCloseSidebar} />
        </div>
        <div className="menu-logo" onClick={handleCloseSidebar}>
          <Link to="/" className="menu-logo-link">
            <img src={LogoWhite} alt="logo" />
          </Link>
        </div>
        <div className="menu-search">
          <AppSearchInput />
        </div>
        <h3 className="dm-header">Discover</h3>
        <Row className="dm-category-buttons">
          <button
            onClick={() => handleSelectDirectory("videos")}
            data-active={selectedDirectory === "videos"}
          >
            Videos
          </button>
          <button
            onClick={() => handleSelectDirectory("channels")}
            data-active={selectedDirectory === "channels"}
          >
            Channels
          </button>
        </Row>
        <Directory />
      </div>
      {isVisible && <UseKey filter="Escape" fn={handleCloseSidebar} />}
    </Fragment>
  );
});

export default Sidebar;
