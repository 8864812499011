import useRootRef from "@Hooks/useRootRef";
import { useEffect } from "react";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function CommunityGuidelines() {
  const rootRef = useRootRef();

  useEffect(() => {
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  return (
    <div className="page-content policy-page">
      <div className="full-container">
        <h1 className="title">Community guidelines</h1>
        <div className="policy-content">
          <h3>
            <strong>Overview‍</strong>
          </h3>
          <p>
            Our Community Guidelines are designed to ensure that our community stays protected. They
            set out what's allowed and not allowed on ShareVision, and apply to all types of content
            on our platform, including videos, comments, links and thumbnails.
          </p>
          <h3>
            ‍<strong>1. Fake Engagement Policy</strong>
          </h3>
          <p>
            ShareVision doesn’t allow anything that artificially increases the number of views,
            likes, comments, or other metric either through the use of automatic systems or by
            serving up videos to unsuspecting viewers. Additionally, content that solely exists to
            incentivise viewers for engagement (views, likes, comments, etc) is prohibited.Content
            and accounts that don't follow this policy may be terminated and removed from
            ShareVision.Important: &nbsp;If you hire someone to promote your account, their
            decisions may impact your account. Any method that violates our policies may result in
            content removal or a account takedown, whether it's an action taken by you or someone
            you've hired.We consider engagement to be legitimate when a human user’s primary intent
            is to interact with content free of coercion or deception, or where the sole purpose of
            the engagement is financial gain. If you find content that violates this policy, please
            report it.<strong>What this policy means for you</strong>Don’t post content on
            ShareVision if it fits any of the descriptions noted below.
          </p>
          <ul role="list">
            <li>
              Links to or promotes third-party services that artificially inflate metrics like
              views, likes, and subscribers
            </li>
            <li>
              Content linking to or promoting third-party view count or subscriber gaming websites
              or services
            </li>
            <li>
              Offering to subscribe to another Creator’s account only if they subscribe to your
              account
            </li>
            <li>
              Note: You're allowed to encourage viewers to subscribe, hit the like button, share, or
              leave a comment
            </li>
            <li>
              Content featuring a Creator purchasing their views from a third party with the intent
              of promoting the service
            </li>
          </ul>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature. Keep in mind that this isn't a complete list.
          </p>
          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision.</p>
          <ul role="list">
            <li>
              A video testimonial in which a Creator shows themselves successfully purchasing
              artificial page traffic from a third party
            </li>
            <li>
              A video in which a Creator links to a third party artificial page traffic provider in
              a promotional or supportive context. For example: “I got 1 million subscribers on this
              video in a day and you can too!”
            </li>
            <li>
              A video that tries to force or trick viewers into watching another video through
              deceptive means (for example: a misleadingly labeled info card)
            </li>
            <li>
              Accounts dedicated to artificial account engagement traffic or promoting businesses
              that exist for this sole purpose
            </li>
          </ul>
          <p>
            Remember these are just some examples, and don't post content if you think it might
            violate this policy.
          </p>

          <h3>
            <strong>2. Policy on impersonation</strong>
          </h3>

          <p>
            Content intended to impersonate a person or account is not allowed on ShareVision.
            ShareVision also enforces trademark holder rights. When a account, or content in the
            account, causes confusion about the source of goods and services advertised, it may not
            be allowed.
          </p>
          <p>If you see content that violates this policy, please report it.</p>
          <ul role="list">
            <li>
              If you feel that yours or another Creator's account is being impersonated, you can
              report the account.
            </li>
          </ul>
          <p>
            <strong>What these policies mean for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions noted below:
          </p>
          <ul role="list">
            <li>
              Account impersonation: A account that copies another account's profile, background, or
              overall look and feel in such a way that makes it look like someone else's account.
              The account does not have to be 100% identical, as long as the intent is clear to copy
              the other account.
            </li>
            <li>
              Personal impersonation: Content intended to look like someone else is posting it.
            </li>
          </ul>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              Accounts with the same name and image as another account, with the only difference
              being a space inserted into the name or a zero replacing the letter O.
            </li>
            <li>
              Using someone else's real name, user name, image, brand, logo, or other personal
              information to trick people into believing you are that person.
            </li>
            <li>Exactly matching a account description to another account.</li>
            <li>
              Setting up a account using the name and image of a person, and then pretending that
              person is posting content to the account.
            </li>
            <li>
              Setting up a account using the name and image of a person, and then posting comments
              on other accounts as if they were posted by the person.
            </li>
            <li>
              Accounts claiming to be a “fan account,” but actually posing as another’s account and
              reuploading their content.
            </li>
            <li>Accounts impersonating an existing news account.</li>
          </ul>

          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>3. Links in your Content</strong>
          </h3>

          <p>
            Links that send users to websites featuring content that violates our Community
            Guidelines are not allowed on ShareVision.
          </p>

          <p>
            <strong>What this policy means for you</strong>
          </p>
          <p>
            Don’t post links in your content on ShareVision if they fit any of the descriptions
            noted below.
          </p>
          <ul role="list">
            <li>Links to pornography</li>
            <li>Links to websites or apps that install malware</li>
            <li>
              Links to websites or apps phishing for a user’s login credentials, financial
              information, etc.
            </li>
            <li>
              Links to websites, apps, or other information technology that give unauthorised free
              access to audio content, audiovisual content, full video games, software, or streaming
              services that normally require payment
            </li>
            <li>
              Links to websites that seek to raise funds or recruit for terrorist organisations
            </li>
            <li>Links to sites containing Child Sexual Abuse Imagery (CSAI)</li>
          </ul>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature. Please note this is not a complete list.
          </p>
          <p>
            Note: Affiliate content doesn't violate ShareVision’s Terms of Use. However, massively
            posting affiliate content in dedicated accounts may violate our policies around spam.
          </p>
          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              A video featuring sexually themed content whose description says “click here to see
              what ShareVision won’t allow!” and contains a link to a pornographic site.
            </li>
            <li>
              A gameplay video whose description contains a link to a website promising free in-game
              currency or online store credit but really links to a site that infects the user’s
              computer with malware.
            </li>
            <li>
              Posting a link to a phishing site that steals users’ banking logins and passwords.
            </li>
            <li>
              Instructing viewers to copy and paste an unclickable link in the video that takes them
              to a pornographic or spammy site.
            </li>
            <li>
              Any link leading users to a website, file hosting service, etc. that allows them to
              access or download child sexual abuse imagery.
            </li>
          </ul>
          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>4. Spam, deceptive practices &amp; scams policies</strong>
          </h3>

          <p>
            ShareVision doesn’t allow spam, scams, or other deceptive practices that take advantage
            of the ShareVision community. We also don’t allow content where the main purpose is to
            trick others into leaving ShareVision for another site.
          </p>

          <p>
            <strong>What these policies mean for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions noted below:
          </p>
          <ul role="list">
            <li>
              Video Spam: Content that is excessively posted, repetitive, or untargeted and does one
              or more of the following:
            </li>
          </ul>
          <ul role="list">
            <li>Promises viewers they'll see something but instead directs them off site.</li>
            <li>
              Gets clicks, views, or traffic off ShareVision by promising viewers that they’ll make
              money fast.
            </li>
            <li>
              Sends audiences to sites that spread malware, try to gather personal information, or
              other sites that have a negative impact.
            </li>
          </ul>
          <ul role="list">
            <li>
              Misleading Metadata or Thumbnails: Using the title, thumbnails, description, or tags
              to trick users into believing the content is something it is not.
            </li>
            <li>
              Manipulated Media: Content that has been technically manipulated or doctored in a way
              that misleads users (beyond clips taken out of context) and may pose a serious risk of
              egregious harm.
            </li>
            <li>
              Scams: Content offering cash gifts, “get rich quick” schemes, or pyramid schemes
              (sending money without a tangible product in a pyramid structure).
            </li>
            <li>
              Voter Suppression: Content aiming to mislead voters about the time, place, means or
              eligibility requirements for voting, or false claims that could materially discourage
              voting.
            </li>
            <li>
              Suppression of Census Participation: Content aiming to mislead participants about the
              time, means or eligibility requirements for participating in a census.
            </li>
            <li>
              Candidate Eligibility: Content that advances false claims related to the technical
              eligibility requirements for current political candidates and sitting elected
              government officials to serve in office. Eligibility requirements considered are based
              on applicable national law, and include age, citizenship, or vital status.
            </li>
            <li>
              Incitement to interfere with democratic processes: content encouraging others to
              interfere with democratic processes, such as obstructing or interrupting voting
              procedures.
            </li>
            <li>
              Distribution of hacked materials: content that contains hacked information, the
              disclosure of which may interfere with democratic processes, such as elections and
              censuses.
            </li>
            <li>
              Incentivisation Spam: Content that sells engagement metrics such as views, likes,
              comments, or any other metric on ShareVision. This also includes content where the
              only purpose is to boost subscribers, views, or other metrics (e.g. offering to
              subscribe to another Creator’s account solely in exchange for them subscribing to your
              account, also known as "Sub4Sub" content).
            </li>
            <li>
              Comments Spam: Comments where the sole purpose is to gather personal information from
              viewers, misleadingly drive viewers off ShareVision, or perform any of the prohibited
              behaviours noted above.
            </li>
            <li>
              Repetitive comments: Leaving large amounts of identical, untargeted or repetitive
              comments.
            </li>
            <li>
              Live Stream Abuse: Live streams intended to stream content that belongs to somebody
              else and are not corrected following repeated warnings of possible abuse. Live streams
              should be actively monitored by the account owner, and any potential issues should be
              corrected in a timely manner.
            </li>
          </ul>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature. Keep in mind that this isn't a complete list.
          </p>
          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              Telling viewers they can vote through fake methods like texting their vote to a
              particular number
            </li>
            <li>
              Giving made up voter eligibility requirements like saying that a particular election
              is only open to voters over 50 years old
            </li>
            <li>Deliberately telling viewers an incorrect election date</li>
          </ul>

          <h3>
            <strong>5. Child Safety on ShareVision</strong>
          </h3>

          <p>
            ShareVision doesn’t allow content that endangers the emotional and physical well-being
            of minors. A minor is defined as someone under the legal age of majority – usually
            anyone younger than 18 years old in most countries/regions.
          </p>
          <p>
            If you find content that violates this policy, report it. If you believe that a child is
            in danger, you should contact your local law enforcement to report the situation
            immediately.
          </p>
          <p>
            <strong>What this policy means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions below.
          </p>
          <ul role="list">
            <li>
              Sexualization of minors: Sexually explicit content featuring minors and content that
              sexually exploits minors. We report content containing child sexual abuse imagery to
              the{" "}
              <a href="http://www.missingkids.com/home" target="_blank" rel="noreferrer">
                National Center for Missing and Exploited Children
              </a>
              , who work with global law enforcement agencies.
            </li>
            <li>
              Harmful or dangerous acts involving minors: Content showing a minor participating in
              dangerous activities or encouraging minors to do dangerous activities. Never put
              minors in harmful situations that may lead to injury, including dangerous stunts,
              dares, or pranks.
            </li>
            <li>
              Infliction of emotional distress on minors: Content that could cause minor
              participants or viewers emotional distress, including:
            </li>
          </ul>
          <ul role="list">
            <li>Exposing minors to mature themes</li>
            <li>Simulating parental abuse</li>
            <li>Coercing minors</li>
            <li>Violence</li>
            <li>
              Misleading family content: Content that targets young minors and families, but
              contains:
            </li>
            <li>Sexual themes</li>
            <li>Violence</li>
            <li>Obscenity or other mature themes not suitable for young audiences</li>
            <li>
              Family friendly cartoons that target young minors and contain adult or
              age-inappropriate themes such as violence, sex, death, drugs etc. Make sure your
              titles, descriptions, and tags match the audience you are targeting. You can also age
              restrict your content upon upload if it’s intended for mature audiences.
            </li>
            <li>Cyberbullying and harassment involving minors: Content that:</li>
          </ul>
          <ul role="list">
            <li>Targets individuals for abuse or humiliation</li>
            <li>Reveals personal information like email addresses or bank account numbers</li>
            <li>Records someone without their consent</li>
            <li>Sexually harasses</li>
            <li>Encourages others to bully or harass</li>
          </ul>
          <p>
            This policy applies to videos, video descriptions, comments, Stories, Community posts,
            live streams, playlists, and any other ShareVision product or feature. Please note this
            is not a complete list.
          </p>
          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              A video featuring minors involved in provocative, sexual, or sexually suggestive
              activities, challenges and dares, such as kissing or groping.
            </li>
            <li>
              Showing minors involved in dangerous activities, such as physical stunts, using
              weapons or explosives, or using a controlled substance like alcohol or nicotine.
            </li>
            <li>
              A video with tags like "for children" featuring family friendly cartoons engaging in
              inappropriate acts like injecting needles.
            </li>
          </ul>
          <p>
            Remember these are just some examples, and don't post content if you think it might
            violate this policy.
          </p>

          <h3>
            <strong>6. Custom thumbnails policy</strong>
          </h3>

          <p>
            Custom thumbnails that violate our Community Guidelines are not allowed on ShareVision.
          </p>
          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            Don’t post a thumbnail on ShareVision that fits any of the descriptions noted below.
          </p>
          <ul role="list">
            <li>
              Thumbnails that include pornographic images depicting sexual acts, genitals, or
              fetishes for the purpose of sexual gratification
            </li>
            <li>Violent imagery intended to shock or disgust</li>
            <li>
              Thumbnails misleading viewers into thinking they’re about to view something that’s not
              in the video
            </li>
          </ul>
          <p>Please note this is not a complete list.</p>

          <h3>
            <strong>7. Nudity and sexual content policies</strong>
          </h3>

          <p>
            Explicit content meant to be sexually gratifying is not allowed on ShareVision. Posting
            pornography may result in content removal or account termination. Videos containing
            fetish content will be removed or age-restricted. In most cases, violent, graphic, or
            humiliating fetishes are not allowed on ShareVision.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>
          <p>
            Sexually explicit content featuring minors and content that sexually exploits minors is
            not allowed on ShareVision. We report content containing child sexual abuse imagery to
            the{" "}
            <a href="http://www.missingkids.com/home" target="_blank" rel="noreferrer">
              National Center for Missing and Exploited Children
            </a>
            , who work with global law enforcement agencies.
          </p>

          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it has any of the items
            below. Explicit content featuring the below policy violations could result in account
            termination.
          </p>
          <ul role="list">
            <li>
              Depiction of genitals, breasts, or buttocks (clothed or unclothed) for the purpose of
              sexual gratification
            </li>
            <li>
              Pornography depicting sexual acts, genitals, or fetishes for the purpose of sexual
              gratification
            </li>
          </ul>
          <p>Please note this is not a complete list.</p>
          <p>‍</p>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature. &nbsp;Please remember these are just some examples, and
            don't post content if you think it might violate this policy.
          </p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              Clips extracted from non-pornographic films, shows, or other content in order to
              isolate sexual content
            </li>
            <li>
              Groping, pantsing, public masturbation, up-skirting, voyeurism, predatory
              exhibitionism or any other content that depicts someone in a sexualised manner without
              their consent
            </li>
          </ul>

          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>8. Suicide &amp; self-injury</strong>
          </h3>

          <p>
            At ShareVision, we take the health and well-being of all our Creators and viewers
            seriously. Awareness and understanding of mental health is important and we support
            Creators sharing their stories, such as posting content discussing their experiences
            with depression, self-harm, or other mental health issues.
          </p>
          <p>
            We do not, however, allow content on ShareVision that promotes suicide, self-harm, or is
            intended to shock or disgust users.
          </p>

          <p>
            <strong>What to do if you need support</strong>
          </p>
          <p>
            If you are depressed, having thoughts of suicide, or self-harming, know there is help
            and you’re not alone. Many people turn to suicidal thinking and self-harm as ways to
            cope with painful emotions. Talking to a specialist can help you identify healthy,
            effective coping strategies and develop skills to manage difficult feelings.
          </p>
          <p>
            Below is a list of suicide prevention organisations dedicated to helping those in need
            in different countries and regions. The websites{" "}
            <a href="https://www.befrienders.org/" target="_blank" rel="noreferrer">
              www.befrienders.org{" "}
            </a>
            and{" "}
            <a
              href="http://www.suicide.org/international-suicide-hotlines.html"
              target="_blank"
              rel="noreferrer"
            >
              suicide.org
            </a>{" "}
            also have extensive lists of organisations.
          </p>

          <p>
            <strong>Guidelines for posting mental health-related content</strong>
          </p>
          <p>
            ShareVision users should not be afraid to speak openly about the topics of mental health
            or self-harm. However please don’t post content on ShareVision if it fits any of the
            descriptions noted below:
          </p>
          <ul role="list">
            <li>Promoting or glorifying suicide</li>
            <li>Providing instructions on how to self-harm or die by suicide</li>
            <li>Graphic images of self-harm posted to shock or disgust viewers</li>
          </ul>
          <p>
            This Community Guidelines policy applies to videos, video descriptions, comments, live
            streams, and any other ShareVision product or feature. Please note this is not a
            complete list.
          </p>

          <h3>
            <strong>9. Harassment and cyberbullying policy</strong>
          </h3>

          <p>
            Content that threatens individuals is not allowed on ShareVision. We also do not allow
            content that targets an individual with prolonged or malicious insults based on
            intrinsic attributes, including their protected group status or physical traits.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>
          <p>
            If specific threats are made against you and you feel unsafe, report it directly to your
            local law enforcement agency.
          </p>

          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions noted below:
          </p>
          <ul role="list">
            <li>
              Content that features prolonged name calling or malicious insults (such as racial
              slurs) based on their intrinsic attributes. These attributes include their protected
              group status, physical attributes, or their status as a survivor of sexual assault,
              domestic abuse, child abuse etc.
            </li>
            <li>
              Content uploaded with the intent to shame, deceive or insult a minor. A minor is
              defined as a person under the legal age of majority. This usually means anyone younger
              than 18 years old, but the age of a minor might vary by country.
            </li>
          </ul>

          <p>
            <strong>Exceptions</strong>
          </p>
          <p>
            We may allow content that includes harassment if the primary purpose is educational,
            documentary, scientific, or artistic in nature. This is not a free pass to harass
            someone. Some examples include:
          </p>
          <ul role="list">
            <li>
              Debates related to high-profile officials or leaders: Content featuring debates or
              discussions of topical issues concerning people who have positions of power, like
              high-profile government officials or CEOs of major multinational corporations.
            </li>
            <li>
              Scripted performances: Insults made in the context of an artistic medium such as
              scripted satire, stand up comedy, or music (e.g. a diss track). Note: This is not a
              free pass to harass someone and claim “I was joking.”
            </li>
            <li>
              Harassment education or awareness: Content that features actual or simulated
              harassment for documentary purposes or with willing participants (e.g. actors) to
              combat cyberbullying or raise awareness.
            </li>
          </ul>

          <p>
            <strong>Monetisation and other penalties </strong>
          </p>
          <p>In some rare cases, we may remove content or issue other penalties when a Creator:</p>
          <ul role="list">
            <li>Repeatedly encourages abusive audience behaviour.</li>
            <li>
              Repeatedly targets, insults and abuses an identifiable individual based on their
              intrinsic attributes across multiple uploads.
            </li>
            <li>
              Exposes an individual to risks of physical harm based on the local social or political
              context.
            </li>
            <li>
              Creates content that harms the ShareVision ecosystem by persistently inciting
              hostility between Creators for personal financial gain.
            </li>
          </ul>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision:</p>
          <ul role="list">
            <li>
              Repeatedly showing pictures of someone and then making statements like “Look at this
              creature’s teeth, they’re so disgusting!”, with similar commentary targeting intrinsic
              attributes throughout the video.
            </li>
            <li>
              Targeting an individual based on their membership in a protected group, such as by
              saying: “Look at this filthy [slur targeting a protected group], I wish they’d just
              get hit by a truck.”
            </li>
            <li>
              Targeting an individual and making claims they are involved in human trafficking in
              the context of a harmful conspiracy theory where the conspiracy is linked to direct
              threats or violent acts.
            </li>
            <li>
              Using an extreme insult to dehumanise an individual based on their intrinsic
              attributes. For example: “Look at this dog of a woman! She’s not even a human being —
              she must be some sort of mutant or animal!”
            </li>
            <li>
              Depicting an identifiable individual being murdered, seriously injured, or engaged in
              a graphic sexual act without their consent.
            </li>
            <li>
              Accounts dedicated entirely to focusing on maliciously insulting an identifiable
              individual.
            </li>
          </ul>

          <h3>
            <strong>10. Harmful or dangerous content policy</strong>
          </h3>

          <p>
            ShareVision doesn’t allow content that encourages dangerous or illegal activities that
            risk serious physical harm or death.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>
          <p>
            <strong>What this policy means for you</strong>
          </p>
          <p>If you're posting content</p>
          <p>Don’t post content on ShareVision if it fits any of the descriptions noted below.</p>
          <ul role="list">
            <li>
              Extremely dangerous challenges: Challenges that pose an imminent risk of physical
              injury.
            </li>
            <li>
              Dangerous or threatening pranks: Pranks that lead victims to fear imminent serious
              physical danger, or that create serious emotional distress in minors.
            </li>
            <li>
              Instructions to kill or harm: Showing viewers how to perform activities meant to kill
              or maim others. For example, giving instructions to build a bomb meant to injure or
              kill others.
            </li>
            <li>
              Hard drug use or creation: Content that depicts abuse of or giving instructions on how
              to create hard drugs such as cocaine or opioids. Hard drugs are defined as drugs that
              can (mostly) lead to physical addiction.
            </li>
            <li>
              Eating Disorders: Content that praises, glorifies, or encourages viewers to imitate
              anorexia or other eating disorders. Eating disorders are characterised by abnormal or
              disturbed eating habits which negatively affect a person's health (including eating
              non-food items).
            </li>
            <li>
              Violent Events: Promoting or glorifying violent tragedies, such as school shootings.
            </li>
            <li>
              Instructional theft or cheating: Showing viewers how to steal tangible goods or
              promoting dishonest behaviour
            </li>
            <li>
              Hacking: Demonstrating how to use computers or information technology with the intent
              to steal credentials, compromise personal data or cause serious harm to others such as
              (but not limited to) hacking into social media accounts
            </li>
            <li>
              Bypassing payment for digital content or services: Showing viewers how to use apps,
              websites, or other information technology to gain unauthorised free access to audio
              content, audiovisual content, full video games, software, or streaming services that
              normally require payment.
            </li>
            <li>
              Promoting dangerous remedies or cures: Content which claims that harmful substances or
              treatments can have health benefits.
            </li>
          </ul>
          <p>Keep in mind that this isn't a complete list.</p>
          <p>‍</p>
          <p>
            Don’t post content showing a minor participating in dangerous activity, or encouraging
            minors to participate in dangerous activities. Never put minors in harmful situations
            that may lead to injury, including dangerous stunts, dares, or pranks.
          </p>
          <p>
            We might allow videos that depict dangerous acts iIf they’re meant to be educational,
            documentary, scientific, or artistic (EDSA). For example, a news piece on the dangers of
            choking games would be appropriate, but posting clips out of context from the same
            documentary might not be.
          </p>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature.
          </p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>
            The following types of content are not allowed on ShareVision. This isn't a complete
            list.
          </p>
          <ul role="list">
            <li>
              Asphyxiation: Any activity that prevents breathing or can lead to suffocation like:
            </li>
          </ul>
          <ul role="list">
            <li>Choking, drowning, or hanging games</li>
            <li>Eating non-food items</li>
          </ul>
          <ul role="list">
            <li>
              Misuse of weapons: Using weapons, like guns or knives, without proper safety
              precautions or in a way that could cause physical harm. Includes the "No Lackin'"
              challenge.
            </li>
            <li>
              Ingesting Harmful Substances: Eating, consuming, or inserting non-food objects or
              chemicals that may cause illness or poisoning. Includes detergent-eating challenges.
            </li>
            <li>
              Burning, Freezing &amp; Electrocution: Activities with inherent risk of severe burns,
              freezing, frostbite, or electrocution. Includes the fire challenge and hot water
              challenge.
            </li>
            <li>Mutilation &amp; Blunt Force Trauma: Activities like:</li>
          </ul>
          <ul role="list">
            <li>Self-mutilation</li>
            <li>Abstaining from normal health practices</li>
            <li>Falling, impalement, collision, blunt force trauma, or crushing</li>
          </ul>
          <ul role="list">
            <li>
              Challenges featuring children: Showing minors drinking alcohol, using vaporizers,
              e-cigarettes, tobacco or marijuana, or misusing fireworks.
            </li>
          </ul>
          <ul role="list">
            <li>
              Intentional physical harm: Inflicting physical harm on unsuspecting prank victims
              through actions like punching attacks, drugging food or drinks with laxatives, or
              shock pranks.
            </li>
            <li>
              Making someone feel in immediate danger: Tricking others into believing they're in
              real danger, even if no physical harm comes to them. This includes:
            </li>
          </ul>
          <ul role="list">
            <li>Threats with weapons</li>
            <li>Bomb scares</li>
            <li>Swatting or fake 911 calls</li>
            <li>Fake home invasions or robberies</li>
            <li>Fake kidnapping</li>
          </ul>
          <ul role="list">
            <li>
              Emotional distress to minors: Any prank that causes emotional distress to children or
              others who are vulnerable. This includes:
            </li>
          </ul>
          <ul role="list">
            <li>Fake death or suicide</li>
            <li>Fake violence</li>
            <li>Pretending that a parent or caregiver will abandon a child</li>
            <li>Showing a parent or caregiver verbally abuse or shame a child</li>
          </ul>
          <ul role="list">
            <li>
              Bomb-making: Showing viewers how to build a bomb meant to injure or kill others, such
              as:
            </li>
          </ul>
          <ul role="list">
            <li>Pipe bombs</li>
            <li>Package bombs</li>
            <li>Explosive vests</li>
            <li>Molotov cocktails</li>
          </ul>
          <ul role="list">
            <li>Violence involving children: Any real fights or violence between children.</li>
          </ul>
          <ul role="list">
            <li>
              Displays of hard drug uses: Non-educational content that shows the injection of
              intravenous drugs like heroin or huffing/sniffing glue.
            </li>
            <li>Making hard drugs: Non-educational content that explains how to make drugs.</li>
            <li>
              Minors using alcohol or drugs: Showing minors drinking alcohol, using vaporizers,
              e-cigarettes, tobacco or marijuana, or misusing fireworks.
            </li>
            <li>
              Selling hard or soft drugs: Featuring drugs with the goal of selling them. If you're
              using links in your description to sell hard drugs, your account will be terminated.
            </li>
            <li>
              Steroid use: Non-educational content that shows how to use steroids for recreational
              purposes (like body building).
            </li>
          </ul>
          <ul role="list">
            <li>Using terms or phrases in metadata that promote or glorify eating disorders.</li>
            <li>Videos that promote or glorify eating non food items.</li>
            <li>Miracle Mineral Solution (MMS)</li>
            <li>Black Salve</li>
          </ul>

          <h3>
            <strong>11. Hate speech policy</strong>
          </h3>

          <p>Groups based on any of the following attributes:</p>
          <ul role="list">
            <li>Age</li>
            <li>Caste</li>
            <li>Disability</li>
            <li>Ethnicity</li>
            <li>Gender Identity and Expression</li>
            <li>Nationality</li>
            <li>Race</li>
            <li>Immigration Status</li>
            <li>Religion</li>
            <li>Sex/Gender</li>
            <li>Sexual Orientation</li>
            <li>Victims of a major violent event and their kin</li>
            <li>Veteran Status</li>
          </ul>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>

          <p>
            <strong>What this policy means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if the purpose of that
            content is to do one or more of the following.
          </p>
          <ul role="list">
            <li>
              Encourage violence against individuals or groups based on any of the attributes noted
              above. We don’t allow threats on ShareVision, and we treat implied calls for violence
              as real threats.
            </li>
            <li>
              Incite hatred against individuals or groups based on any of the attributes noted
              above.
            </li>
          </ul>

          <p>
            <strong>Monetisation and other penalties </strong>
          </p>
          <p>In some rare cases, we may remove content or issue other penalties when a Creator:</p>
          <ul role="list">
            <li>Repeatedly encourages abusive audience behaviour.</li>
            <li>
              Repeatedly targets, insults and abuses a group based on the attributes noted above
              across multiple uploads.
            </li>
            <li>
              Exposes a group with attributes noted above to risks of physical harm based on the
              local social or political context.
            </li>
            <li>
              Creates content that harms the ShareVision ecosystem by persistently inciting
              hostility against &nbsp;a group with attributes noted above for personal financial
              gain.
            </li>
          </ul>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are examples of hate speech not allowed on ShareVision.</p>
          <ul role="list">
            <li>
              “I’m glad this [violent event] happened. They got what they deserved [referring to
              persons with the attributes noted above].”
            </li>
            <li>
              “[Person with attributes noted above] are dogs” or “[person with attributes noted
              above] are like animals.”
            </li>
          </ul>

          <h3>
            <strong>12. Violent Criminal Organisations</strong>
          </h3>

          <p>
            Content intended to praise, promote, or aid violent criminal organisations is not
            allowed on ShareVision. These organisations are not allowed to use ShareVision for any
            purpose, including recruitment.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>
          <p>
            If you believe anyone is in immediate danger, you should reach out to your local law
            enforcement agency to report the situation immediately.
          </p>

          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions noted below.
          </p>
          <ul role="list">
            <li>Content produced by violent criminal or terrorist organisations</li>
            <li>
              Content praising or memorialising prominent terrorist or criminal figures in order to
              encourage others to carry out acts of violence
            </li>
            <li>
              Content praising or justifying violent acts carried out by violent criminal or
              terrorist organisations
            </li>
            <li>
              Content aimed at recruiting new members to violent criminal or terrorist organisations
            </li>
            <li>
              Content depicting hostages or posted with the intent to solicit, threaten, or
              intimidate on behalf of a violent criminal or terrorist organisation
            </li>
            <li>
              Content that depicts the insignia, logos, or symbols of violent criminal or terrorist
              organisations in order to praise or promote them
            </li>
          </ul>
          <p>
            If posting content related to terrorism or crime for an educational, documentary,
            scientific, or artistic purpose, be mindful to provide enough information in the video
            or audio itself so viewers understand the context. Graphic or controversial footage with
            sufficient context may be subject to age-restrictions or a warning screen.
          </p>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature. &nbsp;Please note this is not a complete list.
          </p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision.</p>
          <ul role="list">
            <li>
              Raw and unmodified re-uploads of content created by terrorist or criminal
              organisations
            </li>
            <li>Celebrating terrorist leaders or their crimes in songs or memorials</li>
            <li>Celebrating terrorist or criminal organisations in songs or memorials</li>
            <li>
              Content directing users to sites that espouse terrorist ideology, are used to
              disseminate prohibited content, or are used for recruitment
            </li>
            <li>
              Video game content which has been developed or modified (“modded”) to glorify a
              violent event, its perpetrators, or support violent criminal or terrorist
              organisations
            </li>
          </ul>

          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>13. Violent or graphic content policies</strong>
          </h3>

          <p>
            Violent or gory content intended to shock or disgust viewers, or content encouraging
            others to commit violent acts are not allowed on ShareVision.
          </p>
          <p>
            If you believe anyone is in imminent danger, you should reach out to your local law
            enforcement agency to report the situation immediately.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>
          <p>‍</p>
          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if it fits any of the
            descriptions noted below:
          </p>
          <ul role="list">
            <li>
              Inciting others to commit violent acts against individuals or a defined group of
              people
            </li>
            <li>Fights involving minors</li>
            <li>
              Footage, audio, or imagery involving road accidents, natural disasters, war aftermath,
              terrorist attack aftermath, street fights, physical attacks, sexual assaults,
              immolation, torture, corpses, protests or riots, robberies, medical procedures, or
              other such scenarios with the intent to shock or disgust viewers
            </li>
            <li>
              Footage or imagery showing bodily fluids, such as blood or vomit, with the intent to
              shock or disgust viewers.
            </li>
            <li>
              Content where there is infliction of unnecessary suffering or harm deliberately
              causing an animal distress.
            </li>
            <li>Content where animals are encouraged or coerced to fight by humans</li>
            <li>
              Dramatized or fictional footage of anything listed above where the viewer is not
              provided with enough context to determine that the footage is dramatized or fictional
            </li>
            <li>Footage of corpses with massive injuries, such as severed limbs</li>
          </ul>

          <p>Please note this is not a complete list.</p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision.</p>
          <ul role="list">
            <li>
              Encouraging others to go to a particular place to commit violence, to perform violence
              at a particular time, or to target individuals or groups with violence
            </li>
            <li>
              Actual schoolyard fights between minors. We may allow content if minors are only play
              fighting and that is evident to viewers.
            </li>
            <li>
              Beatings or brawls outside the context of professional or professionally supervised
              sporting events.
            </li>
          </ul>

          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>14. Policies on content featuring firearms</strong>
          </h3>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            Content intended to sell firearms, instruct viewers on how to make firearms, ammunition,
            and certain accessories, or instruct viewers on how to install those accessories is not
            allowed on ShareVision. ShareVision shouldn't be used as a platform to sell firearms or
            accessories noted below. ShareVision also doesn’t allow live streams that show someone
            holding, handling, or transporting a firearm.
          </p>

          <p>
            <strong>What this means for you</strong>
          </p>
          <p>
            If you're posting content don’t post content on ShareVision if the purpose is to do one
            or more of the following:
          </p>
          <ul role="list">
            <li>
              Sell firearms or certain firearms accessories through direct sales (e.g. private sales
              by individuals) or links to sites that sell these items. These accessories may
              include:
            </li>
          </ul>
          <ul role="list">
            <li>Accessories that enable a firearm to simulate automatic fire,</li>
            <li>
              Accessories that convert a firearm to automatic fire, such as: bump stocks, gatling
              triggers, drop-in auto sears, or conversion kits,
            </li>
            <li>High capacity magazines or belts carrying more than 30 rounds.</li>
          </ul>
          <ul role="list">
            <li>Provide instructions on manufacturing any of the following:</li>
          </ul>
          <ul role="list">
            <li>Firearms,</li>
            <li>Ammunition,</li>
            <li>High capacity magazines,</li>
            <li>Homemade silencers/suppressors,</li>
            <li>Accessories that enable a firearm to simulate automatic fire,</li>
            <li>
              Accessories that convert a firearm to automatic fire, such as: bump stocks, gatling
              triggers, drop-in auto sears, or conversion kits
            </li>
          </ul>
          <ul role="list">
            <li>
              Provide instructions on how to convert a firearm to automatic or simulated automatic
              firing capabilities.
            </li>
            <li>
              Provides instructions on how to install the above-mentioned accessories or
              modifications.
            </li>
          </ul>

          <p>Please note this is not a complete list.</p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that isn’t allowed on ShareVision.</p>
          <ul role="list">
            <li>
              Links in the title or description of your video to sites where firearms or the
              accessories noted above are sold. You can link to sites that discuss or review these
              items as long as those sites don’t sell those items directly.
            </li>
            <li>
              Displaying a firearm with the intention to sell that firearm via private sale. This
              includes giving the seller’s phone number, email address, or other contact
              information.
            </li>
            <li>
              Showing users step-by-step instructions on how to finish a lower receiver in order to
              complete fabrication of a firearm.
            </li>
            <li>
              Showing users how to make a silencer out of flashlight, oil can, solvent catcher or
              other parts.
            </li>
            <li>
              Showing users how to install a bump stock, or install a comparable accessory built to
              enable simulated automatic fire.
            </li>
            <li>
              Live streams that feature someone holding or handling a firearm, regardless of whether
              or not they are firing it. Note: this does not include firearms in video games.
            </li>
            <li>
              Live streams that feature someone transporting firearms from place to place, such as
              by carrying them or traveling with them by car, truck, or other vehicle. Note: this
              does not include firearms in video games.
            </li>
          </ul>
          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>15. Sale of Illegal or Regulated Goods or services</strong>
          </h3>
          <p>
            Content intended to sell certain regulated goods and services is not allowed on
            ShareVision.
          </p>
          <p>
            If you find content that violates this policy, please report it. If you've found
            multiple videos or comments that you would like to report, you can report the account.
          </p>

          <p>
            <strong>What this policy means for you</strong>
          </p>
          <p>
            If you're posting content don't post content on ShareVision if it aims to directly sell,
            &nbsp;link to, or facilitate access to any of the regulated goods and services listed
            below. Making the sale of these items or facilitating the use of these services possible
            by posting links, email, phone number or other means to contact a seller directly is not
            allowed.
          </p>
          <ul role="list">
            <li>Alcohol</li>
            <li>Bank account passwords, stolen credit cards, or other financial information</li>
            <li>Counterfeit documents or currency</li>
            <li>Controlled narcotics and other drugs</li>
            <li>Explosives</li>
            <li>Organs</li>
            <li>Endangered species or parts of endangered species</li>
            <li>Firearms and certain firearms accessories</li>
            <li>Nicotine, including vaping products</li>
            <li>Online gambling sites not yet reviewed by ShareVision</li>
            <li>Pharmaceuticals without a prescription</li>
            <li>Sex or escort services</li>
            <li>Unlicensed medical services</li>
            <li>Human smuggling</li>
          </ul>
          <p>
            This policy applies to videos, video descriptions, comments, live streams, and any other
            ShareVision product or feature.&nbsp;
          </p>

          <p>Please note this is not a complete list.</p>

          <p>
            <strong>Examples</strong>
          </p>
          <p>Here are some examples of content that’s not allowed on ShareVision.</p>
          <ul role="list">
            <li>Linking to an online gambling or sports betting site that is not approved.</li>
            <li>
              Selling counterfeit passports or providing instructions on creating forged official
              documents.
            </li>
            <li>Advertising escort, prostitution, or erotic massage services.</li>
            <li>Content instructing how to purchase drugs on the dark web.</li>
            <li>
              A video of a user making a purchase with software that generates fake credit card
              numbers.
            </li>
            <li>Including a link to an online pharmacy that does not require prescriptions.</li>
          </ul>
          <p>
            Please remember these are just some examples, and don't post content if you think it
            might violate this policy.
          </p>

          <h3>
            <strong>What happens if content violates this policy</strong>
          </h3>
          <p>
            If your content violates any of the above policies we’ll remove the content and send you
            an email to let you know. If this is your first time violating our Community Guidelines,
            you’ll get a warning with no penalty to your account. If it’s not, we’ll issue a strike
            against your account. If you get 3 strikes, your account will be terminated.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CommunityGuidelines;
