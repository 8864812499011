import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { useSelector } from "react-redux";
import { getAvatar } from "@Utils";
import { getAuthUser } from "@Store/selectors/user";
import TextareaAutosize from "react-textarea-autosize";
import classes from "classnames";

type Props = {
  onSubmit: Function;
  onCancelShow: () => void;
  initialState?: string;
  onCheck?: boolean;
  placeholder: string;
  actionLabel: string;
};

function CommentInput({
  onSubmit,
  onCancelShow,
  initialState = "",
  placeholder,
  onCheck,
  actionLabel
}: Props) {
  const [newCommentValue, setNewCommentValue] = useState(initialState);
  const inputRef = useRef(null as any);

  const currentUser = useSelector(getAuthUser);
  const ownerAvatarAlt = getAvatar(currentUser.full_name);

  const handleTextareaChange = (e: { target: { value: string } }) => {
    setNewCommentValue(e.target.value);
  };

  const handleSubmitComment = () => {
    if (newCommentValue.length) {
      onSubmit(newCommentValue);
      setNewCommentValue("");
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <Row
      className={classes("comments-input", {
        ["admin-input"]: !!onCheck
      })}
    >
      <div
        className="avatar"
        style={{
          backgroundColor: ownerAvatarAlt.color
        }}
      >
        {(currentUser.avatar && (
          <img src={currentUser.avatar.replace("[SIZE]", "32x32")} alt="" />
        )) || <span className="name-letters">{ownerAvatarAlt.shortLetters}</span>}
      </div>
      <TextareaAutosize
        value={newCommentValue}
        ref={inputRef}
        onChange={handleTextareaChange}
        className="new-comment-input"
        placeholder={placeholder}
      />
      <Row className="actions-button">
        <Col>
          <button className="submit-button main-button small" onClick={handleSubmitComment}>
            {actionLabel}
          </button>
        </Col>
        <Col>
          <button className="cancel-button main-button small" onClick={onCancelShow}>
            Cancel
          </button>
        </Col>
      </Row>
    </Row>
  );
}

export default CommentInput;
