import { AuthModal } from "../index";
import { SuccessCheck } from "@Assets";
import { Link } from "react-router-dom";

type SignUpModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
  onCloseModal: () => void;
};

function SignUpSuccessModal({ onCloseModal }: SignUpModalTypes) {
  return (
    <article className="pop-content-container success-sign-up">
      <div className="success-animation">
        <img src={SuccessCheck} alt="Success Img" />
      </div>
      <h2 className="heading">Sign up successfull!</h2>
      <p className="description">
        You can start using ShareVision. But please confirm your email (you just have to click the
        link we’ve sent to your email address)
      </p>
      <Link to="/profile" className="main-button" onClick={() => onCloseModal()}>
        Start your ShareVision journey
      </Link>
    </article>
  );
}

export default SignUpSuccessModal;
