import { YoutubeVideo } from "@Models/Video";
import "./style.scss";

interface YoutubeVideoListItemTypes extends YoutubeVideo {
  withCheckbox: boolean;
  checked?: boolean;
  onCheck: () => void;
}

function YoutubeVideoListItem({
  description,
  thumbnail,
  title,
  withCheckbox,
  checked,
  onCheck
}: YoutubeVideoListItemTypes) {
  return (
    <div className="youtube-video-list-item">
      {withCheckbox && (
        <div className="checbox-wrap">
          <label className="select-all-option">
            <input readOnly name="check" type="checkbox" checked={checked} />
            <span className="label-text" onClick={onCheck} />
          </label>
        </div>
      )}
      {thumbnail && <img className="thumb" src={thumbnail} alt="" />}

      <div className="details">
        <div className="title" title={title}>
          {title}
        </div>
        <div className="description" title={description}>
          {description}
        </div>
      </div>
    </div>
  );
}

export default YoutubeVideoListItem;
