import AdminAccordion from "@AdminComponents/AdminAccordion";
import AdminDropdown from "@AdminComponents/AdminDropdown";
import ListWrapper from "../ListWrapper";
import { Subcategory } from "@Models/Subcategory";
import { useState } from "react";
import { AppSelect } from "@Models/AppSelect";
import SelectedItem from "@AdminComponents/SelectedItem";

type SubcategoriesProps = {
  onAddSubcategory: (addedItem: string) => void;
  onDeleteSubcategory: (deleteItemId: number) => void;
  onSubcategoryAssignTopics: (selected: AppSelect[], subcategoryId: number) => void;
  onSubcategoryUnassignTopics: (topics: number[], subcategoryId: number) => void;
  subcategories: Subcategory[];
  topics: AppSelect[];
};

function SubcategoriesManager({
  subcategories,
  onAddSubcategory,
  onDeleteSubcategory,
  onSubcategoryAssignTopics,
  onSubcategoryUnassignTopics,
  topics
}: SubcategoriesProps) {
  const [editMode, setEditMode] = useState(false);
  const onEditModeToggle = (editState: boolean) => {
    setEditMode(editState);
  };

  return (
    <ListWrapper
      listTitle="Subcategories"
      actionText="Add Subcategory"
      actionLabel="Subcategory name"
      onAddItem={onAddSubcategory}
      onEditModeToggle={onEditModeToggle}
      actionButtonLabel="Create subcategory"
    >
      {!!subcategories.length &&
        subcategories.map((subcategory, index) => {
          const selectedTopics = topics.filter(topic => subcategory.topics.includes(topic.value));
          return (
            <AdminAccordion
              isDeletable={subcategory.is_deletable}
              isEditMode={editMode}
              isLiked={!!subcategory.topics.length}
              itemId={subcategory.subcategory_id}
              onDeleteItem={onDeleteSubcategory}
              title={subcategory.name}
              key={index}
            >
              {!!subcategory.topics.length ? (
                <SelectedItem
                  multipleView
                  title="Topics"
                  selectedOptions={selectedTopics}
                  onRemoveItem={id => {
                    onSubcategoryUnassignTopics([id], subcategory.subcategory_id);
                  }}
                  onRemove={() => {
                    onSubcategoryUnassignTopics(subcategory.topics, subcategory.subcategory_id);
                  }}
                />
              ) : (
                <AdminDropdown
                  checkboxSelect
                  onApplyActionChange={(selected: AppSelect[]) => {
                    !!selected && onSubcategoryAssignTopics(selected, subcategory.subcategory_id);
                  }}
                  options={topics}
                  placeholder="Assign topics"
                  noOptionsMessage="No Topics"
                />
              )}
            </AdminAccordion>
          );
        })}
    </ListWrapper>
  );
}

export default SubcategoriesManager;
