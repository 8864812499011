import { createElement } from "react";
import CreateChannelArticle from "@Views/HelpCenter/articles/CreateChannel";
import UploadVideoArticle from "@Views/HelpCenter/articles/UploadVideo";
import LiveStreamArticle from "@Views/HelpCenter/articles/LiveStream";
import ReportProblemArticle from "@Views/HelpCenter/articles/ReportProblem";
import VideoVisibilityArticle from "@Views/HelpCenter/articles/VideoVisibility";

export type ArticleType = {
  id: string;
  label: string;
  component: JSX.Element;
};

export const HELP_ARTICLES_LIST: ArticleType[] = [
  {
    id: "create-channel",
    label: "Create a Channel",
    component: createElement(CreateChannelArticle)
  },
  {
    id: "upload-video",
    label: "Upload video content",
    component: createElement(UploadVideoArticle)
  },
  {
    id: "live-stream",
    label: "Setup a Live Stream",
    component: createElement(LiveStreamArticle)
  },
  {
    id: "video-visibility",
    label: "Video visibility status",
    component: createElement(VideoVisibilityArticle)
  },
  {
    id: "report-problem",
    label: "Report a problem",
    component: createElement(ReportProblemArticle)
  }
];
