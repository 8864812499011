import { useState } from "react";
import { useSelector } from "react-redux";
import { getAppAuthStatus } from "@Store/selectors/app";
import { getAuthUser } from "@Store/selectors/user";
import { Modal } from "@Components";

import UploadVideos from "@Views/UploadVideos";
import StreamModal from "@Views/StreamModal";
import NewsContextMenu from "./Modals/NewsContextMenu";
import AuthModule, { AuthModal } from "@Components/AuthModule";
import { ProfileContextMenu } from "./Modals";

import "./style.scss";

export type ActiveModalTypes = "UPLOAD_VIDEOS" | "INIT_STREAM" | "";

function ProfileControls() {
  const isAuthenticated = useSelector(getAppAuthStatus);
  const userDetails = useSelector(getAuthUser);
  const [activeModal, setActiveModal] = useState<ActiveModalTypes>("");
  const [authModal, setAuthModal] = useState("" as AuthModal);

  const onCloseModal = () => {
    setActiveModal("");
  };

  const MODAL_TYPES = {
    UPLOAD_VIDEOS: <UploadVideos onSetActiveModal={setActiveModal} />,
    INIT_STREAM: <StreamModal onSetActiveModal={setActiveModal} />,
    "": null
  };

  return (
    <>
      {!isAuthenticated ? (
        <ul className="right-header-options">
          <li className="right-header-options-signin">
            <span className="action-link" onClick={() => setAuthModal("SIGN_IN")}>
              Sign in
            </span>
          </li>
          <li className="right-header-options-signup">
            <button className="main-button red tiny-button" onClick={() => setAuthModal("SIGN_UP")}>
              Sign up
            </button>
          </li>
          <li className="right-header-options-news">
            <NewsContextMenu />
          </li>
        </ul>
      ) : (
        <ul className="right-header-options logged-options">
          {userDetails.role !== "viewer" && (
            <>
              <li className="logged-options-item">
                <button
                  className="main-button tiny-button"
                  onClick={() => setActiveModal("INIT_STREAM")}
                >
                  <i className="icon-record" />
                  Go Live
                </button>
              </li>
              <li onClick={() => setActiveModal("UPLOAD_VIDEOS")} className="logged-options-item">
                <i className="icon-upload" />
              </li>
            </>
          )}
          <li className="logged-options-item">
            <NewsContextMenu />
          </li>
          <li className="visible">
            <ProfileContextMenu {...userDetails} />
          </li>
        </ul>
      )}

      {!!activeModal.length && <Modal onClose={onCloseModal}>{MODAL_TYPES[activeModal]}</Modal>}
      {!!authModal.length && <AuthModule modal={authModal} onModalClose={setAuthModal} />}
    </>
  );
}

export default ProfileControls;
