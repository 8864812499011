import ReactPaginate from "react-paginate";
import Select from "react-select";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { LoadingSpinner } from "@Components";
import { requestUserFollowingChannels } from "@Store/actions/user";
import { getAuthUser } from "@Store/selectors/user";
import EmptyFollowing from "./Empty";
import FollowingItem from "./FollowingItem";
import { getAppAuthStatus } from "@Store/selectors/app";
import "./style.scss";

type Props = {
  match: {
    url: string;
    path: string;
    params: { username: string; sectionId: string };
    isExact: boolean;
  };
};

type FollowingChannelTypes = {
  followers: number;
  image: string | null;
  is_follow: boolean;
  name: string;
  slug: string;
  videos: number;
  channel_id: number;
  description: string;
};

const defaultPageInfo = {
  totalResults: 0,
  resultsPerPage: 0,
  currentPage: 1,
  totalPages: 0
};

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

function UserFollowing({ match }: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);
  const isAuthenticated = useSelector(getAppAuthStatus);

  const [followingList, setFollowingList] = useState<FollowingChannelTypes[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [activePage, setActivePage] = useState(0);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    onChangeFollowingChannelsPage(1);
  }, [match.params.username]);

  const onChangeFollowingChannelsPage = (
    nextPage: number,
    itemsPerPage: number = itemsPerPageCount.value
  ) => {
    setIsPending(true);
    dispatch(
      requestUserFollowingChannels(match.params.username, nextPage, itemsPerPage, {
        onSuccess: ({ data, pageInfo }: { data: FollowingChannelTypes[]; pageInfo: any }) => {
          setActivePage(nextPage - 1);
          setFollowingList(data);
          setPageInfo(pageInfo);
          setIsPending(false);
        },
        onError: () => {}
      })
    );
  };

  const onChangeItemsPerPageCount = (item: any) => {
    onChangeFollowingChannelsPage(1, item.value);
    setItemsPerPageCount(item);
  };

  if (isPending) {
    return (
      <section className="user-following">
        <LoadingSpinner />
      </section>
    );
  }

  return (
    <section className="user-following">
      {followingList.length ? (
        <>
          <Row className="heading-table">
            <Col xs className="heading-option name-coll">
              Name
            </Col>
            <Col className="heading-option videos-coll">Videos</Col>
            <Col className="heading-option followers-coll">Followers</Col>
            {isAuthenticated && <Col className="heading-option action-coll">Action</Col>}
          </Row>
          <div className="following-list">
            {followingList.map((channel: FollowingChannelTypes) => (
              <FollowingItem
                key={channel.channel_id}
                {...channel}
                user_channel_id={Number(currentUser.channel_id)}
                isAuth={!!currentUser.username.length}
              />
            ))}
          </div>
        </>
      ) : (
        <EmptyFollowing />
      )}

      <Row className="app-pagination">
        {pageInfo.totalResults > 10 && (
          <Col className="items-per-page-col">
            <span>Show</span>
            <Select
              classNamePrefix="app-select"
              menuPlacement="auto"
              placeholder="Assign Category"
              isSearchable={false}
              value={itemsPerPageCount}
              onChange={onChangeItemsPerPageCount}
              options={ITEMS_PER_PAGE_COUNT_OPTIONS}
            />
          </Col>
        )}

        {pageInfo.totalPages > 10 && (
          <>
            <Col xs>
              <ReactPaginate
                forcePage={activePage}
                pageCount={pageInfo.totalPages}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) => onChangeFollowingChannelsPage(selected + 1)}
                activeClassName="active"
                pageClassName="page-number"
                containerClassName="pagination row"
                previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                nextLabel={<i className="icon icon-chevron-right nav-icon" />}
              />
            </Col>
          </>
        )}
      </Row>
    </section>
  );
}

export default UserFollowing;
