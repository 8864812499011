import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import {
  ADListItem,
  ADListResponse,
  ADMultiCounter,
  ADState,
  ADStatResponse
} from "@Models/AdminDashboard";
import DashboardWidget from "@AdminComponents/DashboardWidget";
import { requestAdminDashboardData } from "@Store/actions/admin";
import "./style.scss";

const emptyData = {
  counts: 0,
  rows: []
};
const defaultData = {
  registeredUsers: emptyData,
  channels: emptyData,
  videos: emptyData,
  views: emptyData,
  videosVisibility: {
    counts: {} as ADMultiCounter,
    rows: []
  },
  followedChannels: null,
  videosByViews: null,
  videosByLiked: null,
  videosByUnLiked: null
};

function AdminDashboard() {
  const dispatch = useDispatch();
  const [dashboardState, setDashboardState] = useState(defaultData as ADState);

  useEffect(() => {
    setTimeout(requestRegisteredUsers, 100);
    setTimeout(requestChannels, 200);
    setTimeout(requestFollowedChannels, 300);
    setTimeout(requestVideos, 400);
    setTimeout(requestVideosVisibility, 500);
    setTimeout(requestVideosByViews, 600);
    setTimeout(requestViews, 700);
    setTimeout(requestVideosByLiked, 800);
    setTimeout(requestVideosByUnLiked, 800);
  }, []);

  const requestRegisteredUsers = () => {
    dispatch(
      requestAdminDashboardData("registered", {
        onSuccess: (data: ADStatResponse) => {
          setDashboardState(prevState => ({
            ...prevState,
            registeredUsers: data
          }));
        }
      })
    );
  };

  const requestChannels = () => {
    dispatch(
      requestAdminDashboardData("channels", {
        onSuccess: (data: ADStatResponse) => {
          setDashboardState(prevState => ({
            ...prevState,
            channels: data
          }));
        }
      })
    );
  };

  const requestVideos = () => {
    dispatch(
      requestAdminDashboardData("videos", {
        onSuccess: (data: ADStatResponse) => {
          setDashboardState(prevState => ({
            ...prevState,
            videos: data
          }));
        }
      })
    );
  };

  const requestViews = () => {
    dispatch(
      requestAdminDashboardData("views", {
        onSuccess: (data: ADStatResponse) => {
          setDashboardState(prevState => ({
            ...prevState,
            views: data
          }));
        }
      })
    );
  };

  const requestFollowedChannels = (interval?: string) => {
    dispatch(
      requestAdminDashboardData(
        "followedChannels",
        {
          onSuccess: (data: ADListItem[]) => {
            setDashboardState(prevState => ({
              ...prevState,
              followedChannels: data
            }));
          }
        },
        interval || "1y"
      )
    );
  };

  const requestVideosVisibility = () => {
    dispatch(
      requestAdminDashboardData("videosVisibility", {
        onSuccess: (data: ADListResponse) => {
          setDashboardState(prevState => ({
            ...prevState,
            videosVisibility: data
          }));
        }
      })
    );
  };

  const requestVideosByViews = (interval?: string) => {
    dispatch(
      requestAdminDashboardData(
        "videosByViews",
        {
          onSuccess: (data: ADListItem[]) => {
            setDashboardState(prevState => ({
              ...prevState,
              videosByViews: data
            }));
          }
        },
        interval || "3m"
      )
    );
  };

  const requestVideosByLiked = (interval?: string) => {
    dispatch(
      requestAdminDashboardData(
        "videosByLiked",
        {
          onSuccess: (data: ADListItem[]) => {
            setDashboardState(prevState => ({
              ...prevState,
              videosByLiked: data
            }));
          }
        },
        interval || "1m"
      )
    );
  };
  const requestVideosByUnLiked = (interval?: string) => {
    dispatch(
      requestAdminDashboardData(
        "videosByUnLiked",
        {
          onSuccess: (data: ADListItem[]) => {
            setDashboardState(prevState => ({
              ...prevState,
              videosByUnLiked: data
            }));
          }
        },
        interval || "1m"
      )
    );
  };

  return (
    <section className="admin-dashboard">
      <Row>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Registered Users"
            type="registered"
            counterLabel="Total Users"
            counter={dashboardState.registeredUsers.counts}
            dataStats={dashboardState.registeredUsers.rows}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Channels"
            type="channels"
            counterLabel="Total Channels"
            counter={dashboardState.channels.counts}
            dataStats={dashboardState.channels.rows}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Top Followed Channels"
            type="views"
            defaultFilter="1y"
            listItemBaseUrl="/channel"
            omSetInterval={interval => {
              requestFollowedChannels(interval);
            }}
            dataList={dashboardState.followedChannels}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Videos"
            type="videos"
            counterLabel="Total Videos"
            counter={dashboardState.videos.counts}
            dataStats={dashboardState.videos.rows}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Videos Visibility"
            type="videos"
            counterLabel="Public Videos"
            multiCounter={dashboardState.videosVisibility.counts}
            dataStats={dashboardState.videosVisibility.rows}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Top Videos by Views"
            type="videosByViews"
            defaultFilter="3m"
            listItemBaseUrl="/video"
            omSetInterval={interval => {
              requestVideosByViews(interval);
            }}
            dataList={dashboardState.videosByViews}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Views"
            type="views"
            counterLabel="Total views"
            counter={dashboardState.views.counts}
            dataStats={dashboardState.views.rows}
          />
        </Col>
        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Most Liked Videos"
            type="videosByLiked"
            defaultFilter="1m"
            listItemBaseUrl="/video"
            omSetInterval={interval => {
              requestVideosByLiked(interval);
            }}
            dataList={dashboardState.videosByLiked}
          />
        </Col>

        <Col xs={4} className="widget-coll">
          <DashboardWidget
            title="Most Unliked Videos"
            type="videosByUnLiked"
            defaultFilter="1m"
            listItemBaseUrl="/video"
            omSetInterval={interval => {
              requestVideosByUnLiked(interval);
            }}
            dataList={dashboardState.videosByUnLiked}
          />
        </Col>
      </Row>
    </section>
  );
}

export default AdminDashboard;
