import classes from "classnames";
import { LiveChatMessage as LiveChatMessageModel } from "@Models/LiveChat";
import Avatar from "../Avatar";
import "./style.scss";

type Props = {
  item: LiveChatMessageModel;
};

const LiveChatMessage: React.FC<Props> = ({ item }) => {
  const { avatar, name, message, host } = item;

  return (
    <div
      className={classes("live-chat-message", {
        "live-chat-message__host": host
      })}
    >
      <Avatar item={{ avatar, name, host }} />
      {message}
    </div>
  );
};

export default LiveChatMessage;
