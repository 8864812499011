import { all, take, takeEvery, call, delay, fork, select, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { propEq } from "ramda";
import { API_URL } from "@Constants";
import authorizedApiRequest from "@Fetch/authorized";
import apiRequest, { fileUploadRequest } from "@Fetch";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import { Filters } from "@Models/Filters";
import {
  getAuthUserLoadedStreams,
  getAuthUserLoadedVideos,
  getStreamManagerDetails
} from "@Store/selectors/video";
import { getVideoLibraryCounts } from "@Store/selectors/channel";
import { updateChannelDetails } from "@Store/actions/channel";

import {
  VIDEO,
  VIDEO_SOCKET_RESPONSE,
  loadUserVideos,
  requestUserVideos,
  setNewVideoDetails,
  updateVideoDetails,
  updateVideoListDetails,
  loadUserStreams,
  setNewStreamDetails,
  updateStreamsListDetails,
  updateRTCStatus,
  createLiveStreamRoom,
  removeLiveStreamRoom,
  initLiveStreamRoom,
  emmitStatusPublicStreamRoom
} from "@Store/actions/video";
import { AppSelect } from "@Models/AppSelect";
import transformQueryParams from "@Utils/transformQueryParams";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

const youtubeResponseHasVideosCheck = (data: Object) => {
  const channels = Object.values(data);

  if (channels.length < 2 && channels.length > 0) {
    return !!Object.values(channels[0].video).length;
  }

  return true;
};

const formatYoutubePlaylistsResponse = (data: any) =>
  Object.keys(data).reduce((acc: any[], channelKey: string) => {
    const playlistVideos = Object.values(data[channelKey].video);

    if (playlistVideos.length) {
      return [
        ...acc,
        {
          ...data[channelKey],
          id: channelKey,
          video: playlistVideos
        }
      ];
    }

    return acc;
  }, []);

export function* getUserVideos({
  payload: { nextPage, itemsPerPage, searchText, filters },
  callbacks
}: {
  payload: { nextPage: number; itemsPerPage: number; searchText: string; filters: Filters };
  callbacks: RequestCallbacks;
}) {
  const query = transformQueryParams(
    filters && {
      ...(!!filters.category && { categories: filters.category.value }),
      ...(!!filters.subcategory && { subcategories: filters.subcategory.value }),
      ...(!!filters.country && { countries: filters.country.value }),
      ...(!!filters.sector && { sectors: filters.sector.value }),
      ...(!!filters.currency && { currencies: filters.currency.value }),
      ...(!!filters.visibility && { visibility: filters.visibility.value }),
      ...(!!filters.views && { views: filters.views.value }),
      ...(!!filters.likes && { likes: filters.likes.value }),
      ...(!!filters.dislikes && { dislikes: filters.dislikes.value }),
      ...(!!searchText && { q: searchText })
    }
  );

  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    !!filters
      ? `/streams/video?page=${nextPage}&limit=${itemsPerPage}&full=1${query ? "&" + query : ""}`
      : `/streams/video?page=${nextPage}&limit=${itemsPerPage}&full=1${
          !!searchText ? "&q=" + searchText : ""
        }`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    yield put(loadUserVideos(data, pageInfo));

    if (callbacks) {
      yield call(callbacks.onSuccess, { data, pageInfo });
    }
  }
}

export function* getUserStreams({
  payload: { nextPage, itemsPerPage },
  callbacks
}: {
  payload: { nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,

    `/streams/video/streams?page=${nextPage}&limit=${itemsPerPage}&full=1`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    yield put(loadUserStreams(data, pageInfo));

    if (callbacks) {
      yield call(callbacks.onSuccess, { data, pageInfo });
    }
  }
}

function* getVideoById({
  payload,
  callbacks
}: {
  payload: { video_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,

    `/streams/VideoPage/${payload.video_id}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    if (callbacks.onError) callbacks.onError(serverResponse);
  }
}

function* getStreamPreviewById({
  payload,
  callbacks
}: {
  payload: { video_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,

    `/streams/LivePreview/${payload.video_id}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError(serverResponse);
  }
}

function* getVideoDetailsById({
  payload: { video_id },
  callbacks
}: {
  payload: { video_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,

    `/streams/Video/${video_id}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* getChildrenDropdownData({
  payload: { selectedIds, type },
  callbacks
}: {
  payload: { selectedIds: number[]; type: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Children?subcategories=${selectedIds.join()}&type=${type}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* requestTopicCountries({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,

    `/streams/TopicCountries`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield call(callbacks.onSuccess, data);
  }
}

function* requestTopicSectors({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/TopicSectors`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield call(callbacks.onSuccess, data);
  }
}

function* requestTopicCurrencies({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/TopicCurrency`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield call(callbacks.onSuccess, data);
  }
}

function* getYoutubeVideos({
  payload: { youtubeLink },
  callbacks
}: {
  payload: { youtubeLink: string };
  callbacks: RequestCallbacks;
}) {
  let windowOpener = {} as Window | null;

  windowOpener = window.open(
    `${API_URL}/oauth/youtube`,
    "",
    "toolbar=0,status=0,width=626,height=436"
  );

  let checkWindowOpener = true;

  while (checkWindowOpener) {
    if (windowOpener && windowOpener.closed) {
      const serverResponse: ServerResponse = yield call(apiRequest, `/Youtube/GetMyVideos`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ youtube_url: youtubeLink })
      });

      if (serverResponse.status === 200) {
        const { data } = yield serverResponse.json();

        if (youtubeResponseHasVideosCheck(data)) {
          yield call(callbacks.onSuccess, formatYoutubePlaylistsResponse(data));
        } else {
          if (callbacks.onError) yield call(callbacks.onError);
        }
      } else {
        if (callbacks.onError) yield call(callbacks.onError, serverResponse);
      }

      checkWindowOpener = false;
    }

    yield delay(500);
  }
}

function* uploadYoutubeVideos({ payload: { list } }: { payload: { list: any } }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/import`, {
    method: "POST",
    body: JSON.stringify({ list })
  });

  const channelCounts = yield select(getVideoLibraryCounts);

  yield put(
    updateChannelDetails({
      counts: {
        ...channelCounts,
        videos: channelCounts.videos + list.length
      }
    })
  );

  if (serverResponse.status === 200) {
    const { message } = yield serverResponse.json();

    toast.success(message);
    // reset user videos pagination if uploading was successfull
    yield put(requestUserVideos(1));
  }
}

function* uploadLocalVideos({ payload: { list } }: { payload: { list: any } }) {
  const cancelRequestFuncInstances: any = {};

  for (const videoFileIndex in list) {
    yield fork(
      uploadLocalVideo,
      list[videoFileIndex],
      list.length,
      Number(videoFileIndex),
      (videoId: number) => (cancelFunc: Function) => {
        Object.defineProperty(cancelRequestFuncInstances, videoId, {
          value: cancelFunc
        });
      }
    );
  }

  yield takeEvery(VIDEO.CANCEL_UPLOAD, function* (action: { payload: { idsList: number[] } }) {
    for (const idIndex in action.payload.idsList) {
      if (cancelRequestFuncInstances[action.payload.idsList[idIndex]]) {
        yield call(cancelRequestFuncInstances[action.payload.idsList[idIndex]]);
      }
    }
  });
}

function* createVideoPlaceholder(fileName: string, onSuccess: (data: any) => void) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/video`, {
    method: "POST",
    body: JSON.stringify({ title: fileName })
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(onSuccess, data);
  }
}

function* createStreamPlaceholder({
  payload: { streamPayload },
  callbacks
}: {
  payload: any;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/video`, {
    method: "POST",
    body: JSON.stringify({ ...streamPayload, is_stream: true })
  });
  const channelCounts = yield select(getVideoLibraryCounts);

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(
      setNewStreamDetails({
        ...data,
        thumb: null
      })
    );

    yield put(
      updateChannelDetails({
        counts: {
          ...channelCounts,
          streams: channelCounts.streams + 1
        }
      })
    );
    yield put(createLiveStreamRoom(data.hash_id));
    callbacks.onSuccess(data);
  }
}

function* updateStreamPlaceholder({
  payload: { streamPayload, video_id },
  callbacks
}: {
  payload: any;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/video/${video_id}`,
    {
      method: "PUT",
      body: JSON.stringify({ ...streamPayload, is_stream: true })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(updateVideoDetails(streamPayload, video_id, "stream"));
    callbacks.onSuccess(data);
  }
}

function* uploadLocalVideo(
  data: any,
  totalCount: number,
  videoFileIndex: number,
  cancelRequest: Function
) {
  yield delay(1000 * videoFileIndex);

  yield call(createVideoPlaceholder, data.title, function* (placeholderData: any) {
    const channelCounts = yield select(getVideoLibraryCounts);

    yield put(setNewVideoDetails(placeholderData));
    yield put(
      updateChannelDetails({
        counts: {
          ...channelCounts,
          videos: channelCounts.videos + 1
        }
      })
    );

    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("count", String(totalCount));
    formData.append("video_id", String(placeholderData.video_id));

    const uploadToServerTask = yield call(
      fileUploadRequest,
      `/streams/upload`,
      formData,
      cancelRequest(placeholderData.video_id)
    );

    while (true) {
      const { loadProgress, error, success } = yield take(uploadToServerTask);

      if (loadProgress) {
        yield put(updateVideoDetails({ loadProgress }, placeholderData.video_id, "video"));
      }

      if (success) {
        sendGTMDataLayer({
          event: "Video Upload",
          eventCategory: "video upload",
          eventAction: "video uploaded",
          eventValue: 1,
          eventLabel: "video upload"
        });
        yield put(
          updateVideoDetails(
            { loadProgress: undefined, converter: { processCodeUpdate: 3 } },
            placeholderData.video_id,
            "video"
          )
        );
      }

      if (error) {
        yield put(
          updateVideoDetails(
            { loadProgress: undefined, is_valid: false },
            placeholderData.video_id,
            "video"
          )
        );

        toast.error(`${data.title} upload failed!`);
      }
    }
  });
}

function* updateServerVideoDetails({
  payload: { details, video_id },
  callbacks
}: {
  payload: { details: any; video_id: number };
  callbacks: RequestCallbacks;
}) {
  const { thumbPreview, ...videoDetails } = details;

  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/Streams/Video/${video_id}`,
    {
      method: "PUT",
      body: JSON.stringify(videoDetails)
    }
  );

  if (serverResponse.status === 200) {
    if (thumbPreview) {
      yield put(updateVideoDetails({ ...videoDetails, thumb: thumbPreview }, video_id, "video"));
    } else {
      toast.success("Video updated");

      yield put(updateVideoDetails(videoDetails, video_id, "video"));
    }

    yield call(callbacks.onSuccess);
  }
}

function* uploadVideoThumbnail({
  payload: { file, video_id, typeVideo },
  callbacks
}: {
  payload: { file: File; video_id: number; typeVideo: "stream" | "video" };
  callbacks: RequestCallbacks;
}) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("video_id", String(video_id));

  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/Streams/upload`, {
    method: "POST",
    body: formData
  });

  if (serverResponse.status === 200) {
    yield put(
      updateVideoDetails(
        {
          thumb: URL.createObjectURL(file)
        },
        video_id,
        typeVideo
      )
    );

    yield call(callbacks.onSuccess);
  }
}

function* deleteVideosListSaga({
  payload: { idsList, isStream },
  callbacks
}: {
  payload: { idsList: number[]; isStream: boolean };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/Streams/Video`, {
    method: "DELETE",
    body: JSON.stringify({ list: idsList })
  });

  if (serverResponse.status === 200) {
    const channelCounts = yield select(getVideoLibraryCounts);

    yield call(callbacks.onSuccess);

    yield put(
      updateChannelDetails({
        counts: isStream
          ? { ...channelCounts, streams: channelCounts.streams - idsList.length }
          : {
              ...channelCounts,
              videos: channelCounts.videos - idsList.length
            }
      })
    );
    if (isStream) {
      const streams = yield select(getAuthUserLoadedStreams);

      yield all(
        idsList.map((id: number) => {
          const stream = streams.filter((stream: any) => stream.video_id === id)[0];
          return put(removeLiveStreamRoom(stream.hash_id));
        })
      );
    }
  }
}

function* syncVideoDetails({ payload }: { payload: any }) {
  const ids_list = Object.keys(payload);

  if (ids_list.length) {
    const userVideos = yield select(getAuthUserLoadedVideos);

    for (const idIndex in ids_list) {
      if (payload[ids_list[idIndex]].videoUpdated) {
        if (userVideos.find(propEq("video_id", Number(ids_list[idIndex])))) {
          yield call(getVideoDetailsById, {
            payload: { video_id: Number(ids_list[idIndex]) },
            callbacks: {
              onSuccess: function* (data: any) {
                yield put(updateVideoDetails(data, Number(ids_list[idIndex]), "video"));
              },
              onError: () => {}
            }
          });
        }
      }
    }
  }
}

function* syncStreamPreviewDetails({ payload }: { payload: any }) {
  if (payload.message) {
    const managerDetails = yield select(getStreamManagerDetails);

    switch (payload.message.event) {
      case "start": {
        if (payload.message.name === managerDetails.activeStreamKey) {
          yield put(updateRTCStatus(true));
        }
        break;
      }
      case "stop": {
        if (payload.message.name === managerDetails.activeStreamKey) {
          yield put(updateRTCStatus(false));
        }
      }
    }
  }
}

function* syncPublicLiveUpdates({ payload }: { payload: any }) {
  !!payload.onUpdate && payload.onUpdate(payload);
}

function* changeVideoLikeStatus({
  payload: { next_status, video_id },
  callbacks
}: {
  payload: { next_status: string; video_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/LikeDislike/${video_id}`,
    {
      method: "PUT",
      body: JSON.stringify({ type: next_status })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* changeVideosCategoryData({
  payload: { categoriesManager, ids_list, isStream }
}: {
  payload: {
    categoriesManager: { [key: string]: number[] };
    ids_list: number[];
    isStream: boolean;
  };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/video`, {
    method: "PUT",
    body: JSON.stringify({
      list: ids_list,
      category_id: categoriesManager.category ? categoriesManager.category[0] : 0,
      subcategories: categoriesManager.subcategories ? categoriesManager.subcategories : [],
      countries: categoriesManager.country ? categoriesManager.country : [],
      sectors: categoriesManager.sector ? categoriesManager.sector : [],
      currency: categoriesManager.currency ? categoriesManager.currency : []
    })
  });

  if (serverResponse.status === 200) {
    yield put(
      isStream
        ? updateStreamsListDetails({ category_id: categoriesManager.category[0] }, ids_list)
        : updateVideoListDetails({ category_id: categoriesManager.category[0] }, ids_list)
    );
  }
}

function* changeVideosVisibility({
  payload: { status_id, ids_list, isStream }
}: {
  payload: { status_id: number; ids_list: number[]; isStream: boolean };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/video`, {
    method: "PUT",
    body: JSON.stringify({
      list: ids_list,
      status: status_id
    })
  });

  if (serverResponse.status === 200) {
    yield put(
      isStream
        ? updateStreamsListDetails({ status: status_id }, ids_list)
        : updateVideoListDetails({ status: status_id }, ids_list)
    );
  }
}

function* requestStartLiveStream({
  payload: { video_id, hash_id, channel_id, streamer_id, streamKey, liveType }
}: {
  payload: {
    video_id: number;
    hash_id: string;
    channel_id: number;
    streamer_id: number;
    streamKey: string;
    liveType: number;
  };
}) {
  const serverResponse = yield call(authorizedApiRequest, `/streams/StartLive/${video_id}`, {
    method: "PUT"
  });

  if (serverResponse.status === 200) {
    yield put(
      initLiveStreamRoom({ channel_id, streamer_id, hash_id, video_id, streamKey, liveType })
    );
    yield put(emmitStatusPublicStreamRoom({ status: "started", hash_id }, hash_id));
  }
}

function* requestStopLiveStream({
  payload: { video_id, hash_id }
}: {
  payload: { video_id: number; hash_id: string };
}) {
  const streamManager: any = yield select(getStreamManagerDetails);
  const serverResponse = yield call(authorizedApiRequest, `/streams/StopLive/${video_id}`, {
    method: "PUT",
    body: JSON.stringify({ storeVideo: streamManager.saveStream })
  });

  if (serverResponse.status === 200) {
    yield put(emmitStatusPublicStreamRoom({ status: "stopped", hash_id }, hash_id));
    yield put(removeLiveStreamRoom(hash_id));
  }
}

function* changeVideosPlaylist({
  payload: { playlist_id, ids_list }
}: {
  payload: { playlist_id: number; ids_list: number[] };
}) {
  yield call(authorizedApiRequest, `/streams/playlist/${playlist_id}`, {
    method: "PUT",
    body: JSON.stringify({
      list: ids_list
    })
  });
}

function* categoryRequestVideos({
  payload: { category, subcategory, tag, appliedFilters, page },
  callbacks
}: {
  payload: {
    category?: string;
    subcategory?: string;
    tag?: string;
    appliedFilters?: any;
    page: number;
  };
  callbacks: RequestCallbacks;
}) {
  const query = transformQueryParams(
    appliedFilters && {
      ...(!!appliedFilters.countries && { countries: appliedFilters.countries }),
      ...(!!appliedFilters.sectors && { sectors: appliedFilters.sectors }),
      ...(!!appliedFilters.currency && { currency: appliedFilters.currency }),
      ...(!!appliedFilters.interval && { interval: appliedFilters.interval }),
      ...(!!appliedFilters.sortby && { sortby: appliedFilters.sortby })
    }
  );

  let serverResponse: ServerResponse | undefined;
  if (category) {
    const subcategoryQuery = !subcategory ? "" : `subcategory=${subcategory}&`;
    serverResponse =
      category === "streams"
        ? yield call(authorizedApiRequest, `/streams/NowOnAir`, {
            method: "GET"
          })
        : yield call(
            authorizedApiRequest,
            `/streams/CategoryVideos/${category}?${subcategoryQuery}page=${page}${
              !!query ? "&" + query : ""
            }`,
            {
              method: "GET"
            }
          );
  } else if (tag) {
    serverResponse = yield call(authorizedApiRequest, `/streams/TagVideos/${tag}?page=${page}`, {
      method: "GET"
    });
  }

  if (serverResponse) {
    if (serverResponse.status === 200) {
      const { data, filters, pageInfo } = yield serverResponse.json();
      yield call(callbacks.onSuccess, { data, filters, pageInfo });
    } else {
      if (callbacks.onError) yield call(callbacks.onError);
    }
  }
}

function* searchVideoTagsByQuery({
  payload: { query },
  callbacks
}: {
  payload: { query: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/search?tag=${query}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError, serverResponse);
  }
}

function* getStreamPreviewSource({
  payload: { rtcAPI, data },
  callbacks
}: {
  payload: { rtcAPI: string; data: any };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    apiRequest,
    rtcAPI,
    {
      method: "POST",
      body: JSON.stringify(data)
    },
    true
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();
    yield call(callbacks.onSuccess, data);
  }
}

export default function* videoSagaWatcher() {
  yield all([
    takeEvery(VIDEO.GET_BY_ID, getVideoById),
    takeEvery(VIDEO.GET_STREAM_BY_ID, getStreamPreviewById),
    takeEvery(VIDEO.GET_STREAM_PREVIEW_SOURCE, getStreamPreviewSource),
    takeEvery(VIDEO.GET_DETAILS, getVideoDetailsById),
    takeEvery(VIDEO.GET_CHILDREN_DROPDOWN, getChildrenDropdownData),
    takeEvery(VIDEO.YOUTUBE_UPLOAD, uploadYoutubeVideos),
    takeEvery(VIDEO.LOCAL_UPLOAD, uploadLocalVideos),
    takeEvery(VIDEO.DELETE_VIDEOS_LIST, deleteVideosListSaga),
    takeEvery(VIDEO.REQUEST_USER_VIDEOS, getUserVideos),
    takeEvery(VIDEO.REQUEST_USER_STREAMS, getUserStreams),
    takeEvery(VIDEO.THUMBNAIL_UPLOAD, uploadVideoThumbnail),
    takeEvery(VIDEO.REQUEST_YOUTUBE_PLAYLISTS, getYoutubeVideos),
    takeEvery(VIDEO.REQUEST_UPDATE_DETAILS, updateServerVideoDetails),
    takeEvery(VIDEO.REQUEST_LIKE_STATUS_CHANGE, changeVideoLikeStatus),
    takeEvery(VIDEO.REQUEST_UPDATE_VISIBILITY, changeVideosVisibility),
    takeEvery(VIDEO.REQUEST_UPDATE_CATEGORY, changeVideosCategoryData),
    takeEvery(VIDEO.REQUEST_UPDATE_PLAYLIST, changeVideosPlaylist),
    takeEvery(VIDEO.REQUEST_CATEGORY_VIDEOS, categoryRequestVideos),
    takeEvery(VIDEO.SEARCH_TAGS_BY_QUERY, searchVideoTagsByQuery),
    takeEvery(VIDEO.CREATE_STREAM, createStreamPlaceholder),
    takeEvery(VIDEO.UPDATE_STREAM, updateStreamPlaceholder),
    takeEvery(VIDEO.REQUEST_START_LIVE, requestStartLiveStream),
    takeEvery(VIDEO.REQUEST_STOP_LIVE, requestStopLiveStream),
    takeEvery(VIDEO.GET_COUNTRIES, requestTopicCountries),
    takeEvery(VIDEO.GET_SECTORS, requestTopicSectors),
    takeEvery(VIDEO.GET_CURRENCIES, requestTopicCurrencies),
    takeEvery(VIDEO_SOCKET_RESPONSE.GET_CONVERTER_UPDATES, syncVideoDetails),
    takeEvery(VIDEO_SOCKET_RESPONSE.GET_RTC_UPDATES, syncStreamPreviewDetails),
    takeEvery(VIDEO_SOCKET_RESPONSE.GET_SUBSCRIBE_PUBLIC_LIVE_UPDATES, syncPublicLiveUpdates)
  ]);
}
