import { UploadScreen3 } from "@Assets";
import "./style.scss";

function VideoVisibilityArticle() {
  return (
    <article className="help-article visibility-article">
      <h2 className="article-title">How to change video visibility in ShareVision?</h2>
      <p className="big-topic">
        When you upload content or Go Live you can select from a range of different visibilities:
      </p>
      <ul className="top-more">
        <li>
          <b>Public</b> : Available to all public to see.
        </li>
        <li>
          <b>Unlisted</b> : Available to anyone that has the link.
        </li>
        <li>
          <b>Exclusive</b> : Available to those who pay for a subscription to your channel
        </li>
        <li>
          <b>Private</b> : Available only to you.
        </li>
      </ul>
      <img src={UploadScreen3} alt="upload-3" className="top-more" />
      <p className="description">
        If you need further support, you can send an email to{" "}
        <a href="mailto:support@sharevision.com">support@sharevision.com</a> where someone will
        assist you.
      </p>
    </article>
  );
}

export default VideoVisibilityArticle;
