import ReactPaginate from "react-paginate";
import Select from "react-select";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Follower } from "@Models/Channel";
import { getAvatar } from "@Utils";
import { LoadingSpinner } from "@Components";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { requestChannelFollowers } from "@Store/actions/channel";
import EmptyFollowers from "./Empty";
import "./style.scss";

type Props = {
  match: { params: { channel_slug: string } };
};

const defaultPageInfo = {
  totalResults: 0,
  resultsPerPage: 0,
  currentPage: 1,
  totalPages: 0
};

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

function ChannelFollowers({ match }: Props) {
  const dispatch = useDispatch();

  const [followersList, setFollowersList] = useState<Follower[]>([]);

  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [activePage, setActivePage] = useState(0);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    onChangeFollowersPage(1);
  }, [match.params.channel_slug]);

  const onChangeFollowersPage = useCallback(
    (nextPage: number, itemsPerPage: number = itemsPerPageCount.value) => {
      setIsPending(true);

      dispatch(
        requestChannelFollowers(match.params.channel_slug, nextPage, itemsPerPage, {
          onSuccess: ({ data, pageInfo }: { data: Follower[]; pageInfo: any }) => {
            setActivePage(nextPage - 1);
            setFollowersList(data);
            setPageInfo(pageInfo);
            setIsPending(false);
          },
          onError: () => {}
        })
      );
    },
    [itemsPerPageCount.value]
  );

  const onChangeItemsPerPageCount = (item: any) => {
    onChangeFollowersPage(1, item.value);
    setItemsPerPageCount(item);
  };

  if (isPending) {
    return (
      <section className="channel-followers">
        <LoadingSpinner />
      </section>
    );
  }

  return (
    <section className="channel-followers">
      {!!followersList.length ? (
        <>
          <Row className="heading-table">
            <Col xs className="heading-option name-coll">
              Name
            </Col>
            <Col className="heading-option duration-coll">Duration</Col>
            <Col className="heading-option date-coll">Date</Col>
          </Row>
          <div className="follower-list">
            {followersList.map((follower: Follower) => {
              const followerAvatarAlt = getAvatar(follower.full_name);

              return (
                <Row className="follower-item" key={follower.username}>
                  <Col xs className=" name-coll">
                    <div className="user-item">
                      <Link to={`/streamer/${follower.username}`} className="link-to-streamer">
                        {follower.avatar ? (
                          <div
                            className="avatar"
                            style={{
                              backgroundImage: `url(${follower.avatar.replace("[SIZE]", "32x32")})`
                            }}
                          />
                        ) : (
                          <div
                            className="avatar"
                            style={{
                              backgroundColor: followerAvatarAlt.color
                            }}
                          >
                            <span className="name-letters">{followerAvatarAlt.shortLetters}</span>
                          </div>
                        )}

                        <span>{follower.full_name}</span>
                      </Link>
                    </div>
                  </Col>
                  <Col className="duration-coll">
                    {formatDistance(new Date(), follower.created_at * 1000)}
                  </Col>
                  <Col className="date-coll">
                    {format(follower.created_at * 1000, "MMM d, yyyy")}
                  </Col>
                </Row>
              );
            })}
          </div>
          <Row className="app-pagination" between="xs">
            {pageInfo.totalResults > 10 && (
              <Col className="items-per-page-col">
                <span>Show</span>
                <Select
                  classNamePrefix="app-select"
                  menuPlacement="auto"
                  placeholder="Assign Category"
                  isSearchable={false}
                  value={itemsPerPageCount}
                  onChange={onChangeItemsPerPageCount}
                  options={ITEMS_PER_PAGE_COUNT_OPTIONS}
                />
              </Col>
            )}

            {pageInfo.totalPages > 1 && (
              <>
                <Col>
                  <ReactPaginate
                    forcePage={activePage}
                    pageCount={pageInfo.totalPages}
                    pageRangeDisplayed={6}
                    marginPagesDisplayed={1}
                    onPageChange={({ selected }) => onChangeFollowersPage(selected + 1)}
                    activeClassName="active"
                    pageClassName="page-number"
                    containerClassName="pagination row"
                    previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                    nextLabel={<i className="icon icon-chevron-right nav-icon" />}
                  />
                </Col>
              </>
            )}
          </Row>
        </>
      ) : (
        <EmptyFollowers />
      )}
    </section>
  );
}

export default ChannelFollowers;
