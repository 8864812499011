import { useState } from "react";
import ListWrapper from "../ListWrapper";
import { SubcategoryListItem } from "@Models/SubcategoryListItem";
import { Subcategory } from "@Models/Subcategory";
import AdminAccordion from "@AdminComponents/AdminAccordion";
import SelectedItem from "@AdminComponents/SelectedItem";

type SubcategoriesListProps = {
  allLists: SubcategoryListItem[];
  subcategories: Subcategory[];
  onAddSubcategoriesList: (addedItem: string, listItems: number[]) => void;
  onDeleteSubcategoriesList: (deleteItemId: number) => void;
  onUpdateSubcategoriesList: (listId: number, listName: string, idsList: number[]) => void;
  onRemoveSubcategoryFromList: (id: number, list_id: number) => void;
};

function SubcategoriesListsManager({
  allLists,
  subcategories,
  onAddSubcategoriesList,
  onDeleteSubcategoriesList,
  onUpdateSubcategoriesList,
  onRemoveSubcategoryFromList
}: SubcategoriesListProps) {
  const [editMode, setEditMode] = useState(false);

  const onEditModeToggle = (editState: boolean) => {
    setEditMode(editState);
  };

  return (
    <ListWrapper
      listTitle="Subcategories Lists"
      actionText="Add Subcategory list"
      actionLabel="List name"
      actionButtonLabel="Create new list"
      onEditModeToggle={onEditModeToggle}
      onAddItemList={onAddSubcategoriesList}
      listChooser={subcategories.map(subcategory => ({
        label: subcategory.name,
        value: subcategory.subcategory_id
      }))}
    >
      {!!allLists.length &&
        allLists.map((subcategoriesListItem, index) => (
          <AdminAccordion
            key={index}
            isDeletable
            itemId={subcategoriesListItem.list_id}
            onDeleteItem={onDeleteSubcategoriesList}
            isEditMode={editMode}
            title={subcategoriesListItem.name}
          >
            <SelectedItem
              multipleView
              title="Subcategories"
              onUpdateList={idsList => {
                onUpdateSubcategoriesList(
                  subcategoriesListItem.list_id,
                  subcategoriesListItem.name,
                  idsList
                );
              }}
              allEditOptions={subcategories.map(subcategory => ({
                label: subcategory.name,
                value: subcategory.subcategory_id
              }))}
              selectedOptions={subcategoriesListItem.children.map(i => ({
                value: i.subcategory_id,
                label: i.name
              }))}
              onRemoveItem={(id: number) => {
                subcategoriesListItem.children.length <= 1
                  ? onDeleteSubcategoriesList(subcategoriesListItem.list_id)
                  : onRemoveSubcategoryFromList(id, subcategoriesListItem.list_id);
              }}
            />
          </AdminAccordion>
        ))}
    </ListWrapper>
  );
}

export default SubcategoriesListsManager;
