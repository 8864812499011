import { CATEGORY_CHART_COLORS } from "@Constants";

const getCategoryChartColor = (text: any) => {
  return CATEGORY_CHART_COLORS[
    [...text].reduce((value, currentValue, index) => (value += text.charCodeAt(index)), 0) %
      CATEGORY_CHART_COLORS.length
  ];
};

export default getCategoryChartColor;
