import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "ramda";
import { requestAdminCategories, requestAdminSubcategories } from "@Store/actions/admin";
import {
  requestTopicCountries,
  requestTopicSectors,
  requestTopicCurrencies
} from "@Store/actions/video";
import { Category } from "@Models/Category";
import { Subcategory } from "@Models/Subcategory";
import { Country } from "@Models/Country";
import { Sector } from "@Models/Sector";
import { Currency } from "@Models/Currency";
import { AppSelect } from "@Models/AppSelect";
import { FilterComponent } from "@Models/FilterComponent";
import { LoadingSpinner } from "@Components";
import { VIDEO_STATUS_OPTIONS } from "@Constants";
import "./style.scss";

type Props = {
  filter: FilterComponent;
  onCancel: () => void;
  onSelectedItem: (selectedItem: FilterComponent) => void;
};
const FilterCheckDropdown = ({ filter, onCancel, onSelectedItem }: Props) => {
  const dispatch = useDispatch();
  const [dropdownData, setDropdownData] = useState<AppSelect[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<AppSelect[]>([]);

  useEffect(() => {
    setDropdownData([]);
    switch (filter.id) {
      case "category": {
        dispatch(
          requestAdminCategories(
            {
              onSuccess: (data: Category[]) => {
                setDropdownData(data.map(i => ({ label: i.name, value: i.category_id })));
              }
            },
            false
          )
        );
        break;
      }
      case "subcategory": {
        dispatch(
          requestAdminSubcategories(
            {
              onSuccess: (data: Subcategory[]) => {
                setDropdownData(data.map(i => ({ label: i.name, value: i.subcategory_id })));
              }
            },
            false
          )
        );
        break;
      }
      case "country": {
        dispatch(
          requestTopicCountries({
            onSuccess: (data: Country[]) => {
              setDropdownData(data.map(i => ({ label: i.name, value: i.country_id })));
            }
          })
        );
        break;
      }
      case "sector": {
        dispatch(
          requestTopicSectors({
            onSuccess: (data: Sector[]) => {
              setDropdownData(data.map(i => ({ label: i.name, value: i.sector_id })));
            }
          })
        );
        break;
      }
      case "currency": {
        dispatch(
          requestTopicCurrencies({
            onSuccess: (data: Currency[]) => {
              setDropdownData(data.map(i => ({ label: i.name, value: i.currency_id })));
            }
          })
        );
        break;
      }
      case "visibility": {
        setDropdownData(
          Object.values(VIDEO_STATUS_OPTIONS).map(i => ({ label: i.label, value: i.value }))
        );
      }
    }
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(filter) && filter.value && dropdownData.length > 0) {
      const clonedDropDownData = [...dropdownData];

      setSelectedStatuses(
        clonedDropDownData.filter(item => {
          return filter.value!.some(o => item.value === o);
        })
      );
    }
  }, [filter, dropdownData]);

  const isSelected = (item: AppSelect) =>
    !!selectedStatuses?.find(checked => checked?.value === item?.value);

  return (
    <div className="dropdown-filter checkbox-filter">
      <div className="selected-item">
        {filter.label} <i className="icon-close" onClick={onCancel} />
      </div>
      <ul className="dropdown-list">
        {!!dropdownData.length ? (
          dropdownData.map((item, index) => (
            <li className="option" key={index}>
              <input
                type="checkbox"
                className="app-label"
                id={`option-${item.value}`}
                checked={!!isSelected(item)}
                onChange={() => {}}
              />
              <label
                className="label-text"
                onClick={() => {
                  const alreadyChecked = selectedStatuses.find(
                    (checked: AppSelect) => checked?.value === item?.value
                  );

                  if (alreadyChecked) {
                    setSelectedStatuses([
                      ...selectedStatuses.filter((checked: AppSelect) => checked !== alreadyChecked)
                    ]);
                  } else {
                    setSelectedStatuses((prevState: AppSelect[]) => [...prevState, item]);
                  }
                }}
              >
                {item.label}
              </label>
            </li>
          ))
        ) : (
          <LoadingSpinner />
        )}
      </ul>
      <button
        className="main-button apply-button"
        disabled={isEmpty(selectedStatuses)}
        onClick={() => {
          const { id, label, componentType } = filter;
          let value = selectedStatuses.map(item => item.value);
          let name = "";

          if (selectedStatuses.length === 1) {
            name = `: ${selectedStatuses[0].label}`;
          } else {
            name = `: ${selectedStatuses.length} Items`;
          }

          onSelectedItem({ id, componentType, label, value, name });
          onCancel();
        }}
      >
        Apply
      </button>
    </div>
  );
};

export default FilterCheckDropdown;
