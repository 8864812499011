import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";
import { FormEvent, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { ChangePassword } from "@Models/ChangePassword";
import { ConfirmModal } from "@Components";
import { useDebounce, useForm } from "@Hooks";
import { getAuthUser } from "@Store/selectors/user";
import { User } from "@Models/User";

import {
  changePassword,
  checkProfileSlug,
  deleteAccount,
  updateProfileInfo
} from "@Store/actions/user";
import CreateChannelModal from "@Views/CreateChannelModal";
import "./style.scss";

const defaultFormState = {
  oldPassword: "",
  password: "",
  password2: ""
};

const defaultProfileFormState = {
  email: "",
  full_name: "",
  username: "",
  email_confirmed: "",
  about: ""
};

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails: User = useSelector(getAuthUser);
  const errorDebounce = useRef<any>();

  const [profileData, setProfileData, _, setInitialProfile] = useForm(defaultProfileFormState);
  const [isPending, setPending] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [saveActions, setSaveActions] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState(-1);
  const [formData, setFormData] = useForm(defaultFormState as ChangePassword);
  const debouncedUsername = useDebounce(profileData.username, 1000);
  const [descriptionRows, setDescriptionRows] = useState(10);
  const [createChannelModal, setCreateChannelModal] = useState(false);

  const handleOnDescriptionFocus = () => {
    setDescriptionRows(500);
  };

  const handleOnDescriptionBlur = () => {
    setDescriptionRows(10);
  };

  useEffect(() => {
    setInitialProfile(userDetails);
    userDetails.role != "creator" &&
      setCreateChannelModal(history.location.search === "?createChannel=ok");
  }, [userDetails]);

  const validateUsernameInput = (event: any) => {
    setUsernameStatus(-1);
    clearTimeout(errorDebounce.current);
    errorDebounce.current = setTimeout(() => {
      if (event.getModifierState("CapsLock")) {
        toast.error(`Caps Lock is "on"`);
      }
    }, 600);
  };

  const onUsernameChange = (event: any) => {
    if (event.target.value.match(/^([a-z0-9._]*)$/)) {
      setProfileData(event);
    }
  };

  useEffect(() => {
    if (debouncedUsername !== "") {
      if (debouncedUsername !== userDetails.username) {
        dispatch(
          checkProfileSlug(debouncedUsername, {
            onSuccess: () => {
              setUsernameStatus(1);
            },
            onError: () => {
              setUsernameStatus(2);
            }
          })
        );
      } else {
        setUsernameStatus(1);
      }
    } else {
      setUsernameStatus(-1);
    }
  }, [debouncedUsername]);

  useEffect(() => {
    JSON.stringify(profileData) !== JSON.stringify(defaultProfileFormState) &&
      setSaveActions(JSON.stringify(profileData) !== JSON.stringify(userDetails));
  }, [JSON.stringify(profileData)]);

  const onDeleteAccount = () => {
    setPending(true);
    dispatch(
      deleteAccount({
        onSuccess: () => {
          setShowConfirmModal(false);
          setPending(true);
        },
        onError: () => {
          setPending(false);
        }
      })
    );
  };

  const onChangePassword = (event: FormEvent) => {
    event.preventDefault();
    setPending(true);

    dispatch(
      changePassword(formData, {
        onSuccess: () => {
          setPending(false);
        },
        onError: () => {
          setPending(false);
        }
      })
    );
  };

  const onCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const onSaveChanges = () => {
    const { channel_slug, ...formData } = profileData;
    dispatch(
      updateProfileInfo(
        {
          about: formData.about,
          username: formData.username,
          email: formData.email,
          full_name: formData.full_name
        },
        userDetails.username,
        {
          onSuccess: () => {
            setSaveActions(false);
            toast.success("Profile settings updated!");
          },
          onError: () => null
        }
      )
    );
  };

  return (
    <section className="profile-settings">
      <Row className="section-heading">
        <Col xs={12} sm={12} md={6}>
          <h1 className="main-heading">Profile Settings</h1>
        </Col>
        <Col xs={12} sm={12} md={6}>
          {saveActions && (
            <div className="save-options">
              <span
                className="action-link"
                onClick={() => {
                  setInitialProfile(userDetails);
                }}
              >
                Undo changes
              </span>
              <button
                className="main-button"
                type="button"
                onClick={onSaveChanges}
                disabled={usernameStatus === -1 || usernameStatus === 2}
              >
                Save changes
              </button>
            </div>
          )}
        </Col>
      </Row>

      <form className="profile-info">
        <Row>
          <Col xs={12} sm={12} md={6} className="user-basics">
            <label htmlFor="full-name">Full name</label>
            <input
              type="text"
              className="main-input"
              placeholder="Your full name"
              name="full_name"
              value={profileData.full_name}
              onChange={setProfileData}
              id="full-name"
            />
            <label htmlFor="username">Username</label>
            <div className="username-slug">
              <input
                type="text"
                className="main-input"
                value={profileData.username}
                placeholder="Unique username"
                autoComplete={"off"}
                onChange={onUsernameChange}
                onKeyUp={validateUsernameInput}
                name="username"
                id="username"
              />
              {usernameStatus !== -1 && (
                <i
                  className={
                    usernameStatus === 2
                      ? "icon-close-rounded slug-status error"
                      : "icon-check-mark slug-status"
                  }
                />
              )}
            </div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="main-input"
              value={profileData.email}
              onChange={setProfileData}
              readOnly
              placeholder="example@gmail.com"
              name="email"
              id="email"
            />
          </Col>
          <Col sm={6}>
            <label htmlFor="about">About you</label>

            <div className="auto-size-description">
              <TextareaAutosize
                className="main-input input-description"
                placeholder="Short bio"
                name="about"
                onFocus={handleOnDescriptionFocus}
                onBlur={handleOnDescriptionBlur}
                value={profileData.about}
                maxRows={descriptionRows}
                minRows={10}
                onChange={setProfileData}
                id="about"
              />
            </div>
          </Col>
        </Row>
      </form>
      <div className="container-primary">
        <Row>
          <Col>
            <div className="heading">Change password</div>
          </Col>
          <Col xs={12} sm={12} md>
            <p className="description">
              Password should be at least 8 symbols, containing letters, numbers and uppercase
            </p>
          </Col>
        </Row>
        <form className="reset-password-form" onSubmit={onChangePassword}>
          <Row>
            <Col sm={4}>
              <input
                type="password"
                className="main-input"
                placeholder="Old password"
                value={formData.oldPassword}
                name="oldPassword"
                required
                onChange={setFormData}
              />
            </Col>
            <Col sm={4}>
              <input
                type="password"
                className="main-input"
                placeholder="New password"
                required
                value={formData.password}
                name="password"
                onChange={setFormData}
              />
            </Col>
            <Col sm={4}>
              <input
                type="password"
                className="main-input"
                placeholder="Repeat new password"
                required
                value={formData.password2}
                name="password2"
                onChange={setFormData}
              />
            </Col>
          </Row>
          <button type="submit" className="main-button white" disabled={isPending}>
            Change password
          </button>
        </form>
      </div>
      <div className="container-primary delete-account">
        <span
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          <i className="icon-remove" />
          <span>Completely delete your profile</span>
        </span>
      </div>
      {showConfirmModal && (
        <ConfirmModal
          bodyText="Are you sure you want to delete your account?"
          isPending={isPending}
          onConfirm={onDeleteAccount}
          onClose={onCloseConfirmModal}
        />
      )}
      {createChannelModal && <CreateChannelModal setCreateChannelPopup={setCreateChannelModal} />}
    </section>
  );
};

export default ProfileSettings;
