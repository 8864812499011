import { useEffect, useState } from "react";
import { convertToTimeDuration } from "@Utils";

let liveTimerInterval: any;

type LiveTimerProps = {
  watchersCount: number;
  liveType: number;
  livePublishStart: number;
};

function LiveTimer({ watchersCount, liveType, livePublishStart = 0 }: LiveTimerProps) {
  const [liveTimer, setLiveTimer] = useState("00:00");

  useEffect(() => {
    const publishDate: any = livePublishStart * 1000;
    liveType !== 2 && startLiveTimer(publishDate);
    return () => {
      clearInterval(liveTimerInterval);
    };
  }, []);

  const startLiveTimer = (publishDate: number) => {
    liveTimerInterval = setInterval(() => {
      const currentDate: any = new Date().getTime();
      setLiveTimer(convertToTimeDuration(Math.floor((currentDate - publishDate) / 1000)));
    }, 1000);
  };

  return (
    <div className="live-label-container">
      <button className="live-btn action-info">
        <i className="icon-record" />
        Live
        {liveType !== 2 && <span className="duration">{liveTimer}</span>}
      </button>
      <span className="watchers-count">
        <i className="icon-eye-look" />
        {watchersCount - 5 > 0 ? watchersCount - 5 : 0}
      </span>
    </div>
  );
}

export default LiveTimer;
