import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { TopVideo } from "@Models/HomeData";
import { convertToTimeDuration, getAvatar, padNumber } from "@Utils";
import { Link } from "react-router-dom";
import abbreviateNumber from "@Utils/abbreviateNumber";

type Props = {
  topVideos: TopVideo[];
};

function TopVideosWeek({ topVideos }: Props) {
  const [limit, setLimit] = useState(3);
  const [showButton, setShowButton] = useState(true);

  const showMore = () => {
    setLimit(limit + 7);
    setShowButton(!showButton);
  };

  return (
    <section className="top-videos-week">
      <h2 className="section-title">Top videos this week</h2>
      <ul className="top-videos-week-list">
        {!!topVideos &&
          topVideos.map((item, i) => {
            return (
              <li
                key={item.hash_id}
                className={
                  i > 2 && !!showButton
                    ? "col-lg-12 col-md-12 col-sm-6 col-xs-12 hidden"
                    : "col-lg-12 col-md-12 col-sm-6 col-xs-12"
                }
              >
                <Row className="top-video-item">
                  <Col>
                    <span className="position-label">{padNumber(++i)}</span>
                  </Col>
                  <Col className="top-video-item-profile">
                    <div className="thumb-profile-container">
                      <Link to={`/video/${item.hash_id}`}>
                        <div
                          className="video-image"
                          style={{
                            backgroundImage: `url(${
                              item.thumb && item.thumb.replace("[SIZE]", "100x100")
                            })`
                          }}
                        >
                          <span className="play-button">
                            <i className="icon-play" />
                          </span>
                          <div className="overlay" />
                        </div>
                      </Link>
                      <Link to={`/channel/${item.channel.slug}`} className="hidden-xs hidden-sm">
                        {item.channel.image ? (
                          <div
                            className="channel-avatar"
                            style={{
                              backgroundImage: `url(${item.channel.image.replace(
                                "[SIZE]",
                                "100x100"
                              )})`
                            }}
                          />
                        ) : (
                          <div
                            className="channel-avatar"
                            style={{
                              backgroundColor: getAvatar(item.channel.name).color
                            }}
                          >
                            <span>{getAvatar(item.channel.name).shortLetters}</span>
                          </div>
                        )}
                      </Link>
                    </div>
                  </Col>
                  <Col md className="top-video-item-desc">
                    <div className="meta-data">
                      <Link
                        className="category meta-data-item"
                        to={`/categories/videos?category=${item.category.name}`}
                      >
                        {item.category.name}
                      </Link>
                      <div className="meta-data-item">
                        <i className="icon-glasses" />
                        <span className="views">{abbreviateNumber(item.views)}</span>
                      </div>
                      <span className="duration meta-data-item">
                        {convertToTimeDuration(item.duration)}
                      </span>
                    </div>
                    <div className="title">
                      <Link to={`/video/${item.hash_id}`}>{item.title}</Link>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    className="top-video-item-channel visible-xs visible-sm hidden-lg hidden-xl"
                  >
                    <Link
                      to={`/channel/${item.channel.slug}`}
                      className="top-video-item-channel-link"
                    >
                      {item.channel.image ? (
                        <div
                          className="channel-avatar"
                          style={{
                            backgroundImage: `url(${item.channel.image.replace(
                              "[SIZE]",
                              "100x100"
                            )})`
                          }}
                        />
                      ) : (
                        <div
                          className="channel-avatar"
                          style={{
                            backgroundColor: getAvatar(item.channel.name).color
                          }}
                        >
                          <span>{getAvatar(item.channel.name).shortLetters}</span>
                        </div>
                      )}
                      <span>{item.channel.name}</span>
                    </Link>
                  </Col>
                </Row>
              </li>
            );
          })}
      </ul>
      {showButton ? (
        <button className="load-more" onClick={showMore}>
          Load more
        </button>
      ) : null}
    </section>
  );
}

export default TopVideosWeek;
