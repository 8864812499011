import videojs from "video.js";
import { useEffect, useMemo, useRef } from "react";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import LiveTimer from "./LiveTimer";
import "./style.scss";

const defaultSettings = {
  autoplay: true,
  controls: true,
  preload: "auto",
  html5: {
    vhs: {
      overrideNative: true
    }
  }
};

type VideoPlayerTypes = {
  src?: string;
  thumb?: string;
  watchMode: boolean;
  isPreloading?: boolean;
  isLiveActive?: boolean;
  watchersCount?: number;
  liveType?: number;
  livePublishStart?: number;
  settings?: any;
  sourceType?: string;
  onPlayVideoEnded?: (playerSettings: any) => void;
  onPlayerRefer?: (playerRef: any) => void;
  onPause?: (playerRef: any) => void;
  onPlay?: (playerRef: any) => void;
};

function VideoPlayer({
  src,
  thumb = "",
  watchMode,
  isPreloading,
  isLiveActive = false,
  livePublishStart = 0,
  watchersCount = 0,
  settings = {},
  onPlayVideoEnded,
  onPlayerRefer,
  onPause,
  liveType = 1,
  onPlay,
  sourceType = "application/x-mpegURL"
}: VideoPlayerTypes) {
  const playerApiRef = useRef<any>(null);
  const playerWrapperRef = useRef<HTMLVideoElement | any>(null);

  const playerSettings = useMemo(
    () =>
      settings.muted
        ? {
            ...defaultSettings,
            ...settings
          }
        : { ...defaultSettings, ...settings, liveui: true },
    [settings]
  );

  useEffect(() => {
    if (playerWrapperRef.current) {
      playerApiRef.current = videojs(playerWrapperRef.current, playerSettings);
      playerApiRef.current.on("ended", () => {
        if (onPlayVideoEnded) {
          onPlayVideoEnded(playerSettings);
        }
      });
    }

    isPreloading && playerApiRef.current.addClass("vjs-waiting");
    onPlayerRefer &&
      playerApiRef.current.bigPlayButton.on("click", function () {
        playerWrapperRef.current.play();
      });

    if (onPlay) {
      playerApiRef.current.on("play", () => {
        onPlay(playerApiRef.current);
      });
    }
    if (onPause) {
      playerApiRef.current.on("pause", () => {
        onPause(playerWrapperRef.current);
        playerWrapperRef.current.pause();
      });
    }

    onPlayerRefer && onPlayerRefer(playerWrapperRef.current);

    return () => {
      if (playerApiRef.current) {
        playerApiRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (playerApiRef.current && !!src) {
      !watchMode && playerApiRef.current.poster(thumb.replace("[SIZE]", "990x656"));
      playerApiRef.current.src({
        src,
        type: sourceType
      });

      if (typeof playerApiRef.current.hlsQualitySelector === "function") {
        playerApiRef.current.hlsQualitySelector({
          vjsIconClass: "icon-settings"
        });
      }
    }
  }, [src]);

  useEffect(() => {
    if (playerWrapperRef.current) {
      playerApiRef.current.autoplay(playerSettings.autoplay);
      playerApiRef.current.off("ended");
      playerApiRef.current.on("ended", () => {
        if (onPlayVideoEnded) {
          onPlayVideoEnded(playerSettings);
        }
      });
    }
  }, [playerSettings.autoplay]);

  return (
    <div className="sharevision-video-player">
      <div className="wrap">
        {isLiveActive && (
          <LiveTimer
            watchersCount={watchersCount}
            liveType={liveType}
            livePublishStart={livePublishStart}
          />
        )}
        <video ref={playerWrapperRef} className="video-js" data-vjs-player playsInline loop />
      </div>
    </div>
  );
}

export default VideoPlayer;
