import { Col, Row } from "react-flexbox-grid";
import {
  PBenzinga,
  PDarQube,
  PEtoro,
  PExness,
  PFullerton,
  PMonetaMarkets,
  PVantageFX,
  PXM,
  PZuluTrade
} from "@Assets";

function Partners() {
  return (
    <section className="partners-section">
      <div className="title">Partners</div>
      <div className="full-container">
        <Row between="xs" className="logos-list">
          <Col>
            <a href="https://darqube.com/" target="_blank" rel="noreferrer" className="logo-link">
              <img src={PDarQube} alt="darQube" />
            </a>
          </Col>

          <Col>
            <a
              href="https://www.exness.com/"
              target="_blank"
              rel="noreferrer"
              className="logo-link"
            >
              <img src={PExness} alt="Exness" />
            </a>
          </Col>
          <Col>
            <a
              href="https://www.vantagefx.com/"
              target="_blank"
              rel="noreferrer"
              className="logo-link"
            >
              <img src={PVantageFX} alt="VantageFX" />
            </a>
          </Col>
          <Col>
            <a
              href="https://www.monetamarkets.com/"
              target="_blank"
              rel="noreferrer"
              className="logo-link"
            >
              <img src={PMonetaMarkets} alt="MonetaMarkets" />
            </a>
          </Col>
          <Col>
            <a
              href="https://www.zulutrade.com/"
              target="_blank"
              rel="noreferrer"
              className="logo-link"
            >
              <img src={PZuluTrade} alt="ZuluTrade" />
            </a>
          </Col>
          <Col>
            <a
              href="https://www.fullertonmarkets.com/"
              target="_blank"
              rel="noreferrer"
              className="logo-link"
            >
              <img src={PFullerton} alt="Fullerton" />
            </a>
          </Col>
        </Row>
        <Row center="xs" className="logos-list second">
          <Col>
            <a href="https://benzinga.com/" target="_blank" rel="noreferrer" className="logo-link">
              <img src={PBenzinga} alt="Benzinga" />
            </a>
          </Col>
          <Col>
            <a href="https://www.etoro.com/" target="_blank" rel="noreferrer" className="logo-link">
              <img src={PEtoro} alt="Etoro" />
            </a>
          </Col>
          <Col>
            <a href="https://www.xm.com/" target="_blank" rel="noreferrer" className="logo-link">
              <img src={PXM} alt="XM" />
            </a>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Partners;
