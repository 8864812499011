import classes from "classnames";
import Select from "react-select";
import { useEffect, useState } from "react";
import { CheckboxOption, CheckboxSelected } from "@Components";
import DropdownIndicator from "@Components/Select/Indicator";
import { AppSelect } from "@Models/AppSelect";
import "./style.scss";

type CheckboxDropdownTypes = {
  placeholder?: string;
  selectedPlaceholder?: string;
  isSearchable?: boolean;
  isTransparent?: boolean;
  checkboxSelect?: boolean;
  selectedValue?: any;
  onActionChange?: (option: any) => void;
  onApplyActionChange?: (option: any) => void;
  noOptionsMessage?: string;
  options: AppSelect[];
};

function CheckboxDropdown({
  placeholder = "",
  selectedPlaceholder = "Items",
  isSearchable = false,
  isTransparent = false,
  checkboxSelect = false,
  onActionChange,
  selectedValue = null,
  onApplyActionChange,
  noOptionsMessage = "No Options",
  options
}: CheckboxDropdownTypes) {
  const [selectedValues, setSelectedValues] = useState([] as AppSelect[]);
  const [filteredOptions, setFilteredOptions] = useState([] as AppSelect[]);
  const [dropDownValue, setDropDownValue] = useState(selectedValue);
  const [defaultValue, setDefaultValue] = useState(selectedValue);
  const [isOpenState, setIsOpenState] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setDropDownValue(selectedValue);
    setDefaultValue(selectedValue);
  }, [selectedValue]);

  const onDropdownChange = (values: any) => {
    setIsEdited(true);
    checkboxSelect && setSelectedValues(values);
    onActionChange && onActionChange(values);
    setDropDownValue(values);
  };

  useEffect(() => {
    if (isOpenState) {
      if (!!defaultValue && defaultValue.length) {
        const filteredOptions = options.filter(
          (item: AppSelect) => !defaultValue.map((i: AppSelect) => i.value).includes(item.value)
        );
        setFilteredOptions([...defaultValue, ...filteredOptions]);
      }
    } else {
      isEdited && onApplyActionChange && onApplyActionChange(selectedValues);
      setIsEdited(false);
    }
  }, [isOpenState, dropDownValue]);

  return (
    <Select
      classNamePrefix="app-select"
      className={classes("checkbox-dropdown-select", {
        "transparent-select": isTransparent
      })}
      components={
        checkboxSelect
          ? { Option: CheckboxOption, ValueContainer: CheckboxSelected, DropdownIndicator }
          : {}
      }
      menuIsOpen={isOpenState}
      value={dropDownValue}
      menuPlacement="bottom"
      placeholder={placeholder}
      isMulti={checkboxSelect}
      closeMenuOnSelect={!checkboxSelect}
      hideSelectedOptions={false}
      isSearchable={isSearchable}
      onChange={onDropdownChange}
      noOptionsMessage={() => noOptionsMessage}
      options={filteredOptions}
      selectedLabel={selectedPlaceholder}
      isTransparent={isTransparent}
      onToggleDropdown={() => {
        setIsOpenState(!isOpenState);
      }}
      onClickOutside={() => {
        setIsOpenState(false);
      }}
    />
  );
}

export default CheckboxDropdown;
