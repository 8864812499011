import classes from "classnames";
import "./style.scss";

interface VideoListItemTypes {
  supressCancel?: boolean;
  loadProgress: number;
  onCancel?: any;
  label?: string;
}

function ProgressBar({
  loadProgress,
  supressCancel,
  onCancel = () => {},
  label
}: VideoListItemTypes) {
  return (
    <div className="progress-bar-container">
      <div className="progress-container">
        <span
          className={classes("progress-wrap", {
            ["full-width"]: supressCancel
          })}
        >
          <span className="progress-bar" style={{ width: `${loadProgress}%` }} />
        </span>
        {label && <span className="progress-label">{label}</span>}
      </div>
      <span className="progress-percentage">{loadProgress}%</span>

      {!supressCancel && (
        <button className="upload-cancel" onClick={onCancel}>
          <i
            className="ico icon-close"
            data-tip="Cancel upload"
            data-for="cancel-upload"
            data-place="bottom"
            data-offset="{'left': 45}"
          />
        </button>
      )}
    </div>
  );
}

export default ProgressBar;
