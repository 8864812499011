import classes from "classnames";
import { useDrop } from "react-dnd";
import { useEffect, useState } from "react";
import { LoadingSpinner, PlaylistAccordion } from "@Components";
import PlaylistVideoListItem from "./PlaylistVideoListItem";
import { Row } from "react-flexbox-grid";

type Props = {
  selectedVideos: any[];
  playlist_id: number;
  playlist: any;
  onDropVideo: (selectedVideos: any[], next_playlist_id: number) => void;
  onUpdateVideoSuccess: (updatedDetails: any) => void;
  onDeleteVideoSuccess: () => void;
  handleLoadMore: (playlist_id: number, page: number) => void;
  onCheckVideo: Function;
  onSubmitEdit: any;
  onRemove: any;
  onCheck: any;
  onToggleExpend: any;
  childrenCount: number;
  expended: boolean;
  isFetching: boolean;
  checked: boolean;
  videos: any[];
};

function PlaylistWrap({
  selectedVideos,
  playlist_id,
  playlist,
  onDropVideo,
  onCheckVideo,
  onSubmitEdit,
  onRemove,
  onCheck,
  childrenCount,
  onToggleExpend,
  expended,
  isFetching,
  checked,
  videos,
  handleLoadMore,
  onDeleteVideoSuccess,
  onUpdateVideoSuccess
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [{ isOver }, drop] = useDrop({
    accept: "PLAYLIST_VIDEO_ITEM",
    drop: ({ selectedVideos }: any) => {
      onDropVideo(selectedVideos, playlist_id);
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  useEffect(() => {
    setIsLoading(false);
  }, [videos]);

  const onLoadMore = () => {
    setIsLoading(true);
    setPage(prevPage => prevPage + 1);
    handleLoadMore(playlist_id, page + 1);
  };
  return (
    <div ref={drop} className={classes("list-wrap", { over: isOver })}>
      <PlaylistAccordion
        editable
        playlist_id={playlist.playlist_id}
        title={playlist.title}
        onCheck={onCheck}
        checked={checked}
        onRemove={onRemove}
        expended={expended}
        onSubmitEdit={onSubmitEdit}
        childrenCount={childrenCount}
        onToggleExpend={() => {
          onToggleExpend();
          setPage(1);
        }}
        playlistStatus={playlist.status}
      >
        {isFetching && !videos.length ? (
          <div className="fetching-playlist">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {(videos.length && (
              <>
                {videos.map((videoListItem: any) => (
                  <PlaylistVideoListItem
                    key={videoListItem.video_id}
                    videoListItem={videoListItem}
                    selectedVideos={selectedVideos}
                    onCheck={onCheckVideo(videoListItem.video_id, playlist_id)}
                    checked={videoListItem.isChecked}
                    onUpdateVideoSuccess={onUpdateVideoSuccess}
                    onDeleteVideoSuccess={onDeleteVideoSuccess}
                  />
                ))}
                {Number(childrenCount) > 100 && videos.length < childrenCount && (
                  <Row center="xs">
                    <button
                      type="button"
                      className="secondary-button outline playlist-load-more"
                      onClick={onLoadMore}
                      disabled={isLoading}
                    >
                      Load more
                    </button>
                  </Row>
                )}
              </>
            )) || (
              <div className="playlist-empty">
                <i className="icon-folder-empty" /> Empty Playlist
              </div>
            )}
          </>
        )}
      </PlaylistAccordion>
    </div>
  );
}

export default PlaylistWrap;
