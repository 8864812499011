function Empty() {
  return (
    <div className="empty-playlist-data">
      <i className="icon-folder-empty" />
      <p className="empty-message">This playlist has no videos at the moment</p>
    </div>
  );
}

export default Empty;
