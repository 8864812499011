import AsyncCreatableSelect from "react-select/async-creatable";
import { propEq } from "ramda";
import { AppSelect } from "@Models/AppSelect";
import { useDispatch } from "react-redux";
import { searchVideoTagsByQuery } from "@Store/actions/video";
import "./style.scss";

type Props = {
  selectedValues: AppSelect[];
  onValueChanges: (data: AppSelect[]) => void;
};

const TagsLiveSearchInput = ({ selectedValues, onValueChanges }: Props) => {
  const dispatch = useDispatch();

  const loadOptions = (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      dispatch(
        searchVideoTagsByQuery(inputValue, {
          onSuccess: callback,
          onError: () => {}
        })
      );
    } else {
      callback([]);
    }
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");

    return inputValue;
  };

  const removeFromSelectedList = (id: number) => {
    onValueChanges(selectedValues.filter(item => item.value !== id));
  };

  const onSelectValueChange = (value: any) => {
    if (!selectedValues.find(propEq("value", value.value))) {
      onValueChanges([...selectedValues, { label: value.label, value: value.value }]);
    }
  };

  return (
    <aside className="searchable-select">
      <AsyncCreatableSelect
        placeholder="Search and select tags..."
        classNamePrefix="app-select"
        className="searchable-select"
        menuPlacement="auto"
        value={null}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onSelectResetsInput
        onBlurResetsInput
        onChange={onSelectValueChange}
      />
      <ul className="selected-tag-list">
        {selectedValues.map((tag: AppSelect, index: number) => (
          <li className="tag-item" key={index}>
            <span>{tag.label}</span>
            <i
              className="icon-close-rounded"
              onClick={() => {
                removeFromSelectedList(tag.value);
              }}
            />
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default TagsLiveSearchInput;
