import { all, call, takeEvery } from "@redux-saga/core/effects";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import authorizedApiRequest from "@Fetch/authorized";
import { ADMIN } from "@Store/actions/admin";
import { Filters } from "@Models/Filters";
import { AppSelect } from "@Models/AppSelect";

function* getAdminGeneralData({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminGeneralInfo`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getAdminDashboardData({
  requestType,
  callbacks,
  interval
}: {
  requestType: string;
  callbacks: RequestCallbacks;
  interval?: string;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminDashboard/${requestType}${interval ? "?interval=" + interval : ""}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getAdminChannelsData({
  payload: { nextPage, itemsPerPage },
  callbacks
}: {
  payload: { nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminChannelsList?page=${nextPage}&limit=${itemsPerPage}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    callbacks.onSuccess({ data, pageInfo: pageInfo });
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getAdminChannelDetailsData({
  payload: { nextPage, itemsPerPage, slug, filters },
  callbacks
}: {
  payload: { nextPage: number; itemsPerPage: number; slug: string; filters: Filters };
  callbacks: RequestCallbacks;
}) {
  const esc = encodeURIComponent;
  const params: any = !!filters
    ? {
        ...(!!filters.category && { categories: filters.category.value }),
        ...(!!filters.subcategory && { subcategories: filters.subcategory.value }),
        ...(!!filters.country && { countries: filters.country.value }),
        ...(!!filters.sector && { sectors: filters.sector.value }),
        ...(!!filters.currency && { currencies: filters.currency.value }),
        ...(!!filters.visibility && { visibility: filters.visibility.value }),
        ...(!!filters.views && { views: filters.views.value }),
        ...(!!filters.likes && { likes: filters.likes.value }),
        ...(!!filters.dislikes && { dislikes: filters.dislikes.value })
      }
    : {};

  const query = Object.keys(params)
    .map(k => esc(k) + "=" + esc(params[k]))
    .join("&");

  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminChannelDetails/${slug}?page=${nextPage}&limit=${itemsPerPage}${
      query ? "&" + query : ""
    }`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    callbacks.onSuccess({ data, pageInfo: pageInfo });
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getAdminCrashedVideosData({
  payload: { nextPage, itemsPerPage },
  callbacks
}: {
  payload: { nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminCrashedList?page=${nextPage}&limit=${itemsPerPage}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    callbacks.onSuccess({ data, pageInfo });
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getAdminActions({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AdminAction`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminAction({
  callbacks,
  payload: { checkedVideos, actionKey }
}: {
  callbacks: RequestCallbacks;
  checkedVideos: number[];
  payload: { checkedVideos: number[]; actionKey: string };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminAction/${actionKey}`,
    {
      method: "PUT",
      body: JSON.stringify({ list: checkedVideos })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminBan({
  callbacks,
  banType,
  slug
}: {
  callbacks: RequestCallbacks;
  banType: string;
  slug: string;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminBanChannel/${slug}`,
    {
      method: "PUT",
      body: JSON.stringify({ type: banType })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminCategories({
  callbacks,
  payload: { adminCategory }
}: {
  callbacks: RequestCallbacks;
  payload: { adminCategory: boolean };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/category${adminCategory ? "?adminCategory=true" : ""}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminSubcategories({
  callbacks,
  payload: { adminSubcategory }
}: {
  callbacks: RequestCallbacks;
  payload: { adminSubcategory: boolean };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/SubCategory${adminSubcategory ? "?adminSubcategory=true" : ""}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminSubcategoriesList({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/SubCategoryList`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* requestAdminTopics({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/Topics`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  }
}

function* requestAdminAssetsClasses({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AssetClasses`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  }
}

function* adminAddCategories({
  callbacks,
  payload: { categoryName }
}: {
  callbacks: RequestCallbacks;
  payload: { categoryName: string };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/category`, {
    method: "POST",
    body: JSON.stringify({ name: categoryName })
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  }
}

function* adminAddSubcategories({
  callbacks,
  payload: { subcategoryName }
}: {
  callbacks: RequestCallbacks;
  payload: { subcategoryName: string };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/SubCategory`, {
    method: "POST",
    body: JSON.stringify({ name: subcategoryName })
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  }
}

function* adminAddSubcategoryList({
  callbacks,
  payload: { subcategoryName }
}: {
  callbacks: RequestCallbacks;
  payload: { subcategoryName: string };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/SubCategoryList`,
    {
      method: "POST",
      body: JSON.stringify({ name: subcategoryName })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  }
}

function* adminCategoryAssignList({
  callbacks,
  payload: { categoryId, list }
}: {
  callbacks: RequestCallbacks;
  payload: { categoryId: number; list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AdminAssign`, {
    method: "PUT",
    body: JSON.stringify({ type: "list", category_id: categoryId, list })
  });

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminSubcategoryAssignTopics({
  callbacks,
  payload: { subcategoryId, list }
}: {
  callbacks: RequestCallbacks;
  payload: { subcategoryId: number; list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AdminAssign`, {
    method: "PUT",
    body: JSON.stringify({ type: "topic", subcategory_id: subcategoryId, list })
  });

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminAssignAssetsClass({
  callbacks,
  payload: { list }
}: {
  callbacks: RequestCallbacks;
  payload: { list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AdminAssign`, {
    method: "PUT",
    body: JSON.stringify({ type: "assetclasses", list })
  });

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminUnassignAssetsClass({
  callbacks,
  payload: { list }
}: {
  callbacks: RequestCallbacks;
  payload: { list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminUnAssign`,
    {
      method: "PUT",
      body: JSON.stringify({ type: "assetclasses", list })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminSubcategoryListAssignItems({
  callbacks,
  payload: { listId, list }
}: {
  callbacks: RequestCallbacks;
  payload: { listId: number; list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/AdminAssign`, {
    method: "PUT",
    body: JSON.stringify({ type: "subcategory", list_id: listId, list })
  });

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminCategoryUnassignList({
  callbacks,
  payload: { categoryId, list }
}: {
  callbacks: RequestCallbacks;
  payload: { categoryId: number; list: number[] };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminUnAssign`,
    {
      method: "PUT",
      body: JSON.stringify({ type: "list", category_id: categoryId, list })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminSubcategoryUnassignTopics({
  callbacks,
  payload: { subcategoryId, topics }
}: {
  callbacks: RequestCallbacks;
  payload: { subcategoryId: number; topics: number[] };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminUnAssign`,
    {
      method: "PUT",
      body: JSON.stringify({ type: "topic", subcategory_id: subcategoryId, list: topics })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminSubcategoryListUnassignItem({
  callbacks,
  payload: { listId, subcategoryId }
}: {
  callbacks: RequestCallbacks;
  payload: { listId: number; subcategoryId: number };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/AdminUnAssign`,
    {
      method: "PUT",
      body: JSON.stringify({ type: "subcategory", list_id: listId, subcategory_id: subcategoryId })
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  }
}

function* adminDeleteCategory({
  callbacks,
  payload: { categoryId }
}: {
  payload: { categoryId: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/category/${categoryId}`,
    {
      method: "DELETE"
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* adminDeleteSubcategory({
  callbacks,
  payload: { subcategoryId }
}: {
  payload: { subcategoryId: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/SubCategory/${subcategoryId}`,
    {
      method: "DELETE"
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* adminDeleteSubcategoryList({
  callbacks,
  payload: { subcategoryListId }
}: {
  payload: { subcategoryListId: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/SubCategoryList/${subcategoryListId}`,
    {
      method: "DELETE"
    }
  );

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

export default function* adminSagaWatcher() {
  yield all([
    takeEvery(ADMIN.GET_DATA, getAdminGeneralData),
    takeEvery(ADMIN.GET_DASHBOARD_DATA, getAdminDashboardData),
    takeEvery(ADMIN.GET_CHANNELS, getAdminChannelsData),
    takeEvery(ADMIN.GET_CHANNEL_DETAILS, getAdminChannelDetailsData),
    takeEvery(ADMIN.GET_CRASHED_VIDEOS_DETAILS, getAdminCrashedVideosData),
    takeEvery(ADMIN.GET_ACTIONS, getAdminActions),
    takeEvery(ADMIN.PUT_ACTION, requestAdminAction),
    takeEvery(ADMIN.PUT_BAN, requestAdminBan),
    takeEvery(ADMIN.GET_TOPICS, requestAdminTopics),
    takeEvery(ADMIN.GET_ASSETS_CLASSES, requestAdminAssetsClasses),
    takeEvery(ADMIN.GET_CATEGORIES, requestAdminCategories),
    takeEvery(ADMIN.GET_SUBCATEGORIES, requestAdminSubcategories),
    takeEvery(ADMIN.GET_SUBCATEGORIES_LIST, requestAdminSubcategoriesList),
    takeEvery(ADMIN.ADD_CATEGORY, adminAddCategories),
    takeEvery(ADMIN.ADD_SUBCATEGORY, adminAddSubcategories),
    takeEvery(ADMIN.ADD_SUBCATEGORIES_LIST, adminAddSubcategoryList),
    takeEvery(ADMIN.ASSIGN_CATEGORY_LIST, adminCategoryAssignList),
    takeEvery(ADMIN.ASSIGN_TOPICS, adminSubcategoryAssignTopics),
    takeEvery(ADMIN.ASSIGN_ASSETS_CLASS, adminAssignAssetsClass),
    takeEvery(ADMIN.UNASSIGN_ASSETS_CLASS, adminUnassignAssetsClass),
    takeEvery(ADMIN.ASSIGN_SUBCATEGORIES_LIST, adminSubcategoryListAssignItems),
    takeEvery(ADMIN.UNASSIGN_CATEGORY_LIST, adminCategoryUnassignList),
    takeEvery(ADMIN.UNASSIGN_TOPICS, adminSubcategoryUnassignTopics),
    takeEvery(ADMIN.UNASSIGN_CATEGORY_LIST_ITEM, adminSubcategoryListUnassignItem),
    takeEvery(ADMIN.REMOVE_CATEGORY, adminDeleteCategory),
    takeEvery(ADMIN.REMOVE_SUBCATEGORY, adminDeleteSubcategory),
    takeEvery(ADMIN.REMOVE_SUBCATEGORIES_LIST, adminDeleteSubcategoryList)
  ]);
}
