import { CHANNEL } from "../actions/channel";
import { Channel } from "@Models/Channel";

const initialState: Channel = {
  channel_slug: "",
  name: "",
  description: "",
  slug: "",
  category_id: -1,
  media_id: 0,
  homepage: "",
  twitter: "",
  facebook: "",
  instagram: "",
  tags: [],
  counts: {
    followers: 0,
    playlists: 0,
    streams: 0,
    videos: 0
  }
};

export default function reducer(state: Channel = initialState, action: any): Channel {
  switch (action.type) {
    case CHANNEL.SET_DETAILS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case CHANNEL.UPDATE_DETAILS: {
      return {
        ...state,
        ...action.payload
      };
    }

    default: {
      return state;
    }
  }
}
