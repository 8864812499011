import { useState } from "react";
import LiveChatHeader from "./Header";
import LiveChatContent from "./Content";
import LiveChatFooter from "./Footer";
import LiveChatParticipants from "./Participants";
import "./style.scss";

const LiveChat: React.FC = () => {
  const [showChat, setShowChat] = useState<boolean>(true);
  const [showParticipants, setShowParticipants] = useState<boolean>(false);

  return (
    <>
      {showChat ? (
        <div className="wrapper-live-chat">
          {showParticipants ? (
            <LiveChatParticipants setShowParticipants={setShowParticipants} />
          ) : (
            <>
              <LiveChatHeader setShowChat={setShowChat} setShowParticipants={setShowParticipants} />
              <LiveChatContent />
              <LiveChatFooter />
            </>
          )}
        </div>
      ) : (
        <div className="show-chat" onClick={() => setShowChat(true)}>
          Show chat
        </div>
      )}
    </>
  );
};

export default LiveChat;
