function EmptyPublicStreams() {
  return (
    <div className="empty-public-streams">
      <i className="icon-stream" />
      <p className="empty-message">This channel hasn't streams for the moment</p>
    </div>
  );
}

export default EmptyPublicStreams;
