import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { requestLogout } from "@Store/actions/user";
import { useOnClickOutside } from "@Hooks";
import { getAvatar } from "@Utils";
import { User } from "@Models/User";
import { getAuthUser } from "@Store/selectors/user";
import { toggleSidebarMobile } from "@Store/actions/sidebar";
import CreateChannelModal from "@Views/CreateChannelModal";
import { useHistory } from "react-router";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function ProfileContextMenu({ full_name, avatar }: User) {
  const dispatch = useDispatch();
  const history = useHistory();
  const avatarAlt = getAvatar(full_name);
  const userDetails = useSelector(getAuthUser);
  const menuNode = useRef<HTMLDivElement | null>(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [createChannel, setCreateChannel] = useState(false);

  const onUserLogout = () => {
    dispatch(requestLogout());
    history.push("/");
  };

  const toggleShowProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const visibleMobileSidebar = () => {
    dispatch(toggleSidebarMobile());
  };

  useOnClickOutside(menuNode, toggleShowProfileMenu);

  const onCreateChannel = () => {
    setCreateChannel(true);
    sendGTMDataLayer({
      event: "Channel Creation Btn",
      eventCategory: "creator signup",
      eventAction: "topnav create btn",
      eventValue: 1,
      eventLabel: "creator signup 1"
    });
  };

  return (
    <div className="profile-menu-trigger">
      {avatar ? (
        <div
          className="trigger-avatar"
          onClick={toggleShowProfileMenu}
          style={{ backgroundImage: `url(${avatar.replace("[SIZE]", "32x32")})` }}
        />
      ) : (
        <div
          className="trigger-avatar"
          onClick={toggleShowProfileMenu}
          style={{
            backgroundColor: avatarAlt.color
          }}
        >
          <span className="name-letters">{avatarAlt.shortLetters}</span>
        </div>
      )}
      {avatar ? (
        <div
          className="trigger-avatar mobile"
          onClick={visibleMobileSidebar}
          style={{ backgroundImage: `url(${avatar.replace("[SIZE]", "32x32")})` }}
        />
      ) : (
        <div
          className="trigger-avatar mobile"
          onClick={visibleMobileSidebar}
          style={{
            backgroundColor: avatarAlt.color
          }}
        >
          <span className="name-letters">{avatarAlt.shortLetters}</span>
        </div>
      )}

      {showProfileMenu && (
        <div className="profile-context-menu" ref={menuNode}>
          <div className="menu-list-item profile">
            {avatar ? (
              <div
                className="avatar"
                style={{
                  backgroundImage: `url(${avatar.replace("[SIZE]", "32x32")})`
                }}
              />
            ) : (
              <div
                className="avatar"
                style={{
                  backgroundColor: avatarAlt.color
                }}
              >
                <span className="name-letters">{avatarAlt.shortLetters}</span>
              </div>
            )}

            <span className="user-name">{full_name}</span>
          </div>

          <div className="list-group visibility">
            <div className="menu-list-item visibility-status">
              <div className="dot online" />
              <span>Online</span>
            </div>
          </div>

          <ul className="list-group menu-group">
            {userDetails.role === "creator" && (
              <li className="menu-list-item" onClick={toggleShowProfileMenu}>
                <Link to={`/channel/${userDetails.channel_slug}`}>My Channel</Link>
              </li>
            )}
            <li className="menu-list-item" onClick={toggleShowProfileMenu}>
              <Link to={`/streamer/${userDetails.username}`}>My Profile</Link>
            </li>
            <li className="menu-list-item" onClick={toggleShowProfileMenu}>
              <Link to="/profile">{userDetails.role === "creator" ? "Dashboard" : "Settings"}</Link>
            </li>
          </ul>

          {userDetails.role !== "creator" && (
            <ul
              className={
                !userDetails.email_confirmed
                  ? "list-group create-channel-group disabled"
                  : "list-group create-channel-group"
              }
            >
              <li className="menu-list-item" onClick={onCreateChannel}>
                <span>Create Channel</span>
              </li>
            </ul>
          )}

          <ul className="list-group logout-group">
            <li className="menu-list-item logout" onClick={toggleShowProfileMenu}>
              <span onClick={onUserLogout}>Log out</span>
            </li>
          </ul>
        </div>
      )}
      {createChannel && <CreateChannelModal setCreateChannelPopup={setCreateChannel} />}
    </div>
  );
}

export default ProfileContextMenu;
