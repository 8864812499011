function EmptyPublicVideos() {
  return (
    <div className="empty-public-videos">
      <i className="icon-folder-empty" />
      <p className="empty-message">This channel has no public videos for the moment</p>
    </div>
  );
}

export default EmptyPublicVideos;
