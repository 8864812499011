import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import AdminDropdown from "@AdminComponents/AdminDropdown";
import { AppSelect } from "@Models/AppSelect";
import { AdminCategory } from "@Models/Category";
import {
  adminAssignAssetsClass,
  adminUnassignAssetsClass,
  requestAdminAssetsClasses
} from "@Store/actions/admin";
import "./style.scss";

type AssetClassesProps = {
  categories: AdminCategory[];
};

function AssetsClassesManager({ categories }: AssetClassesProps) {
  const [assetClassesList, setAssetClassesList] = useState<AdminCategory[]>([]);
  const [selectedDropdown, setSelectedDropdown] = useState(null as any);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      requestAdminAssetsClasses({
        onSuccess: (list: AdminCategory[]) => {
          setAssetClassesList(list);
        }
      })
    );
  }, []);

  const onAssetClassAssign = (selected: AppSelect) => {
    setSelectedDropdown(selected);
    const index = assetClassesList.findIndex(i => i.category_id == selected.value);
    index === -1 &&
      dispatch(
        adminAssignAssetsClass([selected.value], {
          onSuccess: () => {
            setTimeout(() => {
              const addedAsset = categories.filter(i => i.category_id === selected.value)[0];
              setAssetClassesList([...assetClassesList, addedAsset]);
              setSelectedDropdown(null);
            }, 100);
          }
        })
      );
  };

  const onAssetClassUnassign = (id: number) => {
    dispatch(
      adminUnassignAssetsClass([id], {
        onSuccess: () => {
          const filteredAssets = assetClassesList.filter(i => i.category_id !== id);
          setAssetClassesList(filteredAssets);
        }
      })
    );
  };

  return (
    <Row className="assets-classes">
      <Col className="label-coll">Asset classes</Col>
      <Col className="class-coll">
        <ul className="items-list">
          {assetClassesList.map((item, index) => (
            <li className="select-item" key={index}>
              {item.name}
              <i
                className="icon-close"
                onClick={() => {
                  onAssetClassUnassign(item.category_id);
                }}
              />
            </li>
          ))}
        </ul>
      </Col>
      <Col className="class-coll dropdown-coll">
        <AdminDropdown
          selectedValue={selectedDropdown}
          options={categories.map(i => ({ label: i.name, value: i.category_id }))}
          placeholder="Assign categories"
          onActionChange={onAssetClassAssign}
          noOptionsMessage="No Subcateogries list"
        />
      </Col>
    </Row>
  );
}

export default AssetsClassesManager;
