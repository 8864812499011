import { isEmpty } from "ramda";
import { useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "@Components";
import "./style.scss";

type Props = {
  stream: MediaStream | null;
};

const WebcamPreview = ({ stream }: Props) => {
  const cameraPreview: any = useRef(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);

  useEffect(() => {
    if (!isEmpty(stream)) {
      setTimeout(() => {
        cameraPreview.current.srcObject = stream;
        cameraPreview.current.play();
        setIsPreviewLoading(false);
      }, 700);
    }
  }, [stream]);

  return (
    <div className="thumb camera-preview">
      {isPreviewLoading && <LoadingSpinner />}
      <video className="video-preview" muted ref={cameraPreview} />
    </div>
  );
};

export default WebcamPreview;
