import { AVATAR_BACKGROUND_COLORS } from "@Constants";

const getAvatar = (text: any) => {
  const letterArray = text.split(" ").map((text: string) => text.charAt(0));
  let shortLetters = "";
  letterArray.forEach((letter: string, i: number) => {
    if (i <= 1) shortLetters += letter;
  });

  return {
    color:
      AVATAR_BACKGROUND_COLORS[
        [...text].reduce((value, currentValue, index) => (value += text.charCodeAt(index)), 0) %
          AVATAR_BACKGROUND_COLORS.length
      ],
    shortLetters: shortLetters.toUpperCase()
  };
};

export default getAvatar;
