import { propEq } from "ramda";
import { Redirect } from "react-router";
import { ADMIN_SECTIONS } from "../constants";

type RootAdminSectionTypes = {
  match: { params: { sectionId: string; channelSlug?: string } };
};

function RootAdminSection({
  match: {
    params: { sectionId, channelSlug }
  }
}: RootAdminSectionTypes) {
  const sectionIndex = ADMIN_SECTIONS.findIndex(propEq("id", sectionId));

  if (sectionIndex !== -1) {
    return channelSlug
      ? ADMIN_SECTIONS[sectionIndex].subComponent.component
      : ADMIN_SECTIONS[sectionIndex].component;
  }

  return (
    <Redirect
      to={{
        pathname: "/dashboard"
      }}
    />
  );
}

export default RootAdminSection;
