const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const aside = document.createElement("aside");
    aside.style.margin = "0px";
    tooltipEl.appendChild(aside);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const customTooltips = (context: { chart: any; tooltip: any }) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const itemTitle = document.createElement("h4");
  itemTitle.className = "tooltip-title";

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: any) => b.lines);
    const tooltipData = document.createElement("div");
    const asideRoot = tooltipEl.querySelector("aside");
    tooltipData.className = "tooltip-data";

    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i];
      const spanIndicator = document.createElement("span");
      const spanText = document.createElement("span");
      const text = document.createTextNode(body);

      spanIndicator.style.background = colors.backgroundColor;
      spanIndicator.style.borderColor = colors.borderColor;
      spanIndicator.className = "chartjs-tooltip-key";
      tooltipData.appendChild(spanIndicator);
      spanText.appendChild(text);
      tooltipData.appendChild(spanText);
    });

    while (asideRoot.firstChild) {
      asideRoot.firstChild.remove();
    }

    itemTitle.textContent = tooltip.title;
    asideRoot.appendChild(itemTitle);
    asideRoot.appendChild(tooltipData);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};
