import { LiveScreen1 } from "@Assets";
import "./style.scss";

function LiveStreamArticle() {
  return (
    <article className="help-article">
      <h2 className="article-title">How to setup a Live Stream in ShareVision?</h2>
      <p className="big-topic">
        ShareVision have created leading Live Streaming technology, enabling Creators to share live
        feeds with their followers and connect through Live Chat.
      </p>
      <p className="description">
        When signed in, you can select Go Live at the top of the dashboard
      </p>
      <img src={LiveScreen1} alt="live-1" />
      <p className="description">
        Here you can now select your thumbnail, the title of your live stream, description and tags.
        You also have the option of enabling the live chat for customers to speak to you and between
        them during the stream.
      </p>
      <p className="description">Select the visibility:</p>
      <ul>
        <li>
          <b>Public</b> : Available publicly to anyone on ShareVision.
        </li>
        <li>
          <b>Unlisted</b> : Available to anyone that has the link.
        </li>
        <li>
          <b>Exclusive</b> : Available to those who pay and subscribe to your channel.
        </li>
        <li>
          <b>Private</b> : Available only to you.
        </li>
      </ul>
      <p className="description">Select the timeframe:</p>
      <ul>
        <li>
          <b>Regular until 4h</b> : Will allow user to stream until maximum 4 hours
        </li>
        <li>
          <b>24/7</b> : Will allow user to stream 24/7
        </li>
      </ul>
      <p className="description">
        We provide you with the appropriate gear set up to use with whichever software you choose,
        popular choices are{" "}
        <a href="https://obsproject.com/" target="_blank" rel="noreferrer">
          OBS
        </a>
        ,{" "}
        <a href="https://www.telestream.net/wirecast/" target="_blank" rel="noreferrer">
          Wirecast
        </a>
        ,{" "}
        <a href="https://zoom.us/" target="_blank" rel="noreferrer">
          Zoom
        </a>{" "}
        etc.
      </p>
      <p className="description top">You’re now ready to Go Live!</p>
      <p className="description top">
        If you need further support, you can send an email to{" "}
        <a href="mailto:support@sharevision.com">support@sharevision.com</a> where someone will
        assist you.
      </p>
    </article>
  );
}

export default LiveStreamArticle;
