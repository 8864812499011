import classes from "classnames";
import { Row, Col } from "react-flexbox-grid";

type VideoItem = {
  counter: string;
  description: string;
  thumb: null | "string";
  title: string;
  video_id: number;
  hash_id: string;
};

type Props = {
  list: VideoItem[];
  selectedVideo: VideoItem | null;
  onSelectVideo: (videoItem: VideoItem) => void;
};

function CommentedVideosList({ list, onSelectVideo, selectedVideo }: Props) {
  return (
    <Col className="videos-list">
      <Row className="list">
        {!!list.length &&
          list.map((videoItem: VideoItem) => (
            <Row
              className={classes("commented-video-list-item", {
                active: videoItem.video_id === selectedVideo?.video_id
              })}
              key={videoItem.video_id}
              onClick={() => onSelectVideo(videoItem)}
            >
              {videoItem.thumb ? (
                <div
                  className="image-thumb"
                  style={{
                    backgroundImage: `url(${videoItem.thumb.replace("[SIZE]", "100x100")})`
                  }}
                />
              ) : (
                <div className="image-thumb" />
              )}
              <Col className="title">{videoItem.title}</Col>
              {!!videoItem.counter && videoItem.counter !== "0" && (
                <Col className="info-pin">{videoItem.counter}</Col>
              )}
            </Row>
          ))}
      </Row>
    </Col>
  );
}

export default CommentedVideosList;
