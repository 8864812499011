import { AuthModal } from "../index";

type SignUpModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
};

function WelcomeModal({}: SignUpModalTypes) {
  return (
    <article className="form-container welcome-modal">
      <div className="pop-content-container">
        <h2 className="heading">Welcome, Martin!</h2>
        <p className="description">
          Answering these questions will help us personalize your experience. We'll keep your info
          safe in accordance with our{" "}
          <a target="_blank" rel="noreferrer" href="https://sharevision.com/privacy-policy.html">
            privacy policy
          </a>
          .
        </p>
        <div className="basic-filter-container">
          <h4 className="name">Select your interests</h4>
          <ul className="favorite-categories-list">
            <li>Category</li>
          </ul>
        </div>
        <button className="main-button white">Next</button>
        <span className="action-link skip-link">Skip question</span>
      </div>
    </article>
  );
}

export default WelcomeModal;
