import useRootRef from "@Hooks/useRootRef";
import { useHistory } from "react-router";
import { createRef, useEffect, useState } from "react";
import { NavLink, Redirect, Route } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { HELP_ARTICLES_LIST } from "@Views/HelpCenter/constants";
import RootHelpArticles from "@Views/HelpCenter/articles";
import ContactSupportForm from "@Views/HelpCenter/contact";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";
import "./style.scss";

function HelpCenter() {
  let targetRef: any = createRef();
  const { location } = useHistory();
  const rootRef = useRootRef();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  useEffect(() => {
    location.state == "contact"
      ? scrollToTarget()
      : !isLoading && rootRef?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.current && targetRef.current.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  return (
    <div className="page-content help">
      <div className="help-center">
        <div className="full-container">
          <h2 className="page-title">Help Center</h2>
          <p className="page-description">Questions and answers from the Sharevision Team</p>
          <section className="articles">
            <Row top="xs">
              <Col lg={3} xs={12} className="article-tabs">
                <h3 className="tabs-title">Popular Topics</h3>
                <ul className="tabs-list">
                  {HELP_ARTICLES_LIST.map(article => (
                    <li key={article.id}>
                      <NavLink
                        to={`/help/${article.id}`}
                        className="nav-item"
                        activeClassName="active"
                      >
                        {article.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col lg={9} xs={12} className="article-data">
                {location.pathname === "/help" && (
                  <Redirect to={{ pathname: "/help/create-channel", state: location.state }} />
                )}
                <Route
                  path={`/help/:articleId`}
                  render={(props: any) => <RootHelpArticles {...props} />}
                />
              </Col>
            </Row>
          </section>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey="6Lek9PcaAAAAAISAmtmcxZ8FF7jisgP2TM18cdf0">
          <ContactSupportForm targetRef={targetRef} />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
}

export default HelpCenter;
