import Dropzone from "react-dropzone";
import { Row } from "react-flexbox-grid";
import { useEffect, useState } from "react";
import { UPLOAD_IMAGE_FORMATS } from "@Constants";

type ThumbnailChooserTypes = {
  thumbsList: { value: string; preview: string }[];
  onSelect: (thumb: { value: string | File; preview: string }) => void;
};

type SelectedThumbValue = {
  thumb: null | string | File;
  key: string;
};

function ThumbnailChooser({ thumbsList, onSelect }: ThumbnailChooserTypes) {
  const [localImage, setImagePreview] = useState<any>({ preview: "" });
  const [selectedThumb, setSelectedThumb] = useState<SelectedThumbValue>({
    thumb: null,
    key: ""
  });

  useEffect(() => {
    const selectedThumb = thumbsList.find(({ selected }: any) => selected);

    if (selectedThumb) {
      setSelectedThumb({
        thumb: null,
        key: selectedThumb.value
      });
    }
  }, [thumbsList]);

  const onDrop = (files: File[]) => {
    if (files[0]) {
      const localImagePreview = URL.createObjectURL(files[0]);

      setImagePreview({
        preview: localImagePreview,
        file: files[0]
      });

      setSelectedThumb({
        thumb: files[0],
        key: localImagePreview
      });

      onSelect({
        preview: localImagePreview,
        value: files[0]
      });
    }
  };

  return (
    <Row className="thumbnail-chooser">
      <Dropzone onDrop={onDrop} accept={UPLOAD_IMAGE_FORMATS} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div className="upload-dropzone thumb-item" {...getRootProps()}>
            <input {...getInputProps()} />

            {(localImage.preview === "" && (
              <>
                <i className="icon icon-upload-cloud" />
                <span>Browse</span>
              </>
            )) || (
              <>
                <img src={localImage.preview} className="thumb-preview" />
                {selectedThumb.key === localImage.preview && <i className="icon icon-check-mark" />}
              </>
            )}
          </div>
        )}
      </Dropzone>

      {!!thumbsList.length && (
        <div className="available-thumnails-list">
          {thumbsList.map(thumb => (
            <div
              key={thumb.value}
              className="thumb-item"
              onClick={() => {
                setSelectedThumb({ thumb: thumb.value, key: thumb.value });
                onSelect(thumb);
              }}
            >
              {selectedThumb.key === thumb.value && <i className="icon icon-check-mark" />}
              <img alt="" src={thumb.preview.replace("[SIZE]", "190x115")} />
            </div>
          ))}
        </div>
      )}
    </Row>
  );
}

export default ThumbnailChooser;
