import { useEffect, useState, useRef } from "react";
import { useDebounce, useForm } from "@Hooks";
import { useDispatch } from "react-redux";
import { SignUpForm } from "@Models/Authentication";
import { checkProfileSlug, requestSignUp } from "@Store/actions/user";
import { AuthModal } from "../index";
import { toast } from "react-toastify";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

type SignUpWithMailModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
};

const defaultFormDataState: SignUpForm = {
  full_name: "",
  username: "",
  email: "",
  password: "",
  password2: ""
};

function SignUpWithMailModal({ onSetActiveModal }: SignUpWithMailModalTypes) {
  const dispatch = useDispatch();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [signUpInProgress, setSignUpInProgress] = useState(false);
  const [formData, setFormData] = useForm(defaultFormDataState);
  const [usernameStatus, setUsernameStatus] = useState(-1);
  const debouncedUsername = useDebounce(formData.username, 1000);
  const errorDebounce = useRef<any>();

  const validateUsernameInput = (event: any) => {
    setUsernameStatus(-1);
    clearTimeout(errorDebounce.current);
    errorDebounce.current = setTimeout(() => {
      if (event.getModifierState("CapsLock")) {
        toast.error(`Caps Lock is "on"`);
      }
    }, 600);
  };

  const onUsernameChange = (event: any) => {
    if (event.target.value.match(/^([a-z0-9._]*)$/)) {
      setFormData(event);
    }
  };

  useEffect(() => {
    if (debouncedUsername !== "") {
      dispatch(
        checkProfileSlug(debouncedUsername, {
          onSuccess: () => {
            setUsernameStatus(1);
          },
          onError: () => {
            setUsernameStatus(2);
          }
        })
      );
    } else {
      setUsernameStatus(-1);
    }
  }, [debouncedUsername]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setSignUpInProgress(true);

    dispatch(
      requestSignUp(formData, {
        onSuccess: () => {
          onSetActiveModal("SIGN_UP_SUCCESS");
          setSignUpInProgress(false);
          sendGTMDataLayer({
            event: "SignUp",
            eventCategory: "user signup",
            eventAction: "account creation",
            eventValue: 1,
            eventLabel: "user signup"
          });
        },
        onError: () => {
          setSignUpInProgress(false);
        }
      })
    );
  };

  return (
    <article className="form-container sign-up-mail-form">
      <i
        className="icon-chevron-left arrow-back"
        onClick={() => {
          onSetActiveModal("SIGN_UP");
        }}
      />
      <h2 className="heading">Sign Up</h2>
      <form onSubmit={onSubmitForm} className="pop-content-container">
        <input
          value={formData.full_name}
          onChange={setFormData}
          className="main-input"
          name="full_name"
          type="text"
          placeholder="Full Name"
          required
        />

        <div className="username-slug">
          <input
            value={formData.username}
            onChange={onUsernameChange}
            onKeyUp={validateUsernameInput}
            className="main-input"
            autoComplete={"off"}
            name="username"
            type="text"
            placeholder="Public username"
            required
          />
          {usernameStatus !== -1 && (
            <i
              className={
                usernameStatus === 2
                  ? "icon-close-rounded slug-status error"
                  : "icon-check-mark slug-status"
              }
            />
          )}
        </div>

        <input
          value={formData.email}
          onChange={setFormData}
          className="main-input"
          name="email"
          type="email"
          placeholder="Email"
          required
        />

        <input
          value={formData.password}
          onChange={setFormData}
          className="main-input"
          name="password"
          type="password"
          placeholder="Password"
          required
        />

        <input
          value={formData.password2}
          autoComplete={"off"}
          onChange={setFormData}
          className="main-input"
          name="password2"
          type="password"
          placeholder="Repeat password"
          required
        />
        <label>
          <input
            type="checkbox"
            name="check"
            checked={acceptedTerms}
            onChange={() => {
              setAcceptedTerms(!acceptedTerms);
            }}
          />
          <span className="label-text">
            I agree to the ShareVision{" "}
            <a href="https://sharevision.com/terms-conditions" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </span>
        </label>
        <button
          type="submit"
          className="main-button"
          disabled={
            !acceptedTerms || signUpInProgress || usernameStatus === -1 || usernameStatus === 2
          }
        >
          Sign up
        </button>
      </form>
    </article>
  );
}

export default SignUpWithMailModal;
