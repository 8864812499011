import classes from "classnames";
import { AppSelect } from "@Models/AppSelect";
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import ListChooser from "@AdminComponents/ListChooser";
import "./style.scss";

type Props = {
  children: any;
  listTitle: string;
  actionText: string;
  actionLabel: string;
  actionButtonLabel: string;
  listChooser?: AppSelect[];
  onAddItemList?: (addedItem: string, listItems: number[]) => void;
  onAddItem?: (addedItem: string) => void;
  onEditModeToggle: (editState: boolean) => void;
};

function ListWrapper({
  children,
  listTitle,
  actionLabel,
  actionText,
  listChooser = [],
  onAddItemList,
  onAddItem,
  actionButtonLabel,
  onEditModeToggle
}: Props) {
  const [expandedMode, setExpandedMode] = useState(false);
  const [addedItem, setAddedItem] = useState("");
  const [selectedList, setSelectedList] = useState([] as number[]);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null as any);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    setAddedItem("");
  }, [expandedMode]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddedItem(event.target.value);
  };

  const onInputType = (event: KeyboardEvent<HTMLInputElement>) => {
    event.nativeEvent.key === "Enter" && onAddActionMiddleware();
    event.nativeEvent.key === "Escape" && setExpandedMode(false);
  };

  const onAddActionMiddleware = () => {
    !!onAddItemList &&
      !!selectedList.length &&
      !!addedItem &&
      onAddItemList(addedItem, selectedList);
    !!onAddItem && onAddItem(addedItem);
    setExpandedMode(false);
  };

  return (
    <aside className="list-wrapper">
      <div className="list-header">
        {listTitle}{" "}
        <i
          className={`icon-${editMode ? "close" : "edit"}`}
          onClick={() => {
            onEditModeToggle(!editMode);
            setEditMode(!editMode);
          }}
        />
      </div>
      {children}
      <div
        className={classes("action-button", {
          ["active-popover"]: expandedMode
        })}
      >
        {!expandedMode ? (
          <div
            className="static-button"
            onClick={() => {
              setExpandedMode(true);
            }}
          >
            {actionText}
          </div>
        ) : (
          <div className="create-new-item">
            <div className="creation-form">
              <span>{actionLabel}</span>
              <input
                type="text"
                ref={inputRef}
                onKeyUp={onInputType}
                onChange={onNameChange}
                placeholder="Give it a name"
                className="main-input"
              />
            </div>
            {!!listChooser?.length && (
              <ListChooser
                onUpdateList={selectedList => {
                  setSelectedList(selectedList);
                }}
                list={listChooser}
              />
            )}
            <button className="main-button" onClick={onAddActionMiddleware}>
              {actionButtonLabel}
            </button>
            <span
              className="cancel-action"
              onClick={() => {
                setExpandedMode(false);
              }}
            >
              Cancel
            </span>
          </div>
        )}
      </div>
    </aside>
  );
}

export default ListWrapper;
