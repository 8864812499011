import { createElement } from "react";

import FollowingSection from "./sections/Following";

export type SectionType = {
  id: string;
  label: string;
  component: JSX.Element;
};

export const USER_SECTIONS_LIST: SectionType[] = [
  {
    id: "following",
    label: "Following",
    component: createElement(FollowingSection)
  }
];
