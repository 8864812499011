import formatDistance from "date-fns/formatDistance";
import React, { memo } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { VideoPlaylist } from "@Models/Video";

import "./style.scss";

interface PlaylistProps extends VideoPlaylist {
  baseUrl: string;
  image?: string | null;
}

function PlaylistPostItem({
  image,
  title,
  videos,
  baseUrl,
  updatedAt,
  playlist_id
}: PlaylistProps) {
  return (
    <Col md={4} className="playlist-post-block">
      <div className="playlist-post-item">
        <div
          className="heading-thumb"
          style={{
            backgroundImage: image ? `url(${image.replace("[SIZE]", "350x160")})` : ""
          }}
        >
          {!image && <i className="icon-img-thumb" />}
          <div className="overlay" />
        </div>
        <div className="content">
          <Row>
            <Col className="title-icon">
              <i className="icon-playlist-import" />
            </Col>
            <Col xs>
              <h4 className="title">{title}</h4>
            </Col>
          </Row>

          <ul className="playlist-info">
            <li>
              <i className="icon-circle-play" /> {videos} videos
            </li>
            {updatedAt && (
              <li>
                <i className="icon-refresh-arrow" />
                {formatDistance(new Date(), updatedAt * 1000)}
              </li>
            )}
          </ul>
          <Link to={`${baseUrl}/${playlist_id}`}>
            <button className="secondary-button outline playlist-btn">View playlist</button>
          </Link>
        </div>
      </div>
    </Col>
  );
}

export default memo(PlaylistPostItem);
