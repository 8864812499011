import { useEffect, useState } from "react";
import AdminAccordion from "@AdminComponents/AdminAccordion";
import AdminDropdown from "@AdminComponents/AdminDropdown";
import { AdminCategory } from "@Models/Category";
import { AppSelect } from "@Models/AppSelect";
import { SubcategoryListItem } from "@Models/SubcategoryListItem";
import ListWrapper from "../ListWrapper";
import SelectedItem from "@AdminComponents/SelectedItem";

type CategoriesProps = {
  categories: AdminCategory[];
  subcategoriesList: SubcategoryListItem[];
  onAddCategory: (addedItem: string) => void;
  onDeleteCategory: (deleteItemId: number) => void;
  onCategoryAssignList: (selectedList: AppSelect, categoryId: number) => void;
  onCategoryUnassignList: (listId: number, categoryId: number) => void;
};

function CategoriesManager({
  categories,
  subcategoriesList,
  onAddCategory,
  onDeleteCategory,
  onCategoryAssignList,
  onCategoryUnassignList
}: CategoriesProps) {
  const [listOptions, setListOptions] = useState([] as AppSelect[]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const optionsList = subcategoriesList.map((list: any) => ({
      label: list.name,
      value: list.list_id
    }));

    setListOptions(optionsList);
  }, [subcategoriesList]);

  const onEditModeToggle = (editState: boolean) => {
    setEditMode(editState);
  };

  return (
    <ListWrapper
      listTitle="Categories"
      actionText="Add Category"
      actionLabel="Category name"
      onAddItem={onAddCategory}
      onEditModeToggle={onEditModeToggle}
      actionButtonLabel="Create category"
    >
      {!!categories.length &&
        categories.map((category, index) => {
          const selectedList = subcategoriesList.filter(
            list => list.list_id === category.list_id
          )[0];

          return (
            <AdminAccordion
              title={category.name}
              key={index}
              isLiked={!!category.list_id}
              isEditMode={editMode}
              itemId={categories[index].category_id}
              onDeleteItem={onDeleteCategory}
              isDeletable={categories[index].is_deletable}
            >
              {!!category.list_id && !!selectedList ? (
                <SelectedItem
                  title={selectedList.name}
                  onRemove={() => {
                    onCategoryUnassignList(category.list_id || 0, categories[index].category_id);
                  }}
                />
              ) : (
                <AdminDropdown
                  onActionChange={(selected: AppSelect) => {
                    onCategoryAssignList(selected, categories[index].category_id);
                  }}
                  options={listOptions}
                  placeholder="Assign List"
                  noOptionsMessage="No Subcateogries list"
                />
              )}
            </AdminAccordion>
          );
        })}
    </ListWrapper>
  );
}

export default CategoriesManager;
