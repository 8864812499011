import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "@Hooks";
import { AppSelect } from "@Models/AppSelect";
import "./style.scss";

type ChooserProps = {
  list: AppSelect[];
  limit?: number;
  selectedItems?: number[];
  onApply?: () => void;
  onUpdateList: (idList: number[]) => void;
};

function ListChooser({
  list,
  limit = 10,
  selectedItems = [],
  onUpdateList,
  onApply
}: ChooserProps) {
  const [subcategoriesList, setSubcategoriesList] = useState([] as AppSelect[]);
  const [searchValue, setSearchValue] = useState("");
  const [checkedSubcategories, setCheckedSubcategories] = useState([]);
  const debouncedSearchValue = useDebounce(searchValue, 600);

  useEffect(() => {
    setSubcategoriesList(list);
    !!selectedItems?.length &&
      setCheckedSubcategories(Object.assign({}, ...selectedItems?.map(id => ({ [id]: true }))));
  }, [list]);

  useEffect(() => {
    onUpdateList(Object.keys(checkedSubcategories).map(key => +key));
  }, [checkedSubcategories]);

  useEffect(() => {
    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const onCheckItem = (id: any) => {
    setCheckedSubcategories((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const onSearch = (searchTxt: string) => {
    setSubcategoriesList(
      list.filter(option => option.label.toLowerCase().includes(searchTxt.toLowerCase()))
    );
  };

  return (
    <aside className="admin-chooser-list">
      <ul className="list">
        {subcategoriesList.map(
          (option, index) =>
            index < limit && (
              <li key={index}>
                <label className="select-subcategory">
                  <input
                    readOnly
                    name="check"
                    onClick={() => {
                      onCheckItem(option.value);
                    }}
                    type="checkbox"
                    checked={!!checkedSubcategories[option.value]}
                  />
                  <span className="label-text">{option.label}</span>
                </label>
              </li>
            )
        )}
      </ul>
      <div className="search-input">
        <input
          className="main-input search-selected-item"
          placeholder="Search"
          onChange={(searchEvent: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(searchEvent.target.value);
          }}
        />
        <i className="icon-search" />
      </div>
      {!!onApply && (
        <button type="button" className="main-button apply-btn" onClick={onApply}>
          Apply
        </button>
      )}
    </aside>
  );
}

export default ListChooser;
