import { Col, Row } from "react-flexbox-grid";
import RootSection from "./sections";
import { NavLink, Redirect, Route } from "react-router-dom";
import { ADMIN_SECTIONS } from "./constants";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestAdminGeneralData } from "@Store/actions/admin";
import "./style.scss";

type AdminSettingsProps = {
  match: {
    url: string;
    path: string;
    params: { channel_slug: string; sectionId: string };
    isExact: boolean;
  };
};

function AdminSettings({ match }: AdminSettingsProps) {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({} as any);

  useEffect(() => {
    dispatch(
      requestAdminGeneralData({
        onSuccess: data => {
          setAdminData(data);
        },
        onError: () => null
      })
    );
  }, []);

  return (
    <section className="admin-settings">
      <div className="section-heading">
        <Col className="auto-column">
          <h1 className="main-heading">Admin Settings</h1>
        </Col>
        <Col className="admin-tabs-coll nav-tabs-wrap">
          <Row className="nav-tabs">
            {ADMIN_SECTIONS.map(section => (
              <NavLink
                key={section.id}
                to={`${match.url}/${section.id}`}
                activeClassName="active"
                className="nav-tab"
              >
                {section.label}
                {!!adminData && !!section.counter && (
                  <span className="badge-counter">{adminData[section.counter]}</span>
                )}
              </NavLink>
            ))}
          </Row>
        </Col>
      </div>
      {match.isExact && (
        <Redirect
          to={{
            pathname: `${match.url}/dashboard`
          }}
        />
      )}
      <Route path={`${match.path}/:sectionId/:channelSlug?`} render={RootSection as any} />
    </section>
  );
}

export default AdminSettings;
