import { pick } from "ramda";
import { combineReducers } from "redux";
import { USER } from "@Store/actions/user";
import { App, Store } from "@Models/Store";
import app from "./app";
import user from "./user";
import video from "./video";
import playlist from "./playlist";
import category from "./category";
import channel from "./channel";
import notifications from "./notifications";
import sidebar from "./sidebar";
import banList from "./banList";

const combinedReducers = combineReducers({
  app,
  user,
  video,
  category,
  channel,
  playlist,
  notifications,
  sidebar,
  banList
});

type FilteredStore = Store | { app: App };

function reducer(state: Store, action: any) {
  // clear all reducers when user log out
  let filteredState: FilteredStore = state;

  if (action.type === USER.LOGOUT_SUCCESS) {
    filteredState = { ...pick(["app", "sidebar"], state) };
  }

  return combinedReducers(filteredState as any, action);
}

export default reducer;
