import cn from "classnames";
import "./style.scss";

interface IProps {
  message: string;
  className?: string;
  icon?: string;
}

const EmptyPlaceholder = ({
  message,
  className,
  icon = "icon-folder-empty"
}: IProps): JSX.Element => {
  return (
    <div className={cn("empty-placeholder-root", className)}>
      <i className={cn("empty-placeholder-icon", icon)} />
      <p className="empty-placeholder-message">{message}</p>
    </div>
  );
};

export default EmptyPlaceholder;
