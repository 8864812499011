import { call, put, takeEvery, all } from "@redux-saga/core/effects";
import { setSidebarChannels, setSidebarVideos, SIDEBAR } from "../actions/sidebar";
import { ServerResponse } from "@Models/RequestParams";
import authorizedApiRequest from "@Fetch/authorized";

function* getSidebarVideosCategories() {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    "/streams/SidebarVideos",
    { method: "GET" }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield put(setSidebarVideos(data));
  }
}

function* getSidebarChannelsCategories() {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    "/streams/SidebarChannels",
    { method: "GET" }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield put(setSidebarChannels(data));
  }
}

function* getSidebarData() {
  yield call(getSidebarVideosCategories);
  yield call(getSidebarChannelsCategories);
}

export default function* sidebarSagaWatcher() {
  yield all([takeEvery(SIDEBAR.GET_DATA, getSidebarData)]);
}
