import { API_URL } from "@Constants";
import { RequestParams } from "@Models/RequestParams";
import { eventChannel, END } from "redux-saga";
import { toast } from "react-toastify";

function removeFalsyValues(paramsList: any = {}) {
  const newObj: any = {};

  Object.keys(paramsList).forEach(prop => {
    if (paramsList[prop]) {
      newObj[prop] = paramsList[prop];
    }
  });

  return newObj;
}

export function queryBuilder(paramsList = {}) {
  const validKeys = removeFalsyValues(paramsList);

  const keys = Object.keys(validKeys);
  const value = Object.values(validKeys);
  let query = "";

  // eslint-disable-next-line no-restricted-syntax
  for (const item in keys) {
    if (item) query += `&${keys[item]}=${value[item]}`;
    else query += `?${keys[item]}=${value[item]}`;
  }

  return query;
}

export function createFetch(url: string, options: any, isFullUrl?: boolean) {
  const defaults: RequestParams = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json"
    }
  };

  return fetch(isFullUrl ? url : `${API_URL}${url}`, {
    ...defaults,
    ...options,
    headers: {
      ...defaults.headers,
      ...(!!options && options.headers)
    }
  });
}

function fetchApiRequest(url: string, options = {}, isFullUrl?: boolean) {
  return createFetch(url, options, isFullUrl)
    .then(response => {
      if (!response.ok) {
        throw response;
      } else {
        return response;
      }
    })
    .catch(e => {
      e.json().then((res: any) => {
        res.errors.forEach((err: any) => {
          toast.error(err.message);
        });
      });
      return e;
    });
}

export function fileUploadRequest(url: string, formData: FormData, setCancelCallback: Function) {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${API_URL}${url}`, true);

    xhr.setRequestHeader("authorization", `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`);

    const onLoadStart = () => {
      setCancelCallback(() => {
        xhr.abort();
      });
    };

    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        emitter({ loadProgress: Math.ceil((e.loaded / e.total) * 100) });
      }
    };

    const onFailure = (error: any) => {
      emitter({ error });
      emitter(END);
    };

    xhr.upload.addEventListener("loadstart", onLoadStart);
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: xhr });
          emitter(END);
        } else {
          onFailure(xhr);
        }
      }
    };

    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  });
}

export default fetchApiRequest;
