import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { isEmpty } from "ramda";

let initTimeOut: any;

type Props = {
  stream: MediaStream;
};

const AudioMetric = ({ stream }: Props) => {
  const [audioMetric, setAudioMetric] = useState(0);
  const [audioWorkletPending, setAudioWorkletPending] = useState(true);
  const audioContext = useRef<AudioContext>(new AudioContext());
  const microphoneRef = useRef<MediaStreamAudioSourceNode>();
  const workletNode = useRef<AudioWorkletNode>();

  useEffect(() => {
    setTimeout(() => {
      audioContext.current.audioWorklet.addModule("/worklet/audio-processor.js").then(() => {
        setAudioWorkletPending(false);
      });
    }, 500);
    return () => {
      audioContext.current.close().then(() => {
        resetAudioWorklet();
      });
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(stream)) {
      !audioWorkletPending && initAudioWorklet();
    }
  }, [audioWorkletPending, stream]);

  const initAudioWorklet = () => {
    initTimeOut && clearTimeout(initTimeOut);
    initTimeOut = setTimeout(() => {
      !!workletNode.current && resetAudioWorklet();
      microphoneRef.current = audioContext.current.createMediaStreamSource(stream);
      workletNode.current = new AudioWorkletNode(audioContext.current, "vumeter");
      workletNode.current.port.onmessage = event => {
        let volume = 0;
        let sensibility = 0.5;
        if (event.data.volume) volume = event.data.volume;
        setAudioMetric(Math.round((volume * 100) / sensibility));
      };
      microphoneRef.current.connect(workletNode.current).connect(audioContext.current.destination);
    });
  };

  const resetAudioWorklet = () => {
    workletNode.current?.disconnect();
    microphoneRef.current?.disconnect();
  };

  return (
    <div>
      <div className="metric-bar-container">
        <div
          className="metric-bar-value"
          style={{ width: `${audioMetric < 100 ? 30 + audioMetric : 100}%` }}
        />
      </div>
    </div>
  );
};

export default AudioMetric;
