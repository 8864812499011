import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { useForm } from "@Hooks";
import { FacebookLogo, GoogleLogo } from "@Assets";

import { requestSignIn, signInWithFacebook, signInWithGoogle } from "@Store/actions/user";
import { Link } from "react-router-dom";

type SignInModalTypes = {
  onSubmit: () => void;
  onSetActiveModal: (modal: any) => void;
};

const defaultFormDataState = {
  email: "",
  password: ""
};

function SignInModal({ onSetActiveModal }: SignInModalTypes) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useForm(defaultFormDataState);

  const onSubmitForm = (e: any) => {
    e.preventDefault();

    dispatch(
      requestSignIn(formData, {
        onSuccess: () => {
          onSetActiveModal("");
        },
        onError: () => {}
      })
    );
  };

  return (
    <article className="form-container sign-in-container">
      <h2 className="heading">Sign In</h2>
      <form onSubmit={onSubmitForm} className="pop-content-container">
        <input
          value={formData.email}
          onChange={setFormData}
          className="main-input"
          name="email"
          type="text"
          placeholder="Email"
          required
        />
        <label className="label-pass">
          <input
            value={formData.password}
            className="main-input pass"
            onChange={setFormData}
            name="password"
            type="password"
            placeholder="Password"
            required
          />
        </label>

        <Row>
          <Col sm={6} xs={6}>
            <span
              className="forgot-link"
              onClick={() => {
                onSetActiveModal("FORGOT_PASSWORD");
              }}
            >
              Forgot password?
            </span>
          </Col>
          <Col sm={6} xs={6}>
            <button type="submit" className="main-button">
              Sign in
            </button>
          </Col>
        </Row>
        <div className="isolated-break-line">
          <hr className="break-line" />
        </div>
        <Row className="bottom-buttons">
          <Col sm={6} xs={6}>
            <button
              type="button"
              className="secondary-button"
              onClick={() => {
                dispatch(signInWithFacebook());
              }}
            >
              <img src={FacebookLogo} alt="FB Logo" />
            </button>
          </Col>
          <Col sm={6} xs={6}>
            <button
              type="button"
              className="secondary-button"
              onClick={() => {
                dispatch(signInWithGoogle());
              }}
            >
              <img src={GoogleLogo} alt="Google Logo" />
            </button>
          </Col>
        </Row>
        <div className="auth-bottom-info">
          <h4 className="login-register-action">
            Not a member?{" "}
            <span
              onClick={() => {
                onSetActiveModal("SIGN_UP");
              }}
            >
              Join ShareVision
            </span>
          </h4>
          <p className="terms-conditions">
            By continuing, you agree to our{" "}
            <Link
              to="/terms-conditions"
              onClick={() => {
                onSetActiveModal("");
              }}
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy-policy"
              onClick={() => {
                onSetActiveModal("");
              }}
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </form>
    </article>
  );
}

export default SignInModal;
