import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestDeshboardChannelAnalytics } from "@Store/actions/pageSections";
import { LoadingSpinner } from "@Components";
import { abbreviateNumber } from "@Utils";

type IRow = {
  changes: number;
  count: number;
  changes_prc: number;
  direction: string;
};

type AnalyticsData = {
  allFollowers: number;
  rows: {
    [key: string]: IRow;
  };
};

function AnalyticsWidget() {
  const dispatch = useDispatch();
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);

  useEffect(() => {
    dispatch(
      requestDeshboardChannelAnalytics({
        onSuccess: data => {
          setAnalyticsData(data);
        },
        onError: () => {}
      })
    );
  }, []);

  if (!analyticsData) {
    return (
      <div className="widget analytics">
        <div className="content">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="widget analytics">
      <div className="content">
        <h3 className="widget-title">Channel analytics</h3>
        <div className="followers-count">
          <h4>Followers</h4>
          <div className="count">{abbreviateNumber(analyticsData.allFollowers)}</div>
          <p className="latest-stat">
            <span className={analyticsData.rows.followers.count >= 0 ? "increase" : "decrease"}>
              {abbreviateNumber(analyticsData.rows.followers.count)}
            </span>{" "}
            in last 7 days
          </p>
        </div>
        <ul className="details-list">
          {Object.values(analyticsData.rows).map((data, i) => (
            <li key={i}>
              <span className="analytics-row-label">{Object.keys(analyticsData.rows)[i]}</span>
              <div className="direction-label">
                <span>{abbreviateNumber(data.count)}</span>
                {!!data.direction && (
                  <span className="label-value">
                    <i className={`icon icon-caret-${data.direction}`} />
                    <span className={data.changes > 0 ? "increase" : "decrease"}>
                      {data.changes > 0 ? "+" : ""}
                      {abbreviateNumber(data.changes)}
                    </span>
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AnalyticsWidget;
