import formatDate from "date-fns/format";
import classes from "classnames";
import Select from "react-select";
import { propEq } from "ramda";
import { toast } from "react-toastify";
import { useState, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { abbreviateNumber, convertToTimeDuration } from "@Utils";
import { ProgressBar, Modal, ConfirmModal, StatusOption, StatusSelected } from "@Components";
import { VIDEO_STATUS_OPTIONS, VIDEO_PROCESSING_CODES } from "@Constants";
import {
  deleteVideosList,
  requestUserVideos,
  cancelUploadVideosList,
  requestChangeVideosVisibility,
  requestUserStreams
} from "@Store/actions/video";

import EditVideoModal from "@Views/EditVideoModal";
import StreamModal from "@Views/StreamModal";
import LikesRange from "./LikesRange";
import "./style.scss";
import { getSelectCategories } from "@Store/selectors/category";

const copyToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  toast.success("Video link copied to clipboard!");
};

interface VideoListItemTypes {
  withCheckbox: boolean;
  viewOnly?: boolean;
  isStreamEvent?: boolean;
  adminMode?: boolean;
  download_url?: string;
  editOnly?: boolean;
  checked?: boolean;
  loadProgress?: number;
  filename: string | null;
  thumb: string | null;
  title: string;
  description: string;
  createdAt: number;
  dislikes: number;
  likes: number;
  views: number;
  hash_id?: string;
  video_id: number;
  category_id?: number;
  playlist_id?: number;
  tags: Array<string | number>;
  duration: number;
  status?: number;
  stream_ended?: boolean;
  comments?: number;
  is_live?: boolean;
  perPage?: number;
  invalidVideo?: boolean;
  converter?: { processCodeUpdate?: 0 | 1 | 2 | 3; progress?: number };
  onCheck: (checked: boolean) => void;
  onUpdateVideoSuccess?: (updatedDetails: any) => void;
  onDeleteVideoSuccess?: () => void;
}

const statusOptionsList = Object.values(VIDEO_STATUS_OPTIONS)
  .reverse()
  .filter(i => i.value);

function VideoListItem(props: VideoListItemTypes) {
  const {
    onDeleteVideoSuccess,
    onUpdateVideoSuccess,
    viewOnly,
    isStreamEvent,
    is_live,
    adminMode = false,
    editOnly,
    checked,
    onCheck,
    thumb,
    stream_ended,
    title,
    description,
    loadProgress,
    createdAt,
    dislikes,
    likes,
    views,
    video_id,
    invalidVideo,
    category_id,
    playlist_id,
    hash_id,
    duration,
    comments,
    status,
    download_url,
    converter,
    perPage
  } = props;

  const dispatch = useDispatch();
  const categoriesList = useSelector(getSelectCategories);
  const videoDuration = convertToTimeDuration(duration);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleModalShow = (updatedDetails: any) => {
    setShowEditModal(!showEditModal);

    if (onUpdateVideoSuccess) {
      onUpdateVideoSuccess(updatedDetails);
    }
  };

  const toggleStreamDetails = (updatedDetails: any) => {
    setShowStreamModal(!showStreamModal);

    if (onUpdateVideoSuccess) {
      if (!!updatedDetails && !!updatedDetails.thumb) {
        onUpdateVideoSuccess({ ...updatedDetails, thumb: updatedDetails.thumb.preview });
      }
    }
  };

  const toggleDeleteModalShow = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const onCancelUpload = () => {
    dispatch(cancelUploadVideosList([video_id]));
  };

  const onDeleteVideo = () => {
    dispatch(
      deleteVideosList([video_id], !!isStreamEvent, {
        onSuccess: () => {
          if (!onDeleteVideoSuccess) {
            isStreamEvent
              ? dispatch(requestUserStreams(1, perPage))
              : dispatch(requestUserVideos(1, perPage));
          } else {
            onDeleteVideoSuccess();
          }
        },
        onError: () => {}
      })
    );
    toggleDeleteModalShow();
    onCancelUpload();
  };

  const onVisibilityChange = (selectedOption: any) => {
    dispatch(requestChangeVideosVisibility(selectedOption.value, [video_id], !!isStreamEvent));

    if (onUpdateVideoSuccess) {
      onUpdateVideoSuccess({ video_id, playlist_id, status: selectedOption.value });
    }
  };

  const videoCategory = useMemo(() => {
    if (category_id && categoriesList.length) {
      return categoriesList.find(propEq("value", category_id));
    }

    return null;
  }, [category_id, categoriesList.length]);

  const videoVisibilityStatus = useMemo(() => {
    if (status !== undefined && status !== null) {
      return VIDEO_STATUS_OPTIONS[status];
    }

    return null;
  }, [status]);

  const reactionsColl = useMemo(() => {
    if (loadProgress && loadProgress !== 100) {
      return <ProgressBar loadProgress={loadProgress} onCancel={onCancelUpload} />;
    }

    if (converter && converter.processCodeUpdate === 3 && !loadProgress) {
      return (
        <div className="progress-bar-container">
          <span className="progress-label">Processing</span>
        </div>
      );
    }

    if (converter && converter.progress && converter.processCodeUpdate && !loadProgress) {
      return (
        <ProgressBar
          label={VIDEO_PROCESSING_CODES[converter.processCodeUpdate].label}
          loadProgress={converter.progress}
          supressCancel
        />
      );
    }

    return <LikesRange likesCount={likes} dislikesCount={dislikes} />;
  }, [likes, dislikes, loadProgress, converter]);

  if (invalidVideo) {
    return (
      <Row between="xs" className="video-list-item-wrap invalid">
        <Row className="video-list-item content">
          <Col className="auto-column check-coll" />

          <Col className="video-coll">
            <ul className="video-details-list">
              <li className="thumb">
                <div className="default-thumb">
                  <i className="icon-img-thumb" />
                </div>
              </li>
              <li className="info invalid-video">
                <h5 className="title">{title}</h5>
                <p className="description">invalid video!</p>
              </li>
            </ul>
          </Col>

          <Col className="visibility-coll" />
          <Col className="category-coll viewOnly" />
          <Col className="date-coll">
            <div className="added-date">
              {createdAt && formatDate(createdAt * 1000, "MMM d, yyyy")}
            </div>
          </Col>
          <Col className="views-coll" />
          <Col xs className="reactions-coll-data remove-button">
            <button className="icon icon-remove" onClick={toggleDeleteModalShow} />
          </Col>
        </Row>

        {showDeleteModal && (
          <ConfirmModal
            onClose={toggleDeleteModalShow}
            onConfirm={onDeleteVideo}
            bodyText={`Are you sure you want to delete video: "${title}" ?`}
          />
        )}
      </Row>
    );
  }

  return (
    <Row between="xs" className="video-list-item-wrap">
      <Row className="video-list-item content">
        <Col className="auto-column check-coll">
          <label className="select-check">
            <input readOnly name="check" type="checkbox" checked={checked} />
            <span
              className="label-text"
              onClick={() => {
                (!converter || converter.processCodeUpdate === 0) &&
                  !loadProgress &&
                  onCheck(!!checked);
              }}
            />
          </label>
        </Col>
        <Col className="video-coll">
          <ul className="video-details-list">
            <li className="thumb">
              {thumb ? (
                <div
                  className="video-thumb"
                  style={{ backgroundImage: `url(${thumb.replace("[SIZE]", "140x85")})` }}
                />
              ) : (
                <div className="default-thumb">
                  <i className="icon-img-thumb" />
                </div>
              )}
              {!viewOnly && (
                <button
                  className="icon icon-edit edit-video"
                  onClick={!!isStreamEvent ? toggleStreamDetails : toggleModalShow}
                />
              )}
              {!!isStreamEvent ? (
                <>
                  {is_live && <i className="icon-record" />}
                  <i className="icon-stream" />
                </>
              ) : (
                <span className="duration">{videoDuration}</span>
              )}
            </li>
            <li className="info">
              <div
                className={classes({
                  ["hide-on-hover"]:
                    (!viewOnly && !editOnly && (!converter || converter.processCodeUpdate !== 3)) ||
                    adminMode
                })}
              >
                <h5 className="title">{title}</h5>
                <p className="description">
                  {description && !!description.length && <span>{description}</span>}
                </p>
              </div>

              {adminMode && (
                <div className="controls-hover">
                  <Link to={`/video/${hash_id || video_id}`} className="icon icon-play" />
                  <a
                    href={`${download_url}` || ""}
                    rel="noreferrer"
                    target="_blank"
                    className="icon icon-download"
                    download
                  />
                </div>
              )}
              {!viewOnly && !editOnly && (
                <div className="controls-hover">
                  {isStreamEvent && !stream_ended && (
                    <Link to={`/live/${hash_id || video_id}`} className="icon icon-stream" />
                  )}
                  <Link to={`/video/${hash_id || video_id}`} className="icon icon-play" />
                  <button
                    className="icon icon-permalink"
                    onClick={() => {
                      copyToClipboard(`${window.location.host}/video/${hash_id || video_id}`);
                    }}
                  />

                  {!!comments && (
                    <Link
                      className="icon icon-chat-message-o"
                      to={{
                        pathname: "/profile/comments/inbox",
                        search: `?video_id=${hash_id}`
                      }}
                    />
                  )}

                  <button className="icon icon-remove" onClick={toggleDeleteModalShow} />
                </div>
              )}
            </li>
          </ul>
        </Col>
        <Col className="visibility-coll">
          {(!converter || converter.processCodeUpdate === 0) && (
            <div className="video-status">
              <div
                className={classes("", {
                  ["hide-on-hover"]: !viewOnly
                })}
              >
                <span>{videoVisibilityStatus?.label}</span>
                <i className={videoVisibilityStatus?.icon} />
              </div>
              {!viewOnly && !loadProgress && (
                <div className="controls-hover">
                  <Select
                    classNamePrefix="app-select"
                    className="transparent-select"
                    menuPlacement="auto"
                    placeholder="Draft"
                    isSearchable={false}
                    options={statusOptionsList}
                    onChange={onVisibilityChange}
                    components={{ Option: StatusOption, SingleValue: StatusSelected }}
                    value={videoVisibilityStatus}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
        <Col className="category-coll">
          <div className="selected-category">
            {videoCategory ? videoCategory.label : "Unassigned"}
          </div>
        </Col>
        <Col className="date-coll">
          <div className="added-date">
            {createdAt && formatDate(createdAt * 1000, "MMM d, yyyy")}
          </div>
        </Col>
        <Col className="views-coll">
          <div className="views-number">{abbreviateNumber(views)}</div>
        </Col>
        <Col xs className="reactions-coll-data">
          {reactionsColl}
        </Col>
      </Row>

      {showEditModal && (
        <Modal onClose={toggleModalShow}>
          <EditVideoModal {...props} onClose={toggleModalShow} />
        </Modal>
      )}
      {showStreamModal && (
        <Modal onClose={toggleStreamDetails}>
          <StreamModal onClose={toggleStreamDetails} editMode editedProps={props} />
        </Modal>
      )}

      {showDeleteModal && (
        <ConfirmModal
          onClose={toggleDeleteModalShow}
          onConfirm={onDeleteVideo}
          bodyText={`Are you sure you want to delete video: "${title}" ?`}
        />
      )}
    </Row>
  );
}

export default memo(VideoListItem);
