import { Col, Row } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { VideoModel } from "@Models/Video";
import { requestChannelVideos } from "@Store/actions/channel";
import { LoadingSpinner } from "@Components";
import VideoPostItem from "@Components/VideoPostItem";
import EmptyPublicVideos from "./EmptyPublicVideos";
import "./style.scss";
import { FiltersBar, FilterToggle } from "@Components/Filters";
import { Filters } from "@Models/Filters";

type Props = {
  match: { params: { channel_slug: string } };
};

const defaultPaginationInfo = {
  totalResults: 0,
  resultsPerPage: 10,
  currentPage: 0,
  totalPages: 1
};

function ChannelVideos({ match }: Props) {
  const dispatch = useDispatch();
  const [videosList, setVideosList] = useState<VideoModel[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPaginationInfo);
  const [isPending, setIsPending] = useState(true);
  const [filtersState, setFiltersState] = useState(false);
  const [filtersData, setFiltersData] = useState({} as Filters);

  useEffect(() => {
    onChangeChannelVideosPage(filtersData, true);
  }, [filtersData]);

  const onChangeChannelVideosPage = (filters?: Filters, isFilterAction = false) => {
    dispatch(
      requestChannelVideos(
        match.params.channel_slug,
        isFilterAction ? 1 : pageInfo.currentPage + 1,
        {
          onSuccess: ({ data, pageInfo }: { data: VideoModel[]; pageInfo: any }) => {
            setPageInfo(pageInfo);
            setVideosList(isFilterAction ? data : [...videosList, ...data]);
            setIsPending(false);
          },
          onError: () => {}
        },
        filters
      )
    );
  };

  const onFilterUpdates = (filters: Filters) => {
    setFiltersData(filters);
  };

  if (isPending) {
    return (
      <div className="channel-videos">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="channel-videos">
      <Row between="xs">
        <Col>
          <h2 className="title channel-videos-title">Videos</h2>
        </Col>
        <Col>
          <FilterToggle
            status={filtersState}
            onStateChange={state => {
              setFiltersState(state);
            }}
          />
        </Col>
      </Row>
      <FiltersBar
        isPublic
        isActive={filtersState}
        onFiltersUpdate={onFilterUpdates}
        initialFilters={filtersData}
        onClose={() => {
          setFiltersState(false);
        }}
      />
      {videosList.length ? (
        <>
          <Row className="posts-list">
            {videosList.map((video: VideoModel, i) => (
              <Col md={4} key={`video-${video.video_id}-${i}`} className="recommended-post-coll">
                <VideoPostItem {...video} />
              </Col>
            ))}
          </Row>
          {pageInfo.totalResults !== videosList.length && (
            <button
              type="button"
              className="secondary-button outline channel-load-more"
              onClick={() => {
                onChangeChannelVideosPage(filtersData);
              }}
            >
              Load more
            </button>
          )}
        </>
      ) : (
        <EmptyPublicVideos />
      )}
    </div>
  );
}

export default ChannelVideos;
