import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { requestLogout } from "@Store/actions/user";
import { toggleSidebarMobile } from "@Store/actions/sidebar";
import { statusSidebarMobileVisible } from "@Store/selectors/sidebar";
import { getAvatar } from "@Utils";
import { User } from "@Models/User";
import { getAuthUser } from "@Store/selectors/user";
import CreateChannelModal from "@Views/CreateChannelModal";
import classes from "classnames";

function MobileProfileContextMenu({ full_name, avatar }: User) {
  const dispatch = useDispatch();
  const avatarAlt = getAvatar(full_name);
  const userDetails = useSelector(getAuthUser);
  const mobileSidebarStatus = useSelector(statusSidebarMobileVisible);
  const [createChannel, setCreateChannel] = useState(false);

  const onUserLogout = () => {
    dispatch(requestLogout());
  };

  const visibleMobileSidebar = () => {
    dispatch(toggleSidebarMobile());
  };

  if (createChannel) {
    return <CreateChannelModal setCreateChannelPopup={setCreateChannel} />;
  }

  if (mobileSidebarStatus) {
    return (
      <div className="mobile-profile active">
        <div className="mobile-profile-context-menu">
          <div className="menu-top">
            <div className="menu-top-avatar">
              {avatar ? (
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${avatar.replace("[SIZE]", "36x36")})`
                  }}
                />
              ) : (
                <div
                  className="avatar"
                  style={{
                    backgroundColor: avatarAlt.color
                  }}
                >
                  <span className="name-letters">{avatarAlt.shortLetters}</span>
                </div>
              )}
            </div>
            <div className="menu-top-info">
              <p className="menu-top-info-name">{full_name}</p>
              <p className="menu-top-info-status">Online</p>
            </div>
            <div className="menu-top-close" onClick={visibleMobileSidebar}>
              <i className="icon-close-rounded" />
            </div>
          </div>
          <div className="menu-main">
            {userDetails.role != "creator" ? (
              <div
                className={classes("menu-main-item", {
                  disabled: !userDetails.email_confirmed
                })}
                onClick={visibleMobileSidebar}
              >
                <a
                  onClick={() => {
                    setCreateChannel(true);
                  }}
                >
                  Create Channel
                </a>
              </div>
            ) : (
              <div className="menu-main-item" onClick={visibleMobileSidebar}>
                <Link to={`/channel/${userDetails.channel_slug}`}>My Channel</Link>
              </div>
            )}
            <div className="menu-main-item" onClick={visibleMobileSidebar}>
              <Link to="/profile/settings">Profile Settings</Link>
            </div>
            {userDetails.role != "creator" ? (
              <div className="menu-main-item" onClick={visibleMobileSidebar}>
                <Link to={`/streamer/${userDetails.username}`}>My Profile</Link>
              </div>
            ) : (
              <div className="menu-main-item" onClick={visibleMobileSidebar}>
                <Link to={"/profile/channel-settings"}>Channel Settings</Link>
              </div>
            )}

            <div className="menu-main-item" onClick={visibleMobileSidebar}>
              <span onClick={onUserLogout}>Log out</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div className="mobile-profile" />;
}

export default MobileProfileContextMenu;
