import { createNamespace } from "@Utils";
import { SignInForm, SignUpForm } from "@Models/Authentication";
import { RequestCallbacks } from "@Models/RequestParams";
import { ChangePassword } from "@Models/ChangePassword";
import { ProfileDataUser, User } from "@Models/User";
import { UserImageUpload } from "@Models/UserImageUpload";

export const USER = createNamespace("USER", [
  "SET_DETAILS",
  "GET_DETAILS",
  "UPDATE_DETAILS",
  "REQUEST_UPDATE_DETAILS",
  "REQUEST_FOLLOWING_CHANNELS",
  "SIGN_IN",
  "SIGN_UP",
  "SIGN_IN_WITH_GOOGLE",
  "SIGN_IN_WITH_FACEBOOK",
  "RESET_PASSWORD",
  "RESET_PASSWORD_REQUEST",
  "LOGOUT_REQUEST",
  "LOGOUT_SUCCESS",
  "DELETE_ACCOUNT",
  "DELETE_AVATAR",
  "CHANGE_PASSWORD",
  "CHECK_SLUG",
  "RESEND_EMAIL",
  "UPDATE_IMAGE"
]);

export const requestUserDetails = (username: string, callbacks: RequestCallbacks) => ({
  type: USER.GET_DETAILS,
  payload: { username },
  callbacks
});

export const setUserDetails = (payload: User) => ({
  type: USER.SET_DETAILS,
  payload
});

export const updateUserDetails = (payload: Object) => ({
  type: USER.UPDATE_DETAILS,
  payload
});

export const updateProfileInfo = (
  payload: ProfileDataUser,
  username: string,
  callbacks: RequestCallbacks
) => ({
  type: USER.REQUEST_UPDATE_DETAILS,
  payload,
  username,
  callbacks
});

export const requestSignUp = (payload: SignUpForm, callbacks: RequestCallbacks) => ({
  type: USER.SIGN_UP,
  payload,
  callbacks
});

export const requestSignIn = (payload: SignInForm | string, callbacks: RequestCallbacks) => ({
  type: USER.SIGN_IN,
  payload,
  callbacks
});

export const requestResendEmail = () => ({
  type: USER.RESEND_EMAIL
});

export const signInWithFacebook = () => ({
  type: USER.SIGN_IN_WITH_FACEBOOK
});

export const signInWithGoogle = () => ({
  type: USER.SIGN_IN_WITH_GOOGLE
});

export const requestLogout = () => ({
  type: USER.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: USER.LOGOUT_SUCCESS
});

export const requestResetPassword = (recoveryEmail: string, callbacks: RequestCallbacks) => ({
  type: USER.RESET_PASSWORD,
  payload: {
    recoveryEmail
  },
  callbacks
});

export const requestResetPasswordForm = (
  token: string,
  password: string,
  password2: string,
  callbacks: RequestCallbacks
) => ({
  type: USER.RESET_PASSWORD_REQUEST,
  payload: {
    token,
    password,
    password2
  },
  callbacks
});

export const deleteUserAvatar = (username: string) => ({
  type: USER.DELETE_AVATAR,
  username
});

export const deleteAccount = (callbacks: RequestCallbacks) => ({
  type: USER.DELETE_ACCOUNT,
  callbacks
});

export const changePassword = (payload: ChangePassword, callbacks: RequestCallbacks) => ({
  type: USER.CHANGE_PASSWORD,
  payload,
  callbacks
});

export const checkProfileSlug = (payload: string, callbacks: RequestCallbacks) => ({
  type: USER.CHECK_SLUG,
  payload,
  callbacks
});

export const updateUserImage = (payload: UserImageUpload) => ({
  type: USER.UPDATE_IMAGE,
  payload
});

export const requestUserFollowingChannels = (
  username: string,
  nextPage: number,
  itemsPerPage: number,
  callbacks: RequestCallbacks
) => ({
  type: USER.REQUEST_FOLLOWING_CHANNELS,
  payload: {
    username,
    nextPage,
    itemsPerPage
  },
  callbacks
});
