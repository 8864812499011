import { Row, Col } from "react-flexbox-grid";

type Props = {
  channelLoadStatus: string;
};

function PublicChannelPagePlaceholder({}: Props) {
  return (
    <div className="page-content">
      <Row between="xs" className="single-video-page full-container">
        <Col xs={9} className="video-details">
          <Row className="video-header" />
          <Row className="player-placeholder" />
          <Row between="xs">
            <Col xs={6} className="created-date" />
            <Col xs={6} className="controls-list" />
          </Row>
          <Row between="xs">
            <Col xs={10} className="title" />
          </Row>
          <Row>
            <Col xs={12} className="description" />
          </Row>
          <Row>
            <Col xs={12} className="tags-list" />
          </Row>
        </Col>
        <Col xs={3} className="related-videos-list">
          <div className="videos-list-group">
            <div className="list" />
          </div>

          <div className="videos-list-group">
            <div className="list" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PublicChannelPagePlaceholder;
