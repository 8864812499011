import { Category } from "@Models/Category";
import { CATEGORY } from "../actions/category";

type State = {
  allCategories: Category[];
};

const initialState: State = {
  allCategories: []
};

export default function reducer(state: State = initialState, action: any): State {
  switch (action.type) {
    case CATEGORY.SET_ALL: {
      return {
        ...state,
        allCategories: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
