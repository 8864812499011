import Dropzone from "react-dropzone";
import ListItem from "./ListItem";
import { UPLOAD_VIDEO_FORMATS } from "@Constants";

type UploadDropzoneProps = {
  list: object[];
  handleAddItems: (files: any) => void;
  handleRemoveItem: (itemIndex: any) => void;
  handleUploadVideos: () => void;
  importIsFetching: boolean;
};

const UploadDropzone = ({
  list,
  handleAddItems,
  handleRemoveItem,
  handleUploadVideos,
  importIsFetching
}: UploadDropzoneProps) => {
  const onDrop = (files: File[]) => {
    if (files.length) {
      handleAddItems(
        files.map((file: File) => ({
          file: file,
          title: file.name,
          size: file.size
        }))
      );
    }
  };

  return (
    <div className="upload-container modal-body">
      <h3 className="drop-heading">From your computer</h3>
      {!!list.length && (
        <div className="items-wrapper drop-list">
          {list.map((file: any, index: number) => (
            <ListItem {...file} key={index} index={index} onRemove={handleRemoveItem} />
          ))}
        </div>
      )}

      <Dropzone onDrop={onDrop} accept={UPLOAD_VIDEO_FORMATS} multiple>
        {({ getRootProps, getInputProps }) => (
          <div className="items-wrapper dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <i className="icon icon-upload-cloud" />
            <span>Drop files here or</span>
            <button className="button">Select files</button>
          </div>
        )}
      </Dropzone>

      {!!list.length && (
        <button className="main-button" onClick={handleUploadVideos} disabled={importIsFetching}>
          Upload
        </button>
      )}
    </div>
  );
};

export default UploadDropzone;
