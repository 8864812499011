const isBreakpoint = (breakpoint: string) => {
  switch (breakpoint) {
    case "mobile": {
      return window.matchMedia("(max-width: 767px)").matches;
    }
    case "tablet": {
      return window.matchMedia("(max-width: 992px)").matches;
    }
    case "laptop": {
      return window.matchMedia("(max-width: 1199px)").matches;
    }
  }
};

export default isBreakpoint;
