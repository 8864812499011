import { propEq } from "ramda";
import { createSelector } from "reselect";
import { Store } from "@Models/Store";
import { Channel } from "@Models/Channel";
import { getSelectCategories } from "./category";

export const getStoreChannel = ({ channel }: Store): Channel => channel;

export const getChanelName = ({ channel }: Store): string => channel.name;

export const getChanelData = createSelector(
  getStoreChannel,
  getSelectCategories,
  (channel, categoriesList) => ({
    ...channel,
    category: categoriesList.find(propEq("value", channel.category_id))
  })
);

export const getVideoLibraryCounts = createSelector(getChanelData, ({ counts }) => counts);
