export const VIDEO_PLAYER_SETTINGS = {
  autoplay: false
};

export const VIDEO_PRIVACY_OPTIONS = [
  {
    value: 1,
    label: "Private (Subscribers only)",
    description: "Only Premium members will have access to this video"
  },
  {
    value: 5,
    label: "Public",
    description: "Everyone can watch your video"
  },
  {
    value: 3,
    label: "Unlisted",
    description: "You and anyone with the link can watch your video"
  },
  {
    value: 0,
    label: "Save as draft"
  }
];
