import authorizedApiRequest from "@Fetch/authorized";
import { all, call, takeEvery } from "@redux-saga/core/effects";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import { HOME, DESHBOARD, SUPPORT, sendSupportFormData } from "@Store/actions/pageSections";
import apiRequest from "@Fetch";
import { SupportForm } from "@Models/Support";

function* getHomePageData({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/Home`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* sendSupportFormRequest({
  formData,
  callbacks
}: {
  formData: SupportForm;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/Oauth/Support`, {
    method: "POST",
    body: JSON.stringify(formData)
  });

  if (serverResponse.status === 200) {
    callbacks.onSuccess();
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getDeshboardLikesData({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Dashboard/likes`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getDeshboardTopVideos({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Dashboard/topvideo`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getDeshboardTopComments({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Dashboard/comments`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getDeshboardChannelAnalytics({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Dashboard/analytics`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

function* getDeshboardLastUploadedVideo({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Dashboard/latestupload`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    callbacks.onSuccess(data);
  } else {
    callbacks.onError && callbacks.onError();
  }
}

export default function* pageSectionsSagaWatcher() {
  yield all([
    takeEvery(HOME.GET_DATA, getHomePageData),
    takeEvery(SUPPORT.SEND_SUPPORT_FORM, sendSupportFormRequest),
    takeEvery(DESHBOARD.GET_LIKES, getDeshboardLikesData),
    takeEvery(DESHBOARD.GET_TOP_VIDEOS, getDeshboardTopVideos),
    takeEvery(DESHBOARD.GET_TOP_COMMENTS, getDeshboardTopComments),
    takeEvery(DESHBOARD.GET_CHANNEL_ANALYTICS, getDeshboardChannelAnalytics),
    takeEvery(DESHBOARD.GET_LAST_UPLOADED_VIDEO, getDeshboardLastUploadedVideo)
  ]);
}
