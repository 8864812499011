import { createNamespace } from "@Utils";
import { RequestCallbacks } from "@Models/RequestParams";
import { Filters } from "@Models/Filters";

export const ADMIN = createNamespace("ADMIN", [
  "GET_DATA",
  "GET_DASHBOARD_DATA",
  "GET_CHANNELS",
  "GET_CHANNEL_DETAILS",
  "GET_CRASHED_VIDEOS_DETAILS",
  "GET_ACTIONS",
  "GET_ASSETS_CLASSES",
  "GET_TOPICS",
  "GET_CATEGORIES",
  "GET_SUBCATEGORIES",
  "GET_SUBCATEGORIES_LIST",
  "ADD_CATEGORY",
  "ADD_SUBCATEGORY",
  "ADD_SUBCATEGORIES_LIST",
  "REMOVE_CATEGORY",
  "REMOVE_SUBCATEGORY",
  "REMOVE_SUBCATEGORIES_LIST",
  "PUT_ACTION",
  "PUT_BAN",
  "ASSIGN_ASSETS_CLASS",
  "ASSIGN_CATEGORY_LIST",
  "ASSIGN_SUBCATEGORIES_LIST",
  "ASSIGN_TOPICS",
  "UNASSIGN_ASSETS_CLASS",
  "UNASSIGN_CATEGORY_LIST",
  "UNASSIGN_CATEGORY_LIST_ITEM",
  "UNASSIGN_TOPICS"
]);

export const requestAdminCategories = (callbacks: RequestCallbacks, adminCategory: boolean) => ({
  type: ADMIN.GET_CATEGORIES,
  callbacks,
  payload: { adminCategory }
});

export const requestAdminSubcategories = (
  callbacks: RequestCallbacks,
  adminSubcategory: boolean
) => ({
  type: ADMIN.GET_SUBCATEGORIES,
  callbacks,
  payload: { adminSubcategory }
});

export const requestAdminSubcategoriesList = (callbacks: RequestCallbacks) => ({
  type: ADMIN.GET_SUBCATEGORIES_LIST,
  callbacks
});

export const requestAdminTopics = (callbacks: RequestCallbacks) => ({
  type: ADMIN.GET_TOPICS,
  callbacks
});

export const requestAdminAssetsClasses = (callbacks: RequestCallbacks) => ({
  type: ADMIN.GET_ASSETS_CLASSES,
  callbacks
});

export const adminAddCategories = (categoryName: string, callbacks: RequestCallbacks) => ({
  type: ADMIN.ADD_CATEGORY,
  callbacks,
  payload: { categoryName }
});

export const adminAddSubcategories = (subcategoryName: string, callbacks: RequestCallbacks) => ({
  type: ADMIN.ADD_SUBCATEGORY,
  callbacks,
  payload: { subcategoryName }
});

export const adminAddSubcategoryList = (subcategoryName: string, callbacks: RequestCallbacks) => ({
  type: ADMIN.ADD_SUBCATEGORIES_LIST,
  callbacks,
  payload: { subcategoryName }
});

export const adminAssignAssetsClass = (list: number[], callbacks: RequestCallbacks) => ({
  type: ADMIN.ASSIGN_ASSETS_CLASS,
  callbacks,
  payload: { list }
});

export const adminUnassignAssetsClass = (list: number[], callbacks: RequestCallbacks) => ({
  type: ADMIN.UNASSIGN_ASSETS_CLASS,
  callbacks,
  payload: { list }
});

export const adminCategoryAssignList = (
  categoryId: number,
  list: number[],
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.ASSIGN_CATEGORY_LIST,
  callbacks,
  payload: { categoryId, list }
});

export const adminSubcategoryAssignTopics = (
  subcategoryId: number,
  list: number[],
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.ASSIGN_TOPICS,
  callbacks,
  payload: { subcategoryId, list }
});

export const adminSubcategoryListAssignItems = (
  listId: number,
  list: number[],
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.ASSIGN_SUBCATEGORIES_LIST,
  callbacks,
  payload: { listId, list }
});

export const adminCategoryUnassignList = (
  categoryId: number,
  list: number[],
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.UNASSIGN_CATEGORY_LIST,
  callbacks,
  payload: { categoryId, list }
});

export const adminSubcategoryUnassignTopics = (
  subcategoryId: number,
  topics: number[],
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.UNASSIGN_TOPICS,
  callbacks,
  payload: { subcategoryId, topics }
});

export const adminDeleteCategory = (categoryId: number, callbacks: RequestCallbacks) => ({
  type: ADMIN.REMOVE_CATEGORY,
  callbacks,
  payload: { categoryId }
});

export const adminDeleteSubcategory = (subcategoryId: number, callbacks: RequestCallbacks) => ({
  type: ADMIN.REMOVE_SUBCATEGORY,
  callbacks,
  payload: { subcategoryId }
});

export const adminDeleteSubcategoryList = (
  subcategoryListId: number,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.REMOVE_SUBCATEGORIES_LIST,
  callbacks,
  payload: { subcategoryListId }
});

export const adminUnAssignSubcategoryListItem = (
  listId: number,
  subcategoryId: number,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.UNASSIGN_CATEGORY_LIST_ITEM,
  callbacks,
  payload: { listId, subcategoryId }
});

export const requestAdminGeneralData = (callbacks: RequestCallbacks) => ({
  type: ADMIN.GET_DATA,
  callbacks
});

export const requestAdminDashboardData = (
  requestType: string,
  callbacks: RequestCallbacks,
  interval?: string
) => ({
  type: ADMIN.GET_DASHBOARD_DATA,
  requestType,
  callbacks,
  interval
});

export const requestAdminChannelsData = (
  nextPage = 1,
  itemsPerPage = 10,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.GET_CHANNELS,
  payload: { nextPage, itemsPerPage },
  callbacks
});

export const requestAdminChannelDetailsData = (
  nextPage = 1,
  itemsPerPage = 10,
  slug: string,
  filters: Filters,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.GET_CHANNEL_DETAILS,
  payload: { nextPage, itemsPerPage, slug, filters },
  callbacks
});

export const requestAdminCrashedVideosData = (
  nextPage = 1,
  itemsPerPage = 10,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.GET_CRASHED_VIDEOS_DETAILS,
  payload: { nextPage, itemsPerPage },
  callbacks
});

export const requestAdminAction = (callbacks: RequestCallbacks) => ({
  type: ADMIN.GET_ACTIONS,
  callbacks
});

export const requestAdminBan = (callbacks: RequestCallbacks, banType: string, slug: string) => ({
  type: ADMIN.PUT_BAN,
  callbacks,
  banType,
  slug
});

export const makeAdminAction = (
  checkedVideos: number[],
  actionKey: string,
  callbacks: RequestCallbacks
) => ({
  type: ADMIN.PUT_ACTION,
  payload: { checkedVideos, actionKey },
  callbacks
});
