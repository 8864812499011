import { NavLink, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { getChanelData } from "@Store/selectors/channel";
import { getUserNotifications } from "@Store/selectors/notifications";
import { COMMENTS_PAGE_SECTIONS } from "../../constants";
import RootSection from "./sections";
import "./style.scss";

type CommentsPageTypes = {
  match: {
    url: string;
    path: string;
    params: { channel_slug: string; sectionId: string };
    isExact: boolean;
  };
};

function Comments({ match }: CommentsPageTypes) {
  const userNotifications = useSelector(getUserNotifications)["comments"];
  const channelData = useSelector(getChanelData);

  return (
    <section className="profile-comments-page">
      <Row className="section-heading">
        <Col xs className="auto-column">
          <h1 className="main-heading">Comments</h1>
        </Col>
        <Col xs className="nav-tabs-wrap">
          <Row className="nav-tabs">
            {COMMENTS_PAGE_SECTIONS.map(section =>
              !channelData.channel_id ? (
                section.id === "sent" && (
                  <NavLink
                    exact
                    key={section.id}
                    to={`${match.url}/${section.id}`}
                    activeClassName="active"
                    className="nav-tab"
                  >
                    {section.label}
                    {!!userNotifications[section.id] && (
                      <span className="badge-counter">{userNotifications[section.id]}</span>
                    )}
                  </NavLink>
                )
              ) : (
                <NavLink
                  exact
                  key={section.id}
                  to={`${match.url}/${section.id}`}
                  activeClassName="active"
                  className="nav-tab"
                >
                  {section.label}
                  {!!userNotifications[section.id] && (
                    <span className="badge-counter">{userNotifications[section.id]}</span>
                  )}
                </NavLink>
              )
            )}
          </Row>
        </Col>
      </Row>
      {match.isExact &&
        (!channelData.channel_id ? (
          <Redirect
            to={{
              pathname: `${match.url}/sent`
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: `${match.url}/inbox`
            }}
          />
        ))}
      <Route path={`${match.path}/:sectionId`} render={RootSection as any} />
    </section>
  );
}

export default Comments;
