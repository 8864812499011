import classes from "classnames";
import formatDate from "date-fns/format";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { propEq, remove, update } from "ramda";
import { COMMENTS_REPLIES_DEPTH } from "@Constants";
import { getAvatar } from "@Utils";
import { CommentModel } from "@Models/Video";
import {
  deleteVideoComments,
  updateComment,
  addVideoCommentReply,
  getVideoCommentReplies,
  requestChangeCommentLikeStatus
} from "@Store/actions/video";
import { useEffect, useState } from "react";

import CommentInput from "./CommentInput";
import CommentControls from "./CommentControls";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import { LinkDecorator } from "../index";

const defaultPaginationInfo = {
  totalResults: 0,
  resultsPerPage: 10,
  currentPage: 0,
  totalPages: 1
};
export interface CommentItemTypes extends CommentModel {
  onDeleteReplySuccess: Function;
  onAddReplySuccess: Function;
  onDeleteSuccess: Function;
  onUpdateSuccess: Function;
  toggleLikeStatus: Function;
  isAuthentificated: boolean;
  parent_comment_id: number;
  video_channel_id: number;
  video_id: number;
  onCheck?: (comment_id: number) => void;
  checkedCommentsState?: any;
  onlyUnanswered: boolean;
  isBanned: boolean;
  recursion_depth?: number;
}

function CommentItem({
  onlyUnanswered,
  recursion_depth = 1,
  parent_comment_id = 0,
  video_channel_id,
  comment_id,
  created_at,
  like_status,
  dislikes,
  likes,
  owned,
  unread,
  text,
  replies_count,
  depth,
  replies,
  streamer,
  video_id,
  videoOwning,
  onAddReplySuccess,
  onDeleteReplySuccess,
  onDeleteSuccess,
  onUpdateSuccess,
  toggleLikeStatus,
  isAuthentificated,
  checkedCommentsState,
  onCheck,
  isBanned
}: CommentItemTypes) {
  const dispatch = useDispatch();
  const ownerAvatarAlt = getAvatar(streamer.full_name);

  const [repliesList, setRepliesList] = useState<CommentModel[]>(replies);
  const [showEditInput, setShowEditInput] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showCommetReplies, setShowCommentReplies] = useState(onlyUnanswered);
  const [pageInfo, setPageinfo] = useState(defaultPaginationInfo);

  const showReplies =
    COMMENTS_REPLIES_DEPTH > recursion_depth && COMMENTS_REPLIES_DEPTH > depth + 1;

  useEffect(() => {
    setRepliesList(replies);
  }, [replies.length]);

  const toggleEditMode = () => {
    setShowEditInput(!showEditInput);
  };

  const toggleShowReplyInput = () => {
    setShowReplyInput(!showReplyInput);
  };

  const onRequestCommentReplyies = (shouldReset = false) => {
    dispatch(
      getVideoCommentReplies(video_id, shouldReset ? 1 : pageInfo.currentPage + 1, comment_id, {
        onSuccess: ({ data, pageInfo }: { data: { comments: CommentModel[] }; pageInfo: any }) => {
          setRepliesList(shouldReset ? data.comments : [...repliesList, ...data.comments]);
          setShowCommentReplies(true);
          setPageinfo(pageInfo);
        },
        onError: () => {}
      })
    );
  };

  const getCommentReplies = () => {
    if (showCommetReplies) {
      setShowCommentReplies(false);
    } else {
      if (onlyUnanswered) {
        setShowCommentReplies(true);
      } else {
        onRequestCommentReplyies(true);
      }
    }
  };

  const handleRemoveComment = () => {
    dispatch(
      deleteVideoComments([comment_id], {
        onSuccess: () => {
          onDeleteSuccess(comment_id);
        },
        onError: () => {}
      })
    );
  };

  const handleUpdateComment = (commentValue: string) => {
    if (commentValue.length) {
      dispatch(
        updateComment(comment_id, commentValue, {
          onSuccess: (data: CommentModel) => {
            setShowEditInput(false);
            onUpdateSuccess(data);
          },
          onError: () => {}
        })
      );
    }
  };

  const handleSubmitCommentReply = (replyValue: string) => {
    dispatch(
      addVideoCommentReply(video_id, video_channel_id, comment_id, recursion_depth, replyValue, {
        onSuccess: (data: CommentModel) => {
          setPageinfo({ ...pageInfo, totalResults: pageInfo.totalResults + 1 });
          setRepliesList([data, ...repliesList]);
          onAddReplySuccess(comment_id);
          setShowCommentReplies(true);
          setShowReplyInput(false);
        },
        onError: () => {}
      })
    );
  };

  const onAddCommentReplySuccess = (comment_reply_id: number) => {
    const commentIndex = repliesList.findIndex(propEq("comment_id", comment_reply_id));

    if (commentIndex !== -1) {
      setRepliesList(
        update(
          commentIndex,
          {
            ...repliesList[commentIndex],
            replies_count: repliesList[commentIndex].replies_count + 1
          },
          repliesList
        )
      );
    }
  };

  const onDeleteCommentReplyReplySuccess = (parent_id: number) => {
    const commentIndex = repliesList.findIndex(propEq("comment_id", parent_id));

    if (commentIndex !== -1) {
      setRepliesList(
        update(
          commentIndex,
          {
            ...repliesList[commentIndex],
            replies_count: repliesList[commentIndex].replies_count - 1
          },
          repliesList
        )
      );
    }
  };

  const onDeleteCommentReplySuccess = (comment_reply_id: number) => {
    const commentIndex = repliesList.findIndex(propEq("comment_id", comment_reply_id));

    if (commentIndex !== -1) {
      onDeleteReplySuccess(parent_comment_id);
      setRepliesList(remove(commentIndex, 1, repliesList));
      setPageinfo({ ...pageInfo, totalResults: pageInfo.totalResults - 1 });
    }
  };

  const onUpdateCommentReplySuccess = (details: CommentModel) => {
    const commentIndex = repliesList.findIndex(propEq("comment_id", details.comment_id));

    if (commentIndex !== -1) {
      setRepliesList(update(commentIndex, details, repliesList));
    }
  };

  const toggleCommentReplyLikeStatus =
    (comment_id: number, prevStatus: "like" | "dislike", status: "like" | "dislike") => () => {
      if (isAuthentificated) {
        const next_status = prevStatus !== status ? status : `un${status}`;

        dispatch(
          requestChangeCommentLikeStatus(comment_id, next_status, {
            onSuccess: likesCountData => {
              const commentIndex = repliesList.findIndex(propEq("comment_id", comment_id));

              if (commentIndex !== -1) {
                setRepliesList(
                  update(
                    commentIndex,
                    {
                      ...repliesList[commentIndex],
                      ...likesCountData,
                      like_status: next_status
                    },
                    repliesList
                  )
                );
              }
            },
            onError: () => {}
          })
        );
      }
    };

  return (
    <Row className="comment-item">
      <Row>
        {onCheck && owned && (
          <Col className="check-option check-coll">
            <label className="select-all-option">
              <input
                readOnly
                name="check"
                type="checkbox"
                checked={!!checkedCommentsState[comment_id]}
              />
              <span className="label-text" onClick={() => onCheck(comment_id)} />
            </label>
          </Col>
        )}
        <Col
          className={classes("comment-content-wrapper", {
            ["with-checkbox"]: onCheck && owned,
            ["other-comment"]: !owned,
            unread: unread && onlyUnanswered
          })}
        >
          <Row between="xs" className="comment-label">
            <Col className="comment-owner">
              <Link to={`/streamer/${streamer.username}`}>
                {streamer.avatar ? (
                  <div
                    className="avatar image-avatar"
                    style={{
                      backgroundImage: `url(${streamer.avatar.replace("[SIZE]", "32x32")})`
                    }}
                  />
                ) : (
                  <div
                    className="avatar"
                    style={{
                      backgroundColor: ownerAvatarAlt.color
                    }}
                  >
                    <span className="name-letters">{ownerAvatarAlt.shortLetters}</span>
                  </div>
                )}
                <span>{streamer.full_name}</span>
              </Link>
            </Col>
            <Col xs className="comment-date">
              {formatDate(created_at * 1000, "MMMM d, yyyy")}
            </Col>
          </Row>

          {(showEditInput && (
            <CommentInput
              initialState={text}
              onCancelShow={toggleEditMode}
              onSubmit={handleUpdateComment}
              placeholder="Edit Comment..."
              actionLabel="Edit"
            />
          )) || (
            <Row className="comment-content">
              <Linkify componentDecorator={LinkDecorator}>{text}</Linkify>
            </Row>
          )}

          {isAuthentificated && !onCheck && (
            <CommentControls
              owned={owned}
              isBanned={isBanned}
              videoOwning={videoOwning}
              owner_id={streamer.id}
              showReplies={showReplies}
              toggleShowReplyInput={toggleShowReplyInput}
              toggleEditMode={toggleEditMode}
              isRepliesOption={false}
              removeComment={handleRemoveComment}
              toggleLikeStatus={toggleLikeStatus}
              comment_id={comment_id}
              like_status={like_status}
              likes={likes}
              dislikes={dislikes}
            />
          )}
        </Col>
      </Row>

      <Row
        className={classes("comment-footer", {
          ["with-checkbox"]: !!onCheck
        })}
      >
        {isAuthentificated && !!onCheck && (
          <CommentControls
            owned={owned}
            isDashboardMode
            videoOwning={videoOwning}
            isBanned={isBanned}
            owner_id={streamer.id}
            showReplies={showReplies}
            like_status={like_status}
            toggleShowReplyInput={toggleShowReplyInput}
            toggleEditMode={toggleEditMode}
            removeComment={handleRemoveComment}
            isRepliesOption={!!replies_count}
            toggleLikeStatus={toggleLikeStatus}
            comment_id={comment_id}
            likes={likes}
            dislikes={dislikes}
          />
        )}

        {!!replies_count && showReplies && (
          <div onClick={getCommentReplies} className="replies-toggle">
            {showCommetReplies ? (
              <>
                <i className="icon-caret-up" />
                Hide
              </>
            ) : (
              <>
                <i className="icon-caret-down" />
                View
              </>
            )}
            {` ${(!onlyUnanswered && replies_count) || ""} replies`}
          </div>
        )}
        {showReplyInput && (
          <div className="comment-replies">
            <CommentInput
              onCheck={!!onCheck}
              onSubmit={handleSubmitCommentReply}
              onCancelShow={toggleShowReplyInput}
              placeholder="Add reply..."
              actionLabel="Reply"
            />
          </div>
        )}
        {showCommetReplies && showReplies && !!repliesList.length && (
          <div className="comment-replies">
            {repliesList.map((reply: CommentModel) => (
              <CommentItem
                {...reply}
                isBanned={isBanned}
                videoOwning={videoOwning}
                key={reply.comment_id}
                video_id={video_id}
                onCheck={onCheck}
                checkedCommentsState={checkedCommentsState}
                onlyUnanswered={onlyUnanswered}
                recursion_depth={recursion_depth + 1}
                video_channel_id={video_channel_id}
                parent_comment_id={reply.comment_id}
                isAuthentificated={isAuthentificated}
                onAddReplySuccess={onAddCommentReplySuccess}
                onDeleteSuccess={onDeleteCommentReplySuccess}
                onUpdateSuccess={onUpdateCommentReplySuccess}
                toggleLikeStatus={toggleCommentReplyLikeStatus}
                onDeleteReplySuccess={onDeleteCommentReplyReplySuccess}
              />
            ))}
          </div>
        )}
        {pageInfo.totalResults > repliesList.length && showCommetReplies && (
          <button
            type="button"
            onClick={() => {
              onRequestCommentReplyies();
            }}
            className="secondary-button outline load-more-comments"
          >
            Load more
          </button>
        )}
      </Row>
    </Row>
  );
}

export default CommentItem;
