import { NOTIFICATIONS } from "../actions/notifications";

export default function reducer(state = { comments: {} }, action: any) {
  switch (action.type) {
    case NOTIFICATIONS.SET_NOTIFICATION_STATE: {
      return action.payload;
    }

    case NOTIFICATIONS.UPDATE_COMMENTS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
