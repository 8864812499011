function EmptyFollowing() {
  return (
    <div className="empty-following">
      <i className="icon-user-group" />
      <p className="empty-message">This user doesn’t follow anyone</p>
    </div>
  );
}

export default EmptyFollowing;
