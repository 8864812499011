import { createNamespace } from "@Utils";

export const NOTIFICATIONS = createNamespace("NOTIFICATIONS", [
  "SET_NOTIFICATION_STATE",
  "GET",
  "UPDATE_COMMENTS"
]);

export const setNotificationsState = (payload: any) => ({
  type: NOTIFICATIONS.SET_NOTIFICATION_STATE,
  payload
});

export const getNotifications = () => ({
  type: NOTIFICATIONS.GET
});

export const updateCommentsNotifications = (payload: Object) => ({
  type: NOTIFICATIONS.UPDATE_COMMENTS,
  payload
});
