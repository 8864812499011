import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { Col, Row } from "react-flexbox-grid";
import { requestAdminChannelsData } from "@Store/actions/admin";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import ChannelItem from "./ChannelItem";

type ChannelListType = {
  data: [];
  pageInfo: any;
};

function AllChannels() {
  const dispatch = useDispatch();
  const [channelsList, setChannelsList] = useState({} as ChannelListType);
  const [itemsPerPageCount, setItemsPerPageCount] = useState(ITEMS_PER_PAGE_COUNT_OPTIONS[0]);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    dispatch(
      requestAdminChannelsData(1, itemsPerPageCount.value, {
        onSuccess: data => {
          setChannelsList(data);
        },
        onError: () => null
      })
    );
  }, []);

  const onChangeItemsPerPageCount = (item: any) => {
    setItemsPerPageCount(item);
    onChangeVideosPage(1, item.value);
  };

  const onChangeVideosPage = useCallback(
    (nextPAge: number, itemsPerPage = itemsPerPageCount.value) => {
      dispatch(
        requestAdminChannelsData(nextPAge, itemsPerPage, {
          onSuccess: data => {
            setChannelsList(data);
            setActivePage(nextPAge - 1);
          },
          onError: () => null
        })
      );
    },
    [itemsPerPageCount.value]
  );

  return (
    <>
      <Row className="heading-table">
        <Col xs className="heading-option name-coll">
          Name
        </Col>
        <Col className="heading-option videos-coll">Videos</Col>
        <Col className="heading-option followers-coll">Followers</Col>
        <Col className="heading-option action-coll">Action</Col>
      </Row>
      <div className="channels-list">
        {!!channelsList.data &&
          channelsList.data.map((channel, i) => <ChannelItem {...channel} key={i} />)}
        <Row className="app-pagination">
          {channelsList.pageInfo && channelsList.pageInfo.totalResults > 10 && (
            <Col className="items-per-page-col">
              <span>Show</span>
              <Select
                classNamePrefix="app-select"
                menuPlacement="auto"
                placeholder="Show"
                isSearchable={false}
                value={itemsPerPageCount}
                onChange={onChangeItemsPerPageCount}
                options={ITEMS_PER_PAGE_COUNT_OPTIONS}
              />
            </Col>
          )}
          {channelsList.pageInfo && channelsList.pageInfo.totalPages > 1 && (
            <Col xs>
              <ReactPaginate
                forcePage={activePage}
                pageCount={channelsList.pageInfo.totalPages}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                activeClassName="active"
                pageClassName="page-number"
                onPageChange={({ selected }) => onChangeVideosPage(selected + 1)}
                containerClassName="pagination row"
                previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                nextLabel={<i className="icon icon-chevron-right nav-icon" />}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default AllChannels;
