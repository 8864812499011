import { COMMENTS } from "../actions/video";

export default function reducer(state = [], action: any) {
  switch (action.type) {
    case COMMENTS.SET_BAN_LIST: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}
