import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import AssetsClassesManager from "./AssetClasses";
import CategoriesManager from "./Categories";
import SubcategoriesManager from "./Subcategories";
import SubcategoriesListsManager from "./SubcategoriesLists";
import {
  adminAddCategories,
  adminAddSubcategories,
  adminAddSubcategoryList,
  adminCategoryAssignList,
  adminCategoryUnassignList,
  adminDeleteCategory,
  adminDeleteSubcategory,
  adminDeleteSubcategoryList,
  adminSubcategoryAssignTopics,
  adminSubcategoryListAssignItems,
  adminSubcategoryUnassignTopics,
  adminUnAssignSubcategoryListItem,
  requestAdminCategories,
  requestAdminSubcategories,
  requestAdminSubcategoriesList,
  requestAdminTopics
} from "@Store/actions/admin";
import { AdminCategory } from "@Models/Category";
import { Subcategory } from "@Models/Subcategory";
import { AppSelect } from "@Models/AppSelect";
import { SubcategoryListItem } from "@Models/SubcategoryListItem";

function AdminCategories() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<AdminCategory[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [subcategoriesList, setSubcategoriesList] = useState<SubcategoryListItem[]>([]);
  const [topics, setTopics] = useState<AppSelect[]>([]);

  useEffect(() => {
    dispatch(
      requestAdminCategories(
        {
          onSuccess: (categories: AdminCategory[]) => {
            setCategories(categories);
          }
        },
        true
      )
    );
    dispatch(
      requestAdminSubcategories(
        {
          onSuccess: (subcategories: Subcategory[]) => {
            setSubcategories(subcategories);
          }
        },
        true
      )
    );
    dispatch(
      requestAdminSubcategoriesList({
        onSuccess: (subcategoriesList: any) => {
          setSubcategoriesList(subcategoriesList);
        }
      })
    );
    dispatch(
      requestAdminTopics({
        onSuccess: (topics: any) => {
          setTopics(topics);
        }
      })
    );
  }, []);

  const onAddCategory = (categoryName: string) => {
    dispatch(
      adminAddCategories(categoryName, {
        onSuccess: (data: any) => {
          setCategories([
            ...categories,
            {
              name: categoryName,
              slug: categoryName,
              category_id: data.category_id,
              is_deletable: true
            }
          ]);
        }
      })
    );
  };

  const onAddSubcategory = (subcategoryName: string) => {
    dispatch(
      adminAddSubcategories(subcategoryName, {
        onSuccess: (data: any) => {
          setSubcategories([
            ...subcategories,
            {
              name: subcategoryName,
              slug: subcategoryName,
              is_deletable: true,
              topics: [],
              subcategory_id: data.subcategory_id
            }
          ]);
        }
      })
    );
  };

  const onCategoryAssignList = (selectedList: AppSelect, categoryId: number) => {
    const filteredCategories = categories.map(category =>
      category.category_id === categoryId ? { ...category, list_id: selectedList.value } : category
    );
    dispatch(
      adminCategoryAssignList(categoryId, [selectedList.value], {
        onSuccess: () => {
          setCategories(filteredCategories);
        }
      })
    );
  };

  const onSubcategoryAssignTopics = (selectedTopics: AppSelect[], subcategoryId: number) => {
    const filteredSubcategories = subcategories.map(subcategory =>
      subcategory.subcategory_id === subcategoryId
        ? { ...subcategory, topics: selectedTopics.map((topic: AppSelect) => topic.value) }
        : subcategory
    );
    dispatch(
      adminSubcategoryAssignTopics(
        subcategoryId,
        selectedTopics.map(topic => topic.value),
        {
          onSuccess: () => {
            setSubcategories(filteredSubcategories);
          }
        }
      )
    );
  };

  const onCategoryUnassignList = (listId: number, categoryId: number) => {
    const filteredCategories = categories.map(category =>
      category.category_id === categoryId ? { ...category, list_id: 0 } : category
    );
    dispatch(
      adminCategoryUnassignList(categoryId, [listId], {
        onSuccess: () => {
          setCategories(filteredCategories);
        }
      })
    );
  };

  const onSubcategoryUnassignTopics = (topics: number[], subcategoryId: number) => {
    const filteredSubcategories = subcategories.map(subcategory => {
      const filteredTopics =
        subcategory.subcategory_id === subcategoryId
          ? subcategory.topics.filter(subTopic => !topics.includes(subTopic))
          : subcategory.topics;
      return subcategory.subcategory_id === subcategoryId
        ? { ...subcategory, topics: filteredTopics }
        : subcategory;
    });

    dispatch(
      adminSubcategoryUnassignTopics(subcategoryId, topics, {
        onSuccess: () => {
          setSubcategories(filteredSubcategories);
        }
      })
    );
  };

  const onAddSubcategoriesLists = (subcategoriesListName: string, listItems: number[]) => {
    dispatch(
      adminAddSubcategoryList(subcategoriesListName, {
        onSuccess: ({ list_id }: { list_id: number }) => {
          dispatch(
            adminSubcategoryListAssignItems(list_id, listItems, {
              onSuccess: () => {
                const filteredSubcategories = listItems.map(
                  id => subcategories.filter(sub => sub.subcategory_id === id)[0]
                );

                setSubcategoriesList([
                  ...subcategoriesList,
                  {
                    list_id,
                    name: subcategoriesListName,
                    children: filteredSubcategories
                  }
                ]);
              }
            })
          );
        }
      })
    );
  };

  const onUpdateSubcategoriesLists = (listId: number, listName: string, idsList: number[]) => {
    dispatch(
      adminSubcategoryListAssignItems(listId, idsList, {
        onSuccess: () => {
          const filteredSubcategories = idsList.map(
            id => subcategories.filter(sub => sub.subcategory_id === id)[0]
          );
          const filteredSubcategoriesList = subcategoriesList.map(list =>
            list.list_id === listId
              ? {
                  list_id: listId,
                  name: listName,
                  children: filteredSubcategories
                }
              : list
          );
          setSubcategoriesList(filteredSubcategoriesList);
        }
      })
    );
  };

  const onRemoveSubcategoryFromList = (id: number, list_id: number) => {
    dispatch(
      adminUnAssignSubcategoryListItem(list_id, id, {
        onSuccess: () => {
          const filteredSubcategoriesList = subcategoriesList.map(list => {
            let data = list as SubcategoryListItem;
            if (list.list_id === list_id) {
              const filteredChildren = list.children.filter(i => i.subcategory_id !== id);
              data = { ...list, children: filteredChildren };
            }
            return data;
          });

          setSubcategoriesList(filteredSubcategoriesList);
        }
      })
    );
  };

  const onDeleteCategory = (categoryId: number) => {
    const filteredCategories = categories.filter(category => category.category_id !== categoryId);
    dispatch(
      adminDeleteCategory(categoryId, {
        onSuccess: () => {
          setCategories(filteredCategories);
        }
      })
    );
  };

  const onDeleteSubcategory = (subcategoryId: number) => {
    const filteredCategories = subcategories.filter(
      subcategory => subcategory.subcategory_id !== subcategoryId
    );
    dispatch(
      adminDeleteSubcategory(subcategoryId, {
        onSuccess: () => {
          setSubcategories(filteredCategories);
        }
      })
    );
  };

  const onDeleteSubcategoryList = (listId: number) => {
    dispatch(
      adminDeleteSubcategoryList(listId, {
        onSuccess: () => {
          const filteredSubcategoriesList = subcategoriesList.filter(
            subcategoryList => subcategoryList.list_id !== listId
          );
          const filteredCategories = categories.map(category =>
            category.list_id === listId ? { ...category, list_id: 0 } : category
          );

          setSubcategoriesList(filteredSubcategoriesList);
          setCategories(filteredCategories);
        }
      })
    );
  };

  return (
    <section className="admin-categories">
      <AssetsClassesManager categories={categories} />
      <Row>
        <Col xs={4}>
          <CategoriesManager
            categories={categories}
            onCategoryAssignList={onCategoryAssignList}
            onCategoryUnassignList={onCategoryUnassignList}
            subcategoriesList={subcategoriesList}
            onAddCategory={onAddCategory}
            onDeleteCategory={onDeleteCategory}
          />
        </Col>
        <Col xs={4}>
          <SubcategoriesManager
            onAddSubcategory={onAddSubcategory}
            onDeleteSubcategory={onDeleteSubcategory}
            onSubcategoryAssignTopics={onSubcategoryAssignTopics}
            onSubcategoryUnassignTopics={onSubcategoryUnassignTopics}
            subcategories={subcategories}
            topics={topics}
          />
        </Col>
        <Col xs={4}>
          <SubcategoriesListsManager
            subcategories={subcategories}
            allLists={subcategoriesList}
            onAddSubcategoriesList={onAddSubcategoriesLists}
            onDeleteSubcategoriesList={onDeleteSubcategoryList}
            onUpdateSubcategoriesList={onUpdateSubcategoriesLists}
            onRemoveSubcategoryFromList={onRemoveSubcategoryFromList}
          />
        </Col>
      </Row>
    </section>
  );
}

export default AdminCategories;
