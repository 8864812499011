import { YoutubeLogo } from "@Assets";

type YoutubeUploaderProps = {
  value: string;
  handleSubmitLink: Function;
  onInputChange: Function;
  importIsFetching: boolean;
};

const YoutubeUploader = ({
  value,
  onInputChange,
  handleSubmitLink,
  importIsFetching
}: YoutubeUploaderProps) => {
  return (
    <div className="upload-container">
      <div className="upload-container-header">
        <h3>From Youtube</h3>
        <span>You can add link to the whole channel, to the playlist or add single videos</span>
      </div>
      <div className="items-wrapper">
        <div className="upload-list-item youtube">
          <img src={YoutubeLogo} alt="YT" />
          <input
            onChange={onInputChange as any}
            value={value}
            placeholder="Youtube link"
            className="input"
            autoFocus
            type="text"
          />
        </div>
      </div>
      <button
        className="main-button"
        onClick={handleSubmitLink as any}
        disabled={value.length < 5 || importIsFetching}
      >
        {(importIsFetching && "Loading...") || "Start"}
      </button>
    </div>
  );
};

export default YoutubeUploader;
