import { Link } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestDeshboardTopVideos } from "@Store/actions/pageSections";
import { LoadingSpinner } from "@Components";
import { abbreviateNumber } from "@Utils";

type TopVideoData = {
  hash_id: string;
  title: string;
  views: number;
};

function TopVideosWidget() {
  const dispatch = useDispatch();

  const [data, setData] = useState<TopVideoData[] | null>(null);

  useEffect(() => {
    dispatch(
      requestDeshboardTopVideos({
        onSuccess: setData,
        onError: () => {}
      })
    );
  }, []);

  if (!data) {
    return (
      <div className="widget latest-upload">
        <div className="content">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (!data.length) {
    return <></>;
  }

  return (
    <div className="widget top-videos">
      <div className="content">
        <Row between="xs">
          <Col>
            <h3 className="widget-title">Top videos</h3>
          </Col>
          <Col className="top-label">Views in last 7 days</Col>
        </Row>
        {data.length && (
          <div className="top-views-list">
            {data.map((videoItem: TopVideoData) => (
              <Link
                to={`/video/${videoItem.hash_id}`}
                key={videoItem.hash_id}
                className="video-item"
              >
                <span>{videoItem.title}</span> <span>{abbreviateNumber(videoItem.views)}</span>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default TopVideosWidget;
