import Select, { ValueType } from "react-select";
import { Col } from "react-flexbox-grid";
import TextareaAutosize from "react-textarea-autosize";
import { CheckboxDropdown, TagsLiveSearchInput, ToggleSwitch } from "@Components";
import ThumbnailChooser from "./ThumbnailChooser";
import { useEffect, useState } from "react";
import { AppSelect } from "@Models/AppSelect";
import { useDispatch } from "react-redux";
import { requestChildrenDropdown } from "@Store/actions/video";
import { DropdownChildren } from "@Models/DropdownChildren";
import { isEmpty } from "ramda";

type DetailsFormTypes = {
  formData: any;
  setFormData: () => void;
  setCustomKeyFormValue: Function;
  onSecondMandatory: (status: boolean) => void;
  allCategories: any[];
  allPlaylists: any[];
};

function DetailsForm({
  allPlaylists,
  allCategories,
  setCustomKeyFormValue,
  onSecondMandatory,
  setFormData,
  formData
}: DetailsFormTypes) {
  const dispatch = useDispatch();
  const [descriptionRows, setDescriptionRows] = useState(3);
  const [subcategoriesData, setSubcategoriesData] = useState<AppSelect[]>([]);
  const [country, setCountryData] = useState<AppSelect[]>([]);
  const [sector, setSectorData] = useState<AppSelect[]>([]);
  const [currency, setCurrencyData] = useState<AppSelect[]>([]);

  useEffect(() => {
    if (!!formData.category && !isEmpty(formData.category)) {
      onSecondMandatory(false);
      dispatch(
        requestChildrenDropdown([formData.category.value], "subcategories", {
          onSuccess: (data: DropdownChildren) => {
            data.subcategories && setSubcategoriesData(data.subcategories);
          }
        })
      );
    }
  }, [formData.category]);

  useEffect(() => {
    if (formData.subcategories && formData.subcategories.length) {
      dispatch(
        requestChildrenDropdown(
          formData.subcategories.map((i: AppSelect) => i.value),
          "topics",
          {
            onSuccess: (data: DropdownChildren) => {
              data.countries?.length && setCountryData(data.countries);
              data.sectors?.length && setSectorData(data.sectors);
              data.currency?.length && setCurrencyData(data.currency);
            }
          }
        )
      );
    }
  }, [formData.subcategories]);

  const handleOnDescriptionFocus = () => {
    setDescriptionRows(500);
  };

  const handleOnDescriptionBlur = () => {
    setDescriptionRows(3);
  };

  const onSelectedCategory = (selectedCategory: AppSelect) => {
    setCustomKeyFormValue("subcategories", []);
    resetDropdowns();
    dispatch(
      requestChildrenDropdown([selectedCategory.value], "subcategories", {
        onSuccess: (data: DropdownChildren) => {
          if (data.subcategories?.length) {
            setSubcategoriesData(data.subcategories);
          }
          onSecondMandatory(!!data.subcategories?.length);
        }
      })
    );
    setCustomKeyFormValue("category", selectedCategory);
  };

  const onSelectedSubcategories = (selectedSubcategories: AppSelect[] | any) => {
    resetDropdowns();
    !!selectedSubcategories &&
      dispatch(
        requestChildrenDropdown(
          selectedSubcategories.map((i: AppSelect) => i.value),
          "topics",
          {
            onSuccess: (data: DropdownChildren) => {
              onSecondMandatory(false);
              data.countries?.length && setCountryData(data.countries);
              data.sectors?.length && setSectorData(data.sectors);
              data.currency?.length && setCurrencyData(data.currency);
            }
          }
        )
      );
    setCustomKeyFormValue("subcategories", selectedSubcategories);
  };

  const resetDropdowns = () => {
    setCustomKeyFormValue("countries", []);
    setCustomKeyFormValue("sectors", []);
    setCountryData([]);
    setSectorData([]);
    setCurrencyData([]);
  };
  return (
    <Col xs={6} className="edit-video-form">
      <label htmlFor="video-title">Title</label>
      <input
        type="text"
        className="main-input"
        placeholder="Video title"
        autoComplete="off"
        name="title"
        value={formData.title}
        onChange={setFormData}
        required
      />

      <label htmlFor="video-description">Description</label>

      <div className="auto-size-description">
        <TextareaAutosize
          className="main-input input-description"
          placeholder="Add description"
          name="description"
          onFocus={handleOnDescriptionFocus}
          onBlur={handleOnDescriptionBlur}
          value={formData.description}
          maxRows={descriptionRows}
          minRows={3}
          onChange={setFormData}
        />
      </div>

      <label>Thumbnail</label>
      <p className="addition-description">
        Select or upload a picture that shows what's in your video. A good thumbnail stands out and
        draws viewers' attention.
      </p>
      <ThumbnailChooser
        thumbsList={formData.thumbsList}
        onSelect={nextValue => {
          setCustomKeyFormValue("loadedThumb", nextValue);
        }}
      />

      <p className="addition-description mandatory">Main Category of the video</p>
      <Select
        classNamePrefix="app-select"
        menuPlacement="auto"
        placeholder="Assign Category"
        req
        isSearchable={false}
        value={formData.category}
        options={allCategories}
        onChange={onSelectedCategory}
      />
      {!!subcategoriesData.length && (
        <>
          <p className="addition-description mandatory">Subcategory of the video</p>
          <CheckboxDropdown
            checkboxSelect
            placeholder="Assign Subcategory"
            selectedPlaceholder="subcategories"
            selectedValue={formData.subcategories}
            onApplyActionChange={onSelectedSubcategories}
            options={subcategoriesData}
            noOptionsMessage="No Subcategories"
          />
        </>
      )}

      {!!country.length && (
        <>
          <p className="addition-description">Main geographical focus (*Optional) </p>
          <CheckboxDropdown
            isSearchable
            checkboxSelect
            placeholder="Assign Country"
            selectedPlaceholder="countries"
            selectedValue={formData.countries}
            onApplyActionChange={(selected: ValueType<AppSelect[], any>) => {
              setCustomKeyFormValue("countries", selected);
            }}
            options={country}
            noOptionsMessage="No Countries"
          />
        </>
      )}
      {!!sector.length && (
        <>
          <p className="addition-description">Main sector covered by your video (*Optional)</p>
          <CheckboxDropdown
            checkboxSelect
            selectedValue={formData.sectors}
            placeholder="Assign Sector"
            selectedPlaceholder="sectors"
            onApplyActionChange={(selected: ValueType<AppSelect[], any>) => {
              setCustomKeyFormValue("sectors", selected);
            }}
            options={sector}
            noOptionsMessage="No Sectors"
          />
        </>
      )}
      {!!currency.length && (
        <>
          <p className="addition-description">Main Currency (*Optional)</p>
          <CheckboxDropdown
            checkboxSelect
            selectedValue={formData.currency}
            placeholder="Assign Currency"
            selectedPlaceholder="currency"
            onApplyActionChange={(selected: ValueType<AppSelect[], any>) => {
              setCustomKeyFormValue("currency", selected);
            }}
            options={currency}
            noOptionsMessage="No Currency"
          />
        </>
      )}

      <p className="addition-description">More Tags</p>
      <TagsLiveSearchInput
        selectedValues={formData.tags}
        onValueChanges={nextTags => {
          setCustomKeyFormValue("tags", nextTags);
        }}
      />
      <label htmlFor="channel-playlists">Playlists</label>
      <p className="addition-description">
        Add your video to one or more playlists. Playlists can help viewers discover your content
        faster.
      </p>

      <Select
        classNamePrefix="app-select"
        menuPlacement="auto"
        placeholder="Assign Playlist"
        isSearchable={false}
        value={formData.playlist}
        options={allPlaylists}
        onChange={nextValue => {
          setCustomKeyFormValue("playlist", nextValue);
        }}
      />

      <p className="addition-description" />
      <label htmlFor="channel-comments-settings">Comments and ratings</label>

      <label>
        <ToggleSwitch
          className="show-comments-switch"
          checked={formData.enable_comments}
          onCheck={nextValue => {
            setCustomKeyFormValue("enable_comments", nextValue);
          }}
        />
        <span className="label-texsubcategoriest">Enable comments for this video</span>
      </label>
    </Col>
  );
}

export default DetailsForm;
