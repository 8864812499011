import { useRef, useState } from "react";
import { FilterComponent } from "@Models/FilterComponent";
import { FilterCheckDropdown, FilterRange } from "@Components/Filters";
import { useOnClickOutside } from "@Hooks";
import { FILTERS_LIST } from "./constants";
import "./style.scss";

type Props = {
  activeList?: string[];
  isPublic?: boolean;
  onClickOutside: () => void;
  onFilterApply: (filterValue: FilterComponent) => void;
};

const FilterChooser = ({ onClickOutside, onFilterApply, isPublic, activeList = [] }: Props) => {
  const chooserNode = useRef<HTMLDivElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState({} as FilterComponent);
  useOnClickOutside(chooserNode, onClickOutside);

  return (
    <div className="filter-chooser" ref={chooserNode}>
      {!selectedFilter.id ? (
        <ul className="filter-options">
          <li className="option">
            Filter by <i className="icon-close-rounded" onClick={onClickOutside} />
          </li>
          {FILTERS_LIST.filter(item => !activeList.includes(item.id))
            .filter(item => {
              return isPublic ? item.id !== "visibility" : item;
            })
            .map((filter, key) => (
              <li
                className="option"
                key={key}
                onClick={() => {
                  setSelectedFilter(filter);
                }}
              >
                {filter.label}
              </li>
            ))}
        </ul>
      ) : (
        <>
          {selectedFilter.componentType === "checkDropdown" && (
            <FilterCheckDropdown
              filter={selectedFilter}
              onCancel={onClickOutside}
              onSelectedItem={onFilterApply}
            />
          )}
          {selectedFilter.componentType === "range" && (
            <FilterRange
              filter={selectedFilter}
              onSelectedItem={onFilterApply}
              onCancel={onClickOutside}
            />
          )}
        </>
      )}
    </div>
  );
};
export default FilterChooser;
