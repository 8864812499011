import { FilterComponent } from "@Models/FilterComponent";

export const FILTERS_LIST: FilterComponent[] = [
  {
    label: "Category",
    id: "category",
    componentType: "checkDropdown"
  },
  {
    label: "Subcategory",
    id: "subcategory",
    componentType: "checkDropdown"
  },
  {
    label: "Country",
    id: "country",
    componentType: "checkDropdown"
  },
  {
    label: "Sector",
    id: "sector",
    componentType: "checkDropdown"
  },
  {
    label: "Currency",
    id: "currency",
    componentType: "checkDropdown"
  },
  {
    label: "Visibility",
    id: "visibility",
    componentType: "checkDropdown"
  },
  {
    label: "Views",
    id: "views",
    componentType: "range"
  },
  {
    label: "Likes",
    id: "likes",
    componentType: "range"
  },
  {
    label: "Dislikes",
    id: "dislikes",
    componentType: "range"
  }
];
