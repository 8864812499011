import formatDistance from "date-fns/formatDistance";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { convertToTimeDuration, getAvatar } from "@Utils";
import { memo, useEffect, useState } from "react";
import "./style.scss";

interface Props {
  duration: number;
  thumb: string;
  views: number;
  title: string;
  category_id?: string;
  createdAt: number;
  hash_id: string;
  is_stream?: boolean;
  live_type?: number;
  published_at: number;
  channel?: boolean;
}

let liveTimerInterval: any;

function StreamLivePostItem({
  thumb,
  title,
  is_stream,
  published_at,
  createdAt,
  live_type,
  hash_id,
  channel
}: Props | any) {
  const [liveTimer, setLiveTimer] = useState("");

  useEffect(() => {
    if (published_at) {
      const publishDate: any = published_at * 1000;
      live_type !== 2 && startLiveTimer(publishDate);
      return () => {
        clearInterval(liveTimerInterval);
      };
    }
  }, [published_at]);

  const startLiveTimer = (publishDate: number) => {
    liveTimerInterval = setInterval(() => {
      const currentDate: any = new Date().getTime();
      setLiveTimer(convertToTimeDuration(Math.floor((currentDate - publishDate) / 1000)));
    }, 1000);
  };

  return (
    <div className="video-post-item">
      <div
        className="post-image"
        style={{
          backgroundImage: thumb ? `url(${thumb.replace("[SIZE]", "300x168")})` : ""
        }}
      />
      <div className="content">
        <Row>
          <Col xs>
            <ul className="post-heading-counts">
              <button className="live-btn action-info">
                <i className="icon-record" />
                Live
              </button>
              <li />
            </ul>
          </Col>
          <Col />
          <Link to={`/video/${hash_id}`} className="click-post-area" />
          <Col xs={12}>
            <Link className="play-button" to={`/video/${hash_id}`}>
              <i className="icon-play" />
            </Link>
            <div className="main-info">
              <h3 className="title">{title}</h3>
            </div>
          </Col>
          {createdAt && (
            <>
              {!!channel ? (
                <Col xs className="user-bottom">
                  <Link to={`/channel/${channel.slug}`}>
                    {channel.avatar ? (
                      <div
                        className="avatar"
                        style={{
                          backgroundImage: `url(${channel.avatar.replace("[SIZE]", "100x100")})`
                        }}
                      />
                    ) : (
                      <div
                        className="avatar"
                        style={{
                          backgroundColor: getAvatar(channel.name).color
                        }}
                      >
                        <span>{getAvatar(channel.name).shortLetters}</span>
                      </div>
                    )}
                    <span>{channel.name}</span>
                  </Link>
                </Col>
              ) : (
                <Col xs className="date">
                  {formatDistance(new Date(), createdAt * 1000)}
                </Col>
              )}
              {is_stream ? (
                <Col className="duration">
                  <i className="icon-stream" />
                </Col>
              ) : (
                <Col className="duration duration-timer">
                  <i className="icon-record" />
                  {live_type !== 2 ? liveTimer : "24/7"}
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
    </div>
  );
}

export default memo(StreamLivePostItem);
