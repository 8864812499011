import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { requestChannelStreams } from "@Store/actions/channel";
import { LoadingSpinner } from "@Components";
import { useLocation } from "react-router-dom";
import { VideoModel } from "@Models/Video";
import VideoPostItem from "@Components/VideoPostItem";
import EmptyPublicStreams from "./EmptyPublicStreams";
import "./style.scss";

type Props = {
  match: { params: { channel_slug: string } };
};

const defaultPaginationInfo = {
  totalResults: 0,
  resultsPerPage: 9,
  currentPage: 0,
  totalPages: 1
};

function ChannelStreams({ match }: Props) {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [isPending, setIsPending] = useState(true);
  const [pageInfo, setPageInfo] = useState(defaultPaginationInfo);
  const [streamsList, setStreamsList] = useState<VideoModel[]>([]);

  useEffect(() => {
    onChangeChannelStreamsPage();
  }, [match.params.channel_slug]);

  const onChangeChannelStreamsPage = () => {
    const postsLimit = location.state && location.state.is_live ? 8 : 9;
    dispatch(
      requestChannelStreams(match.params.channel_slug, pageInfo.currentPage + 1, postsLimit, {
        onSuccess: ({ data, pageInfo }: { data: VideoModel[]; pageInfo: any }) => {
          const sortData: any[] = [];
          data.forEach((item: any) => {
            item.is_live ? sortData.unshift(item) : sortData.push(item);
            return;
          });

          setPageInfo({
            ...pageInfo,
            resultsPerPage: postsLimit
          });
          setStreamsList([...streamsList, ...sortData]);
          setIsPending(false);
        },
        onError: () => {}
      })
    );
  };

  if (isPending) {
    return (
      <div className="channel-videos">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="channel-videos">
      <h2 className="title">Streams</h2>
      {streamsList.length ? (
        <>
          <Row className="posts-list">
            {streamsList.map((video: VideoModel | any) =>
              video.is_live ? (
                <Col xs={12} sm={12} md={8} key={video.video_id} className="posts-list-item">
                  <VideoPostItem {...video} isLive />
                </Col>
              ) : (
                <Col xs={12} sm={12} md={4} key={video.video_id} className="posts-list-item">
                  <VideoPostItem {...video} />
                </Col>
              )
            )}
          </Row>
          {pageInfo.totalResults !== streamsList.length && (
            <button
              type="button"
              className="secondary-button outline channel-load-more"
              onClick={onChangeChannelStreamsPage}
            >
              Load more
            </button>
          )}
        </>
      ) : (
        <EmptyPublicStreams />
      )}
    </div>
  );
}

export default ChannelStreams;
