import { useMemo } from "react";
import { Row, Col } from "react-flexbox-grid";
import ChartDoughnut from "@Components/Charts/ChartDoughnut";

type Props = {
  market_allocation: { color: string; label: string; slug: string; value: number }[];
};

function ChannelLatestUpdates({ market_allocation }: Props) {
  const totalVideos = useMemo(
    () => market_allocation.reduce((acc: number, { value }: { value: number }) => acc + value, 0),
    [market_allocation]
  );

  return (
    <Row className="channel-updates">
      <Col className="updates-container">
        <h3>Market allocation</h3>
        <div className="rates-graph">
          {!!market_allocation.length ? (
            <>
              <div className="chart-container">
                <ChartDoughnut
                  radius={56}
                  isVideoMode={true}
                  data={market_allocation}
                  allItems={totalVideos}
                />
              </div>
              <ul className="category-list-allocation">
                {market_allocation.map((item, key) => (
                  <li className="item" key={key}>
                    <span className="category-color" style={{ backgroundColor: item.color }} />
                    {item.label}{" "}
                    <span className="percentage">
                      {((item.value * 100) / totalVideos).toFixed(0)}%
                    </span>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className="chart-no-data">
              <i className="icon-no-data" />
              <p>No Data</p>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default ChannelLatestUpdates;
