import { components } from "react-select";

const CheckboxOption = (props: any) => {
  return (
    <components.Option
      {...props}
      className={
        props.isSelected
          ? "app-select__option app-select__option--is-selected checkbox-option"
          : "app-select__option checkbox-option"
      }
    >
      <input
        type="checkbox"
        className="app-label"
        id={`option-${props.value}`}
        checked={props.isSelected}
        onChange={() => null}
      />
      <label className="label-text">
        <span>{props.label}</span>
      </label>
    </components.Option>
  );
};

export default CheckboxOption;
