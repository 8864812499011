import LiveChatAvatar from "../Avatar";
import "./style.scss";

const LiveChatFooter: React.FC = () => {
  const handleChange = () => {
    console.log("handleChange");
  };

  const handleSubmit = () => {
    console.log("handleChange");
  };

  return (
    <div className="live-chat-footer">
      <div className="live-chat-footer-title">
        <LiveChatAvatar item={{ name: "John Doe", host: true, avatar: "" }} />
        <div className="live-chat-footer-title__count">
          <span>170</span>/200
        </div>
      </div>
      <div className="live-chat-footer-message">
        <div className="live-chat-footer-message__textarea">
          <div
            className="main-input"
            contentEditable
            data-placeholder="Say something.."
            onInput={handleChange}
          />
          <i className="icon-emoji live-chat-footer-message__textarea-icon" />
        </div>
        <button className="main-button tiny-button" onClick={handleSubmit}>
          <i className="icon-chevron-left" />
        </button>
      </div>
    </div>
  );
};

export default LiveChatFooter;
