import { Col, Row } from "react-flexbox-grid";
import ChartDoughnut from "@Components/Charts/ChartDoughnut";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestDeshboardLikesData } from "@Store/actions/pageSections";
import { LoadingSpinner } from "@Components";
import { abbreviateNumber } from "@Utils";

type LikesData = {
  Likes: number;
  Dislikes: number;
};

function LikeStatusWidget() {
  const dispatch = useDispatch();

  const [data, setData] = useState<LikesData | null>(null);

  useEffect(() => {
    dispatch(
      requestDeshboardLikesData({
        onSuccess: setData,
        onError: () => {}
      })
    );
  }, []);

  if (!data) {
    return (
      <div className="widget likes-status">
        <div className="content">
          <h3 className="widget-title">Likes vs Dislikes</h3>
          <Row className="likes-chart">
            <LoadingSpinner />
          </Row>
        </div>
      </div>
    );
  }

  return (
    <div className="widget likes-status">
      <div className="content">
        <h3 className="widget-title">Likes vs Dislikes</h3>
        <Row className="likes-chart">
          <Col sm={6} className="chart-coll">
            {data.Likes + data.Dislikes ? (
              <ChartDoughnut
                isVideoMode={false}
                radius={40}
                data={[
                  { color: "#4f59d9", value: data.Likes, label: "Likes" },
                  { color: "#d4496e", value: data.Dislikes, label: "Dislikes" }
                ]}
                allItems={data.Likes + data.Dislikes}
              />
            ) : (
              <div className="chart-no-data">
                <i className="icon-no-data" />
                <p>No Data</p>
              </div>
            )}
          </Col>
          <Col sm={6}>
            <ul className="labels-items">
              <li>
                <i className="icon-thumbs-up" />
                {abbreviateNumber(data.Likes)} Likes
              </li>
              <li>
                <i className="icon-thumbs-down" />
                {abbreviateNumber(data.Dislikes)} Dislikes
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LikeStatusWidget;
