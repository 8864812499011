import authorizedApiRequest from "@Fetch/authorized";
import apiRequest from "@Fetch";
import { API_URL } from "@Constants";
import { toast } from "react-toastify";
import { all, takeEvery, call, put } from "redux-saga/effects";
import { reconnectUserSockets } from "@Store/actions/app";
import { USER, logoutSuccess, updateUserDetails, requestLogout } from "@Store/actions/user";
import {
  SignInForm,
  SignUpForm,
  ResetPasswordForm,
  ResetPasswordRequestForm
} from "@Models/Authentication";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import { ChangePassword } from "@Models/ChangePassword";
import { UserImageUpload } from "@Models/UserImageUpload";
import { ProfileDataUser, User } from "@Models/User";
import { authSucces } from "./app";

const getSignInRequestBody = (payload: SignInForm | string) => {
  if (typeof payload === "string") {
    return {
      token: payload
    };
  }

  return {
    email: payload.email,
    password: payload.password
  };
};

export function* updatePersistedUserDetails(details = {}) {
  const authUserData = JSON.parse(String(localStorage.getItem("AUTH_USER")));

  if (!!authUserData) {
    localStorage.setItem("AUTH_USER", JSON.stringify({ ...authUserData, ...details }));
  }

  yield put(updateUserDetails(details));
}

function* signInUserSaga({
  payload,
  callbacks
}: {
  payload: SignInForm;
  callbacks: RequestCallbacks;
}) {
  const requestBody = getSignInRequestBody(payload);

  const serverResponse: ServerResponse = yield call(apiRequest, "/oauth/login", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(requestBody)
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    const authUserData = JSON.stringify({
      email: data.email,
      full_name: data.full_name,
      avatar: data.avatar,
      username: data.username,
      role: data.role,
      channel_id: data.channel_id,
      email_confirmed: data.email_confirmed,
      channel_slug: data.channel_slug,
      admin_role: data.admin_role
    });

    localStorage.setItem("AUTH_USER", authUserData);
    localStorage.setItem("ACCESS_TOKEN", data.access_token);
    localStorage.setItem("ACCESS_TOKEN_EXPIRES_ON", `${data.expires_at}`);

    yield call(authSucces, "RESOLVED");
    yield put(reconnectUserSockets());
    yield call(callbacks.onSuccess);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* signUpUserSaga({
  payload,
  callbacks
}: {
  payload: SignUpForm;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(apiRequest, "/oauth/register", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload)
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    const authUserData = JSON.stringify({
      email: data.email,
      full_name: data.full_name,
      username: data.username,
      role: data.role,
      email_confirmed: data.email_confirmed,
      channel_slug: ""
    });

    localStorage.setItem("AUTH_USER", authUserData);
    localStorage.setItem("ACCESS_TOKEN", data.access_token);
    localStorage.setItem("ACCESS_TOKEN_EXPIRES_ON", `${data.expires_at}`);

    yield call(authSucces, "RESOLVED");

    yield put(reconnectUserSockets());

    yield call(callbacks.onSuccess);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* signInWithGoogleSaga() {
  yield (window.location.href = `${API_URL}/Oauth/Social/Google`);
}

function* signInWithFacebookSaga() {
  yield (window.location.href = `${API_URL}/Oauth/Social/Facebook`);
}

export function* logoutUserSaga() {
  yield call(authorizedApiRequest, "/oauth/logout", {
    method: "POST"
  });

  localStorage.removeItem("AUTH_USER");
  localStorage.removeItem("ACCESS_TOKEN");
  localStorage.removeItem("ACCESS_TOKEN_EXPIRES_ON");

  yield put(reconnectUserSockets());
  yield put(logoutSuccess());
}

function* resetPasswordSaga({ payload, callbacks }: ResetPasswordForm) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/oauth/ResetPassword", {
    method: "POST",
    body: JSON.stringify({
      email: payload.recoveryEmail
    })
  });

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

function* resetPasswordRequest({ payload, callbacks }: ResetPasswordRequestForm) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/Oauth/ResetPassword", {
    method: "POST",
    body: JSON.stringify({
      token: payload.token,
      password: payload.password,
      password2: payload.password2
    })
  });

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

function* changePassword({
  payload,
  callbacks
}: {
  payload: ChangePassword;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/Oauth/ChangePassword", {
    method: "POST",
    body: JSON.stringify(payload)
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    localStorage.setItem("ACCESS_TOKEN", data.access_token);
    localStorage.setItem("ACCESS_TOKEN_EXPIRES_ON", `${data.expires_at}`);

    toast.success("Password has been changed!");
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* deleteAccount({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/Oauth/DeleteAccount", {
    method: "POST"
  });

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
    toast.error("Account has been deleted!");

    yield put(reconnectUserSockets());
    yield put(requestLogout());
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* getUserDetails({
  payload: { username },
  callbacks
}: {
  payload: { username: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Profile/${username}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* checkProfileSlug({
  payload,
  callbacks
}: {
  payload: string;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(apiRequest, "/streams/CheckProfileSlug", {
    method: "POST",
    body: JSON.stringify({ username: payload })
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    if (!data.isUsed) {
      yield call(callbacks.onSuccess);
    } else {
      if (callbacks.onError) yield call(callbacks.onError);
    }
  }
}

function* updateProfileInfo({
  payload,
  username,
  callbacks
}: {
  payload: ProfileDataUser;
  username: string;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Profile/${username}`,
    {
      method: "PUT",
      body: JSON.stringify(payload)
    }
  );

  if (serverResponse.status === 200) {
    yield call(updatePersistedUserDetails, {
      username: payload.username,
      about: payload.about,
      full_name: payload.full_name
    });

    yield call(callbacks.onSuccess);
  }
}

function* userImageUpload({ payload }: { payload: UserImageUpload; callbacks: RequestCallbacks }) {
  const formData = new FormData();
  formData.append("username", payload.username as any);
  formData.append("file", payload.file);

  const serverResponse = yield call(authorizedApiRequest, "/streams/Upload", {
    method: "POST",
    body: formData
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield call(updatePersistedUserDetails, { avatar: data.image });
  }
}

function* deleteUserAvatar({ username }: { username: string }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/Streams/Avatar/1", {
    method: "DELETE",
    body: JSON.stringify({ username })
  });

  if (serverResponse.status === 200) {
    yield call(updatePersistedUserDetails, { avatar: null });
  }
}

function* requestUserFollowingChannels({
  payload: { username, nextPage, itemsPerPage },
  callbacks
}: {
  payload: { username: string; nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Profile/${username}/following?page=${nextPage}&limit=${itemsPerPage}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* resendConfirmEmail({ callbacks }: { callbacks: RequestCallbacks }) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/oauth/ResendActivation`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

export default function* userSagaWatcher() {
  yield all([
    takeEvery(USER.SIGN_UP, signUpUserSaga),
    takeEvery(USER.SIGN_IN, signInUserSaga),
    takeEvery(USER.SIGN_IN_WITH_GOOGLE, signInWithGoogleSaga),
    takeEvery(USER.SIGN_IN_WITH_FACEBOOK, signInWithFacebookSaga),
    takeEvery(USER.LOGOUT_REQUEST, logoutUserSaga),
    takeEvery(USER.RESET_PASSWORD, resetPasswordSaga),
    takeEvery(USER.RESET_PASSWORD_REQUEST, resetPasswordRequest),
    takeEvery(USER.CHANGE_PASSWORD, changePassword),
    takeEvery(USER.DELETE_AVATAR, deleteUserAvatar),
    takeEvery(USER.DELETE_ACCOUNT, deleteAccount),
    takeEvery(USER.GET_DETAILS, getUserDetails),
    takeEvery(USER.CHECK_SLUG, checkProfileSlug),
    takeEvery(USER.REQUEST_UPDATE_DETAILS, updateProfileInfo),
    takeEvery(USER.UPDATE_IMAGE, userImageUpload),
    takeEvery(USER.REQUEST_FOLLOWING_CHANNELS, requestUserFollowingChannels),
    takeEvery(USER.RESEND_EMAIL, resendConfirmEmail)
  ]);
}
