import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { remove } from "ramda";
import { YoutubePlaylist } from "@Models/Video";
import { requestYoutubeVideos, uploadLocalVideos, uploadYoutubeVideos } from "@Store/actions/video";
import UploadDropzone from "./UploadDropzone";
import YoutubeUploader from "./YoutubeUploader";
import YoutubePreview from "./YoutubePreview";

const initialListState = {
  youtubePlaylists: [],
  localFiles: [],
  youtube: ""
};

type loadedDataModel = {
  youtubePlaylists: YoutubePlaylist[];
  localFiles: object[];
  youtube: string;
};

function UploadSource({
  setShowArrowBack,
  showArrowBack,
  onCloseModal
}: {
  setShowArrowBack: Function;
  onCloseModal: Function;
  showArrowBack: boolean;
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [importIsFetching, setImportIsFetching] = useState(false);
  const [loadedData, setLoadedData] = useState(initialListState as loadedDataModel);

  useEffect(() => {
    if (!showArrowBack) {
      setLoadedData(initialListState);
      setImportIsFetching(false);
    }
  }, [showArrowBack, loadedData.localFiles]);

  const handleAddItems = (items: object[]) => {
    const localFiles = [...loadedData.localFiles, ...items];

    setShowArrowBack(!!localFiles.length);
    setLoadedData({ ...loadedData, localFiles });
  };

  const handleRemoveItem = (index: number) => {
    const localFiles = remove(index, 1, loadedData.localFiles);

    setShowArrowBack(!!localFiles.length);
    setLoadedData({ ...loadedData, localFiles });
  };

  const handleChangeYoutubeLink = (e: { target: { value: string } }) => {
    setLoadedData({ ...loadedData, youtube: e.target.value });
  };

  const handleSubmitYoutubeLink = () => {
    setImportIsFetching(true);

    dispatch(
      requestYoutubeVideos(loadedData.youtube, {
        onSuccess: playlists => {
          setShowArrowBack(true);
          setImportIsFetching(false);
          setLoadedData({ ...loadedData, youtubePlaylists: playlists });
        },
        onError: () => {
          setImportIsFetching(false);
        }
      })
    );
  };

  const onCancelYoutubePreview = () => {
    setLoadedData({ ...loadedData, youtubePlaylists: [] });
  };

  const handleSubmitYoutubeVideos = (videoList: any[]) => {
    setImportIsFetching(true);
    dispatch(uploadYoutubeVideos(videoList));
    history.push("/profile/library");
    onCloseModal();
  };

  const handleSubmitLocalVideos = () => {
    setImportIsFetching(true);

    dispatch(uploadLocalVideos(loadedData.localFiles));
    history.push("/profile/library/videos");
    onCloseModal();
  };

  if (loadedData.youtubePlaylists.length) {
    return (
      <YoutubePreview
        onCancel={onCancelYoutubePreview}
        playlists={loadedData.youtubePlaylists}
        handleUploadVideos={handleSubmitYoutubeVideos}
        importIsFetching={importIsFetching}
      />
    );
  }

  return (
    <div className="upload-source-chooser">
      <UploadDropzone
        list={loadedData.localFiles}
        handleAddItems={handleAddItems}
        handleRemoveItem={handleRemoveItem}
        handleUploadVideos={handleSubmitLocalVideos}
        importIsFetching={importIsFetching}
      />
      {!showArrowBack && (
        <YoutubeUploader
          value={loadedData.youtube}
          onInputChange={handleChangeYoutubeLink}
          handleSubmitLink={handleSubmitYoutubeLink}
          importIsFetching={importIsFetching}
        />
      )}
    </div>
  );
}

export default UploadSource;
