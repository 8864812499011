import { VideoStatus } from "@Models/VideoStatus";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_API_URL || "";

export const UPLOAD_VIDEO_FORMATS = [
  "video/x-flv",
  "video/mpeg",
  "video/x-msvideo",
  "video/wmv",
  "video/3gpp",
  "video/quicktime",
  "video/mp4",
  "video/webm",
  "video/avi",
  "video/x-matroska"
];

export const UPLOAD_IMAGE_FORMATS = ["image/jpeg", "image/gif", "image/png", "image/webp"];

export const AVATAR_BACKGROUND_COLORS = [
  "#44355B",
  "#31263E",
  "#ECA72C",
  "#EE5622",
  "#00798C",
  "#1C6DB1",
  "#510087",
  "#A8577E",
  "#F4A5AE",
  "#AA7DCE",
  "#3B429F",
  "#60495A",
  "#1BAD91",
  "#8D5B4C",
  "#A5978B",
  "#9DA3A4",
  "#548687",
  "#AD2831",
  "#5F7AA7",
  "#ADBB86",
  "#1DA0EC",
  "#4F59D9",
  "#A83B62",
  "#BB6BD9"
];

export const CATEGORY_CHART_COLORS = [
  "#0A2676",
  "#3842BF",
  "#5986FF",
  "#70A6E2",
  "#53CAEE",
  "#D4E3F0",
  "#76C3B6",
  "#29937F",
  "#00798C",
  "#A83B62",
  "#802054",
  "#60495A",
  "#A5978B",
  "#ADBB86",
  "#C6A56A",
  "#A982B8",
  "#A982B8",
  "#44355B",
  "#2D2239"
];

export const VIDEO_STATUS_OPTIONS: { [key: number]: VideoStatus } = {
  0: {
    icon: "icon-file-erase",
    label: "Draft",
    value: 0
  },
  1: {
    icon: "icon-lock",
    label: "Private",
    value: 1
  },
  3: {
    icon: "icon-eye",
    label: "Unlisted",
    value: 3
  },
  2: {
    icon: "icon-user-group",
    label: "Exclusive",
    value: 2
  },
  5: {
    icon: "icon-wireless",
    label: "Public",
    value: 5
  }
};

export const ITEMS_PER_PAGE_COUNT_OPTIONS = [
  {
    label: "10",
    value: 10
  },
  {
    label: "15",
    value: 15
  },
  {
    label: "20",
    value: 20
  },
  {
    label: "50",
    value: 50
  },
  {
    label: "100",
    value: 100
  }
];

export const VIDEO_PROCESSING_CODES = {
  0: {
    label: "",
    value: 0
  },
  1: {
    label: "Downloading",
    value: 1
  },
  2: {
    label: "Processing",
    value: 2
  },
  3: {
    label: "Processing",
    value: 3
  }
};

export const ADMIN_ROLES = {
  superAdmin: 2,
  manager: 1
};

export const FILTER_UPLOAD_DATE = [
  { label: "Last Hour", value: "hour" },
  { label: "Today", value: "today" },
  { label: "Last Week", value: "week" },
  { label: "Last Month", value: "month" },
  { label: "Last Year", value: "year" }
];

export const FILTER_DASHBOARD_ITEMS = [
  { label: "1D", value: "1d" },
  { label: "1W", value: "1w" },
  { label: "1M", value: "1m" },
  { label: "3M", value: "3m" },
  { label: "YTD", value: "ytd" },
  { label: "1Y", value: "1y" }
];

export const SORT_VIDEOS_BY = [
  { label: "Upload date", value: "upload" },
  { label: "Views", value: "views" },
  { label: "Rating", value: "rating" }
];

export const SORT_CHANNELS_BY = [
  { label: "Relevance", value: "relevance" },
  { label: "Subscribers", value: "subscribers" },
  { label: "Rating", value: "rating" }
];

export const COMMENTS_REPLIES_DEPTH = 2;
