import DatePicker from "react-datepicker";
import classes from "classnames";
import { useHistory } from "react-router";
import { useState, memo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { propEq } from "ramda";
import { toast } from "react-toastify";
import { LIVE_TYPES, STREAM_PRIVACY_OPTIONS } from "./constants";
import { getSelectCategories } from "@Store/selectors/category";
import { Row, Col } from "react-flexbox-grid";
import { useForm } from "@Hooks";
import {
  createStreamEvent,
  requestUploadVideoThumbnail,
  requestVideoDetails,
  updateStreamEvent
} from "@Store/actions/video";
import { ToggleSwitch } from "@Components";
import { AppSelect } from "@Models/AppSelect";
import { SteamModalDefaultData, StreamSetup } from "@Models/LiveStream";
import WebcamInputs from "@Views/StreamModal/WebcamInputs";
import PreviewForm from "./PreviewForm";
import SetupForm from "./SetupForm";
import "./style.scss";
import ThumbPreview from "@Views/StreamModal/ThumbPreview";
import WebcamPreview from "@Views/StreamModal/WebcamPreview";
import WebStream from "@Views/StreamModal/WebStream";

type ACTIVE_FORM_TYPES = "SETUP" | "PREVIEW";

const defaultFormData: SteamModalDefaultData = {
  title: "",
  live_type: LIVE_TYPES[0],
  description: "",
  stream_key: "",
  stream_server: "",
  video_id: 0,
  tags: [],
  status: STREAM_PRIVACY_OPTIONS[0].value,
  live_chat: true,
  enable_comments: false,
  category_id: 0,
  published_at: new Date(),
  isSchedule: false,
  autostart: false,
  advanced: true,
  loadedThumb: null,
  save_status: 0,
  thumb: {
    file: null,
    preview: null
  }
};

let tempFile: File | null = null;

function StreamModal({
  onSetActiveModal,
  onClose,
  editMode = false,
  editedProps,
  isManager
}: {
  onSetActiveModal?: Function;
  onClose?: Function;
  editMode?: boolean;
  isManager?: boolean;
  editedProps?: any;
}) {
  const dispatch = useDispatch();
  const allCategories = useSelector(getSelectCategories);
  const [activeForm, setActiveForm] = useState<ACTIVE_FORM_TYPES>("SETUP");
  const [advancedStream, setAdvancedStream] = useState(true);
  const [formData, setFormData, setCustomKeyFormValue, setStreamData] = useForm(defaultFormData);
  const updateImageInput: any = useRef(null);
  const history = useHistory();

  useEffect(() => {
    editMode &&
      dispatch(
        requestVideoDetails(editedProps.video_id, {
          onSuccess: (data: any) => {
            const category = allCategories.find(propEq("value", data.category_id));
            const live_type = LIVE_TYPES.find(propEq("value", data.live_type));

            setAdvancedStream(data.advanced);
            setStreamData({
              ...formData,
              ...data,
              category,
              live_type,
              thumb: {
                file: null,
                preview: data.thumb
              }
            });
          },
          onError: () => {}
        })
      );
  }, []);

  const showSetupForm = () => {
    setActiveForm("SETUP");
  };

  const onStreamSetup = () => {
    const data: StreamSetup = {
      title: formData.title,
      description: formData.description,
      category_id: formData.category_id,
      live_chat: formData.live_chat,
      status: formData.status,
      enable_comments: formData.enable_comments,
      tags: formData.tags.map((tag: AppSelect) => tag.value),
      live_type: formData.live_type.value,
      autostart: formData.autostart,
      advanced: advancedStream
    };

    formData.isSchedule && (data.published_at = formData.published_at.getTime() / 1000);
    if (formData.title && formData.category_id && formData.live_type) {
      if (formData.video_id) {
        dispatch(
          updateStreamEvent(data, formData.video_id, {
            onSuccess: () => {
              if (JSON.stringify(tempFile) !== JSON.stringify(formData.thumb.file)) {
                dispatch(
                  requestUploadVideoThumbnail(formData.thumb.file, formData.video_id, "stream", {
                    onSuccess: () => null,
                    onError: () => {}
                  })
                );
              }
              validateScheduleEvent();
            },
            onError: () => null
          })
        );
      } else {
        tempFile = formData.thumb.file;

        dispatch(
          createStreamEvent(data, {
            onSuccess: (data: any) => {
              setCustomKeyFormValue("video_id", data.video_id);
              setCustomKeyFormValue("stream_key", data.stream_key);
              setCustomKeyFormValue("hash_id", data.hash_id);
              setCustomKeyFormValue("stream_server", data.stream_server);
              if (formData.thumb.file) {
                dispatch(
                  requestUploadVideoThumbnail(formData.thumb.file, data.video_id, "stream", {
                    onSuccess: () => null,
                    onError: () => {}
                  })
                );
              }
              validateScheduleEvent();
            },
            onError: () => null
          })
        );
      }
    } else {
      toast.error("Complete all fields !");
    }
  };

  const validateScheduleEvent = () => {
    if (!formData.isSchedule && !editMode) {
      setActiveForm("PREVIEW");
    } else {
      onClose && onClose(formData);
      onSetActiveModal && onSetActiveModal("");
      !formData.video_id && history.push("/profile/library/videos/streams");
    }
  };

  const handleUploadImage = () => {
    updateImageInput.current.click();
  };

  const handleCheckImage = (event: any) => {
    setCustomKeyFormValue("thumb", {
      file: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0])
    });
  };

  const onGoLiveStudio = () => {
    const data: any = {
      title: formData.title,
      description: formData.description,
      category_id: formData.category_id,
      live_chat: formData.live_chat,
      status: formData.status,
      enable_comments: formData.enable_comments,
      tags: formData.tags.map((tag: AppSelect) => tag.value),
      live_type: formData.live_type.value,
      autostart: formData.autostart,
      advanced: advancedStream
    };
    dispatch(
      updateStreamEvent(data, formData.video_id, {
        onSuccess: () => {
          onSetActiveModal && onSetActiveModal("");
          history.push(`/live/${formData.hash_id}`);

          if (JSON.stringify(tempFile) !== JSON.stringify(formData.thumb.file)) {
            dispatch(
              requestUploadVideoThumbnail(formData.thumb.file, formData.video_id, "stream", {
                onSuccess: () => null,
                onError: () => {}
              })
            );
          }
        },
        onError: () => null
      })
    );
  };

  return (
    <div className="init-stream-modal modal-container">
      <div
        className={classes("modal-header stream-modal-header", {
          ["manager"]: isManager
        })}
      >
        <Row>
          <Col className="auto-column">
            <h2 className="modal-heading">
              {activeForm === "SETUP" ? "Stream Event" : "Stream Preview"}
            </h2>
          </Col>
          <Col
            xs
            className={classes("schedule-options", {
              ["preview-mode"]: activeForm === "PREVIEW"
            })}
          >
            {!isManager && !formData.stream_ended && (
              <label className="schedule-toggle">
                <ToggleSwitch
                  className="show-comments-switch"
                  checked={formData.isSchedule}
                  onCheck={nextValue => {
                    activeForm !== "PREVIEW" && setCustomKeyFormValue("isSchedule", nextValue);
                  }}
                />
                <span className="label-text">Schedule for later</span>
              </label>
            )}
            {formData.isSchedule && (
              <div className="date-picker-container">
                <div className="select-container">
                  <DatePicker
                    className="main-input date-picker"
                    readOnly={activeForm === "PREVIEW"}
                    placeholderText="Choose date"
                    selected={formData.published_at}
                    onChange={(date: any) => setCustomKeyFormValue("published_at", date)}
                  />
                  <i className="icon-calendar" />
                </div>
                <div className="select-container">
                  <DatePicker
                    selected={formData.published_at}
                    onChange={(date: any) => setCustomKeyFormValue("published_at", date)}
                    showTimeSelect
                    readOnly={activeForm === "PREVIEW"}
                    showTimeSelectOnly
                    className="main-input time-picker"
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                  <i className="icon-clock-o" />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="init-stream modal-body">
        {!isManager && (
          <Row between="xs" className="stream-mode-tabs">
            <Col xs={6}>
              <div
                className={classes("stream-tab left", {
                  ["active"]: advancedStream
                })}
                onClick={() => {
                  setAdvancedStream(true);
                }}
              >
                Advanced
              </div>
            </Col>
            <Col xs={6}>
              <div
                className={classes("stream-tab right", {
                  ["active"]: !advancedStream
                })}
                onClick={() => {
                  setAdvancedStream(false);
                }}
              >
                Webstream
              </div>
            </Col>
          </Row>
        )}
        <div className="modal-content-container">
          <Row className="stream-modal-content">
            <Col xs={6} className="thumb-preview">
              {!advancedStream && <WebStream />}
              <span className="section-label">Thumbnail</span>
              <ThumbPreview preview={formData.thumb.preview} />
              {activeForm !== "PREVIEW" && (
                <Row className="details-row">
                  <span className="value">
                    <button className="thumb-change-button" onClick={handleUploadImage}>
                      <i className="icon icon-upload-cloud" />
                      Change thumbnail
                    </button>
                    <input
                      type="file"
                      ref={updateImageInput}
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={handleCheckImage}
                      style={{ display: "none" }}
                    />
                  </span>
                </Row>
              )}

              {formData.stream_key && !formData.stream_ended && advancedStream && (
                <Row className="details-row gear-setup">
                  <span className="label">Gear Setup</span>
                  <span className="value">
                    <input
                      type="text"
                      className="main-input"
                      placeholder="Stream key"
                      readOnly
                      value={formData.stream_key}
                    />
                    <input
                      type="text"
                      className="main-input"
                      placeholder="Server URL Address"
                      value={formData.stream_server}
                      readOnly
                    />
                  </span>
                </Row>
              )}
            </Col>

            {(activeForm === "SETUP" && (
              <SetupForm
                formData={formData}
                setFormData={setFormData}
                setCustomKeyFormValue={setCustomKeyFormValue}
                allCategories={allCategories}
              />
            )) || (
              <PreviewForm
                formData={formData}
                setFormData={setFormData}
                allCategories={allCategories}
              />
            )}
            <Col xs={6} className="init-stream-form" />
          </Row>
        </div>
      </div>
      <div className="modal-footer">
        <Row>
          <Col className="video-status" xs={6} />
          <Col className="submit-controls" xs={6}>
            {(activeForm === "SETUP" && (
              <a
                className={
                  formData.isSchedule
                    ? "main-button"
                    : !formData.video_id && !editMode
                    ? "main-button next-button"
                    : "main-button"
                }
                onClick={onStreamSetup}
              >
                {formData.stream_ended
                  ? "Update"
                  : formData.isSchedule
                  ? "Schedule"
                  : !formData.video_id && !editMode
                  ? "Create Stream"
                  : "Update Stream"}
              </a>
            )) || (
              <>
                <a className="back-button" onClick={showSetupForm}>
                  Back
                </a>
              </>
            )}
            {((editMode && !formData.isSchedule && !isManager && !formData.stream_ended) ||
              (!formData.isSchedule && activeForm !== "SETUP")) && (
              <a className="main-button go-live" onClick={onGoLiveStudio}>
                Live Studio
              </a>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default memo(StreamModal);
