import { Row } from "react-flexbox-grid";
import ChannelCard from "@Components/ChannelCard";

type Props = {
  market_allocation: { color: string; label: string; slug: string; value: number }[];
  similar_channels: { followers: any; image: string | null; name: string; slug: string }[];
};

function SimilarChannels({ market_allocation, similar_channels }: Props) {
  return (
    <>
      <h2 className="section-title">Discover similar Channels</h2>
      <div className="channel-tags-list tags-list">
        {market_allocation.map(category => (
          <div className="video-tag" key={category.slug}>
            {category.label}
          </div>
        ))}
      </div>

      <Row className="similar-channels-list">
        {similar_channels.map(channel => (
          <ChannelCard key={channel.slug} {...channel} />
        ))}
      </Row>
    </>
  );
}

export default SimilarChannels;
