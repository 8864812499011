import { Row, Col } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { deleteVideoComments } from "@Store/actions/video";

type Props = {
  idsList: any[];
  onDeleteSuccess: () => void;
};

function HeadingControls({ idsList, onDeleteSuccess }: Props) {
  const dispatch = useDispatch();

  const onBanCommentsOwners = () => {};

  const onDeleteCommentsList = () => {
    dispatch(
      deleteVideoComments(idsList, {
        onSuccess: () => {
          onDeleteSuccess();
        },
        onError: () => {}
      })
    );
  };

  return (
    <Row className="heading-controls">
      <Col className="control-item selected-items-count">{idsList.length} SELECTED</Col>

      <Col className="control-item delete-button" onClick={onBanCommentsOwners}>
        <i className="icon icon-stop-hand" />
        <span>Ban</span>
      </Col>

      <Col className="control-item delete-button" onClick={onDeleteCommentsList}>
        <i className="icon icon-remove" />
        <span>Delete</span>
      </Col>
    </Row>
  );
}

export default HeadingControls;
