import classes from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { updateSaveStreamOption } from "@Store/actions/video";
import { getStreamManagerDetails } from "@Store/selectors/video";
import Modal from "../Modal";
import "./style.scss";

type ConfirmPopupProps = {
  bodyText: string;
  actionText?: string;
  isPending?: boolean;
  streamSaveOption?: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

function ConfirmModal({
  onClose,
  onConfirm,
  bodyText,
  isPending,
  actionText,
  streamSaveOption = false
}: ConfirmPopupProps) {
  const dispatch = useDispatch();
  const streamManager: any = useSelector(getStreamManagerDetails);

  return (
    <Modal onClose={onClose}>
      <div
        className={classes("confirm-popup modal-container", {
          ["stop-stream-modal"]: streamSaveOption
        })}
      >
        <h2 className="modal-heading">Please confirm</h2>
        <p className="popup-description">{bodyText}</p>
        {streamSaveOption && (
          <label className="save-option">
            <input
              type="checkbox"
              name="check"
              checked={streamManager.saveStream}
              onChange={() => {
                dispatch(updateSaveStreamOption(!streamManager.saveStream));
              }}
            />
            <span className="label-text">Save stream on platform</span>
          </label>
        )}
        <button
          type="button"
          className="popup-button warning"
          onClick={onConfirm}
          disabled={isPending}
        >
          {actionText ? actionText : "Delete"}
        </button>

        <span className="action-link" onClick={onClose}>
          Cancel
        </span>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
