export const STREAM_PRIVACY_OPTIONS = [
  {
    value: 5,
    label: "Public"
  },
  {
    value: 3,
    label: "Unlisted"
  },
  {
    value: 2,
    label: "Exclusive (Subscribers only)"
  },
  {
    value: 1,
    label: "Private"
  }
];

export const LIVE_TYPES = [
  {
    value: 1,
    label: "Regular (until 4h)"
  },
  {
    value: 2,
    label: "24/7 Live"
  }
];
