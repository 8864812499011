import classes from "classnames";
import { useDebounce } from "@Hooks";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FilterToggle } from "@Components/Filters";
import { FILTER_UPLOAD_DATE, SORT_CHANNELS_BY, SORT_VIDEOS_BY } from "@Constants";
import { equals, reject } from "ramda";

type FiltersProps = {
  onFiltersUpdate: (filters: any) => void;
};

const CategoriesChannelsFilters = ({ onFiltersUpdate }: FiltersProps) => {
  const [filtersState, setFiltersState] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [outAnimate, setOutAnimate] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState("upload");
  const [selectedFilterUpload, setSelectedFilterUpload] = useState("");
  const [selectedLetterFilter, setSelectedLetterFilter] = useState("all");
  const [filtersReadyState, setFiltersReadyState] = useState(false);
  const debouncedAppliedFilters = useDebounce(appliedFilters, 1000);
  const letters = "abcdefghijklmnopqrstuvwxyz".split("");

  useEffect(() => {
    const outputFilters = reject(equals([]))(debouncedAppliedFilters);
    filtersReadyState && onFiltersUpdate(outputFilters);
  }, [debouncedAppliedFilters]);

  const onSortRadioUpdate = (query: string, sortSlug: string) => {
    setFiltersReadyState(true);
    setAppliedFilters({
      ...appliedFilters,
      [query]: sortSlug
    });
  };

  return (
    <>
      <FilterToggle
        status={filtersState}
        onStateChange={state => {
          setFiltersState(state);
          setOutAnimate(true);
        }}
      />
      <Row
        start="xs"
        className={classes("block-filters", {
          "filters-in": filtersState,
          "filters-out": !filtersState && outAnimate
        })}
      >
        <Col md={3} className="filter-coll">
          <h4 className="filter-title">Last Active</h4>
          <ul className="sort-list-chooser">
            {FILTER_UPLOAD_DATE.map((sort, i) => (
              <li key={i}>
                <input
                  type="radio"
                  id={sort.value}
                  value={sort.value}
                  checked={sort.value === selectedFilterUpload}
                  onChange={e => {
                    setSelectedFilterUpload(e.target.value);
                    onSortRadioUpdate("interval", e.target.value);
                  }}
                  name="upload-date"
                />
                <label htmlFor={sort.value}>{sort.label}</label>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={3} className="filter-coll">
          <h4 className="filter-title">Sort by</h4>
          <ul className="sort-list-chooser">
            {SORT_CHANNELS_BY.map((sort, i) => (
              <li key={i}>
                <input
                  type="radio"
                  id={sort.value}
                  value={sort.value}
                  checked={sort.value === selectedSortBy}
                  onChange={e => {
                    setSelectedSortBy(e.target.value);
                    onSortRadioUpdate("sortby", e.target.value);
                  }}
                  name="sort-videos"
                />
                <label htmlFor={sort.value}>{sort.label}</label>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <ul className="letters-filter-bar">
        {["all", ...letters].map((letter, idx) => (
          <li key={`${letter}-${idx}`}>
            <input
              type="radio"
              id={letter}
              value={letter}
              checked={letter === selectedLetterFilter}
              onChange={e => {
                setSelectedLetterFilter(e.target.value);
                onSortRadioUpdate("letter", e.target.value);
              }}
              name="filter-letters"
            />
            <label htmlFor={letter}>{letter}</label>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CategoriesChannelsFilters;
