import "./style.scss";
import classes from "classnames";

type Props = {
  status: boolean;
  onStateChange: (state: boolean) => void;
};

const FilterToggle = ({ status, onStateChange }: Props) => (
  <div
    className={classes("filters-toggle", {
      ["active"]: status
    })}
    onClick={() => {
      onStateChange(!status);
    }}
  >
    <i className="icon-menu-filter" />
  </div>
);

export default FilterToggle;
