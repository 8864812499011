type LocalUploadListItemProps = {
  onRemove: Function;
  title: string;
  size: number;
  index: number;
};

const getFileSize = (bytes: number) => {
  if (bytes === 0) return `0.00 B`;

  const e = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${" KMGTP".charAt(e)}B`;
};

const LocalUploadListItem = ({ size, title, index, onRemove }: LocalUploadListItemProps) => {
  const handleRemoveItem = () => {
    onRemove(index);
  };

  const fileSize = getFileSize(size);

  return (
    <div className="upload-list-item">
      <span>{title}</span>
      <div className="pull-right">
        <span>{fileSize}</span>
        <button type="button" className="button" onClick={handleRemoveItem}>
          <i className="icon-close" />
        </button>
      </div>
    </div>
  );
};

export default LocalUploadListItem;
