import Select from "react-select";
import classes from "classnames";
import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { useOnClickOutside } from "@Hooks";
import { StatusOption, StatusSelected } from "@Components";
import { VIDEO_STATUS_OPTIONS } from "@Constants";
import "./style.scss";

type PlaylistAccordionTypes = {
  childrenCount?: number;
  children?: any;
  editable?: boolean;
  expended?: boolean;
  checked?: boolean;
  title?: string;
  onCheck: () => void;
  playlist_id?: number;
  onToggleExpend: () => void;
  onRemove?: (playlist_id: number) => void;
  onSubmitEdit?: (details: any) => void;
  playlistStatus?: number;
};

function PlaylistAccordion({
  children,
  title,
  checked,
  expended,
  onCheck,
  onRemove,
  playlist_id,
  onToggleExpend,
  editable = false,
  onSubmitEdit,
  childrenCount,
  playlistStatus = 0
}: PlaylistAccordionTypes) {
  const [editMode, setEditMode] = useState(false);
  const [nextTitle, setNextTitle] = useState(title || "");
  const [playlistStatusState, setPlaylistStatusState] = useState(
    VIDEO_STATUS_OPTIONS[playlistStatus]
  );
  const inputRefWrap = useRef(null as any);

  const toggleEditMode = () => {
    if (editable) {
      setEditMode(!editMode);

      setTimeout(() => {
        if (inputRefWrap.current) {
          inputRefWrap.current.children[0].focus();
        }
      }, 100);
    }
  };

  useOnClickOutside(inputRefWrap, toggleEditMode);

  const onPlaylistTitleChange = ({ target }: { target: { value: string } }) => {
    setNextTitle(target.value);
  };

  const onSubmitDetails = () => {
    if (!!onSubmitEdit) {
      onSubmitEdit({ title: nextTitle });
      setEditMode(false);
    }
  };

  const onStatusUpdate = (selectOption: any) => {
    if (!!onSubmitEdit) {
      onSubmitEdit({ status: selectOption.value });
      setPlaylistStatusState(selectOption);
    }
  };

  const onSubmitEditMode = (target: { keyCode: number }) => {
    if (target.keyCode === 13) {
      onSubmitDetails();
    }

    if (target.keyCode === 27) {
      setEditMode(false);
    }
  };

  const statusOptionsList = Object.values(VIDEO_STATUS_OPTIONS)
    .reverse()
    .filter(i => i.value && i.value !== 3);

  return (
    <div
      className={classes("playlist-accordion", {
        ["edit-options-off"]: onRemove && !playlist_id
      })}
    >
      <div className="playlist-header">
        <div className="playlist-info">
          <label className="select-all-option">
            <input readOnly name="check" type="checkbox" checked={checked || false} />
            <span className="label-text" onClick={onCheck} />
          </label>
          <i className="icon icon-playlist-import" onClick={onToggleExpend} />

          {editMode ? (
            <div className="input-wrap" ref={inputRefWrap}>
              <input
                type="text"
                value={nextTitle}
                onChange={onPlaylistTitleChange}
                onKeyDown={onSubmitEditMode}
                className="playlist-title input"
              />
              <span className="submit-button" onClick={onSubmitDetails}>
                <i className="icon-check-mark" />
              </span>
              <span className="cancel-button" onClick={toggleEditMode}>
                <i className="icon-close-rounded" />
              </span>
            </div>
          ) : editable && !editMode && playlist_id !== 0 ? (
            <Row>
              <Col className="playlist-title-coll">
                <div className="playlist-title">
                  <span>{title}</span>
                </div>
                <span className="children-count">{childrenCount}</span>
              </Col>
              <Col className="playlist-status-coll">
                <div className="status-display">
                  {playlistStatusState.label} <i className={playlistStatusState.icon} />
                </div>
                <div className="hover-controls">
                  <Select
                    classNamePrefix="app-select"
                    className="transparent-select"
                    menuPlacement="auto"
                    isSearchable={false}
                    options={statusOptionsList}
                    onChange={onStatusUpdate}
                    value={playlistStatusState}
                    components={{ Option: StatusOption, SingleValue: StatusSelected }}
                  />
                </div>
              </Col>
              <Col xs className="playlist-date-coll">
                Dec 03, 2020
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="playlist-title-coll">
                <div className="playlist-title">
                  <span>{title}</span>
                  <span className="children-count">{childrenCount}</span>
                </div>
              </Col>
            </Row>
          )}
        </div>
        {!editMode && (
          <div className="expend-toggle">
            <i
              onClick={onToggleExpend}
              className={`icon ${expended ? "icon-dash-line-bold" : "icon-rounded-plus"}`}
            />
          </div>
        )}
        {editable && !editMode && onRemove && !!playlist_id && (
          <div className="hover-controls">
            <i className="icon-edit" onClick={toggleEditMode} />
            <i className="icon-open-link-item" />
            <i className="icon-permalink" />
            <i className="icon-remove" onClick={() => onRemove(playlist_id)} />
          </div>
        )}
      </div>
      {expended && <div className="playlist-body">{children}</div>}
    </div>
  );
}

export default PlaylistAccordion;
