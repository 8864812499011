import { call, put } from "redux-saga/effects";
import authorizedApiRequest from "@Fetch/authorized";
import { ServerResponse } from "@Models/RequestParams";
import { fetchInitialStateSuccess } from "@Store/actions/app";
import { setUserDetails } from "@Store/actions/user";
import { logoutUserSaga } from "./user";
import { getAllCategories } from "./category";
import { User } from "@Models/User";
import { getUserNotifications } from "./notifications";

type AuthSuccessStatusTypes = "RESOLVED" | "REJECTED";

export function* authSucces(status: AuthSuccessStatusTypes) {
  if (status === "RESOLVED") {
    const userDetails: User = JSON.parse(String(localStorage.getItem("AUTH_USER")));
    // load user initial state
    if (!!userDetails) {
      yield put(setUserDetails(userDetails));
      yield call(getUserNotifications);
      yield call(getAllCategories);
    }
  }

  yield put(fetchInitialStateSuccess());
}

export function* refreshAccessToken() {
  const oldToken = localStorage.getItem("ACCESS_TOKEN");

  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/oauth/renew", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${oldToken}`
    }
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    localStorage.setItem("ACCESS_TOKEN", data.access_token);
    localStorage.setItem("ACCESS_TOKEN_EXPIRES_ON", `${data.expires_at}`);

    yield call(authSucces, "RESOLVED");
  } else {
    yield call(logoutUserSaga);
  }
}

function* fetchAuthState() {
  const access_token = localStorage.getItem("ACCESS_TOKEN");
  const expires_at = localStorage.getItem("ACCESS_TOKEN_EXPIRES_ON");

  if (access_token && expires_at) {
    if (parseInt(expires_at, 10) > new Date().getTime() / 1000) {
      yield call(authSucces, "RESOLVED");
    } else {
      yield call(refreshAccessToken);
    }
  } else {
    yield call(authSucces, "REJECTED");
  }
}

export default fetchAuthState;
