import { call, put } from "@redux-saga/core/effects";
import { setAllCategories } from "../actions/category";
import { ServerResponse } from "@Models/RequestParams";
import authorizedApiRequest from "@Fetch/authorized";

export function* getAllCategories() {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/category`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield put(setAllCategories(data));
  }
}
