import classes from "classnames";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";
import { followChannel, unfollowChannel } from "@Store/actions/channel";
import { getAvatar, abbreviateNumber } from "@Utils";
import "./style.scss";

type Props = {
  followers: number;
  image: string | null;
  is_follow: boolean;
  name: string;
  slug: string;
  videos: number;
  channel_id: number;
  description: string;
  user_channel_id?: number;
  isAuth?: boolean;
};

function FollowingItem({
  followers,
  image,
  is_follow,
  name,
  slug,
  videos,
  channel_id,
  description,
  user_channel_id,
  isAuth
}: Props) {
  const dispatch = useDispatch();

  const [followIsPending, setFollowIsPending] = useState(false);
  const [isFollow, setIsFollow] = useState(is_follow);

  const toggleChannelFollowStatus = () => {
    setFollowIsPending(true);

    if (isFollow) {
      dispatch(
        unfollowChannel(channel_id, {
          onSuccess: () => {
            setIsFollow(false);
            setFollowIsPending(false);
          },
          onError: () => {
            setFollowIsPending(false);
          }
        })
      );
    } else {
      dispatch(
        followChannel(channel_id, {
          onSuccess: () => {
            setIsFollow(true);
            setFollowIsPending(false);
          },
          onError: () => {
            setFollowIsPending(false);
          }
        })
      );
    }
  };

  const channelAvatarAlt = getAvatar(name);

  return (
    <Row className="following-item">
      <div className="following-item-mobile">
        <div className="item-avatar">
          {image ? (
            <Link to={`/channel/${slug}`} className="link-to-channel">
              <div
                className="avatar"
                style={{
                  backgroundImage: `url(${image.replace("[SIZE]", "72x72")})`
                }}
              />
            </Link>
          ) : (
            <Link to={`/channel/${slug}`} className="link-to-channel">
              <div
                className="avatar"
                style={{
                  backgroundColor: channelAvatarAlt.color
                }}
              >
                <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
              </div>
            </Link>
          )}
        </div>
        <div className="item-info">
          <div className="item-info-title">
            <Link to={`/channel/${slug}`} className="link-to-channel">
              {name}
            </Link>
          </div>
          <div className="item-info-count">
            <div className="item-info-count-item">
              <i className="icon-multimedia-dash" />
              <span>{abbreviateNumber(videos)}</span>
            </div>
            <div className="item-info-count-item">
              <i className="icon-users-group" />
              <span>{abbreviateNumber(followers)}</span>
            </div>
          </div>
          <div className="item-info-btn">
            {user_channel_id !== channel_id && (
              <button
                type="button"
                className={classes("round-action-button follow-action", { following: isFollow })}
                onClick={toggleChannelFollowStatus}
                disabled={followIsPending}
              >
                {isFollow ? (
                  <>
                    <i className="icon-check-mark" />
                    <span>Following</span>
                  </>
                ) : (
                  <span>Follow</span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <Col xs className=" name-coll">
        <div className="channel-item">
          {image ? (
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${image.replace("[SIZE]", "200x200")})`
              }}
            >
              <Link to={`/channel/${slug}`} className="link-to-channel">
                <i className="icon-eye" />
              </Link>
            </div>
          ) : (
            <div
              className="avatar"
              style={{
                backgroundColor: channelAvatarAlt.color
              }}
            >
              <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
              <Link to={`/channel/${slug}`} className="link-to-channel">
                <i className="icon-eye" />
              </Link>
            </div>
          )}

          <div className="channel-info">
            <Link to={`/channel/${slug}`} className="link-to-channel">
              <h3 className="title">{name}</h3>
            </Link>
            <p className="description">{description}</p>
          </div>
        </div>
      </Col>
      <Col className="videos-coll">{abbreviateNumber(videos)}</Col>
      <Col className="followers-coll">
        <i className="icon-users-group" />
        {abbreviateNumber(followers)}
      </Col>
      {isAuth && (
        <Col className="action-coll">
          {user_channel_id !== channel_id && (
            <button
              type="button"
              className={classes("round-action-button follow-action", { following: isFollow })}
              onClick={toggleChannelFollowStatus}
              disabled={followIsPending}
            >
              {isFollow ? (
                <>
                  <i className="icon-check-mark" />
                  <span>Following</span>
                </>
              ) : (
                <span>Follow</span>
              )}
            </button>
          )}
        </Col>
      )}
    </Row>
  );
}

export default FollowingItem;
