import useRootRef from "@Hooks/useRootRef";
import { useEffect } from "react";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function CookiePolicy() {
  const rootRef = useRootRef();

  useEffect(() => {
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  return (
    <div className="page-content policy-page">
      <div className="full-container">
        <h1 className="title">Cookie Policy</h1>
        <div className="policy-content">
          <p className="limited-txt">
            Below is a list of the cookies that we use. We have tried to ensure this is complete and
            up to date, but if you think that we have missed a cookie or there is any discrepancy,
            please let us know.
          </p>
          <h3>
            <strong>Strictly necessary</strong>
          </h3>
          <p>We use the following strictly necessary cookies:</p>
          <ul role="list">
            <li>
              ‍<strong>Website cookies</strong> – We use this session cookie to remember you and
              maintain your session whilst you are using our website
            </li>
          </ul>
          <h3>Analytical/performance</h3>
          <p>We use the following analytical/performance cookies:</p>
          <ul role="list">
            <li>
              <strong>Analytics cookies</strong> – We use this cookie to help us analyse how users
              use the website
            </li>
          </ul>
          <h3>Functionality</h3>
          <p>We use the following functionality cookies:</p>
          <ul role="list">
            <li>
              <strong>Functional cookies</strong> – We use this cookie to identify your computer and
              analyse traffic patterns on our website
            </li>
          </ul>
          <h3>Targeting</h3>
          <p>We use the following targeting cookies:</p>
          <ul role="list">
            <li>
              <strong>Targeting cookies</strong> – We use this cookie to enable us to show you
              adverts while you are browsing our website and other websites on the internet
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
