import ReactPaginate from "react-paginate";
import classes from "classnames";
import Select from "react-select";
import { useDebounce } from "@Hooks";
import { useCallback, useEffect, useMemo, useState, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { getAuthUserLoadedVideos, getAuthUserVideosPageInfo } from "@Store/selectors/video";
import { requestUserPlaylists } from "@Store/actions/playlist";
import { getSelectCategories } from "@Store/selectors/category";
import { getPlaylistsSelectList } from "@Store/selectors/playlist";
import { LoadingSpinner, Modal, VideoListItem } from "@Components";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { FiltersBar, FilterToggle } from "@Components/Filters";
import useRootRef from "@Hooks/useRootRef";
import {
  requestUserVideos,
  subscribeVideoConverterUpdates,
  unsubscribeVideoConverterUpdates
} from "@Store/actions/video";
import UploadVideos from "@Views/UploadVideos";
import EmptyLibrary from "./EmptyLibrary";
import LibraryHeader from "./LibraryHeader";
import { Filters } from "@Models/Filters";
import { isEmpty } from "ramda";

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

const isInvalidVideo = (video: any) => {
  return (
    !video.loadProgress &&
    video.is_valid !== 0 &&
    (!video.converter || video.converter.processCodeUpdate !== 0) &&
    !video.is_valid
  );
};

function VideosTab() {
  const dispatch = useDispatch();

  const videosList = useSelector(getAuthUserLoadedVideos);
  const categoriesList = useSelector(getSelectCategories);
  const playlistsList = useSelector(getPlaylistsSelectList);
  const pageInfo = useSelector(getAuthUserVideosPageInfo);

  const [checkedVideos, setCheckedVideos] = useState<any>({});
  const [uploadModal, setUploadModal] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [searchInputToggle, setSearchInputToggle] = useState(false);

  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [pageChooserInputValue, setPageChooserInputValue] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filtersState, setFiltersState] = useState(false);
  const [filtersData, setFiltersData] = useState({} as Filters);
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const searchRef = useRef(null as any);
  const rootRef = useRootRef();

  useEffect(() => {
    searchInputToggle &&
      onChangeVideosPage(1, itemsPerPageCount.value, debouncedSearchValue, filtersData);
  }, [debouncedSearchValue]);

  const checkedVideoIdsList = useMemo(
    () =>
      Object.keys(checkedVideos).reduce((acc: any, video_id) => {
        if (checkedVideos[video_id]) {
          return [...acc, Number(video_id)];
        }

        return acc;
      }, []),
    [checkedVideos]
  );

  const invalidVideosCount = useMemo(() => {
    return videosList.reduce((acc, video) => (isInvalidVideo(video) ? acc + 1 : acc), 0);
  }, [videosList]);

  const onChangeVideosPage = useCallback(
    (
      nextPAge: number,
      itemsPerPage = itemsPerPageCount.value,
      searchText?: string,
      filters?: Filters
    ) => {
      setIsPending(true);
      dispatch(
        requestUserVideos(
          nextPAge,
          itemsPerPage,
          {
            onSuccess: () => {
              setIsPending(false);
              rootRef?.scrollTo(0, 0);
              setActivePage(nextPAge - 1);
              setCheckedVideos({});
              searchRef.current && searchRef.current.focus();
            },
            onError: () => {}
          },
          searchText,
          filters
        )
      );
    },
    [itemsPerPageCount.value]
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(subscribeVideoConverterUpdates());
      dispatch(requestUserPlaylists());
      onChangeVideosPage(1);
    }, 200);

    return () => {
      dispatch(unsubscribeVideoConverterUpdates());
    };
  }, []);

  useEffect(() => {
    setActivePage(pageInfo.currentPage - 1);
    !!searchValue && setSearchInputToggle(true);
  }, [pageInfo.currentPage]);

  useEffect(() => {
    !isPending &&
      dispatch(
        requestUserVideos(
          1,
          itemsPerPageCount.value,
          {
            onSuccess: () => {
              setIsPending(false);
              rootRef?.scrollTo(0, 0);
              setActivePage(0);
              setCheckedVideos({});
              searchRef.current && searchRef.current.focus();
            },
            onError: () => {}
          },
          undefined,
          filtersData
        )
      );
  }, [filtersData]);

  const onCheckVideo = (video_id: any) => () => {
    setCheckedVideos((prevstate: any) => ({
      ...prevstate,
      [video_id]: !prevstate[video_id]
    }));
  };

  const onCheckAllVideos = () => {
    const nextList = videosList.reduce((acc, video) => {
      if (
        video.video_id &&
        !isInvalidVideo(video) &&
        !video.loadProgress &&
        (!video.converter || video.converter.processCodeUpdate === 0)
      ) {
        return {
          ...acc,
          [video.video_id]:
            checkedVideoIdsList.length !==
            videosList.filter(
              i =>
                !i.loadProgress &&
                (!i.converter || i.converter.processCodeUpdate === 0) &&
                i.is_valid
            ).length
        };
      }

      return acc;
    }, {});

    setCheckedVideos(nextList);
  };

  const onChangeItemsPerPageCount = (item: any) => {
    setItemsPerPageCount(item);
    onChangeVideosPage(1, item.value, debouncedSearchValue, filtersData);
    searchValue && setSearchInputToggle(true);
  };

  const onChangeItemsPage = (filters: Filters) => {
    if (pageChooserInputValue <= pageInfo.totalPages && pageChooserInputValue >= 1) {
      setActivePage(pageChooserInputValue - 1);
      onChangeVideosPage(pageChooserInputValue);
    } else {
      setActivePage(pageInfo.totalPages - 1);
      !isEmpty(filters)
        ? onChangeVideosPage(pageInfo.totalPages, itemsPerPageCount.value, undefined, filters)
        : onChangeVideosPage(pageInfo.totalPages);
      setPageChooserInputValue(pageInfo.totalPages);
    }
  };

  const onGoToPageType = (event: any) => {
    if (event.which === 13) {
      onChangeItemsPage(filtersData);
    }
  };

  const onToggleSearch = (status: boolean) => {
    setSearchInputToggle(status);
    if (searchRef.current && status) searchRef.current.focus();
  };

  const onFilterUpdates = (filters: Filters) => {
    setFiltersData(filters);
  };

  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FilterToggle
        status={filtersState}
        onStateChange={state => {
          setFiltersState(state);
        }}
      />
      <FiltersBar
        isActive={filtersState}
        onFiltersUpdate={onFilterUpdates}
        initialFilters={filtersData}
        onClose={() => {
          setFiltersState(false);
        }}
      />

      {!!checkedVideoIdsList.length && (
        <LibraryHeader
          playlistsList={playlistsList.filter(i => i.value !== 0)}
          categoriesList={categoriesList}
          checkedItems={checkedVideoIdsList}
          perPage={itemsPerPageCount.value}
          setCheckedVideos={setCheckedVideos}
        />
      )}
      {!!videosList.length || debouncedSearchValue ? (
        <Row className="heading">
          <Col className="check-option check-coll">
            <label
              className={classes("select-all-option ", {
                ["partial-selected"]:
                  checkedVideoIdsList.length && checkedVideoIdsList.length !== videosList.length
              })}
            >
              <input
                readOnly
                name="check"
                type="checkbox"
                checked={
                  !!checkedVideoIdsList.length &&
                  checkedVideoIdsList.length === videosList.length - invalidVideosCount
                }
              />
              <span className="label-text" onClick={onCheckAllVideos} />
            </label>
          </Col>
          <Col className="heading-option video-coll">
            Video
            <span className="filter-search">
              <i
                className={classes("icon-search", {
                  ["active"]: searchInputToggle
                })}
                onClick={() => {
                  onToggleSearch(true);
                }}
              />
              <input
                type="text"
                ref={searchRef}
                className={classes("search-input", {
                  ["active"]: searchInputToggle
                })}
                value={searchValue}
                onChange={event => {
                  setSearchValue(event.target.value);
                }}
                onBlur={() => {
                  !searchValue && onToggleSearch(false);
                }}
                placeholder="Search videos"
              />
            </span>
          </Col>
          <Col className="heading-option visibility-coll">Visibility</Col>
          <Col className="heading-option category-coll">Category</Col>
          <Col className="heading-option date-coll">Date</Col>
          <Col className="heading-option views-coll">Views</Col>
          <Col xs className="heading-option reactions-coll">
            Reactions
          </Col>
        </Row>
      ) : (
        <EmptyLibrary
          onUploadVideos={() => {
            setUploadModal(true);
          }}
        />
      )}

      {videosList.map((video: any, index: number) => (
        <VideoListItem
          {...video}
          key={index}
          perPage={itemsPerPageCount.value}
          onCheck={onCheckVideo(video.video_id)}
          checked={!!checkedVideos[video.video_id]}
          editOnly={
            video.loadProgress ||
            (video?.converter?.processCodeUpdate && video.converter.processCodeUpdate !== 0)
          }
          invalidVideo={isInvalidVideo(video)}
        />
      ))}

      {debouncedSearchValue && !videosList.length && (
        <div className="videos-search-empty">
          <i className="icon-folder-empty" /> No videos was founded
        </div>
      )}

      <Row className="app-pagination">
        {pageInfo.totalResults > 10 && (
          <Col className="items-per-page-col">
            <span>Show</span>
            <Select
              classNamePrefix="app-select"
              menuPlacement="auto"
              placeholder="Assign Category"
              isSearchable={false}
              value={itemsPerPageCount}
              onChange={onChangeItemsPerPageCount}
              options={ITEMS_PER_PAGE_COUNT_OPTIONS}
            />
          </Col>
        )}

        {pageInfo.totalPages > 1 && (
          <>
            <Col xs>
              <ReactPaginate
                forcePage={activePage}
                pageCount={pageInfo.totalPages}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) =>
                  onChangeVideosPage(
                    selected + 1,
                    itemsPerPageCount.value,
                    debouncedSearchValue,
                    filtersData
                  )
                }
                activeClassName="active"
                pageClassName="page-number"
                containerClassName="pagination row"
                previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                nextLabel={<i className="icon icon-chevron-right nav-icon" />}
              />
            </Col>
            <Col className="go-to-page">
              <span>Go to page</span>
              <input
                type="number"
                onChange={({ target }: any) => {
                  setPageChooserInputValue(target.value || "");
                }}
                onKeyUp={onGoToPageType}
                value={pageChooserInputValue}
              />
              <button
                onClick={() => {
                  onChangeItemsPage(filtersData);
                }}
              >
                <i className="icon-chevron-right" />
              </button>
            </Col>
          </>
        )}
      </Row>

      {uploadModal && (
        <Modal
          onClose={() => {
            setUploadModal(false);
          }}
        >
          <UploadVideos
            onSetActiveModal={() => {
              setUploadModal(false);
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default memo(VideosTab);
