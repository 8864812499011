import { Col, Row } from "react-flexbox-grid";
import AnalyticsWidget from "./components/AnalyticsWidget";
import LatestUploadWidget from "./components/LatestUploadWidget";
import LikeStatusWidget from "./components/LikesStatusWidget";
import CommentsWidget from "./components/CommentsWidget";
import TopVideosWidget from "./components/TopVideosWidget";
import "./style.scss";

function Dashboard() {
  return (
    <div className="dashboard">
      <Row className="section-heading">
        <Col xs className="auto-column">
          <h1 className="main-heading">Channel Dashboard</h1>
        </Col>
      </Row>
      <div className="masonry-grid">
        <AnalyticsWidget />
        <LikeStatusWidget />
        <LatestUploadWidget />
        <TopVideosWidget />
        <CommentsWidget />
      </div>
    </div>
  );
}

export default Dashboard;
