import classes from "classnames";
import { useState, useMemo } from "react";
import { propEq, update } from "ramda";
import { Col, Row } from "react-flexbox-grid";
import { YoutubePlaylist } from "@Models/Video";
import { PlaylistAccordion, YoutubeVideoListItem } from "@Components";
import "./youtubePreview.scss";

type YoutubePreviewTypes = {
  handleUploadVideos: (list: YoutubePlaylist[]) => void;
  importIsFetching: boolean;
  onCancel: () => void;
  playlists: YoutubePlaylist[];
};

function YoutubePreview({ handleUploadVideos, importIsFetching, playlists }: YoutubePreviewTypes) {
  const [youtubeListState, setYoutubeListState] = useState({
    playlists,
    isExpended: false,
    isChecked: false
  });

  const handleCheckPlaylist = (playlistId: string) => () => {
    const playlistIndex = youtubeListState.playlists.findIndex(({ id }) => id === playlistId);

    if (playlistIndex !== -1) {
      const nextCheckStatus = !youtubeListState.playlists[playlistIndex].isChecked;

      const nextPlaylists = update(
        playlistIndex,
        {
          ...youtubeListState.playlists[playlistIndex],
          isChecked: nextCheckStatus,
          video: youtubeListState.playlists[playlistIndex].video.map((video: any) => ({
            ...video,
            isChecked: nextCheckStatus
          }))
        },
        youtubeListState.playlists
      );

      setYoutubeListState((prevState: any) => ({
        ...prevState,
        isChecked: nextPlaylists.every(({ isChecked }) => isChecked),
        playlists: nextPlaylists
      }));
    }
  };

  const handleCheckPlaylistVideo = (playlistId: string, video_id: string) => () => {
    const playlistIndex = youtubeListState.playlists.findIndex(propEq("id", playlistId) as any);

    if (playlistIndex !== -1) {
      const playlistVideoIndex = youtubeListState.playlists[playlistIndex].video.findIndex(
        ({ youtube_id }) => youtube_id === video_id
      );

      const nextCheckedStatus =
        !youtubeListState.playlists[playlistIndex].video[playlistVideoIndex].isChecked;

      const nextVideosState = update(
        playlistVideoIndex,
        {
          ...youtubeListState.playlists[playlistIndex].video[playlistVideoIndex],
          isChecked: nextCheckedStatus
        },
        youtubeListState.playlists[playlistIndex].video
      );

      const nextPlaylistState = update(
        playlistIndex,
        {
          ...youtubeListState.playlists[playlistIndex],
          isChecked: nextVideosState.every(({ isChecked }) => isChecked),
          video: nextVideosState
        },
        youtubeListState.playlists
      );

      if (playlistVideoIndex !== -1) {
        setYoutubeListState((prevState: any) => ({
          ...prevState,
          isChecked: nextPlaylistState.flat().every(({ isChecked }) => isChecked),
          playlists: nextPlaylistState
        }));
      }
    }
  };

  const togglePlaylistExpend = (playlistId: string) => () => {
    const playlistIndex = youtubeListState.playlists.findIndex(propEq("id", playlistId) as any);

    if (playlistIndex !== -1) {
      setYoutubeListState((prevState: any) => ({
        ...prevState,
        playlists: update(
          playlistIndex,
          {
            ...prevState.playlists[playlistIndex],
            isExpended: !prevState.playlists[playlistIndex].isExpended
          },
          prevState.playlists
        )
      }));
    }
  };

  const toggleCollapseAll = () => {
    setYoutubeListState((prevState: any) => ({
      ...prevState,
      isExpended: !prevState.isExpended,
      playlists: prevState.playlists.map((playlist: any) => ({
        ...playlist,
        isExpended: !prevState.isExpended
      }))
    }));
  };

  const toggleCheckAll = () => {
    setYoutubeListState((prevState: any) => ({
      ...prevState,
      isChecked: !prevState.isChecked,
      playlists: prevState.playlists.map((playlist: any) => ({
        ...playlist,
        isChecked: !prevState.isChecked,
        video: playlist.video.map((video: any) => ({
          ...video,
          isChecked: !prevState.isChecked
        }))
      }))
    }));
  };

  const selectedVideos = useMemo(() => {
    return youtubeListState.playlists
      .map((playlist: any) => playlist.video)
      .flat()
      .filter(propEq("isChecked", true));
  }, [youtubeListState.playlists]);

  const onsubmitVideos = () => {
    handleUploadVideos(selectedVideos);
  };

  return (
    <section className="youtube-list modal-body">
      <Row className="import-heading">
        <Col sm={10} className="heading-actions">
          <h2 className="action-item heading">From Youtube</h2>
          <div className="action-item">
            <label className="select-all-option">
              <input readOnly name="check" type="checkbox" checked={youtubeListState.isChecked} />
              <span className="label-text" onClick={toggleCheckAll}>
                Select All
              </span>
            </label>
          </div>
          <div
            className={classes("action-item item-badge", {
              active: selectedVideos.length
            })}
          >
            {selectedVideos.length} videos selected
          </div>
        </Col>
        <Col sm={2} className="collapse-all">
          <span className="action-link" onClick={toggleCollapseAll}>
            {(youtubeListState.isExpended && "Collapse All") || "Expand All"}
          </span>
        </Col>
      </Row>
      <Row className="playlists-list">
        {youtubeListState.playlists.map((playlist: YoutubePlaylist) => {
          return (
            <PlaylistAccordion
              key={playlist.id}
              title={playlist.playlistTitle}
              checked={playlist.isChecked}
              expended={playlist.isExpended}
              onCheck={handleCheckPlaylist(playlist.id)}
              onToggleExpend={togglePlaylistExpend(playlist.id)}
              childrenCount={playlist.video.length}
            >
              {playlist.video.map(video => (
                <YoutubeVideoListItem
                  {...video}
                  withCheckbox
                  key={video.youtube_id}
                  onCheck={handleCheckPlaylistVideo(playlist.id, video.youtube_id)}
                  checked={video.isChecked}
                />
              ))}
            </PlaylistAccordion>
          );
        })}
      </Row>
      <Row className="import-footer">
        <Col sm={5}>
          <button
            type="button"
            className="main-button"
            onClick={onsubmitVideos}
            disabled={importIsFetching}
          >
            {(importIsFetching && "Importing...") || "Import videos to Sharevision"}
          </button>
        </Col>
      </Row>
    </section>
  );
}

export default YoutubePreview;
