import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { Layout, AuthRoute } from "@Components";
import { getAuthUserUploadingVideos } from "@Store/selectors/video";

import Homepage from "./views/HomePage";
import ProfilePage from "./views/ProfilePage";
import NotFoundPage from "./views/NotFoundPage";
import SingleVideoPage from "./views/SingleVideoPage";
import PublicChannelPage from "./views/PublicChannelPage";
import PublicUserPage from "./views/PublicUserPage";
import CategoriesVideos from "./views/CategoriesVideos";
import CategoriesChannels from "./views/CategoriesChannels";
import GetAuthTokenRedirect from "./views/GetAuthTokenRedirect";
import SearchResultsPage from "./views/SearchResultsPage";
import CommunityGuidelines from "./views/Policy/CommunityGuidelines";
import CookiePolicy from "./views/Policy/CookiePolicy";
import PrivacyPolicy from "./views/Policy/PrivacyPolicy";
import TermsConditions from "./views/Policy/TermsConditions";
import StreamManagerPage from "./views/StreamManagerPage";
import HelpCenter from "@Views/HelpCenter";
import "./sass/style.scss";

function App() {
  const uploadingVideos = useSelector(getAuthUserUploadingVideos);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();

      if (!uploadingVideos.length) {
        return;
      }
      e.returnValue = true;
    };
    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, [uploadingVideos.length]);

  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/get-auth-token" component={GetAuthTokenRedirect} />
        <Route exact path="/video/:hash_id" component={SingleVideoPage} />
        <Route exact path="/live/:hash_id" component={StreamManagerPage} />
        <Route path="/channel/:channel_slug" component={PublicChannelPage} />
        <Route path="/streamer/:username" component={PublicUserPage} />
        <Route path="/categories/videos" component={CategoriesVideos} />
        <Route path="/categories/channels" component={CategoriesChannels} />
        <Route path="/community" component={CommunityGuidelines} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/help" component={HelpCenter} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/search" component={SearchResultsPage} />
        <AuthRoute path="/profile" component={ProfilePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default App;
