import { Component } from "react";
import ReactDOM from "react-dom";
import "./style.scss";

class Modal extends Component {
  el: HTMLDivElement;
  closer: HTMLDivElement;
  modalContent: HTMLDivElement;
  iconClose: HTMLElement;
  static defaultProps: { onClose: (data?: any) => void };

  constructor(props: any, context: any) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.el = document.createElement("div");
    this.closer = document.createElement("div");
    this.modalContent = document.createElement("div");
    this.iconClose = document.createElement("i");
    this.modalContent.className = "content";
    this.el.className = "popupModal";
    this.closer.className = "closer";
    this.iconClose.className = "icon-close-rounded popup-icon-close";
    this.closer.onclick = this.closePopup;
    this.iconClose.onclick = this.closePopup;
  }

  closePopup = () => {
    this.modalContent.className = "content fadeOutUp";
    setTimeout(() => {
      (this.props as any).onClose();
    }, 400);
  };

  escFunction(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    event.key === "Escape" && this.closePopup();
  }

  componentDidMount() {
    document.documentElement.style.overflowY = "hidden";
    document.body.appendChild(this.el);
    this.el.appendChild(this.closer);
    this.modalContent.appendChild(this.iconClose);
    this.el.appendChild(this.modalContent);
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    document.documentElement.style.overflowY = "overlay";
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.modalContent);
  }
}

Modal.defaultProps = {
  onClose: () => {}
};

export default Modal;
