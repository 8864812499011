import "./style.scss";

function EmptyPlaylistArchive() {
  return (
    <div className="empty-playlist-archive">
      <i className="icon-playlist-import" />
      <p className="empty-message">This channel has no playlists for the moment</p>
    </div>
  );
}

export default EmptyPlaylistArchive;
