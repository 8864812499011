import { PLAYLIST } from "../actions/playlist";
import { VideoPlaylist } from "@Models/Video";
import { propEq, update } from "ramda";

type State = {
  list: VideoPlaylist[];
};

const initialState: State = {
  list: []
};

const updatePlaylistDetails = (state: State, action: any) => {
  const playlistIndex = state.list.findIndex(propEq("playlist_id", action.payload.playlist_id));

  if (playlistIndex !== -1) {
    return {
      ...state,
      list: update(
        playlistIndex,
        {
          ...state.list[playlistIndex],
          ...action.payload.details
        },
        state.list
      )
    };
  }

  return state;
};

export default function reducer(state: State = initialState, action: any): State {
  switch (action.type) {
    case PLAYLIST.SET_LIST:
      return {
        ...state,
        list: action.payload.list
      };

    case PLAYLIST.SET_LIST_COUNTS:
      return {
        ...state,
        list: state.list.map((item, i) => ({ ...item, videos: action.payload.list[i].videos }))
      };

    case PLAYLIST.SET_LIST_ITEM:
      return {
        ...state,
        list: state.list.concat({ ...action.payload, videos: 0 })
      };
    case PLAYLIST.UPDATE:
      return updatePlaylistDetails(state, action);
    default: {
      return state;
    }
  }
}
