import { createElement } from "react";
import AdminDashboard from "./sections/Dashboard";
import AllChannels from "./sections/Channels";
import CrashedVideos from "./sections/CrashedVideos";
import AdminCategories from "./sections/Categories";
import ViewChannel from "./sections/Channels/SingleChannel";

type AdminOptionsType = {
  id: string;
  label: string;
  component: any;
  counter?: string;
  subComponent?: any;
};

export const ADMIN_SECTIONS: AdminOptionsType[] = [
  {
    id: "dashboard",
    label: "Dashboard",
    component: createElement(AdminDashboard)
  },
  {
    id: "videos",
    label: "Videos",
    counter: "videos",
    component: createElement(CrashedVideos)
  },
  {
    id: "channels",
    label: "Channels",
    component: createElement(AllChannels),
    counter: "channels",
    subComponent: {
      id: "channels-details",
      label: "",
      component: createElement(ViewChannel)
    }
  },
  {
    id: "categories",
    label: "Categories",
    component: createElement(AdminCategories)
  }
];
