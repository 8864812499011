import { Link } from "react-router-dom";
import { abbreviateNumber, convertToTimeDuration, getAvatar } from "@Utils";
import React from "react";

type Props = {
  thumb: string;
  views: number;
  title: string;
  hash_id: string;
  description: string;
  duration: number;
  channel: { name: string; slug: string; avatar: string | null };
};

function SearchResultItem(videoItem: Props) {
  return (
    <div className="search-result-item">
      <Link to={`/video/${videoItem.hash_id}`}>
        <div
          className="post-image"
          style={{
            backgroundImage: videoItem.thumb
              ? `url(${videoItem.thumb.replace("[SIZE]", "300x168")})`
              : ""
          }}
        >
          <div className="duration">
            <span className="duration-time">{convertToTimeDuration(videoItem.duration)}</span>
          </div>
        </div>
      </Link>

      <div className="item-details">
        <Link to={`/video/${videoItem.hash_id}`}>
          <div className="title">
            <span>{videoItem.title}</span>
          </div>
        </Link>
        <div className="owner_details">
          <Link to={`/channel/${videoItem.channel.slug}`} className="owner_details-channel">
            {videoItem.channel.avatar ? (
              <div
                className="avatar avatar-img"
                style={{
                  backgroundImage: `url(${videoItem.channel.avatar.replace("[SIZE]", "100x100")})`
                }}
              />
            ) : (
              <div
                className="avatar avatar-custom"
                style={{
                  backgroundColor: getAvatar(videoItem.channel.name).color
                }}
              >
                <span>{getAvatar(videoItem.channel.name).shortLetters}</span>
              </div>
            )}
            <span className="owner_details-channel-title">{videoItem.channel.name}</span>
          </Link>

          <div className="owner_details-views">
            <i className="icon-glasses" />
            <span>{abbreviateNumber(videoItem.views)}</span>
          </div>
        </div>
        <div className="description">{videoItem.description}</div>
      </div>
    </div>
  );
}

export default SearchResultItem;
