import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import authorizedApiRequest from "@Fetch/authorized";
import {
  CHANNEL,
  setChannelDetails,
  updateChannelDetails,
  updateChannelImage
} from "../actions/channel";
import { ChannelImageUpload } from "@Models/ChannelImageUpload";
import { updatePersistedUserDetails } from "./user";
import { Channel } from "@Models/Channel";
import { isEmpty, prop } from "ramda";
import { refreshAccessToken } from "./app";
import { Filters } from "@Models/Filters";
import transformQueryParams from "@Utils/transformQueryParams";
import { AppSelect } from "@Models/AppSelect";

function* requestChannelDetails({
  payload: { channel_slug, full },
  callbacks
}: {
  payload: { channel_slug: string; full: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Channel/${channel_slug}?full=${full}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* checkChannelSlug({
  payload,
  callbacks
}: {
  payload: string;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    "/streams/CheckChannelSlug",
    {
      method: "POST",
      body: JSON.stringify({ slug: payload })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    if (!data.isUsed) {
      yield call(callbacks.onSuccess);
    } else {
      if (callbacks.onError) yield call(callbacks.onError);
    }
  }
}

function* createChannel({
  payload,
  imageFile,
  callbacks
}: {
  payload: Channel;
  imageFile: File;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/streams/Channel", {
    method: "POST",
    body: JSON.stringify(payload)
  });

  if (serverResponse.status === 200) {
    yield call(refreshAccessToken);

    const { data }: { data: Channel } = yield serverResponse.json();

    if (!isEmpty(imageFile) && data.channel_id) {
      yield put(
        updateChannelImage({
          channel_id: data.channel_id,
          file: imageFile
        })
      );
    }

    yield call(updatePersistedUserDetails, {
      channel_id: data.channel_id,
      channel_slug: data.slug,
      role: "creator"
    });

    yield put(setChannelDetails(data));
    yield call(callbacks.onSuccess);
  }
}

function* channelImageUpload({
  payload
}: {
  payload: ChannelImageUpload;
  callbacks: RequestCallbacks;
}) {
  const formData = new FormData();
  formData.append("channel_id", String(payload.channel_id));
  formData.append("file", payload.file);

  const serverResponse = yield call(authorizedApiRequest, "/streams/Upload", {
    method: "POST",
    body: formData
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(updateChannelDetails({ image: data.thumb }));
  }
}

function* updateChannelInfo({
  payload,
  channel_id,
  callbacks
}: {
  payload: Channel;
  channel_id: number;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Channel/${channel_id}`,
    {
      method: "PUT",
      body: JSON.stringify({
        ...payload,
        tags: payload.tags.map(prop("value"))
      })
    }
  );

  if (serverResponse.status === 200) {
    yield put(setChannelDetails(payload));

    yield call(updatePersistedUserDetails, {
      channel_slug: payload.slug
    });

    yield call(callbacks.onSuccess);
  }
}

function* followChannel({
  channel_id,
  callbacks
}: {
  channel_id: number;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/follow/${channel_id}`,
    {
      method: "PUT"
    }
  );

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

function* unfollowChannel({
  channel_id,
  callbacks
}: {
  channel_id: number;
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/follow/${channel_id}`,
    {
      method: "DELETE"
    }
  );

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

function* requestChannelVideos({
  payload: { channel_slug, nextPage, filters },
  callbacks
}: {
  payload: { channel_slug: string; nextPage: number; filters: Filters };
  callbacks: RequestCallbacks;
}) {
  const query = transformQueryParams(
    filters && {
      ...(!!filters.category && { categories: filters.category.value }),
      ...(!!filters.subcategory && { subcategories: filters.subcategory.value }),
      ...(!!filters.country && { countries: filters.country.value }),
      ...(!!filters.sector && { sectors: filters.sector.value }),
      ...(!!filters.currency && { currencies: filters.currency.value }),
      ...(!!filters.visibility && { visibility: filters.visibility.value }),
      ...(!!filters.views && { views: filters.views.value }),
      ...(!!filters.likes && { likes: filters.likes.value }),
      ...(!!filters.dislikes && { dislikes: filters.dislikes.value })
    }
  );
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    !!filters
      ? `/streams/Channel/${channel_slug}/videos?page=${nextPage}&full=0${query ? "&" + query : ""}`
      : `/streams/Channel/${channel_slug}/videos?page=${nextPage}&full=0`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* requestChannelStreams({
  payload: { channel_slug, nextPage, limit },
  callbacks
}: {
  payload: { channel_slug: string; nextPage: number; limit: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Channel/${channel_slug}/streams?page=${nextPage}&full=0&limit=${limit}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* requestChannelPlaylists({
  payload: { channel_slug },
  callbacks
}: {
  payload: { channel_slug: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlists/${channel_slug}?full=0`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* requestChannelFollowers({
  payload: { channel_slug, nextPage, itemsPerPage },
  callbacks
}: {
  payload: { channel_slug: string; nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/channel/${channel_slug}/followers?page=${nextPage}&limit=${itemsPerPage}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* deleteChannelAvatar({ channel_id }: { channel_id: number }) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, "/Streams/Avatar/1", {
    method: "DELETE",
    body: JSON.stringify({ channel_id })
  });

  if (serverResponse.status === 200) {
    yield put(updateChannelDetails({ image: null }));
  }
}

function* requestChannelMarketAllocation({
  payload: { channel_slug },
  callbacks
}: {
  payload: { channel_slug: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Channel/${channel_slug}/allocation`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* requestChannelSimilarChannels({
  payload: { channel_slug },
  callbacks
}: {
  payload: { channel_slug: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Channel/${channel_slug}/similar`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data.channels);
  }
}

function* categoryRequestChannels({
  payload: { category, tag, appliedFilters, page },
  callbacks
}: {
  payload: { category?: string; tag?: string; appliedFilters?: any; page: number };
  callbacks: RequestCallbacks;
}) {
  let serverResponse: ServerResponse | undefined;
  const businessChannelQuery = category === "business-channels" ? `business=1&` : "";

  const query = transformQueryParams(
    appliedFilters && {
      ...(!!appliedFilters.interval && { interval: appliedFilters.interval }),
      ...(!!appliedFilters.sortby && { sortby: appliedFilters.sortby }),
      ...(!!appliedFilters.letter && { letter: appliedFilters.letter })
    }
  );

  if (category) {
    serverResponse =
      category === "all" || businessChannelQuery.length
        ? yield call(
            authorizedApiRequest,
            `/streams/AllChannels?${businessChannelQuery}page=${page}${!!query ? "&" + query : ""}`,
            {
              method: "GET"
            }
          )
        : yield call(
            authorizedApiRequest,
            `/streams/CategoryChannels/${category}?page=${page}${!!query ? "&" + query : ""}`,
            {
              method: "GET"
            }
          );
  } else if (tag) {
    serverResponse = yield call(
      authorizedApiRequest,
      `/streams/TagChannels/${tag}?page=${page}${!!query ? "&" + query : ""} `,
      {
        method: "GET"
      }
    );
  }

  if (serverResponse) {
    if (serverResponse.status === 200) {
      const { data, pageInfo } = yield serverResponse.json();

      yield call(callbacks.onSuccess, { data, pageInfo });
    } else {
      if (callbacks.onError) {
        if (callbacks.onError) yield call(callbacks.onError);
      }
    }
  }
}

export default function* channelSagaWatcher() {
  yield all([
    takeEvery(CHANNEL.REQUEST_DETAILS, requestChannelDetails),
    takeEvery(CHANNEL.CHECK_SLUG, checkChannelSlug),
    takeEvery(CHANNEL.CREATE, createChannel),
    takeEvery(CHANNEL.UPDATE_IMAGE, channelImageUpload),
    takeEvery(CHANNEL.UPDATE_INFO, updateChannelInfo),
    takeEvery(CHANNEL.UNFOLLOW, unfollowChannel),
    takeEvery(CHANNEL.FOLLOW, followChannel),
    takeEvery(CHANNEL.REQUEST_VIDEOS, requestChannelVideos),
    takeEvery(CHANNEL.REQUEST_STREAMS, requestChannelStreams),
    takeEvery(CHANNEL.REQUEST_PLAYLISTS, requestChannelPlaylists),
    takeEvery(CHANNEL.REQUEST_FOLLOWERS, requestChannelFollowers),
    takeEvery(CHANNEL.REQUEST_SIMILAR_CHANNELS, requestChannelSimilarChannels),
    takeEvery(CHANNEL.REQUEST_MARKET_ALLOCATION, requestChannelMarketAllocation),
    takeEvery(CHANNEL.DELETE_AVATAR, deleteChannelAvatar),
    takeEvery(CHANNEL.REQUEST_CATEGORY_CHANNELS, categoryRequestChannels)
  ]);
}
