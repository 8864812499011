import { Col, Row } from "react-flexbox-grid";
import { LogoWhite } from "@Assets";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="main-footer">
      <div className="full-container">
        <Row>
          <Col xs={12} sm={12} md={4} className="main-footer-top">
            <Link to="/">
              <img src={LogoWhite} width="190" alt="logo" />
            </Link>
            <p className="company-slogan">
              Discover, reach out and communicate with a global financial community
            </p>
            <ul className="socials-links">
              <li>
                <a href="https://twitter.com/SharevisionUK" target="_blank" rel="noreferrer">
                  <i className="icon-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/sharevisionfb" target="_blank" rel="noreferrer">
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/sharevision-discover-learn-invest"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-linkedin" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/sharevision_uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
            </ul>
          </Col>
          <Col sm={2} className="menu-coll">
            <h4 className="footer-title">Resources</h4>
            <ul className="footer-menu">
              <li>
                <Link to="/help">Help Center</Link>
              </li>
              <li>
                <Link to={{ pathname: "/help", state: "contact" }}>Contact us</Link>
              </li>
            </ul>
          </Col>
          <Col sm={3} className="menu-coll">
            <h4 className="footer-title">Disclaimers</h4>
            <ul className="footer-menu">
              <li>
                <Link to="/community">Community guidelines </Link>
              </li>
              <li>
                <Link to="/cookie-policy">Cookie policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </Col>
          <Col sm={3} className="info-coll">
            <h4 className="footer-title">Contact details</h4>
            <ul className="contacts-list">
              <li>
                <i className="icon-location" />
                Profile West Suite 2, Floor 1, 950 Great West Road, Brentford, England, TW8 9ES
              </li>
              <li>
                <a href="mailto:contact@sharevision.com" target="_blank" rel="noreferrer">
                  <i className="icon-mail" />
                  contact@sharevision.com
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12} xs={12}>
            <p className="copyright">© {new Date().getFullYear()}. All rights reserved</p>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
