import classes from "classnames";
import "./style.scss";

type Props = {
  value: number | string;
  options: { value: number | string; label: string | React.Component; description?: string }[];
  onChange: Function;
  type?: "horizontal" | "veritcal";
};

const RadioSelect = ({ value, onChange, options, type = "veritcal" }: Props) => {
  return (
    <aside className={`radio-select ${type}`}>
      {options.map(option => (
        <div
          key={option.value}
          className={classes("select-item", { checked: option.value === value })}
          onClick={() => onChange(option.value)}
        >
          <div className="checkbox" />
          <div className="details">
            <span>{option.label}</span>
            {option.description && <p>{option.description}</p>}
          </div>
        </div>
      ))}
    </aside>
  );
};

export default RadioSelect;
