import { createSelector } from "reselect";
import { Store } from "@Models/Store";
import { VideoPlaylist } from "@Models/Video";

export const getPlaylistsList = ({ playlist }: Store): VideoPlaylist[] => playlist.list;

export const getPlaylistsSelectList = createSelector(getPlaylistsList, playlists =>
  playlists.map(playlist => ({
    value: playlist.playlist_id,
    label: playlist.title
  }))
);
