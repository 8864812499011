import RootPlaylistSection from "./sections";
import "./style.scss";

type PlaylistProps = {
  match: { params: { playlistId: string }; url: string };
};

function ChannelPlaylists({ match }: PlaylistProps) {
  return (
    <div className="channel-playlists">
      {!match.params.playlistId && <h2 className="title">Playlists</h2>}
      <RootPlaylistSection match={match} />
    </div>
  );
}

export default ChannelPlaylists;
