import { Col, Row } from "react-flexbox-grid";
import { TopCreator } from "@Models/HomeData";
import { Link } from "react-router-dom";
import { getAvatar } from "@Utils";

type Props = {
  topCreators: TopCreator[];
};

function TopCreators({ topCreators }: Props) {
  return (
    <article className="top-creators">
      <div className="top-creators-top">
        <h2 className="section-title">Top Channels</h2>
        <Link className="top-creators-all" to="/categories/channels?category=all">
          All
        </Link>
      </div>
      <ul className="top-creators-list">
        {topCreators &&
          topCreators.map((creator, i) => (
            <li className="creator-item" key={i}>
              <Row>
                <Col className="top-creators-list-image">
                  <Link to={`/channel/${creator.channel.slug}`}>
                    {creator.channel.image ? (
                      <div className="avatar-container">
                        {creator.channel.is_live && (
                          <div className="live-badge">
                            <div className="live-beep" />
                          </div>
                        )}
                        <div
                          className="user-avatar"
                          style={{
                            backgroundImage: `url(${creator.channel.image.replace(
                              "[SIZE]",
                              "250x250"
                            )})`
                          }}
                        />
                      </div>
                    ) : (
                      <div className="avatar-container">
                        {creator.channel.is_live && (
                          <div className="live-badge">
                            <div className="live-beep" />
                          </div>
                        )}
                        <div
                          className="user-avatar"
                          style={{
                            backgroundColor: getAvatar(creator.channel.name).color
                          }}
                        >
                          <span>{getAvatar(creator.channel.name).shortLetters}</span>
                        </div>
                      </div>
                    )}
                  </Link>
                </Col>
                <Col xs className="top-creators-list-info">
                  <div className="metadata">
                    <span className="category">{creator.category.name}</span>
                    <Link className="metadata-name" to={`/channel/${creator.channel.slug}`}>
                      {creator.channel.name}
                    </Link>
                    <span className="followers-count">
                      <i className="icon-users-group" /> {creator.followers}
                    </span>
                  </div>
                  <div className="user-name">
                    <Link to={`/channel/${creator.channel.slug}`}>{creator.channel.name}</Link>
                  </div>
                </Col>
              </Row>
            </li>
          ))}
      </ul>
    </article>
  );
}

export default TopCreators;
