import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "@Components";
import { FILTER_DASHBOARD_ITEMS } from "@Constants";
import { abbreviateNumber, formatCommaNumber } from "@Utils";
import { ADListItem, ADMultiCounter, ADStatItem } from "@Models/AdminDashboard";
import "./style.scss";

type DashboardWidgetProps = {
  title: string;
  type: string;
  counter?: number;
  counterLabel?: string;
  multiCounter?: ADMultiCounter;
  dataStats?: ADStatItem[];
  dataList?: ADListItem[] | null;
  defaultFilter?: string;
  listItemBaseUrl?: string;
  omSetInterval?: (interval: string) => void;
};

function DashboardWidget({
  type,
  title,
  dataList,
  dataStats,
  counter,
  counterLabel,
  multiCounter,
  omSetInterval,
  defaultFilter,
  listItemBaseUrl = "/video"
}: DashboardWidgetProps) {
  const [selectedFilter, setSelectedFilter] = useState("1d");
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (!!dataList || dataStats?.length) {
      setIsFetching(false);
    }
  }, [dataList, dataStats]);

  useEffect(() => {
    !!defaultFilter && setSelectedFilter(defaultFilter);
  }, [defaultFilter]);

  if (isFetching) {
    return (
      <article className="dashboard-widget">
        <LoadingSpinner />
      </article>
    );
  }

  return (
    <article className="dashboard-widget">
      <h4 className="title">{title}</h4>
      {counter && (
        <>
          <p className="counter-label">{counterLabel}</p>
          <div className="counter">{formatCommaNumber(counter)}</div>
        </>
      )}
      {multiCounter && (
        <>
          <p className="counter-label">{counterLabel}</p>
          <ul className="advanced-counter">
            <li>
              <div className="counter">{formatCommaNumber(multiCounter.public)}</div>
            </li>
            <li>
              <div className="counter-total">
                <span className="divider">/</span>
                {formatCommaNumber(multiCounter.total)}
              </div>
            </li>
          </ul>
        </>
      )}
      {!!dataList && (
        <>
          <ul className="sort-filter-bar">
            {FILTER_DASHBOARD_ITEMS.map((filter, idx) => (
              <li key={`${type}-${idx}`}>
                <input
                  type="radio"
                  id={`${type}-${filter.value}`}
                  value={filter.value}
                  checked={filter.value === selectedFilter}
                  onChange={e => {
                    setSelectedFilter(e.target.value);
                    omSetInterval && omSetInterval(e.target.value);
                  }}
                  name={`filter-period-${type}`}
                />
                <label htmlFor={`${type}-${filter.value}`}>{filter.label}</label>
              </li>
            ))}
          </ul>
          <div className="data-list">
            {dataList?.length ? (
              dataList.map((item: ADListItem, idx) => (
                <Link
                  to={`${listItemBaseUrl}/${item.slug}`}
                  key={`${item.slug}-${idx}`}
                  className="list-item"
                >
                  <span>{item.title}</span> <span>{abbreviateNumber(item.count)}</span>
                </Link>
              ))
            ) : (
              <div className="no-list-data">
                <i className="icon-no-data" />
                <span>No Data</span>
              </div>
            )}
          </div>
        </>
      )}
      {dataStats && (
        <>
          <ul className="data-stat">
            {dataStats.map((item: ADStatItem, idx) => (
              <li key={idx}>
                <span>{item.title}</span>
                <div className="direction-label">
                  <span>{abbreviateNumber(item.value)}</span>
                  {!!item.indicator && (
                    <span className="label-value">
                      <i className={`icon icon-caret-${item.indicator}`} />
                      <span className={`value-indicator-${item.indicator}`}>
                        {`${item.indicator == "up" ? "+" : ""}${abbreviateNumber(
                          item.valueIndicator
                        )}`}
                      </span>
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </article>
  );
}

export default DashboardWidget;
