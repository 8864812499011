import { useState, useRef } from "react";
import { useOnClickOutside } from "@Hooks";
import "./style.scss";

type Props = {
  setShowChat: (item: boolean) => void;
  setShowParticipants: (item: boolean) => void;
};

const LiveChatHeader: React.FC<Props> = ({ setShowChat, setShowParticipants }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuNode = useRef<HTMLDivElement | null>(null);

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  useOnClickOutside(menuNode, toggleShowMenu);

  return (
    <div className="live-chat-header">
      <div className="live-chat-header__block-left">
        <i className="icon-chat-message-fill live-chat-header__icon-message" />
        <span className="live-chat-header__title">Live Chat</span>
      </div>
      <div className="live-chat-header__block-right">
        <i className="icon-settings live-chat-header__icon-settings" onClick={toggleShowMenu} />
        {showMenu && (
          <div className="live-chat-menu" ref={menuNode}>
            <div className="live-chat-menu__item" onClick={() => setShowParticipants(true)}>
              Participants
            </div>
            <div className="live-chat-menu__item" onClick={() => setShowChat(false)}>
              Hide chat
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveChatHeader;
