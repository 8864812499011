import formatDate from "date-fns/format";
import Linkify from "react-linkify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { Link, NavLink, Redirect, Route } from "react-router-dom";
import { requestUserDetails } from "@Store/actions/user";
import { USER_SECTIONS_LIST } from "./constants";
import { LinkDecorator, LoadingSpinner } from "@Components";
import { getAvatar } from "@Utils";
import useRootRef from "@Hooks/useRootRef";
import RootSection from "./sections";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

type PublicUserPageTypes = {
  match: {
    url: string;
    path: string;
    params: { username: string; sectionId: string };
    isExact: boolean;
  };
};

type PublicProfileDetailsTypes = {
  about: string;
  channel: { channel_id: number; slug: string; image: string | null; name: string };
  created_at: number;
  full_name: string;
  following: number;
  image: string | null;
  streamer_id: number;
  username: string;
};

function PublicUserPage({ match }: PublicUserPageTypes) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<PublicProfileDetailsTypes | null>(null);
  const [expandDescription, setExpandDescription] = useState(false);
  const rootRef = useRootRef();

  useEffect(() => {
    dispatch(
      requestUserDetails(match.params.username, {
        onSuccess: setUserData,
        onError: () => {}
      })
    );
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, [match.params.username]);

  if (!userData) {
    return (
      <div className="page-content">
        <section className="full-container public-profile-page user-profile">
          <LoadingSpinner />
        </section>
      </div>
    );
  }

  const userAvatarAlt = getAvatar(userData.full_name);
  const channelAvatarAlt = getAvatar(userData.channel.name ? userData.channel.name : "");

  return (
    <div className="page-content">
      <section className="full-container public-profile-page user-profile">
        <div className="mobile-block">
          <div className="mobile-block-item breadcrumbs-block">
            <Link to={`/`} className="breadcrumbs-block-link">
              Home
            </Link>
            <span>/</span>
            <Link to={`/streamer/`} className="breadcrumbs-block-link">
              Profile
            </Link>
          </div>
          <div className="mobile-block-item mobile-block-title">
            <span>{userData.full_name}</span>
          </div>
          <div className="mobile-block-item mobile-block-info">
            <div className="mobile-block-info-avatar">
              {userData.image ? (
                <div
                  className="owner-avatar avatar"
                  style={{
                    backgroundImage: `url(${userData.image.replace("[SIZE]", "100x100")})`
                  }}
                />
              ) : (
                <div
                  className="owner-avatar avatar"
                  style={{
                    backgroundColor: userAvatarAlt.color
                  }}
                >
                  <span className="name-letters">{userAvatarAlt.shortLetters}</span>
                </div>
              )}
            </div>
          </div>
          <div className="mobile-block-item mobile-block-joined">
            <i className="icon-calendar" />
            <p className="muted">{`Joined on ${formatDate(
              userData.created_at * 1000,
              "MMM d, yyyy"
            )}`}</p>
          </div>
          {userData.about && (
            <div className="mobile-block-item mobile-block-desc">
              <p>
                <Linkify componentDecorator={LinkDecorator}>
                  {((userData.about.match(/\n/g) || []).length >= 3 ||
                    userData.about.length > 375) &&
                  !expandDescription ? (
                    <>
                      {(userData.about.match(/\n/g) || []).length >= 3 &&
                      userData.about.split("\n")[0].length < 100 &&
                      userData.about.split("\n")[1].length < 100
                        ? userData.about.split("\n").splice(0, 3).join("\n")
                        : userData.about.substring(0, 375)}{" "}
                      <span
                        className="desc-indicator"
                        onClick={() => {
                          setExpandDescription(true);
                        }}
                      >
                        More...
                      </span>
                    </>
                  ) : (
                    <>
                      {userData.about}
                      {expandDescription && (
                        <span
                          className="desc-indicator"
                          onClick={() => {
                            setExpandDescription(false);
                          }}
                        >
                          {" "}
                          less
                        </span>
                      )}
                    </>
                  )}
                </Linkify>
              </p>
            </div>
          )}
          <div className="mobile-block-item mobile-block-owner">
            {userData.channel.name && (
              <div className="streamer-details">
                <span className="muted">Owner of</span>
                <Link to={`/channel/${userData.channel.slug}`}>
                  {userData.channel.image ? (
                    <div
                      className="streamer-avatar avatar"
                      style={{
                        backgroundImage: `url(${userData.channel.image.replace("[SIZE]", "32x32")})`
                      }}
                    />
                  ) : (
                    <div
                      className="streamer-avatar avatar"
                      style={{
                        backgroundColor: channelAvatarAlt.color
                      }}
                    >
                      <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
                    </div>
                  )}

                  <span className="channel-name">{userData.channel.name}</span>
                </Link>
              </div>
            )}
          </div>
        </div>
        <Row className="account-heading">
          <Col className="left-side">
            <Row className="account-image">
              {userData.image ? (
                <div className="avatar-container">
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundImage: `url(${userData.image.replace("[SIZE]", "400x400")})`
                    }}
                  />
                </div>
              ) : (
                <div className="avatar-container">
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundColor: userAvatarAlt.color
                    }}
                  >
                    <span className="name-letters">{userAvatarAlt.shortLetters}</span>
                  </div>
                </div>
              )}
              {userData.channel.name && (
                <div className="streamer-details">
                  <span className="muted">Owner of</span>
                  <Link to={`/channel/${userData.channel.slug}`}>
                    {userData.channel.image ? (
                      <div
                        className="streamer-avatar avatar"
                        style={{
                          backgroundImage: `url(${userData.channel.image.replace(
                            "[SIZE]",
                            "32x32"
                          )})`
                        }}
                      />
                    ) : (
                      <div
                        className="streamer-avatar avatar"
                        style={{
                          backgroundColor: channelAvatarAlt.color
                        }}
                      >
                        <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
                      </div>
                    )}

                    <span className="channel-name">{userData.channel.name}</span>
                  </Link>
                </div>
              )}
            </Row>
          </Col>
          <Col className="account-details" xs>
            <Row between="xs">
              <Col>
                <h2 className="account-name">{userData.full_name}</h2>
              </Col>
            </Row>
            <Row between="xs">
              <Col>
                <div className="details-col">
                  <i className="icon-calendar" />
                  <p className="muted">{`Joined on ${formatDate(
                    userData.created_at * 1000,
                    "MMM d, yyyy"
                  )}`}</p>
                  {/* <i className="icon-clock-o" />
                <p className="muted">{`Last seen 3 min ago`}</p> */}
                </div>
              </Col>
            </Row>
            {userData.about && (
              <Row className="description">
                <i className="icon-info-o" />
                <p>
                  <Linkify componentDecorator={LinkDecorator}>
                    {((userData.about.match(/\n/g) || []).length >= 3 ||
                      userData.about.length > 375) &&
                    !expandDescription ? (
                      <>
                        {(userData.about.match(/\n/g) || []).length >= 3 &&
                        userData.about.split("\n")[0].length < 100 &&
                        userData.about.split("\n")[1].length < 100
                          ? userData.about.split("\n").splice(0, 3).join("\n")
                          : userData.about.substring(0, 375)}{" "}
                        <span
                          className="desc-indicator"
                          onClick={() => {
                            setExpandDescription(true);
                          }}
                        >
                          More...
                        </span>
                      </>
                    ) : (
                      <>
                        {userData.about}
                        {expandDescription && (
                          <span
                            className="desc-indicator"
                            onClick={() => {
                              setExpandDescription(false);
                            }}
                          >
                            {" "}
                            less
                          </span>
                        )}
                      </>
                    )}
                  </Linkify>
                </p>
              </Row>
            )}
            <Row className="nav-tabs">
              {USER_SECTIONS_LIST.map(section => (
                <NavLink
                  key={section.id}
                  to={`${match.url}/${section.id}`}
                  activeClassName="active"
                  className="nav-tab"
                >
                  {section.label}
                  {section.id === "following" && (
                    <span className="badge-counter">{userData.following}</span>
                  )}
                </NavLink>
              ))}
            </Row>
            <Row>
              {match.isExact && (
                <Redirect
                  to={{
                    pathname: `${match.url}/following`
                  }}
                />
              )}
              <Route path={`${match.path}/:sectionId/:playlistId?`} render={RootSection as any} />
            </Row>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default PublicUserPage;
