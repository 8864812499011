import { useMemo, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { requestCategoryChannels } from "@Store/actions/channel";
import { IPageInfo } from "@Models/Store";
import { BaseChannel } from "@Models/Channel";
import PageWrapper from "@Components/PageWrapper";
import ChannelCard from "@Components/ChannelCard";
import LoadingSpinner from "@Components/LoadingSpinner";
import EmptyPlaceholder from "@Components/EmptyPlaceholder";
import CategoriesChannelsFilters from "@Views/CategoriesChannels/Filters/intex";
import useRootRef from "@Hooks/useRootRef";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

const CategoriesChannels = (): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [channels, setChannels] = useState<BaseChannel[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const tagSlug = useMemo(() => new URLSearchParams(search).get("tag") || undefined, [search]);
  const categorySlug = useMemo(
    () => new URLSearchParams(search).get("category") || undefined,
    [search]
  );
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState({});
  const rootRef = useRootRef();

  useEffect(() => {
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  const fetchChannels = useCallback(
    (category, tag, page, appliedFilters?, initial?) => {
      if (categorySlug || tagSlug) {
        if (page === 1) {
          setIsFetchingData(true);
          setChannels([]);
        }
        dispatch(
          requestCategoryChannels({ category, tag, appliedFilters }, page, {
            onSuccess: ({
              data,
              pageInfo: requestPageInfo
            }: {
              data: BaseChannel[];
              pageInfo: IPageInfo;
            }) => {
              setChannels(existingChannels => existingChannels.concat(data));
              setPageInfo(requestPageInfo);
              setIsFetchingData(false);
              initial && !!data.length && setIsFilterEnabled(true);
            },
            onError: () => {
              setIsFetchingData(false);
            }
          })
        );
      }
    },
    [categorySlug, tagSlug]
  );

  const handleFetchNextPage = useCallback(() => {
    pageInfo && fetchChannels(categorySlug, tagSlug, pageInfo.currentPage + 1, filtersApplied);
  }, [pageInfo]);

  useEffect(() => {
    fetchChannels(categorySlug, tagSlug, 1, undefined, true);
    rootRef?.scrollTo(0, 0);
  }, [tagSlug, categorySlug]);

  const onFiltersUpdate = (filters: any) => {
    setFiltersApplied(filters);
    fetchChannels(categorySlug, tagSlug, 1, filters);
  };

  return (
    <PageWrapper title={categorySlug || tagSlug || "Channels"} className="category-channels-root">
      {isFilterEnabled && <CategoriesChannelsFilters onFiltersUpdate={onFiltersUpdate} />}
      <div className="category-page-content">
        {isFetchingData ? (
          <LoadingSpinner />
        ) : (
          <>
            {channels.length !== 0 ? (
              <Row className="category-content">
                {channels.map(channel => (
                  <Col className="channel-item" key={channel.slug} lg={3} md={4} sm={6}>
                    <ChannelCard {...channel} />
                  </Col>
                ))}
                {pageInfo && pageInfo.currentPage < pageInfo.totalPages && (
                  <Row center="xs">
                    <button
                      type="button"
                      className="secondary-button outline"
                      onClick={handleFetchNextPage}
                      disabled={isFetchingData}
                    >
                      Load more
                    </button>
                  </Row>
                )}
              </Row>
            ) : (
              <EmptyPlaceholder message="No channels in this category" />
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default CategoriesChannels;
