import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import EmptyPlaceholder from "@Components/EmptyPlaceholder";
import PageWrapper from "@Components/PageWrapper";
import LoadingSpinner from "@Components/LoadingSpinner";
import { requestCategoryVideos } from "@Store/actions/video";
import { IPageInfo } from "@Models/Store";
import { VideoModel } from "@Models/Video";
import { AppSelect } from "@Models/AppSelect";
import VideoPostItem from "@Components/VideoPostItem";
import StreamLivePostItem from "@Components/StreamLivePostItem";
import CategoriesVideosFilters from "@Views/CategoriesVideos/Filters/intex";
import useRootRef from "@Hooks/useRootRef";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

export type FiltersDataType = {
  [key: string]: AppSelect[];
};

const CategoriesVideos = (): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [videos, setVideos] = useState<VideoModel[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfo>();
  const tagSlug = useMemo(() => new URLSearchParams(search).get("tag"), [search]);
  const categorySlug = useMemo(() => new URLSearchParams(search).get("category"), [search]);
  const subcategorySlug = useMemo(() => new URLSearchParams(search).get("subcategory"), [search]);
  const [filtersData, setFiltersData] = useState({} as FiltersDataType);
  const [filtersApplied, setFiltersApplied] = useState({});
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const rootRef = useRootRef();

  useEffect(() => {
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  const fetchVideos = useCallback(
    (category, subcategory, tag, page, appliedFilters?: any, initial = false) => {
      if (categorySlug || tagSlug) {
        if (page === 1) {
          setIsFetchingData(true);
          setVideos([]);
        }
        dispatch(
          requestCategoryVideos({ category, subcategory, tag, appliedFilters }, page, {
            onSuccess: ({
              data,
              filters,
              pageInfo: requestPageInfo
            }: {
              data: VideoModel[];
              filters: FiltersDataType;
              pageInfo: IPageInfo;
            }) => {
              setFiltersData(filters);
              setVideos(existingVideos => existingVideos.concat(data));
              setPageInfo(requestPageInfo);
              setIsFetchingData(false);
              initial && !!data.length && setIsFilterEnabled(true);
            },
            onError: () => {
              setIsFetchingData(false);
            }
          })
        );
      }
    },
    [categorySlug, tagSlug]
  );

  const handleLoadMoreData = useCallback(() => {
    pageInfo &&
      fetchVideos(categorySlug, subcategorySlug, tagSlug, pageInfo.currentPage + 1, filtersApplied);
  }, [pageInfo, categorySlug, tagSlug, subcategorySlug]);

  useEffect(() => {
    setFiltersData({});
    setIsFilterEnabled(false);
    fetchVideos(categorySlug, subcategorySlug, tagSlug, 1, undefined, true);
    rootRef?.scrollTo(0, 0);
  }, [tagSlug, categorySlug, subcategorySlug]);

  const onFiltersUpdate = (filters: any) => {
    setFiltersApplied(filters);
    fetchVideos(categorySlug, subcategorySlug, tagSlug, 1, filters);
  };

  return (
    <PageWrapper
      title={(!subcategorySlug ? categorySlug : subcategorySlug) || tagSlug || "Videos"}
      className="category-video-root"
    >
      {isFilterEnabled && (
        <CategoriesVideosFilters filtersData={filtersData} onFiltersUpdate={onFiltersUpdate} />
      )}
      <div className="category-page-content">
        {!isFetchingData ? (
          <>
            {videos.length !== 0 ? (
              <>
                <Row className="category-video-content">
                  {videos.map((video, i) => (
                    <Col
                      key={`${video.hash_id}-${i}`}
                      lg={3}
                      md={4}
                      sm={6}
                      className="category-video-content-item"
                    >
                      {categorySlug === "streams" ? (
                        <StreamLivePostItem {...video} />
                      ) : (
                        <VideoPostItem {...video} />
                      )}
                    </Col>
                  ))}
                </Row>
                {pageInfo && pageInfo.currentPage < pageInfo.totalPages && (
                  <Row center="xs">
                    <button
                      type="button"
                      className="secondary-button outline"
                      onClick={handleLoadMoreData}
                      disabled={isFetchingData}
                    >
                      Load more
                    </button>
                  </Row>
                )}
              </>
            ) : (
              <>
                {categorySlug === "streams" ? (
                  <EmptyPlaceholder
                    icon="icon-stream"
                    message="No available streams at the moment"
                  />
                ) : (
                  <EmptyPlaceholder message="No videos in this category" />
                )}
              </>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </PageWrapper>
  );
};

export default CategoriesVideos;
