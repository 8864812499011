import cn from "classnames";
import "./style.scss";

interface IProps {
  visible: boolean;
  offsetTop: number;
  onClick(): void;
}

const Overlay = ({ visible, offsetTop, onClick }: IProps): JSX.Element => (
  <div
    className={cn("dm-overlay-root", [visible && "dm-overlay-visible"])}
    style={{ top: offsetTop }}
    onClick={onClick}
  />
);

export default Overlay;
