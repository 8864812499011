import classes from "classnames";
import { propEq, update } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Comments } from "@Components";
import { getVideosWithNewComments, markAsReadVideoWithNewComments } from "@Store/actions/video";
import { getUserNotifications } from "@Store/selectors/notifications";

import VideosList from "../CommentedVideosList";
import HeadingControls from "../HeadingControls";
import { Link } from "react-router-dom";
import EmptyComments from "../EmptyComments";
import { updateCommentsNotifications } from "@Store/actions/notifications";

type VideoItem = {
  counter: string;
  description: string;
  thumb: null | "string";
  title: string;
  video_id: number;
  hash_id: string;
};

type ActiveTabTypes = "unanswered" | "all";

type Props = {
  match: { params: { sectionId: string } };
  location: { search: string };
};

function CommentsInboxPage({ location }: Props) {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getUserNotifications)["comments"]["inbox"];

  const [videosList, setVideosList] = useState<VideoItem[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<VideoItem | null>(null);
  const [checkedCommentsState, setCheckedCommentsState] = useState({} as any);
  const [activeTab, setActiveTab] = useState<ActiveTabTypes>("all");
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    const preselectedVideoId =
      location && location.search ? new URLSearchParams(location.search).get("video_id") : null;

    dispatch(
      getVideosWithNewComments(activeTab, {
        onSuccess: (list: VideoItem[]) => {
          setVideosList(list);
          if (preselectedVideoId) {
            const preselectedVideoIndex = list.findIndex(propEq("hash_id", preselectedVideoId));

            if (preselectedVideoIndex !== -1) {
              setSelectedVideo(list[preselectedVideoIndex]);
              setActiveTab("all");
            }
          } else {
            setSelectedVideo(list[0]);
          }
        },
        onError: () => {}
      })
    );

    if (notificationsCount && !preselectedVideoId) {
      setActiveTab("unanswered");
    }
  }, [activeTab]);

  const onLoadVideoCommentsSuccess = () => {
    if (selectedVideo) {
      dispatch(markAsReadVideoWithNewComments(selectedVideo.video_id, false));

      dispatch(
        updateCommentsNotifications({
          inbox:
            notificationsCount - Number(selectedVideo.counter) > 0
              ? notificationsCount - Number(selectedVideo.counter)
              : 0
        })
      );

      const videoIndex = videosList.findIndex(
        ({ video_id }) => video_id === selectedVideo.video_id
      );

      if (videoIndex !== -1) {
        setVideosList(
          update(
            videoIndex,
            {
              ...videosList[videoIndex],
              counter: "0"
            },
            videosList
          )
        );
      }
    }
  };

  const onSetActiveTab = (type: ActiveTabTypes) => () => {
    setActiveTab(type);
    setSelectedVideo(null);
  };

  const onCheckComment = (comment_id: number) => {
    setCheckedCommentsState({
      ...checkedCommentsState,
      [comment_id]: !checkedCommentsState[comment_id]
    });
  };

  const onDeleteCommentsSuccess = () => {
    setResetTrigger(!resetTrigger);
    setCheckedCommentsState({});
  };

  const selectedCommentsId = useMemo(() => {
    return Object.keys(checkedCommentsState).filter(key => checkedCommentsState[key]);
  }, [checkedCommentsState]);

  return (
    <section className="comments-inbox-page">
      <Row className="filters">
        <Col
          className={classes("filter-item", { active: activeTab === "all" })}
          onClick={onSetActiveTab("all")}
        >
          All
        </Col>
        <Col
          className={classes("filter-item", { active: activeTab === "unanswered" })}
          onClick={onSetActiveTab("unanswered")}
        >
          Unread
          {!!notificationsCount && <span className="badge-counter">{notificationsCount}</span>}
        </Col>
      </Row>
      {!!videosList.length ? (
        <Row className="videos-comments">
          <VideosList
            list={videosList}
            selectedVideo={selectedVideo}
            onSelectVideo={setSelectedVideo}
          />
          <Col xs className="video-comments">
            {!!selectedCommentsId.length && (
              <HeadingControls
                idsList={selectedCommentsId}
                onDeleteSuccess={onDeleteCommentsSuccess}
              />
            )}
            <Row className="heading" between="xs">
              <Col className="check-option check-coll">
                <label className="select-all-option">
                  <span className="label-text">COMMENTS</span>
                </label>
              </Col>
              <Col className="check-option">
                {selectedVideo && (
                  <Link className="thumb" to={`/video/${selectedVideo.hash_id}`}>
                    <button className="open-post-link icon-open-link-item" />
                  </Link>
                )}
              </Col>
            </Row>
            {selectedVideo && (
              <Comments
                onCheckComment={onCheckComment}
                checkedCommentsState={checkedCommentsState}
                video_id={selectedVideo.video_id}
                resetTrigger={resetTrigger}
                video_channel_id={0}
                onLoadSuccess={onLoadVideoCommentsSuccess}
                onlyUnanswered={activeTab === "unanswered"}
                type={activeTab === "unanswered" ? "commentsInbox" : "comments"}
              />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyComments />
      )}
    </section>
  );
}

export default CommentsInboxPage;
