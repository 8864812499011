import { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col } from "react-flexbox-grid";
import { FilterComponent } from "@Models/FilterComponent";
import "./style.scss";

type Props = {
  filter: FilterComponent;
  onCancel: () => void;
  onSelectedItem: (selectedItem: FilterComponent) => void;
};

type RangeType = {
  label: string;
  value: string;
};

const RangeOperators: RangeType[] = [
  { label: ">=", value: "ge" },
  { label: "<=", value: "le" },
  { label: "<", value: "lt" },
  { label: ">", value: "gt" },
  { label: "==", value: "eq" },
  { label: "!=", value: "ne" }
];

const FilterRange = ({ filter, onCancel, onSelectedItem }: Props) => {
  const [selectedValue, setSelectedValue] = useState<RangeType | undefined | null>(
    RangeOperators[0]
  );
  const [viewsNumber, setViewsNumber] = useState<number | string>(0);

  useEffect(() => {
    if (!!filter && filter.value) {
      const [value, views] = filter.value;
      setViewsNumber(views);
      setSelectedValue(RangeOperators.find(item => item.value === value));
    }
  }, [filter]);

  return (
    <div className="range-filter">
      <div className="selected-item">
        {filter.label} <i className="icon-close" onClick={onCancel} />
      </div>
      <div className="range-body">
        <Row between="xs">
          <Col xs>
            <Select
              classNamePrefix="app-select"
              className={"app-select range-select"}
              menuPlacement="bottom"
              value={selectedValue}
              isSearchable={false}
              onChange={selected => {
                setSelectedValue(selected);
              }}
              options={RangeOperators}
            />
          </Col>
          <Col xs>
            <input
              type="number"
              value={viewsNumber}
              onChange={event => {
                setViewsNumber(event.target.value);
              }}
              className="main-input"
            />
          </Col>
        </Row>
      </div>
      <button
        className="main-button apply-button"
        onClick={() => {
          if (!!selectedValue) {
            const { id, label, componentType } = filter;
            const value = [selectedValue.value, viewsNumber];
            let name = ` ${selectedValue.label} ${viewsNumber}`;

            onSelectedItem({ id, componentType, label, value, name });
            onCancel();
          }
        }}
      >
        Apply
      </button>
    </div>
  );
};

export default FilterRange;
