import classes from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { getAvatar } from "@Utils";
import { requestAdminBan } from "@Store/actions/admin";

type ChannelProps = {
  viewMode: boolean;
  channel_id?: number;
  description: string;
  followers: number;
  image: string;
  name: string;
  slug: string;
  banned: boolean;
  videos: number;
};

function ChannelItem({
  image,
  name,
  description,
  videos,
  followers,
  banned,
  slug,
  viewMode = false
}: ChannelProps) {
  const dispatch = useDispatch();
  const [isBanned, setIsBanned] = useState(false);
  const channelAvatarAlt = getAvatar(name || "");

  useEffect(() => {
    setIsBanned(banned);
  }, [banned]);

  const handleBanAction = (type: string, slugToBan: string) => {
    dispatch(
      requestAdminBan(
        {
          onSuccess: () => {
            setIsBanned(type === "ban");
          },
          onError: () => null
        },
        type,
        slugToBan
      )
    );
  };

  return (
    <Row className="channel-item-container">
      <Col xs className=" name-coll">
        <div className="channel-item">
          {image ? (
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${image.replace("[SIZE]", "200x200")})`
              }}
            >
              <Link to={`/channel/${slug}`} className="link-to-channel">
                <i className="icon-eye" />
              </Link>
            </div>
          ) : (
            <div
              className="avatar"
              style={{
                backgroundColor: channelAvatarAlt.color
              }}
            >
              <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
              <Link to={`/channel/${slug}`} className="link-to-channel">
                <i className="icon-eye" />
              </Link>
            </div>
          )}

          <div className="channel-info">
            <h3 className="title">{name}</h3>
            <p className="description">{description}</p>
          </div>
        </div>
      </Col>
      <Col className="videos-coll">
        {viewMode && <i className="icon-circle-play" />}
        {videos}
      </Col>
      <Col className="followers-coll">
        <i className="icon-users-group" />
        {followers}
      </Col>
      <Col className="action-coll">
        <button
          type="button"
          className={classes("round-action-button ban-btn", {
            ["banned"]: isBanned
          })}
          onClick={() => {
            handleBanAction(!isBanned ? "ban" : "unban", slug);
          }}
        >
          {isBanned ? (
            <>
              <span>Banned</span>
              <span className="hover-btn-text">Unban</span>
            </>
          ) : (
            <span>Ban</span>
          )}
        </button>
        {viewMode ? (
          <Link
            to={`/profile/admin-settings/channels`}
            type="button"
            className="round-action-button back-button-channel"
          >
            <span>Back</span>
          </Link>
        ) : (
          <Link to={`/profile/admin-settings/channels/${slug}`} className="round-action-button">
            <span>Manage Channel</span>
          </Link>
        )}
      </Col>
    </Row>
  );
}

export default ChannelItem;
