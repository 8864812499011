import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { requestAdminAction, requestAdminChannelDetailsData } from "@Store/actions/admin";
import useRootRef from "@Hooks/useRootRef";
import AdminMassEdit from "@AdminComponents/MassEdit";
import { VideoListItem } from "@Components";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import ChannelItem from "../ChannelItem";
import { FiltersBar, FilterToggle } from "@Components/Filters";
import { Filters } from "@Models/Filters";

type ChannelDataProps = {
  data: any;
  pageInfo: any;
};

function ViewChannel() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [channelData, setChannelData] = useState({} as ChannelDataProps);
  const [checkedVideos, setCheckedVideos] = useState<any>({});
  const [activePage, setActivePage] = useState(0);
  const [videoActions, setVideoActions] = useState({});
  const [channelSlug] = useState(String(location.pathname.split("/").pop()));
  const [itemsPerPageCount, setItemsPerPageCount] = useState(ITEMS_PER_PAGE_COUNT_OPTIONS[0]);
  const [filtersState, setFiltersState] = useState(false);
  const [filtersData, setFiltersData] = useState({} as Filters);
  const rootRef = useRootRef();

  useEffect(() => {
    dispatch(
      requestAdminChannelDetailsData(
        1,
        itemsPerPageCount.value,
        channelSlug,
        {},
        {
          onSuccess: data => {
            setChannelData(data);
          },
          onError: () => null
        }
      )
    );
    dispatch(
      requestAdminAction({
        onSuccess: actions => {
          setVideoActions(actions);
        },
        onError: () => null
      })
    );
  }, [channelSlug]);

  useEffect(() => {
    dispatch(
      requestAdminChannelDetailsData(1, itemsPerPageCount.value, channelSlug, filtersData, {
        onSuccess: data => {
          setChannelData(data);
          setActivePage(0);
        },
        onError: () => null
      })
    );
  }, [filtersData]);

  const isInvalidVideo = (video: any) =>
    !video.loadProgress && !video.converter?.processCodeUpdate && !video.is_valid;

  const invalidVideosCount = useMemo(() => {
    return (
      !!channelData.data &&
      channelData.data.videosList.reduce(
        (acc: any, video: any) => (isInvalidVideo(video) ? acc + 1 : acc),
        0
      )
    );
  }, [channelData.data]);

  const checkedVideoIdsList = useMemo(
    () =>
      Object.keys(checkedVideos).reduce((acc: any, video_id) => {
        if (checkedVideos[video_id]) {
          return [...acc, Number(video_id)];
        }

        return acc;
      }, []),
    [checkedVideos]
  );

  const onCheckAllVideos = () => {
    const nextList = channelData.data.videosList.reduce((acc: any, video: any) => {
      if (video.video_id && !isInvalidVideo(video)) {
        return {
          ...acc,
          [video.video_id]:
            checkedVideoIdsList.length !== channelData.data.videosList.length - invalidVideosCount
        };
      }

      return acc;
    }, {});
    setCheckedVideos(nextList);
  };

  const onCheckVideo = (video_id: any) => () => {
    setCheckedVideos((prevstate: any) => ({
      ...prevstate,
      [video_id]: !prevstate[video_id]
    }));
  };

  const onChangeItemsPerPageCount = (item: any) => {
    setItemsPerPageCount(item);
    onChangeVideosPage(1, item.value, filtersData);
  };

  const onChangeVideosPage = useCallback(
    (nextPAge: number, itemsPerPage = itemsPerPageCount.value, filtersData?: any) => {
      dispatch(
        requestAdminChannelDetailsData(nextPAge, itemsPerPage, channelSlug, filtersData, {
          onSuccess: data => {
            setChannelData(data);
            setActivePage(nextPAge - 1);
          },
          onError: () => null
        })
      );
      rootRef?.scrollTo(0, 0);
    },
    [itemsPerPageCount.value]
  );

  const onFilterUpdates = (filters: Filters) => {
    setFiltersData(filters);
  };

  return (
    <div className="channel-view-settings">
      {!!channelData.data && <ChannelItem viewMode {...channelData.data} />}
      <FilterToggle
        status={filtersState}
        onStateChange={state => {
          setFiltersState(state);
        }}
      />
      <FiltersBar
        isActive={filtersState}
        onFiltersUpdate={onFilterUpdates}
        initialFilters={filtersData}
        onClose={() => {
          setFiltersState(false);
        }}
      />
      {!!checkedVideoIdsList.length && (
        <AdminMassEdit
          actions={videoActions}
          checkedItems={checkedVideoIdsList}
          setCheckedVideos={setCheckedVideos}
        />
      )}
      {!!channelData.data && !!channelData.data.videosList.length && (
        <Row className="heading heading-videos">
          <Col className="check-option check-coll">
            <label className="select-all-option">
              <input
                readOnly
                name="check"
                type="checkbox"
                checked={
                  !!checkedVideoIdsList.length &&
                  checkedVideoIdsList.length ===
                    channelData.data.videosList.length - invalidVideosCount
                }
              />
              <span className="label-text" onClick={onCheckAllVideos} />
            </label>
          </Col>
          <Col className="heading-option video-coll">Video</Col>
          <Col className="heading-option visibility-coll">Visibility</Col>
          <Col className="heading-option category-coll">Category</Col>
          <Col className="heading-option date-coll">Date</Col>
          <Col className="heading-option views-coll">Views</Col>
          <Col xs className="heading-option reactions-coll">
            Reactions
          </Col>
        </Row>
      )}

      {!!channelData.data && channelData.data.videosList.length ? (
        channelData.data.videosList.map((video: any, index: number) => (
          <VideoListItem
            {...video}
            key={index}
            adminMode
            download_url={video.download_url}
            viewOnly
            onCheck={onCheckVideo(video.video_id)}
            checked={!!checkedVideos[video.video_id]}
          />
        ))
      ) : (
        <div className="empty-admin-videos">
          <i className="icon-folder-empty" />
          <p className="empty-message">This channel doesn't have videos</p>
        </div>
      )}

      <Row className="app-pagination">
        {channelData.pageInfo && channelData.pageInfo.totalResults > 10 && (
          <Col className="items-per-page-col">
            <span>Show</span>
            <Select
              classNamePrefix="app-select"
              menuPlacement="auto"
              placeholder="Assign Category"
              isSearchable={false}
              value={itemsPerPageCount}
              onChange={onChangeItemsPerPageCount}
              options={ITEMS_PER_PAGE_COUNT_OPTIONS}
            />
          </Col>
        )}

        {channelData.pageInfo && channelData.pageInfo.totalPages > 1 && (
          <>
            <Col xs>
              <ReactPaginate
                forcePage={activePage}
                pageCount={channelData.pageInfo.totalPages}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) =>
                  onChangeVideosPage(selected + 1, itemsPerPageCount.value, filtersData)
                }
                activeClassName="active"
                pageClassName="page-number"
                containerClassName="pagination row"
                previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                nextLabel={<i className="icon icon-chevron-right nav-icon" />}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default ViewChannel;
