import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import classes from "classnames";
import { useWindowSize } from "react-use";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import ProfileControls from "@Views/ProfileControls";
import Sidebar from "@Components/DiscoveryMenu";
import TriggerMenuButton from "@Components/DiscoveryMenu/TriggerButton/TriggerMenuButton";
import { selectSidebarVisibility } from "@Store/selectors/sidebar";
import AppSearchInput from "./AppSearchInput";
import { LogoWhite } from "@Assets";

type Props = {
  scrolled: boolean;
  offsetTop: number;
};

const Header = ({ scrolled, offsetTop }: Props) => {
  const isSidebarVisible = useSelector(selectSidebarVisibility);
  const sidebarButtonContainerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  const sidebarButtonOffsetLeft = useMemo(
    () =>
      isSidebarVisible && sidebarRef.current && sidebarButtonContainerRef.current
        ? sidebarRef.current.offsetLeft - sidebarButtonContainerRef.current.offsetLeft
        : undefined,
    [isSidebarVisible, width]
  );

  return (
    <header className={classes("main-header", { fixed: scrolled })}>
      <div className="full-container">
        <Row>
          <Col className="main-header-left">
            <div ref={sidebarButtonContainerRef}>
              <TriggerMenuButton offsetLeft={sidebarButtonOffsetLeft} />
            </div>
            <Link to="/" className="main-logo">
              <img src={LogoWhite} width="225" alt="logo" />
            </Link>
          </Col>
          <AppSearchInput />
          <Col className="auto-column dynamic-auto main-header-right">
            <ProfileControls />
          </Col>
        </Row>
      </div>
      <Sidebar ref={sidebarRef} offsetTop={offsetTop} />
    </header>
  );
};

export default Header;
