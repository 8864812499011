import Select from "react-select";
import { Row, Col } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { VIDEO_STATUS_OPTIONS } from "@Constants";
import { CheckboxDropdown, ConfirmModal, StatusOption, StatusSelected } from "@Components";

import {
  deleteVideosList,
  requestUserVideos,
  cancelUploadVideosList,
  requestChangeVideosPlaylist,
  requestChangeVideosCategoryData,
  requestChangeVideosVisibility,
  requestChildrenDropdown
} from "@Store/actions/video";
import { useEffect, useState } from "react";
import { AppSelect } from "@Models/AppSelect";
import { DropdownChildren } from "@Models/DropdownChildren";
import { isEmpty } from "ramda";

type LibraryHeaderTypes = {
  checkedItems: any[];
  categoriesList: any[];
  playlistsList: any[];
  setCheckedVideos: Function;
  perPage?: number;
};

type DropdownData = {
  [key: string]: number[];
};

const statusOptionsList = Object.values(VIDEO_STATUS_OPTIONS)
  .reverse()
  .filter(i => i.value);

function LibraryHeader({
  categoriesList,
  playlistsList,
  checkedItems,
  setCheckedVideos,
  perPage
}: LibraryHeaderTypes) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dropdownsData, setDropdownsData] = useState({} as DropdownData);
  const [requestUpdateStatus, setRequestUpdateStatus] = useState(false);
  const [subcategoriesData, setSubcategoriesData] = useState<AppSelect[]>([]);
  const [country, setCountryData] = useState<AppSelect[]>([]);
  const [currency, setCurrencyData] = useState<AppSelect[]>([]);
  const [sector, setSectorData] = useState<AppSelect[]>([]);

  useEffect(() => {
    if (!isEmpty(dropdownsData) && requestUpdateStatus) {
      dispatch(requestChangeVideosCategoryData(dropdownsData, checkedItems, false));
    }
  }, [dropdownsData]);

  const toggleDeleteModalShow = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const onDeleteCheckedVideos = () => {
    if (checkedItems.length) {
      dispatch(
        deleteVideosList(checkedItems, false, {
          onSuccess: () => {
            dispatch(requestUserVideos(1, perPage));
          },
          onError: () => {}
        })
      );
      dispatch(cancelUploadVideosList(checkedItems));
      setCheckedVideos({});
    }
  };

  const onVisibilityChange = (selectedOption: any) => {
    if (checkedItems.length) {
      dispatch(requestChangeVideosVisibility(selectedOption.value, checkedItems, false));
    }
  };

  const onPlaylistChange = (selectedOption: any) => {
    if (checkedItems.length) {
      dispatch(requestChangeVideosPlaylist(selectedOption.value, checkedItems));
    }
  };

  const onCategoryChange = (selectedOption: AppSelect) => {
    resetDropdowns();
    setSubcategoriesData([]);
    dispatch(
      requestChildrenDropdown([selectedOption.value], "subcategories", {
        onSuccess: (data: DropdownChildren) => {
          if (data.subcategories?.length) {
            setSubcategoriesData(data.subcategories);
          } else {
            setRequestUpdateStatus(true);
          }
          setDropdownsData({ category: [selectedOption.value] });
        }
      })
    );
  };

  const onSubcategoryChange = (selectedSubcategories: AppSelect[] | any) => {
    resetDropdowns();
    if (!!selectedSubcategories) {
      setRequestUpdateStatus(true);
      setDropdownsData({
        ...dropdownsData,
        subcategories: selectedSubcategories.map((i: AppSelect) => i.value)
      });
      dispatch(
        requestChildrenDropdown(
          selectedSubcategories.map((i: AppSelect) => i.value),
          "topics",
          {
            onSuccess: (data: DropdownChildren) => {
              data.countries?.length && setCountryData(data.countries);
              data.sectors?.length && setSectorData(data.sectors);
              data.currency?.length && setCurrencyData(data.currency);
            }
          }
        )
      );
    }
  };

  const resetDropdowns = () => {
    setCountryData([]);
    setSectorData([]);
    setCurrencyData([]);
  };

  const onTopicSelected = (type: string, selected: AppSelect[]) => {
    selected &&
      setDropdownsData({ ...dropdownsData, [type]: selected.map((i: AppSelect) => i.value) });
  };

  return (
    <Row className="video-library-header">
      <Col className="control-item selected-items-count">{checkedItems.length} SELECTED</Col>
      <Col className="control-item visibility-item">
        <Select
          classNamePrefix="app-select"
          className="transparent-select"
          menuPlacement="auto"
          placeholder="Visibility"
          isSearchable={false}
          options={statusOptionsList}
          onChange={onVisibilityChange}
          components={{ Option: StatusOption, SingleValue: StatusSelected }}
        />
      </Col>
      <Col className="control-item">
        <Select
          classNamePrefix="app-select"
          className={"transparent-select"}
          menuPlacement="bottom"
          placeholder="Add to playlist"
          isSearchable={false}
          onChange={onPlaylistChange}
          noOptionsMessage={() => "No playlists"}
          options={playlistsList}
        />
      </Col>
      <Col className="control-item">
        <Select
          classNamePrefix="app-select"
          className={"transparent-select"}
          menuPlacement="bottom"
          placeholder="Category"
          isSearchable={false}
          onChange={onCategoryChange}
          options={categoriesList}
        />
      </Col>
      {!!subcategoriesData.length && (
        <Col className="control-item">
          <CheckboxDropdown
            checkboxSelect
            selectedPlaceholder="subcategories"
            isTransparent
            onApplyActionChange={onSubcategoryChange}
            options={subcategoriesData}
            placeholder="Assign Subcategory"
            noOptionsMessage="No Subcategories"
          />
        </Col>
      )}
      {!!country.length && (
        <Col className="control-item">
          <CheckboxDropdown
            checkboxSelect
            selectedPlaceholder="countries"
            isTransparent
            onApplyActionChange={(selected: any) => {
              onTopicSelected("country", selected);
            }}
            options={country}
            placeholder="Country"
            noOptionsMessage="No Countries"
          />
        </Col>
      )}
      {!!sector.length && (
        <Col className="control-item">
          <CheckboxDropdown
            checkboxSelect
            placeholder="Sector"
            selectedPlaceholder="sectors"
            isTransparent
            onApplyActionChange={(selected: any) => {
              onTopicSelected("sector", selected);
            }}
            options={sector}
            noOptionsMessage="No Subcategories"
          />
        </Col>
      )}
      {!!currency.length && (
        <Col className="control-item">
          <CheckboxDropdown
            checkboxSelect
            placeholder="Currency"
            selectedPlaceholder="currency"
            isTransparent
            onApplyActionChange={(selected: any) => {
              onTopicSelected("currency", selected);
            }}
            options={currency}
            noOptionsMessage="No Currency"
          />
        </Col>
      )}
      <Col className="control-item delete-button" onClick={toggleDeleteModalShow}>
        <i className="icon icon-remove" />
        <span>Delete</span>
      </Col>
      {showDeleteModal && (
        <ConfirmModal
          onClose={toggleDeleteModalShow}
          onConfirm={onDeleteCheckedVideos}
          bodyText={`Are you sure you want to delete selected videos ?`}
        />
      )}
    </Row>
  );
}

export default LibraryHeader;
