import { Col, Row } from "react-flexbox-grid";
import { VideoOverlay } from "@Assets";
import { Modal, VideoPlayer } from "@Components";
import { useState } from "react";

function MainSlider() {
  const [videoPopup, setVideoPopup] = useState(false);
  return (
    <section className="main-slider">
      <video autoPlay muted loop className="video-background" playsInline>
        <source src="https://about.sharevision.com/loop_v2_h264_slow.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="video-overlay" style={{ backgroundImage: `url(${VideoOverlay})` }} />
      <div className="content">
        <div className="full-container">
          <Row className="hero-info">
            <Col md={12} className="info-heading">
              <div className="we-are-live">
                <i className="icon-record" />
                We are live
              </div>
              <h1 className="welcome-txt">
                First dedicated streaming platform for the world of finance
              </h1>
              <div
                className="play-button"
                onClick={() => {
                  setVideoPopup(true);
                }}
              >
                <i className="icon-play" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {videoPopup && (
        <Modal
          onClose={() => {
            setVideoPopup(false);
          }}
        >
          <div className="video-popup">
            <VideoPlayer
              watchMode={true}
              src="https://about.sharevision.com/ShareVision.mp4"
              sourceType="video/mp4"
            />
          </div>
        </Modal>
      )}
    </section>
  );
}

export default MainSlider;
