import authorizedApiRequest from "@Fetch/authorized";
import { all, call, put, takeEvery, select } from "@redux-saga/core/effects";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";
import {
  PLAYLIST,
  setListItem,
  setPlaylistsList,
  updatePlaylistDetails,
  updatePlaylistsCounts
} from "@Store/actions/playlist";
import { updateChannelDetails } from "@Store/actions/channel";
import { getVideoLibraryCounts } from "@Store/selectors/channel";
import { getAuthUser } from "@Store/selectors/user";

function* requestCreatePlaylist({
  payload: { playlistName }
}: {
  payload: { playlistName: string };
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/playlist`, {
    method: "POST",
    body: JSON.stringify({ title: playlistName })
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    const channelCounts = yield select(getVideoLibraryCounts);

    yield put(
      updateChannelDetails({
        counts: {
          ...channelCounts,
          playlists: channelCounts.playlists + 1
        }
      })
    );

    yield put(setListItem(data));
  }
}

function* getUserPlaylists() {
  const authUser = yield select(getAuthUser);

  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlists/${authUser.channel_id}?full=1`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield put(setPlaylistsList(data));
  }
}

function* getUserPlaylistsCounts() {
  const authUser = yield select(getAuthUser);

  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlists/${authUser.channel_id}?full=1`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(updatePlaylistsCounts(data));
  }
}

function* getUserPlaylistVideos({
  payload: { playlist_id, page, limit, full },
  callbacks
}: {
  payload: { playlist_id: number; page: number; limit: number; full: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlist/${playlist_id}?page=${page}${limit ? "&limit=" + limit : ""}&full=${full}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data, pageInfo } = yield serverResponse.json();

    callbacks.onSuccess({ data, pageInfo });
  }
}

function* requestUpdatePlaylist({
  payload: { playlist_id, details }
}: {
  payload: { playlist_id: number; details: Object };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlist/${playlist_id}`,
    {
      method: "PUT",
      body: JSON.stringify(details)
    }
  );

  if (serverResponse.status === 200) {
    yield put(updatePlaylistDetails(playlist_id, details));
  }
}

function* requestDeletePlaylist({
  payload: { playlist_id }
}: {
  payload: { playlist_id: number };
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/playlist/${playlist_id}`,
    {
      method: "DELETE"
    }
  );

  if (serverResponse.status === 200) {
    const channelCounts = yield select(getVideoLibraryCounts);

    yield call(getUserPlaylists);

    yield put(
      updateChannelDetails({
        counts: {
          ...channelCounts,
          playlists: channelCounts.playlists - 1
        }
      })
    );
  }
}

export default function* playlistSagaWatcher() {
  yield all([
    takeEvery(PLAYLIST.REQUEST_LIST, getUserPlaylists),
    takeEvery(PLAYLIST.REQUEST_COUNTS, getUserPlaylistsCounts),
    takeEvery(PLAYLIST.REQUEST_CREATE, requestCreatePlaylist),
    takeEvery(PLAYLIST.REQUEST_VIDEOS, getUserPlaylistVideos),
    takeEvery(PLAYLIST.REQUEST_UPDATE, requestUpdatePlaylist),
    takeEvery(PLAYLIST.REQUEST_DELETE, requestDeletePlaylist)
  ]);
}
