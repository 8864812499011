import { createNamespace } from "@Utils";
import { VideosData, ChannelsData } from "@Models/Sidebar";
import { Directory } from "@Store/reducers/sidebar";

export const SIDEBAR = createNamespace("SIDEBAR", [
  "SHOW",
  "HIDE",
  "TOGGLE",
  "TOGGLE_MOBILE_MENU",
  "SET_ANIMATED",
  "SET_DIRECTORY",
  "GET_DATA",
  "SET_VIDEOS",
  "SET_CHANNELS"
]);

export const showSidebar = () => ({ type: SIDEBAR.SHOW });
export const hideSidebar = () => ({ type: SIDEBAR.HIDE });
export const toggleSidebar = () => ({ type: SIDEBAR.TOGGLE });
export const toggleSidebarMobile = () => ({ type: SIDEBAR.TOGGLE_MOBILE_MENU });
export const setSidebarAnimation = (payload: boolean) => ({ type: SIDEBAR.SET_ANIMATED, payload });
export const setSidebarDirectory = (payload: Directory) => ({
  type: SIDEBAR.SET_DIRECTORY,
  payload
});
export const getSidebarData = () => ({ type: SIDEBAR.GET_DATA });
export const setSidebarVideos = (payload: VideosData) => ({ type: SIDEBAR.SET_VIDEOS, payload });
export const setSidebarChannels = (payload: ChannelsData) => ({
  type: SIDEBAR.SET_CHANNELS,
  payload
});
