import { createElement } from "react";

import StreamsSection from "./sections/Streams";
import VideosSection from "./sections/Videos";
import PlaylistsSection from "./sections/Playlists";
import FollowersSection from "./sections/Followers";

export type SectionType = {
  id: string;
  label: string;
  component: JSX.Element;
};

export const CHANNEL_SECTIONS_LIST: SectionType[] = [
  {
    id: "videos",
    label: "Videos",
    component: createElement(VideosSection)
  },
  {
    id: "streams",
    label: "Streams",
    component: createElement(StreamsSection)
  },
  {
    id: "playlists",
    label: "Playlists",
    component: createElement(PlaylistsSection)
  },
  {
    id: "followers",
    label: "Followers",
    component: createElement(FollowersSection)
  }
];
