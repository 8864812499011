import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createBufferMiddleware from "./bufferMiddleware";
import createSagaMiddleware from "redux-saga";

import appSagas from "./sagas";
import appReducers from "./reducers";
import appBufferActions from "./bufferActions";

const sagaMiddleware = createSagaMiddleware();
const bufferMiddleware = createBufferMiddleware(appBufferActions);

export default createStore(
  appReducers as any,
  composeWithDevTools(applyMiddleware(bufferMiddleware, sagaMiddleware))
);

sagaMiddleware.run(appSagas);
