import { useSelector } from "react-redux";
import { getAppAuthStatus } from "@Store/selectors/app";
import { Route, Redirect } from "react-router-dom";

function AuthRoute(params: any) {
  const isAuthenticated = useSelector(getAppAuthStatus);

  if (isAuthenticated) {
    return <Route {...params} />;
  }
  return <Redirect to="/" />;
}

export default AuthRoute;
