import { USER } from "@Store/actions/user";
import { User } from "@Models/User";

const initialState: User = {
  email: "",
  full_name: "",
  username: "",
  about: "",
  avatar: "",
  role: "",
  channel_slug: "",
  email_confirmed: "",
  channel_id: 0
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case USER.SET_DETAILS:
      return action.payload;

    case USER.UPDATE_DETAILS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
