import { createSelector } from "reselect";
import { Store } from "@Models/Store";

export const getAuthUserLoadedVideos = (store: Store) => store.video.list;
export const getAuthUserLoadedStreams = (store: Store) => store.video.streamsList;
export const getStreamManagerDetails = (store: Store) => store.video.streamManager;

export const getAuthUserVideosPageInfo = (store: Store) => store.video.pageInfo;
export const getBanListData = (store: Store) => store.banList;

export const getAuthUserUploadingVideos = createSelector(getAuthUserLoadedVideos, list =>
  list.filter(({ loadProgress }) => loadProgress)
);
