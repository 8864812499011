import PlaylistDetails from "./PlaylistDetails";
import PlaylistArchive from "./PlaylistArchive";

type RootProfileSectionTypes = {
  match: any;
};

function RootPlaylistSection({ match }: RootProfileSectionTypes) {
  if (!!match.params.playlistId) {
    return <PlaylistDetails playlist_id={match.params.playlistId} />;
  }

  return <PlaylistArchive match={match} />;
}

export default RootPlaylistSection;
