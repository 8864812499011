import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useMeasure } from "react-use";
import { LayoutWrap } from "@Models/Layout";
import { LoadingSpinner } from "@Components";
import { ROOT_ELEMENT_ID } from "@Hooks/useRootRef";
import { getAppFetchingStatus } from "@Store/selectors/app";
import { getAuthUser } from "@Store/selectors/user";
import { Header, Footer, MarketsViewIframe } from "./components";
import { MobileProfileContextMenu } from "@Views/ProfileControls/Modals";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

function Layout({ children }: LayoutWrap) {
  const appIsFetching = useSelector(getAppFetchingStatus);
  const userDetails = useSelector(getAuthUser);
  const [scrolled, setScrolled] = useState(false);
  const [iframeRef, { height: iframeHeight }] = useMeasure<HTMLIFrameElement>();

  const handleScroll = useCallback((event: React.UIEvent<HTMLElement, UIEvent>) => {
    const isScrolled = event.currentTarget.scrollTop !== 0;

    setScrolled(isScrolled);
  }, []);

  if (appIsFetching) {
    return (
      <div className="app-wrap">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="root-wrap">
      <MarketsViewIframe ref={iframeRef} />
      <MobileProfileContextMenu {...userDetails} />
      <div className="app-root">
        <div id={ROOT_ELEMENT_ID} className="app-wrap" onScroll={handleScroll}>
          <Header scrolled={scrolled} offsetTop={iframeHeight} />
          <div className="app-content">{children}</div>
          <Footer />
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            closeButton={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
}

export default Layout;
