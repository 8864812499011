import { createNamespace } from "@Utils";
import { RequestCallbacks } from "@Models/RequestParams";

export const PLAYLIST = createNamespace("PLAYLIST", [
  "REQUEST_LIST",
  "REQUEST_COUNTS",
  "REQUEST_CREATE",
  "REQUEST_UPDATE",
  "UPDATE",
  "SET_LIST",
  "SET_LIST_COUNTS",
  "SET_LIST_ITEM",
  "REQUEST_VIDEOS",
  "GET_VIDEOS",
  "REQUEST_DELETE"
]);

export const requestUserPlaylists = () => ({
  type: PLAYLIST.REQUEST_LIST
});
export const requestUserPlaylistsCounts = () => ({
  type: PLAYLIST.REQUEST_COUNTS
});

export const requestCreatePlaylist = (playlistName: string) => ({
  type: PLAYLIST.REQUEST_CREATE,
  payload: { playlistName }
});

export const setListItem = (details: Object) => ({
  type: PLAYLIST.SET_LIST_ITEM,
  payload: details
});

export const setPlaylistsList = (list: Object) => ({
  type: PLAYLIST.SET_LIST,
  payload: { list }
});

export const updatePlaylistsCounts = (list: Object) => ({
  type: PLAYLIST.SET_LIST_COUNTS,
  payload: { list }
});

export const requestPlaylistVideos = (
  playlist_id: number,
  page: number,
  full: number,
  callbacks: RequestCallbacks,
  limit?: number
) => ({
  type: PLAYLIST.REQUEST_VIDEOS,
  payload: { playlist_id, page, limit, full },
  callbacks
});

export const requestUpdatePlaylist = (playlist_id: number, details: Object) => ({
  type: PLAYLIST.REQUEST_UPDATE,
  payload: { details, playlist_id }
});

export const updatePlaylistDetails = (playlist_id: number, details: Object) => ({
  type: PLAYLIST.UPDATE,
  payload: { details, playlist_id }
});

export const requestDeletePlaylist = (playlist_id: number) => ({
  type: PLAYLIST.REQUEST_DELETE,
  payload: { playlist_id }
});
