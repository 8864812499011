import { Row } from "react-flexbox-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingSpinner } from "@Components";
import PlaylistPostItem from "@Components/PlaylistPostItem";
import { requestChannelPlaylists } from "@Store/actions/channel";
import { VideoPlaylist } from "@Models/Video";
import EmptyPlaylistArchive from "./EmptyPlaylistArchive";

type ArchiveProps = {
  match: { params: { channel_slug: string }; url: string };
};

function PlaylistArchive({
  match: {
    url,
    params: { channel_slug }
  }
}: ArchiveProps) {
  const dispatch = useDispatch();

  const [playlistsList, setPlaylistsList] = useState<VideoPlaylist[]>([]);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    dispatch(
      requestChannelPlaylists(channel_slug, {
        onSuccess: (list: VideoPlaylist[]) => {
          setPlaylistsList(list.filter(({ playlist_id }) => playlist_id !== 0));
          setIsPending(false);
        },
        onError: () => {}
      })
    );
  }, [channel_slug]);

  if (isPending) {
    return (
      <Row className="posts-list">
        <div className="archive-loader">
          <LoadingSpinner />
        </div>
      </Row>
    );
  }

  return (
    <Row className="posts-list">
      {(!!playlistsList.length &&
        playlistsList.map(playlist => (
          <PlaylistPostItem baseUrl={url} {...playlist} key={playlist.playlist_id} />
        ))) || <EmptyPlaylistArchive />}
    </Row>
  );
}

export default PlaylistArchive;
