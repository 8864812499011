import { useEffect, useState } from "react";
import { AppSelect } from "@Models/AppSelect";
import "./style.scss";

type Props = {
  slug: string;
  list: AppSelect[];
  onUpdateList: (slug: string, idList: number[]) => void;
};

const ItemsListChooser = ({ slug, list, onUpdateList }: Props) => {
  const [checkedItems, setCheckedItems] = useState([] as AppSelect[]);
  const [isPending, setIsPending] = useState(true);
  const onCheckItem = (id: any) => {
    setIsPending(false);
    setCheckedItems((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    if (!isPending) {
      const values = Object.values(checkedItems);
      onUpdateList(
        slug,
        Object.keys(checkedItems)
          .filter((nm, i) => values[i])
          .map(item => +item)
      );
    }
  }, [checkedItems]);
  return (
    <div className="filters-chooser-list">
      <ul className="list">
        {list.map((option, index) => (
          <li key={index}>
            <label className="select-subcategory">
              <input
                readOnly
                name="check"
                onClick={() => {
                  onCheckItem(option.value);
                }}
                type="checkbox"
                checked={!!checkedItems[option.value]}
              />
              <span className="label-text">{option.label}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemsListChooser;
