const bufferedTimeouts: any = {};
const buffer: any = {};

export default (actionsList: any) => () => (next: any) => (action: any) => {
  if (!actionsList[action.type]) return next(action);

  const actionTime = new Date().getTime();

  if (bufferedTimeouts[action.type]) {
    if (actionTime < bufferedTimeouts[action.type]) {
      clearTimeout(buffer[action.type].timeout);

      Object.defineProperty(buffer, action.type, {
        value: {
          value: actionsList[action.type].buffer(buffer[action.type].value, action),
          timeout: setTimeout(() => {
            next({ type: action.type, payload: buffer[action.type].value });
          }, bufferedTimeouts[action.type] - actionTime)
        },
        configurable: true
      });

      return;
    } else {
      clearTimeout(buffer[action.type].timeout);
      delete bufferedTimeouts[action.type];
      delete buffer[action.type];

      Object.defineProperty(bufferedTimeouts, action.type, {
        value: actionTime + actionsList[action.type].interval,
        configurable: true
      });

      Object.defineProperty(buffer, action.type, {
        value: {
          value: actionsList[action.type].buffer(undefined, action),
          timeout: setTimeout(() => {
            next({
              type: action.type,
              payload: actionsList[action.type].buffer(undefined, action)
            });
          }, bufferedTimeouts[action.type] - actionTime)
        },
        configurable: true
      });

      return;
    }
  } else {
    Object.defineProperty(bufferedTimeouts, action.type, {
      value: actionTime + actionsList[action.type].interval,
      configurable: true
    });

    Object.defineProperty(buffer, action.type, {
      value: {
        value: actionsList[action.type].buffer(undefined, action),
        timeout: setTimeout(() => {
          next({ type: action.type, payload: actionsList[action.type].buffer(undefined, action) });
        }, bufferedTimeouts[action.type] - actionTime)
      },
      configurable: true
    });

    return;
  }
};
