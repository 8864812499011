import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router";
import { Provider } from "react-redux";
import store from "./store";
import TagManager from "react-gtm-module";
import App from "./App";

const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: "GTM-TL6HCS5",
  dataLayerName: "PageDataLayer"
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
