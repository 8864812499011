import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { Col, Row } from "react-flexbox-grid";
import { Modal } from "@Components";
import { useHistory } from "react-router";
import { BecomeCreatorBg, BecomeCreatorPlaceholder } from "@Assets";
import { AccountAvatar } from "@Components";
import { useDebounce, useForm } from "@Hooks";
import { useDispatch, useSelector } from "react-redux";
import { getSelectCategories } from "@Store/selectors/category";
import { Channel } from "@Models/Channel";
import { isBreakpoint } from "@Utils";
import { checkChannelSlug, createChannel } from "@Store/actions/channel";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";
import "./style.scss";

type CreateChannelModalProps = {
  setCreateChannelPopup: (state: boolean) => void;
};

const defaultFormDataState = {
  name: "",
  description: "",
  category_id: null,
  slug: ""
};

function CreateChannelModal({ setCreateChannelPopup }: CreateChannelModalProps) {
  const allCategories = useSelector(getSelectCategories);
  const dispatch = useDispatch();
  const history = useHistory();
  const errorDebounce = useRef<any>();

  const [slugStatus, setSlugStatus] = useState(-1);
  const [startCreate, setStartCreate] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [selectedFile, setSelectedFile] = useState({} as File);
  const [formData, setFormData, setCustomKeyFormValue] = useForm(defaultFormDataState as Channel);
  const [descriptionRows, setDescriptionRows] = useState(3);
  const debouncedSlug = useDebounce(formData.slug, 1000);

  const handleOnDescriptionFocus = () => {
    setDescriptionRows(500);
  };

  const handleOnDescriptionBlur = () => {
    setDescriptionRows(3);
  };

  useEffect(() => {
    if (debouncedSlug !== "") {
      dispatch(
        checkChannelSlug(debouncedSlug, {
          onSuccess: () => {
            setSlugStatus(1);
          },
          onError: () => {
            setSlugStatus(2);
          }
        })
      );
    } else {
      setSlugStatus(-1);
    }
  }, [debouncedSlug]);

  const onCloseModal = () => {
    setCreateChannelPopup(false);
  };

  const validateSlugInput = (event: any) => {
    setSlugStatus(-1);

    clearTimeout(errorDebounce.current);

    errorDebounce.current = setTimeout(() => {
      if (event.getModifierState("CapsLock")) {
        toast.error(`Caps Lock is "on"`);
      }
    }, 600);
  };

  const onSlugChange = (event: any) => {
    if (event.target.value.match(/^([a-z0-9._]*)$/)) {
      setFormData(event);
    }
  };

  const handleCategoryChange = (selectedOption: any) => {
    setCustomKeyFormValue("category_id", selectedOption.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    dispatch(
      createChannel(formData, selectedFile, {
        onSuccess: () => {
          onCloseModal();
          history.push("/profile/channel-settings");
          sendGTMDataLayer({
            event: "Channel Creation Form",
            eventCategory: "creator signup",
            eventAction: "creator form submit",
            eventValue: 1,
            eventLabel: "creator signup 2"
          });
        },
        onError: () => null
      })
    );
  };

  const becomeStreamerTemplate = () => {
    return (
      <>
        <aside
          className="modal-content"
          style={{
            backgroundImage: `${!isBreakpoint("mobile") ? "url(" + BecomeCreatorBg + ")" : ""}`
          }}
        >
          <div className="become-creator-container">
            <Row>
              <Col md={5}>
                <img src={BecomeCreatorPlaceholder} alt="Creator" />
              </Col>
              <Col md={7} className="info">
                <h3>Become a Creator</h3>
                <p>
                  To upload your video content to Sharevision and run live streams you should create
                  a public channel. You’ll have to complete all the required fields and upload your
                  Channel picture.
                </p>
              </Col>
            </Row>
          </div>
        </aside>

        <aside className="modal-footer">
          <button
            type="button"
            className="main-button white"
            onClick={() => {
              setStartCreate(true);
            }}
          >
            Start
          </button>
        </aside>
      </>
    );
  };
  const createChannelTemplate = () => {
    return (
      <form className="channel-form" onSubmit={onSubmit}>
        <aside className="modal-content">
          <Row>
            <Col md={3} className="channel-picture">
              <label>Channel Picture</label>
              <AccountAvatar
                type="create-channel"
                data={{}}
                onFileSelected={(file: any) => {
                  setSelectedFile(file);
                }}
              />
            </Col>
            <Col md={9}>
              <label htmlFor="channel-name">Channel name</label>
              <input
                type="text"
                className="main-input"
                placeholder="Your channel name"
                name="name"
                autoComplete="off"
                required
                value={formData.name}
                onChange={setFormData}
                id="channel-name"
              />
              <label htmlFor="channel-description">Description</label>
              <div className="auto-size-description">
                <TextareaAutosize
                  className="main-input input-description"
                  placeholder="Description of your channel"
                  name="description"
                  autoComplete="off"
                  onFocus={handleOnDescriptionFocus}
                  onBlur={handleOnDescriptionBlur}
                  value={formData.description}
                  maxRows={descriptionRows}
                  minRows={3}
                  id="channel-description"
                  onChange={setFormData}
                />
              </div>
              <label htmlFor="url-slug">URL Slug</label>
              <div className="slug-input-container">
                <span className="slug-base">sharevision.com/</span>
                <input
                  type="text"
                  className="main-input"
                  required
                  placeholder="public.id"
                  autoComplete="off"
                  value={formData.slug}
                  onChange={onSlugChange}
                  onKeyUp={validateSlugInput}
                  name="slug"
                  id="url-slug"
                />
                {slugStatus !== -1 && (
                  <i
                    className={
                      slugStatus === 2
                        ? "icon-close-rounded slug-status error"
                        : "icon-check-mark slug-status"
                    }
                  />
                )}
              </div>
              <label htmlFor="category-select" className="category">
                Category
              </label>
              <p className="label-description">Main category your channel’s content will cover</p>
              <Select
                classNamePrefix="app-select"
                menuPlacement="top"
                placeholder="Assign Category"
                isSearchable={false}
                onChange={handleCategoryChange}
                options={allCategories}
              />
            </Col>
          </Row>
        </aside>
        <aside className="modal-footer">
          <Row>
            <Col md={9}>
              <label className="terms-conditions-check">
                <input
                  type="checkbox"
                  name="check"
                  onChange={() => {
                    setAcceptedTerms(!acceptedTerms);
                  }}
                />
                <span className="label-text">
                  I agree to the ShareVision <Link to="/terms-conditions">Terms of Service</Link>{" "}
                  and <Link to="/community">Community Guidelines</Link>
                </span>
              </label>
            </Col>
            <Col md={3}>
              <button
                type="submit"
                disabled={
                  !acceptedTerms || slugStatus === -1 || slugStatus === 2 || !formData.category_id
                }
                className="main-button"
              >
                Create
              </button>
            </Col>
          </Row>
        </aside>
      </form>
    );
  };

  return (
    <Modal onClose={onCloseModal}>
      <div className="create-channel-modal modal-container">
        <aside className="modal-header">
          <h2 className="modal-heading">Create a channel</h2>
        </aside>
        {startCreate ? createChannelTemplate() : becomeStreamerTemplate()}
      </div>
    </Modal>
  );
}

export default CreateChannelModal;
