import { Row } from "react-flexbox-grid";

type Props = {
  preview: string;
};

const ThumbPreview = ({ preview }: Props) => {
  return (
    <Row
      className="thumb"
      style={{
        backgroundImage: preview ? `url(${preview && preview.replace("[SIZE]", "300x300")})` : ""
      }}
    >
      {!preview && <i className="icon-stream" />}
    </Row>
  );
};

export default ThumbPreview;
