import classes from "classnames";
import { VIDEO_STATUS_OPTIONS } from "@Constants";
import Linkify from "react-linkify";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAppAuthStatus } from "@Store/selectors/app";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import {
  getWatchersCount,
  requestStartLive,
  requestStopLive,
  requestStreamPreviewById,
  requestStreamPreviewRtcSource,
  sendRTCUpdater,
  setStreamManagerKey,
  subscribeLivePublicUpdates,
  subscribeRTCUpdates,
  unsubscribeRTCUpdates,
  updateRTCStatus,
  updateSaveStreamOption
} from "@Store/actions/video";
import useRootRef from "@Hooks/useRootRef";
import { ConfirmModal, LinkDecorator, LoadingSpinner, Modal, VideoPlayer } from "@Components";
import { abbreviateNumber, convertToTimeDuration } from "@Utils";
import { getStreamManagerDetails } from "@Store/selectors/video";
import { getAuthUser } from "@Store/selectors/user";
import { LiveChat } from "@Components";
import StreamModal from "../StreamModal";
import "./style.scss";

let sdk: any = null;
let liveTimerInterval: any;

type SingleVideoPageTypes = {
  match: { params: { hash_id: string } };
};

function StreamManagerPage({ match: { params } }: SingleVideoPageTypes) {
  const isAuthenticated = useSelector(getAppAuthStatus);
  const streamManagerDetails = useSelector(getStreamManagerDetails);
  const userDetails = useSelector(getAuthUser);

  const dispatch = useDispatch();
  const history = useHistory();
  const [watchersCount, setWatchersCount] = useState(0);
  const [videoDetails, setVideoDetails] = useState<any>(null);
  const [showStopStreamModal, setShowStopStreamModal] = useState(false);
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [expandDescription, setExpandDescription] = useState(false);
  const [liveTimer, setLiveTimer] = useState("00:00");
  const rootRef = useRootRef();

  useEffect(() => {
    dispatch(
      requestStreamPreviewById(params.hash_id, {
        onSuccess: data => {
          setVideoDetails(data);
          const publishDate: any = data.published_at * 1000;

          data.is_live && startLiveTimer(publishDate);
          dispatch(setStreamManagerKey(data.stream_key));
          dispatch(sendRTCUpdater(data.stream_key));
          dispatch(
            subscribeLivePublicUpdates(params.hash_id, (data: any) => {
              updateWatchersCount(params.hash_id);
              if (data.event) {
                if (data.event === "left" && data.user.name !== userDetails.username) {
                  updateWatchersCount(params.hash_id);
                }

                if (data.event === "joined" && data.user.name !== userDetails.username) {
                  updateWatchersCount(params.hash_id);
                }
              }
            })
          );
        },
        onError: () => {
          history.push("/");
        }
      })
    );

    setExpandDescription(false);
    rootRef?.scrollTo(0, 0);
    dispatch(subscribeRTCUpdates());

    return () => {
      dispatch(unsubscribeRTCUpdates());
    };
  }, [params.hash_id, isAuthenticated]);

  const updateWatchersCount = (hash_id: string) => {
    dispatch(
      getWatchersCount(hash_id, (count: number) => {
        setWatchersCount(count);
      })
    );
  };

  const startLiveTimer = (publishDate: number) => {
    liveTimerInterval = setInterval(() => {
      const currentDate: any = new Date().getTime();
      setLiveTimer(convertToTimeDuration(Math.floor((currentDate - publishDate) / 1000)));
    }, 1000);
  };

  const toggleStreamDetails = (updatedDetails: any) => {
    updatedDetails &&
      updatedDetails.title &&
      setVideoDetails({
        ...videoDetails,
        title: updatedDetails.title,
        description: updatedDetails.description,
        tags: updatedDetails.tags,
        category: updatedDetails.category,
        status: updatedDetails.status,
        category_id: updatedDetails.category_id
      });
    setShowStreamModal(!showStreamModal);
  };

  const rtcPlayerAsync = (playerRef: any) => {
    const play = async function () {
      peerConnection.addTransceiver("audio", {
        direction: "recvonly"
      });
      peerConnection.addTransceiver("video", {
        direction: "recvonly"
      });

      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      const session: any = await new Promise(function (resolve, reject) {
        const data: any = {
          api: videoDetails.rtc_api,
          streamurl: `${videoDetails.rtc_url}/${videoDetails.stream_key}`,
          clientip: null,
          sdp: offer.sdp
        };

        dispatch(
          requestStreamPreviewRtcSource(videoDetails.rtc_api, data, {
            onSuccess: (data: any) => {
              if (data.code) {
                reject(data);
                return;
              }
              dispatch(updateRTCStatus(true));
              playerRef.play();
              resolve(data);
            },
            onError: (reason: any) => {
              reject(reason);
            }
          })
        );
      });

      await peerConnection.setRemoteDescription(
        new RTCSessionDescription({
          type: "answer",
          sdp: session.sdp
        })
      );

      return session;
    };

    const close = function () {
      peerConnection.close();
    };

    const peerConnection: any = new RTCPeerConnection();
    peerConnection.onaddstream = function (event: any) {
      playerRef.srcObject = event.stream;
      playerRef.play();
    };

    return {
      play,
      close
    };
  };

  const startPlay = (playerRef: any) => {
    if (sdk) {
      sdk.close();
    }
    sdk = rtcPlayerAsync(playerRef);
    setTimeout(() => {
      sdk.play().catch(() => {
        sdk.close();
      });
    }, 1000);
  };

  const videoCategoriesAndInterests = useMemo(() => {
    if (videoDetails && videoDetails.categories && videoDetails.tags) {
      const categories = videoDetails.categories.map((category: any) => ({
        label: category.name,
        value: category.slug,
        primary: true
      }));

      return [...categories, ...videoDetails.tags];
    }

    return [];
  }, [videoDetails]);

  useEffect(() => {
    videoDetails && videoDetails.autostart && onStreamAction(true);
    if (videoDetails && videoDetails.is_live && !streamManagerDetails.streamReceived) {
      setTimeout(() => {
        setShowStopStreamModal(true);
      }, 3000);
    } else {
      setShowStopStreamModal(false);
    }
  }, [streamManagerDetails.streamReceived]);

  const onStreamAction = (isStarting: boolean) => {
    if (isStarting) {
      dispatch(
        requestStartLive(
          videoDetails.video_id,
          videoDetails.hash_id,
          videoDetails.channel.channel_id,
          videoDetails.streamer.id,
          videoDetails.stream_key,
          videoDetails.live_type
        )
      );
      setVideoDetails({ ...videoDetails, is_live: true });
      startLiveTimer(new Date().getTime());
    } else {
      clearInterval(liveTimerInterval);
      setVideoDetails({ ...videoDetails, is_live: false });
      setShowStopStreamModal(false);
      videoDetails.live_type === 2 && dispatch(updateSaveStreamOption(false));
      dispatch(requestStopLive(videoDetails.video_id, videoDetails.hash_id));
      history.push("/profile/library/videos/streams");
    }
  };

  const toggleStopStreamModalShow = () => {
    setShowStopStreamModal(!showStopStreamModal);
  };

  return (
    videoDetails && (
      <div className="page-content">
        <Row between="xs" className="single-video-page stream-manager-page full-container">
          <Col className="video-details ">
            <Row className="video-header" between="xs">
              <Col
                xs
                className={classes("live-stats-coll", {
                  ["active"]: videoDetails.is_live
                })}
              >
                <div className="stream-live-btn">
                  <i className="icon-record" />
                  Live <span>{liveTimer}</span>
                </div>
                <div className="watchers">
                  <i className="icon icon-eye" />
                  <span>{watchersCount - 5 > 0 ? watchersCount - 5 : 0}</span>
                </div>
              </Col>
              <Col>
                <div className="connection-settings">
                  <div className="circle-status good" />
                  <div className="label-status">Connection</div>
                  <div className="status-info">
                    <i className="icon-check-mark" /> <span>Ok</span>
                  </div>
                  <i className="icon-settings edit-stream" onClick={toggleStreamDetails} />
                  <div className="visibility-status">
                    <i className={VIDEO_STATUS_OPTIONS[videoDetails.status].icon} />
                    <span>{VIDEO_STATUS_OPTIONS[videoDetails.status].label}</span>
                  </div>
                </div>
              </Col>
            </Row>
            {streamManagerDetails.streamReceived ? (
              <VideoPlayer
                watchMode={true}
                isPreloading
                settings={{ muted: true, autoplay: true }}
                onPause={playerRef => {
                  const ply = document.querySelector(".vjs-paused");
                  ply &&
                    ply.addEventListener("click", () => {
                      playerRef.play();
                    });
                }}
                onPlayerRefer={(playerRef: any) => {
                  startPlay(playerRef);
                }}
              />
            ) : (
              <div className="video-placeholder">
                <div
                  className={classes("content-manager", {
                    ["active-manager"]: !videoDetails.is_live
                  })}
                >
                  <LoadingSpinner />
                  {!videoDetails.is_live && (
                    <p>
                      Connect your streaming software to go live <br /> Viewers will be able to
                      watch your stream once you will start streaming
                    </p>
                  )}
                </div>
              </div>
            )}
            <Row between="xs">
              <Col xs className="title">
                {videoDetails.title}
              </Col>
              <Col xs={6} className="controls-list">
                <div className="controls likes active">
                  <i className="icon icon-thumbs-up" />
                  <span>{abbreviateNumber(videoDetails.likes)}</span>
                </div>
                <div className="controls likes active">
                  <i className="icon icon-thumbs-down" />
                  <span>{abbreviateNumber(videoDetails.dislikes)}</span>
                </div>
                <div className="controls">
                  <i className="icon icon-permalink" />
                </div>
                <div className="controls action-stream-control">
                  <button
                    className={classes("main-button red tiny-button stream-action-btn", {
                      ["disabled"]: !streamManagerDetails.streamReceived && !videoDetails.is_live
                    })}
                    onClick={() => {
                      !videoDetails.is_live ? onStreamAction(true) : setShowStopStreamModal(true);
                    }}
                  >
                    {videoDetails.is_live ? (
                      <>
                        <i className="icon-player-stop" />
                        Stop Streaming
                      </>
                    ) : (
                      <>
                        <i className="icon-play" />
                        Start Streaming
                      </>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            <Row between="xs">
              {videoDetails.description && (
                <Col
                  xs
                  className={classes("description ", {
                    ["full"]: expandDescription
                  })}
                >
                  <Linkify componentDecorator={LinkDecorator}>{videoDetails.description}</Linkify>
                </Col>
              )}
              <Col className="expand-details">
                {(videoDetails.description.length > 225 ||
                  (videoDetails.description.match(/\n/g) || []).length >= 2) && (
                  <div
                    className={classes("controls ", {
                      ["opened"]: expandDescription
                    })}
                    onClick={() => {
                      setExpandDescription(!expandDescription);
                    }}
                  >
                    <i className="icon icon-chevron-left" />
                  </div>
                )}
              </Col>
            </Row>
            <Row className="video-categories-tags">
              <Col xs={12} className="tags-list">
                {videoCategoriesAndInterests.map((category: any) => (
                  <div
                    className={classes("video-tag", { default: category.primary })}
                    key={category.value}
                  >
                    {category.label}
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
          <Col>
            <LiveChat />
          </Col>
        </Row>
        {showStreamModal && (
          <Modal onClose={toggleStreamDetails}>
            <StreamModal
              onClose={toggleStreamDetails}
              editMode
              isManager
              editedProps={videoDetails}
            />
          </Modal>
        )}
        {showStopStreamModal && (
          <ConfirmModal
            onClose={toggleStopStreamModalShow}
            actionText="Stop Streaming"
            streamSaveOption={videoDetails.live_type !== 2}
            onConfirm={() => {
              onStreamAction(false);
            }}
            bodyText="Are you sure you want to stop streaming?"
          />
        )}
      </div>
    )
  );
}

export default StreamManagerPage;
