import { Col } from "react-flexbox-grid";
import { RadioSelect } from "@Components";

type PermissionsFormTypes = {
  setFormData: Function;
  formData: { status: number };
  privacyOptions: { value: number; label: string }[];
};

function PermissionsForm({ privacyOptions, setFormData, formData }: PermissionsFormTypes) {
  return (
    <Col xs={6} className="edit-video-form">
      <label htmlFor="channel-transcript-settings">Visibility</label>

      <div className="video-privacy-options">
        <RadioSelect
          options={privacyOptions}
          value={formData.status}
          onChange={(nextStatus: number) => {
            setFormData("status", nextStatus);
          }}
        />
      </div>
    </Col>
  );
}

export default PermissionsForm;
