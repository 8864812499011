import io from "socket.io-client";
import { take, call, fork, takeEvery, put } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { SOCKET_API_URL } from "@Constants";
import { AUTH_STATE } from "@Store/actions/app";
import { PublicLiveRoomMessage, StartLivePayload } from "@Models/LiveStream";

function connect() {
  const socket = io(SOCKET_API_URL, { transports: ["websocket"] });

  return new Promise(resolve => {
    socket.on("connect", () => {
      const authUserData = localStorage.getItem("AUTH_USER");
      const visitorUsername = `visitor-${new Date().getTime()}`;
      const username = authUserData ? JSON.parse(authUserData).username : visitorUsername;

      socket.emit("register", { name: username }, () => {
        resolve(socket);
      });
    });
  });
}

export function subscribe(socket: any, topic: string) {
  return eventChannel(emitter => {
    socket.emit("join", topic, (_: any, history: any) => {
      emitter({ history });
    });

    socket.on("message", (event: any) => {
      emitter({ event });
    });

    return () => {};
  });
}

export function RTCStatusUpdater(socket: any, topic: string, streamKey: string) {
  return eventChannel(emitter => {
    socket.emit("join", topic, (_: any, history: any) => {
      emitter({ history });
    });

    socket.emit("privateMessage", streamKey, "rtc", "streamStatus");

    return () => {};
  });
}

export function createLiveRoom(socket: any, videoHashId: string) {
  return eventChannel(() => {
    socket.emit("addRoom", videoHashId);
    return () => null;
  });
}

export function getWatchersCountMessage(
  socket: any,
  videoHashId: string,
  userCountUpdate: (count: number) => void
) {
  return eventChannel(() => {
    socket.emit("chatroomUsers", videoHashId, (_: any, watchers: any) => {
      watchers && userCountUpdate(watchers.length);
    });
    return () => null;
  });
}

export function removeLiveRoom(socket: any, videoHashId: string) {
  setTimeout(() => {
    return eventChannel(() => {
      socket.emit("delRoom", videoHashId);
      return () => null;
    });
  }, 25000);
}

export function startLiveRoom(socket: any, payload: StartLivePayload) {
  return eventChannel(() => {
    socket.emit("privateMessage", payload.streamKey, "rtc", { event: "startLive", ...payload });
    return () => {};
  });
}
export function emmitStatusPublicLiveRoom(socket: any, streamStatus: PublicLiveRoomMessage) {
  return eventChannel(emitter => {
    socket.emit("join", streamStatus.hash_id, (_: any, history: any) => {
      emitter({ history });
    });

    setTimeout(
      () => {
        socket.emit(
          "message",
          {
            chatroomName: streamStatus.hash_id,
            message: streamStatus
          },
          () => {}
        );
      },
      streamStatus.status === "started" ? 18000 : 0
    );
    return () => {};
  });
}

function* listen(
  socket: any,
  actionType: string,
  socketParams: {
    subscribe?: boolean;
    topic: string;
    onUpdate?: () => void;
    updateRTCStatusKey?: string;
    streamStatus?: PublicLiveRoomMessage;
    startLiveRoomPayload?: StartLivePayload;
    newRoomLive?: string;
    removeRoomLive?: string;
    watchers?: string;
    userCountUpdate?: (count: number) => void;
    history?: boolean;
    reconnect?: boolean;
    liveType: number;
  }
) {
  if (!!socketParams.subscribe) {
    const channel = yield call(subscribe, socket, socketParams.topic);
    while (true) {
      const { history, event } = yield take(channel);

      if (history && socketParams.history) {
        yield put({
          type: `GET_${actionType}_HISTORY`,
          payload: history
        });
      }

      if (event) {
        yield put({
          type: `GET_${actionType}`,
          payload: !!socketParams.onUpdate
            ? {
                ...event,
                onUpdate: socketParams.onUpdate
              }
            : event
        });
      }
    }
  } else if (socketParams.subscribe === false) {
    socket.emit("leave", socketParams.topic, () => {});
  }

  if (!!socketParams.updateRTCStatusKey) {
    yield call(RTCStatusUpdater, socket, socketParams.topic, socketParams.updateRTCStatusKey);
  }

  if (!!socketParams.newRoomLive) {
    yield call(createLiveRoom, socket, socketParams.newRoomLive);
  }

  if (!!socketParams.removeRoomLive) {
    yield call(removeLiveRoom, socket, socketParams.removeRoomLive);
  }

  if (!!socketParams.startLiveRoomPayload) {
    yield call(startLiveRoom, socket, socketParams.startLiveRoomPayload);
  }

  if (!!socketParams.streamStatus) {
    yield call(emmitStatusPublicLiveRoom, socket, socketParams.streamStatus);
  }

  if (!!socketParams.watchers && !!socketParams.userCountUpdate) {
    yield call(
      getWatchersCountMessage,
      socket,
      socketParams.watchers,
      socketParams.userCountUpdate
    );
  }
}

export default function* socketSubscriptionsWatcher() {
  let socket = yield call(connect);

  if (socket.connected) {
    yield put({ type: AUTH_STATE.SOCKET_SUCCESS });
  } else {
    yield put({ type: AUTH_STATE.SOCKET_FAILURE });
  }

  yield takeEvery("*", function* (action: any) {
    if (action.socket && action.socket.topic && !action.socket.reconnect) {
      yield fork(listen, socket, action.type, action.socket);
    }

    if (action.socket && action.socket.reconnect) {
      socket.disconnect();
      socket = yield call(connect);
    }
  });
}
