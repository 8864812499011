import classes from "classnames";
import { prop } from "ramda";
import { Row } from "react-flexbox-grid";
import { abbreviateNumber } from "@Utils";
import { useDispatch, useSelector } from "react-redux";
import { requestCommentsBan } from "@Store/actions/video";
import { getBanListData } from "@Store/selectors/video";
import { getAuthUser } from "@Store/selectors/user";

type Props = {
  toggleLikeStatus: Function;
  toggleShowReplyInput: () => void;
  toggleEditMode: () => void;
  removeComment: () => void;
  like_status: string | null;
  owned: boolean;
  showReplies: boolean;
  comment_id: number;
  likes: number;
  isRepliesOption: boolean;
  dislikes: number;
  videoOwning: boolean;
  owner_id: number;
  isBanned: boolean;
  isDashboardMode?: boolean;
};

function CommentControls({
  toggleLikeStatus,
  isDashboardMode = false,
  toggleShowReplyInput,
  toggleEditMode,
  removeComment,
  showReplies,
  isRepliesOption,
  like_status,
  comment_id,
  likes,
  dislikes,
  isBanned,
  owned,
  videoOwning,
  owner_id
}: Props) {
  const dispatch = useDispatch();
  const userDetails = useSelector(getAuthUser);
  const banList = useSelector(getBanListData);
  const ownerBanned = userDetails.role !== "viewer" && banList.map(prop("id")).includes(owner_id);

  const onBanUser = (userId: number, userBanned: boolean) => {
    dispatch(requestCommentsBan(userId, !userBanned));
  };
  return (
    <Row
      className={classes("comment-footer-controls", {
        ["replies-option"]: isRepliesOption
      })}
    >
      <div
        className={classes("controls likes", { active: like_status === "like" })}
        onClick={toggleLikeStatus(comment_id, like_status, "like")}
      >
        <i className="icon icon-thumbs-up" />
        <span>{abbreviateNumber(likes)}</span>
      </div>
      <div
        className={classes("controls likes", { active: like_status === "dislike" })}
        onClick={toggleLikeStatus(comment_id, like_status, "dislike")}
      >
        <i className="icon icon-thumbs-down" />
        <span>{abbreviateNumber(dislikes)}</span>
      </div>
      {showReplies && !isBanned && (
        <div className="controls reply" onClick={toggleShowReplyInput}>
          <i className="icon icon-return-arrow" />
          <span>Reply</span>
        </div>
      )}
      {owned && (
        <div className="controls" onClick={toggleEditMode}>
          <i className="icon icon-edit" />
          <span>Edit</span>
        </div>
      )}
      {(owned || videoOwning) && isDashboardMode && (
        <div className="controls delete-action" onClick={removeComment}>
          <i className="icon icon-remove" />
          <span>Delete</span>
        </div>
      )}

      {!owned && videoOwning && isDashboardMode && userDetails.role !== "viewer" && (
        <div
          className={classes("controls ban-action", {
            ["banned"]: ownerBanned
          })}
          onClick={() => {
            onBanUser(owner_id, ownerBanned);
          }}
        >
          <i className="icon icon-stop-hand" />
          <span>{ownerBanned ? "Unban user" : "Ban user"}</span>
        </div>
      )}
    </Row>
  );
}

export default CommentControls;
