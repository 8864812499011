import { useState } from "react";
import { useDispatch } from "react-redux";
import { AuthModal } from "../index";
import { useForm } from "@Hooks";
import { useHistory } from "react-router";
import { requestResetPasswordForm } from "@Store/actions/user";
import { toast } from "react-toastify";

type ForgotPasswordModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
};

const defaultFormDataState = {
  password: "",
  password2: ""
};

function ResetPasswordModal({ onSetActiveModal }: ForgotPasswordModalTypes) {
  const dispatch = useDispatch();
  const { location } = useHistory();
  const [formData, setFormData] = useForm(defaultFormDataState);
  const [isRequestPending, setRequestPending] = useState(false);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setRequestPending(true);
    dispatch(
      requestResetPasswordForm(
        location.search.split("?reset-password-token=")[1],
        formData.password,
        formData.password2,
        {
          onSuccess: () => {
            onSetActiveModal("");
            toast.success("Your password has been reset");
          },
          onError: () => {
            setRequestPending(false);
          }
        }
      )
    );
  };

  return (
    <div className="form-container forgot-password">
      <i
        className="icon-chevron-left arrow-back"
        onClick={() => {
          onSetActiveModal("SIGN_IN");
        }}
      />
      <h2 className="heading">Reset your password</h2>
      <form onSubmit={onSubmitForm} autoComplete={"off"} className="pop-content-container">
        <input
          className="main-input"
          value={formData.password}
          onChange={setFormData}
          name="password"
          type="password"
          placeholder="New Password"
          required
        />
        <input
          className="main-input"
          value={formData.password2}
          onChange={setFormData}
          name="password2"
          type="password"
          placeholder="Repeat Password"
          required
        />

        <button type="submit" className="main-button" disabled={isRequestPending}>
          Reset your password
        </button>
      </form>
    </div>
  );
}

export default ResetPasswordModal;
