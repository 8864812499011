import { propEq } from "ramda";
import { Redirect } from "react-router";
import { CHANNEL_SECTIONS_LIST } from "../constants";
import { cloneElement } from "react";

type RootProfileSectionTypes = {
  match: { params: { sectionId: string } };
};

function RootChannelSection({ match }: RootProfileSectionTypes) {
  const {
    params: { sectionId }
  } = match;

  const sectionIndex = CHANNEL_SECTIONS_LIST.findIndex(propEq("id", sectionId));

  if (sectionIndex !== -1) {
    return cloneElement(CHANNEL_SECTIONS_LIST[sectionIndex].component, { match });
  }

  return (
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  );
}

export default RootChannelSection;
