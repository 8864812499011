import formatDistance from "date-fns/formatDistance";
import { Link } from "react-router-dom";
import { convertToTimeDuration, abbreviateNumber, getAvatar } from "@Utils";
import "./style.scss";

type VideoListItemTypes = {
  category_id: number;
  createdAt: number;
  description: string;
  duration: number;
  thumb: string;
  title: string;
  hash_id: number;
  youtube: boolean;
  views: number;
  channel: { channel_id: number; image: string | null; name: string; slug: string };
};

function VideoListItem({
  thumb,
  title,
  duration,
  hash_id,
  channel,
  views,
  createdAt
}: VideoListItemTypes) {
  const videoDuration = convertToTimeDuration(duration);
  const ownerAvatarAlt = getAvatar(channel.name || "");

  return (
    <div className="related-video-list-item">
      <Link to={`/video/${hash_id}`} className="thumb">
        {thumb && (
          <div
            className="video-image"
            style={{
              backgroundImage: `url(${thumb.replace("[SIZE]", "150x100")})`
            }}
          />
        )}
        <span className="duration">{videoDuration}</span>
      </Link>

      <div className="details">
        <Link to={`/video/${hash_id}`} className="title">
          {title}
        </Link>
        <span className="video-stats">
          <i className="icon-glasses" />
          <span>{abbreviateNumber(views)}</span>
          <i className="icon-clock-o" />
          <span>{formatDistance(new Date(), createdAt * 1000)}</span>
        </span>
        <Link to={`/channel/${channel.slug}`} className="user-details">
          {channel.image ? (
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${channel.image.replace("[SIZE]", "50x50")})`
              }}
            />
          ) : (
            <div
              className="avatar"
              style={{
                backgroundColor: ownerAvatarAlt.color
              }}
            >
              <span className="name-letters">{ownerAvatarAlt.shortLetters}</span>
            </div>
          )}
          <div className="user-name">{channel.name}</div>
        </Link>
      </div>
    </div>
  );
}

export default VideoListItem;
