import { isEmpty } from "ramda";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import WebcamPreview from "@Views/StreamModal/WebcamPreview";
import WebcamInputs from "@Views/StreamModal/WebcamInputs";

export type DeviceInputsType = {
  videoInputs: InputSelect[];
  audioInputs: InputSelect[];
};

type InputSelect = {
  value: string;
  label: string;
};

const WebStream = () => {
  const [deviceInputs, setDeviceInputs] = useState<DeviceInputsType>({
    videoInputs: [],
    audioInputs: []
  });
  const [audioInput, setAudioInput] = useState<InputSelect>();
  const [videoInput, setVideoInput] = useState<InputSelect>();
  const [isPending, setIsPending] = useState(true);
  const [mediaStream, setMediaStream] = useState({} as MediaStream);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      navigator.permissions.query({ name: "camera" }).then(permissionObj => {
        permissionObj.state === "denied" && toast.error("Camera Access Denied");
      });
      navigator.permissions.query({ name: "microphone" }).then(permissionObj => {
        switch (permissionObj.state) {
          case "denied": {
            toast.error("Microphone Access Denied");
            break;
          }
          case "prompt": {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
              loadDevices();
            });
            break;
          }
          case "granted": {
            loadDevices();
          }
        }
      });
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
        loadDevices();
      });
    }

    return () => {
      resetWebStreamSubscriptions();
      if (mediaStreamRef.current) {
        mediaStreamRef.current = null;
      }
    };
  }, []);

  const resetWebStreamSubscriptions = () => {
    if (mediaStreamRef.current && !isEmpty(mediaStreamRef.current)) {
      mediaStreamRef.current.getTracks().map(track => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    mediaStreamRef.current = mediaStream;
  }, [mediaStream]);

  useEffect(() => {
    if (deviceInputs.audioInputs.length) {
      isPending && loadMediaStream();
    }
  }, [deviceInputs]);

  useEffect(() => {
    if (audioInput && !!audioInput.value) {
      !isPending && loadMediaStream();
    }
  }, [audioInput]);

  useEffect(() => {
    if (videoInput && !!videoInput.value) {
      !isPending && loadMediaStream();
    }
  }, [videoInput]);

  const loadMediaStream = () => {
    const constraints = {
      audio: { deviceId: audioInput ? { exact: audioInput.value } : undefined },
      video: { deviceId: videoInput ? { exact: videoInput.value } : undefined }
    };

    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
      setMediaStream(stream);
      setIsPending(false);
    });
  };

  const loadDevices = () => {
    navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
      const audioInputs = deviceInfos
        .filter(input => input.kind === "audioinput" && input.label !== "")
        .map(audioInput => ({
          label: audioInput.label,
          value: audioInput.deviceId
        }));
      const videoInputs = deviceInfos
        .filter(input => input.kind === "videoinput" && input.label !== "")
        .map(audioInput => ({
          label: audioInput.label,
          value: audioInput.deviceId
        }));

      setDeviceInputs({
        audioInputs,
        videoInputs
      });
      setAudioInput(audioInputs[0]);
      setVideoInput(videoInputs[0]);
    });
  };

  const onAudioInputChange = (selectedOption: any) => {
    setAudioInput(selectedOption);
  };

  const onVideoInputChange = (selectedOption: any) => {
    setVideoInput(selectedOption);
  };

  return (
    <>
      <span className="section-label">Camera Preview</span>
      <WebcamPreview stream={mediaStreamRef.current} />
      <WebcamInputs
        stream={mediaStream}
        selectedAudio={audioInput}
        selectedVideo={videoInput}
        deviceInputs={deviceInputs}
        onAudioInputChange={onAudioInputChange}
        onVideoInputChange={onVideoInputChange}
      />
    </>
  );
};

export default WebStream;
