import { useEffect, useState, useMemo, useCallback } from "react";
import { propEq, update, uniqBy, prop, remove } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
  requestPlaylistVideos,
  requestUpdatePlaylist,
  requestDeletePlaylist,
  requestUserPlaylistsCounts
} from "@Store/actions/playlist";
import { requestChangeVideosPlaylist } from "@Store/actions/video";
import { getPlaylistsList } from "@Store/selectors/playlist";

import PlaylistWrap from "./PlaylistWrap";

function PlaylistsContent() {
  const dispatch = useDispatch();
  const playlists = useSelector(getPlaylistsList);
  const [playlistsDetails, setPlaylistsDetails] = useState({} as any);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    isFetching
      ? setPlaylistsDetails(
          playlists.reduce((acc, playlist: any) => {
            return {
              ...acc,
              [playlist.playlist_id]: {
                ...playlist,
                videos: [],
                isExpended: !playlist.playlist_id,
                items_count: playlist.videos
              }
            };
          }, {})
        )
      : setPlaylistsDetails(
          playlists.reduce((acc, playlist: any) => {
            return {
              ...acc,
              [playlist.playlist_id]: {
                ...playlist,
                videos: [],
                items_count: playlist.videos
              }
            };
          }, {})
        );
  }, [playlists]);

  useEffect(() => {
    if (!!playlistsDetails[0]) {
      !playlistsDetails[0].playlist_id && updatePlaylistVideos(0, 1);
    }
  }, [!!playlistsDetails[0]]);

  const selectedVideos = useMemo(() => {
    return Object.values(playlistsDetails)
      .map((playlist: any) => playlist.videos)
      .flat()
      .filter(propEq("isChecked", true));
  }, [playlistsDetails]);

  const onUpdateVideoSuccess = (details: any) => {
    if (details?.video_id) {
      const videoIndex = playlistsDetails[details.playlist_id].videos.findIndex(
        propEq("video_id", details.video_id)
      );

      if (videoIndex !== -1) {
        const videoDetails = {
          ...playlistsDetails[details.playlist_id].videos[videoIndex],
          ...details
        };

        if (!details.playlist || details.playlist.value === details.playlist_id) {
          setPlaylistsDetails({
            ...playlistsDetails,
            [details.playlist_id]: {
              ...playlistsDetails[details.playlist_id],
              videos: update(videoIndex, videoDetails, playlistsDetails[details.playlist_id].videos)
            }
          });
        } else {
          setPlaylistsDetails({
            ...playlistsDetails,
            [details.playlist_id]: {
              ...playlistsDetails[details.playlist_id],
              items_count: playlistsDetails[details.playlist_id].items_count - 1,
              videos: remove(videoIndex, 1, playlistsDetails[details.playlist_id].videos)
            },
            [details.playlist.value]: {
              ...playlistsDetails[details.playlist.value],
              items_count: playlistsDetails[details.playlist.value].items_count + 1,
              videos: [
                { ...videoDetails, playlist_id: details.playlist.value },
                ...playlistsDetails[details.playlist.value].videos
              ]
            }
          });
        }
      }
    }
  };

  const updatePlaylistVideos = (playlist_id: number, page: number) => {
    dispatch(
      requestPlaylistVideos(playlist_id, page, 1, {
        onSuccess: ({ data }) => {
          setPlaylistsDetails({
            ...playlistsDetails,
            [playlist_id]: {
              ...playlistsDetails[playlist_id],
              items_count: data.count,
              videos:
                page !== 1
                  ? [
                      ...playlistsDetails[playlist_id].videos,
                      ...data.videos.map((video: any) => ({
                        ...video,
                        isChecked: !!playlistsDetails[playlist_id].isChecked
                      }))
                    ]
                  : data.videos.map((video: any) => ({
                      ...video,
                      isChecked: !!playlistsDetails[playlist_id].isChecked
                    })),
              isExpended: true
            }
          });
          setIsFetching(false);
        },
        onError: () => {}
      })
    );
  };

  const onExpendPlaylist = useCallback(
    (playlist_id: number) => () => {
      if (!playlistsDetails[playlist_id]?.isExpended) {
        setPlaylistsDetails({
          ...playlistsDetails,
          [playlist_id]: {
            ...playlistsDetails[playlist_id],
            videos: [],
            isExpended: true
          }
        });
        setIsFetching(true);
        updatePlaylistVideos(playlist_id, 1);
      } else {
        setPlaylistsDetails({
          ...playlistsDetails,
          [playlist_id]: {
            ...playlistsDetails[playlist_id],
            isExpended: false
          }
        });
      }
    },
    [playlistsDetails]
  );

  const onSubmitEditPlaylist = (playlist_id: number) => (details: any) => {
    dispatch(requestUpdatePlaylist(playlist_id, details));
  };

  const onCheckPlaylist = useCallback(
    (playlist_id: number) => () => {
      setPlaylistsDetails((prevState: any) => {
        return {
          ...prevState,
          [playlist_id]: {
            ...prevState[playlist_id],
            isChecked: !prevState[playlist_id].isChecked,
            videos: prevState[playlist_id].videos.map((video: any) => ({
              ...video,
              isChecked: !prevState[playlist_id].isChecked
            }))
          }
        };
      });
    },
    []
  );

  const onCheckVideo = useCallback(
    (video_id: number, playlist_id: number) => (isChecked: boolean) => {
      const playlistVideos = playlistsDetails[playlist_id].videos;
      const videoIndex = playlistVideos.findIndex(propEq("video_id", video_id));

      if (videoIndex !== -1) {
        setPlaylistsDetails((prevState: any) => ({
          ...prevState,
          [playlist_id]: {
            ...prevState[playlist_id],
            videos: update(
              videoIndex,
              {
                ...prevState[playlist_id].videos[videoIndex],
                isChecked: !isChecked
              },
              prevState[playlist_id].videos
            )
          }
        }));
      }
    },
    [playlistsDetails]
  );

  const onRemovePlaylist = (playlist_id: number) => {
    dispatch(requestDeletePlaylist(playlist_id));
  };

  const onDropVideo = useCallback(
    (selectedVideos: any[], next_playlist_id: number) => {
      const selectedVideosId = selectedVideos.map(({ video_id }) => video_id);

      dispatch(requestChangeVideosPlaylist(next_playlist_id, selectedVideosId));
      dispatch(requestUserPlaylistsCounts());

      const nextPlaylistsState = Object.keys(playlistsDetails).reduce(
        (acc: any, playlist_id: string) => {
          if (parseInt(playlist_id, 10) === next_playlist_id) {
            const playlistVideos = uniqBy(prop("video_id") as any, [
              ...selectedVideos.map(video => ({
                ...video,
                isChecked: false,
                playlist_id: next_playlist_id
              })),
              ...playlistsDetails[next_playlist_id].videos
            ]);

            return {
              ...acc,
              [next_playlist_id]: {
                ...playlistsDetails[next_playlist_id],
                isChecked: false,
                videos: playlistVideos
              }
            };
          } else {
            const playlistVideos = playlistsDetails[playlist_id].videos.filter(
              ({ video_id }: any) => !selectedVideosId.includes(video_id)
            );

            return {
              ...acc,
              [playlist_id]: {
                ...playlistsDetails[playlist_id],
                isChecked: false,
                videos: playlistVideos
              }
            };
          }
        },
        {}
      );

      setPlaylistsDetails(nextPlaylistsState);
    },
    [playlistsDetails]
  );

  return (
    <div className="row playlist-list manage-playlists">
      {Object.values(playlistsDetails).map((playlist: any) => (
        <PlaylistWrap
          isFetching={isFetching}
          handleLoadMore={(id, page) => {
            updatePlaylistVideos(id, page);
          }}
          key={playlist.playlist_id}
          onSubmitEdit={onSubmitEditPlaylist(playlist.playlist_id)}
          onRemove={onRemovePlaylist}
          onCheck={onCheckPlaylist(playlist.playlist_id)}
          childrenCount={playlistsDetails[playlist.playlist_id]?.items_count}
          expended={playlistsDetails[playlist.playlist_id]?.isExpended}
          onToggleExpend={onExpendPlaylist(playlist.playlist_id)}
          checked={playlist.isChecked}
          playlist_id={playlist.playlist_id}
          selectedVideos={selectedVideos}
          playlist={playlistsDetails[playlist.playlist_id]}
          videos={playlistsDetails[playlist.playlist_id]?.videos}
          onUpdateVideoSuccess={onUpdateVideoSuccess}
          onDeleteVideoSuccess={() => {
            updatePlaylistVideos(playlist.playlist_id, 1);
          }}
          onDropVideo={onDropVideo}
          onCheckVideo={onCheckVideo}
        />
      ))}
    </div>
  );
}

export default PlaylistsContent;
