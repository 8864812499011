import useRootRef from "@Hooks/useRootRef";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function PrivacyPolicy() {
  const rootRef = useRootRef();

  useEffect(() => {
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  return (
    <div className="page-content policy-page">
      <div className="full-container">
        <h1 className="title">Privacy Policy</h1>
        <div className="policy-content">
          <p>
            This privacy policy applies between you, the User of this Website and Sharevision Ltd,
            the owner and provider of this Website. Sharevision Ltd takes the privacy of your
            information very seriously. This privacy policy applies to our use of any and all Data
            collected by us or provided by you in relation to your use of the Website.
          </p>
          <p>
            This privacy policy should be read alongside, and in addition to, our Terms and
            Conditions, which can be found at:{" "}
            <Link to="/terms-conditions">sharevision.com/terms-conditions</Link>.
          </p>
          <p>
            <strong>Please read this privacy policy carefully.</strong>
          </p>

          <h3>
            <strong>Definitions and interpretation</strong>
          </h3>

          <ol start={1} role="list">
            <li>In this privacy policy, the following definitions are used:</li>
          </ol>
          <ul role="list">
            <li>
              <strong>Data</strong> – collectively all information that you submit to Sharevision
              Ltd via the Website. This definition incorporates, where applicable, the definitions
              provided in the Data Protection Laws
            </li>
            <li>
              <strong>Cookies</strong> – a small text file placed on your computer by this Website
              when you visit certain parts of the Website and/or when you use certain features of
              the Website. Details of the cookies used by this Website are set out in the clause
              below ( <strong>Cookies</strong>);
            </li>
            <li>
              <strong>Data Protection Laws</strong> – any applicable law relating to the processing
              of personal Data, including but not limited to the Directive 96/46/EC (Data Protection
              Directive) or the GDPR, and any national implementing laws, regulations and secondary
              legislation, for as long as the GDPR is effective in the UK;
            </li>
            <li>
              <strong>GDPR</strong>&nbsp;– the General Data Protection Regulation (EU) 2016/679;
            </li>
            <li>
              <strong>Sharevision Ltd, we </strong>or <strong>us</strong> – Sharevision Ltd, a
              company incorporated in England and Wales with registered number 12949231 whose
              registered office is at Suite 2 , &nbsp;125 - 135 Staines Road, &nbsp; Hounslow,
              &nbsp;TW3 3JB;
            </li>
            <li>
              <strong>UK and EU Cookie Law</strong> – the Privacy and Electronic Communications (EC
              Directive) Regulations 2003 as amended by the Privacy and Electronic Communications
              (EC Directive) (Amendment) Regulations 2011;
            </li>
            <li>
              <strong>User</strong> or <strong>you</strong> – any third party that accesses the
              Website and is not either (i) employed by Sharevision Ltd and acting in the course of
              their employment or (ii) engaged as a consultant or otherwise providing services to
              Sharevision Ltd and accessing the Website in connection with the provision of such
              services; and
            </li>
            <li>
              <strong>Website</strong> – the website that you are currently using, sharevision.com,
              and any sub-domains of this site unless expressly excluded by their own terms and
              conditions.
            </li>
          </ul>
          <ol start={2} role="list">
            <li>In this privacy policy, unless the context requires a different interpretation:</li>
          </ol>
          <ul role="list">
            <li>the singular includes the plural and vice versa;</li>
            <li>
              references to sub-clauses, clauses, schedules or appendices are to sub-clauses,
              clauses, schedules or appendices of this privacy policy;
            </li>
            <li>
              a reference to a person includes firms, companies, government entities, trusts and
              partnerships;
            </li>
            <li>"including" is understood to mean "including without limitation";</li>
            <li>
              reference to any statutory provision includes any modification or amendment of it;
            </li>
            <li>the headings and sub-headings do not form part of this privacy policy.</li>
          </ul>

          <h3>
            <strong>Scope of this privacy policy</strong>
          </h3>

          <ol start={3} role="list">
            <li>
              This privacy policy applies only to the actions of Sharevision Ltd and Users with
              respect to this Website. It does not extend to any websites that can be accessed from
              this Website including, but not limited to, any links we may provide to social media
              websites.
            </li>
          </ol>
          <ol start={4} role="list">
            <li>
              For purposes of the applicable Data Protection Laws, Sharevision Ltd is the "data
              controller". This means that Sharevision Ltd determines the purposes for which, and
              the manner in which, your Data is processed.
            </li>
          </ol>

          <h3>
            <strong>Data collected</strong>
          </h3>

          <ol start={5} role="list">
            <li>We may collect the following Data, which includes personal Data, from you:</li>
          </ol>
          <ul role="list">
            <li>name;</li>
            <li>date of birth;</li>
            <li>gender;</li>
            <li>job title;</li>
            <li>profession;</li>
            <li>contact Information such as email addresses and telephone numbers;</li>
            <li>demographic information such as postcode, preferences and interests;</li>
            <li>financial information such as credit / debit card numbers;</li>
            <li>IP address (automatically collected);</li>
            <li>web browser type and version (automatically collected);</li>
            <li>operating system (automatically collected);</li>
            <li>
              a list of URLs starting with a referring site, your activity on this Website, and the
              site you exit to (automatically collected);
            </li>
          </ul>
          <p>in each case, in accordance with this privacy policy.</p>

          <h3>
            <strong>How we collect Data</strong>
          </h3>

          <ol start={6} role="list">
            <li>We collect Data in the following ways:</li>
          </ol>
          <ul role="list">
            <li>data is given to us by you;</li>
            <li>data is received from other sources; and</li>
            <li>data is collected automatically.</li>
          </ul>

          <h3>
            <strong>Data that is given to us by you</strong>
          </h3>

          <ol start={7} role="list">
            <li>Sharevision Ltd will collect your Data in a number of ways, for example:</li>
          </ol>
          <ul role="list">
            <li>
              when you contact us through the Website, by telephone, post, e-mail or through any
              other means;
            </li>
            <li>
              when you register with us and set up an account to receive our products/services;
            </li>
            <li>
              when you complete surveys that we use for research purposes (although you are not
              obliged to respond to them);
            </li>
            <li>when you enter a competition or promotion through a social media channel;</li>
            <li>when you make payments to us, through this Website or otherwise;</li>
            <li>when you elect to receive marketing communications from us;</li>
            <li>when you use our services;</li>
          </ul>
          <p>in each case, in accordance with this privacy policy.</p>

          <h3>
            <strong>Data that is received from third parties</strong>
          </h3>

          <ol start={8} role="list">
            <li>Sharevision Ltd will receive Data about you from the following third parties:</li>
          </ol>
          <ul role="list">
            <li>google analytics and other analytic software.</li>
          </ul>

          <h3>
            <strong>Data that is received from publicly available third parties sources</strong>
          </h3>

          <ol start={9} role="list">
            <li>
              We will receive Data about you from the following publicly available third party
              sources:
            </li>
          </ol>

          <h3>
            <strong>Data that is collected automatically</strong>
          </h3>

          <ol start={10} role="list">
            <li>
              To the extent that you access the Website, we will collect your Data automatically,
              for example:
            </li>
          </ol>
          <ul role="list">
            <li>
              we automatically collect some information about your visit to the Website. This
              information helps us to make improvements to Website content and navigation, and
              includes your IP address, the date, times and frequency with which you access the
              Website and the way you use and interact with its content.
            </li>
            <li>
              we will collect your Data automatically via cookies, in line with the cookie settings
              on your browser. For more information about cookies, and how we use them on the
              Website, see the section below, headed "Cookies".
            </li>
          </ul>

          <h3>
            <strong>Our use of Data</strong>
          </h3>

          <ol start={11} role="list">
            <li>
              Any or all of the above Data may be required by us from time to time in order to
              provide you with the best possible service and experience when using our Website.
              Specifically, Data may be used by us for the following reasons:
            </li>
          </ol>
          <ul role="list">
            <li>internal record keeping;</li>
            <li>improvement of our products / services;</li>
            <li>transmission by email of marketing materials that may be of interest to you;</li>
            <li>
              contact for market research purposes which may be done using email, telephone, fax or
              mail. Such information may be used to customise or update the Website;
            </li>
          </ul>
          <p>in each case, in accordance with this privacy policy.</p>
          <ol start={12} role="list">
            <li>
              We may use your Data for the above purposes if we deem it necessary to do so for our
              legitimate interests. If you are not satisfied with this, you have the right to object
              in certain circumstances (see the section headed "Your rights" below).
            </li>
          </ol>
          <ol start={13} role="list">
            <li>
              For the delivery of direct marketing to you via e-mail, we'll need your consent,
              whether via an opt-in or soft-opt-in:
            </li>
          </ol>
          <ul role="list">
            <li>
              soft opt-in consent is a specific type of consent which applies when you have
              previously engaged with us (for example, you contact us to ask us for more details
              about a particular product/service, and we are marketing similar products/services).
              Under "soft opt-in" consent, we will take your consent as given unless you opt-out.
            </li>
            <li>
              for other types of e-marketing, we are required to obtain your explicit consent; that
              is, you need to take positive and affirmative action when consenting by, for example,
              checking a tick box that we'll provide.
            </li>
            <li>
              if you are not satisfied about our approach to marketing, you have the right to
              withdraw consent at any time. To find out how to withdraw your consent, see the
              section headed "Your rights" below.
            </li>
          </ul>
          <ol start={14} role="list">
            <li>
              When you register with us and set up an account to receive our services, the legal
              basis for this processing is the performance of a contract between you and us and/or
              taking steps, at your request, to enter into such a contract.
            </li>
          </ol>
          <ol start={15} role="list">
            <li>
              We may use your Data to show you Sharevision Ltd adverts and other content on other
              websites. If you do not want us to use your data to show you Sharevision Ltd adverts
              and other content on other websites, please turn off the relevant cookies (please
              refer to the section headed "Cookies" below).
            </li>
          </ol>

          <h3>
            <strong>Who we share Data with</strong>
          </h3>

          <ol start={16} role="list">
            <li>
              We may share your Data with the following groups of people for the following reasons:
            </li>
          </ol>
          <ul role="list">
            <li>
              any of our group companies or affiliates - to ensure the proper administration of the
              website and business;
            </li>
            <li>
              our employees, agents and/or professional advisors - to ensure the adequate use of
              personal data;
            </li>
            <li>
              third party service providers who provide services to us which require the processing
              of personal data - to help third party service providers in receipt of any shared data
              to perform functions on our behalf to help ensure the website runs smoothly;
            </li>
            <li>
              third party payment providers who process payments made over the Website - to enable
              third party payment providers to process user payments and refunds;
            </li>
            <li>
              relevant authorities - to facilitate the detection of crime or the collection of taxes
              or duties;
            </li>
          </ul>
          <p>in each case, in accordance with this privacy policy.</p>

          <h3>
            <strong>Keeping Data secure</strong>
          </h3>

          <ol start={17} role="list">
            <li>
              We will use technical and organisational measures to safeguard your Data, for example:
            </li>
          </ol>
          <ul role="list">
            <li>
              access to your account is controlled by a password and a user name that is unique to
              you.
            </li>
            <li>we store your Data on secure servers.</li>
            <li>
              payment details are encrypted using SSL technology (typically you will see a lock icon
              or green address bar (or both) in your browser when we use this technology.
            </li>
          </ul>
          <ol start={18} role="list">
            <li>
              Technical and organisational measures include measures to deal with any suspected data
              breach. If you suspect any misuse or loss or unauthorised access to your Data, please
              let us know immediately by contacting us via this e-mail address:
              info@sharevision.com.
            </li>
          </ol>
          <ol start={19} role="list">
            <li>
              If you want detailed information from Get Safe Online on how to protect your
              information and your computers and devices against fraud, identity theft, viruses and
              many other online problems, please visit www.getsafeonline.org. Get Safe Online is
              supported by HM Government and leading businesses.
            </li>
          </ol>

          <h3>
            <strong>Data retention</strong>
          </h3>

          <ol start={20} role="list">
            <li>
              Unless a longer retention period is required or permitted by law, we will only hold
              your Data on our systems for the period necessary to fulfil the purposes outlined in
              this privacy policy or until you request that the Data be deleted.
            </li>
          </ol>
          <ol start={21} role="list">
            <li>
              Even if we delete your Data, it may persist on backup or archival media for legal, tax
              or regulatory purposes.
            </li>
          </ol>

          <h3>
            <strong>Your rights</strong>
          </h3>

          <ol start={22} role="list">
            <li>You have the following rights in relation to your Data:</li>
          </ol>
          <ul role="list">
            <li>
              <strong>Right to access</strong> - the right to request (i) copies of the information
              we hold about you at any time, or (ii) that we modify, update or delete such
              information. If we provide you with access to the information we hold about you, we
              will not charge you for this, unless your request is "manifestly unfounded or
              excessive." Where we are legally permitted to do so, we may refuse your request. If we
              refuse your request, we will tell you the reasons why.
            </li>
            <li>
              <strong>Right to correct</strong> - the right to have your Data rectified if it is
              inaccurate or incomplete.
            </li>
            <li>
              <strong>Right to erase</strong> - the right to request that we delete or remove your
              Data from our systems.
            </li>
            <li>
              <strong>Right to restrict our use of your Data</strong> - the right to "block" us from
              using your Data or limit the way in which we can use it.
            </li>
            <li>
              <strong>Right to data portability</strong> - the right to request that we move, copy
              or transfer your Data.
            </li>
            <li>
              <strong>Right to object - </strong>the right to object to our use of your Data
              including where we use it for our legitimate interests.
            </li>
          </ul>
          <ol start={23} role="list">
            <li>
              To make enquiries, exercise any of your rights set out above, or withdraw your consent
              to the processing of your Data (where consent is our legal basis for processing your
              Data), please contact us via this e-mail address: info@sharevision.com.
            </li>
          </ol>
          <ol start={24} role="list">
            <li>
              If you are not satisfied with the way a complaint you make in relation to your Data is
              handled by us, you may be able to refer your complaint to the relevant data protection
              authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's
              contact details can be found on their website at https://ico.org.uk/.
            </li>
          </ol>
          <ol start={25} role="list">
            <li>
              It is important that the Data we hold about you is accurate and current. Please keep
              us informed if your Data changes during the period for which we hold it.
            </li>
          </ol>

          <h3>
            <strong>Links to other websites</strong>
          </h3>

          <ol start={26} role="list">
            <li>
              This Website may, from time to time, provide links to other websites. We have no
              control over such websites and are not responsible for the content of these websites.
              This privacy policy does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to using them.
            </li>
          </ol>

          <h3>
            <strong>Changes of business ownership and control</strong>
          </h3>

          <ol start={27} role="list">
            <li>
              Sharevision Ltd may, from time to time, expand or reduce our business and this may
              involve the sale and/or the transfer of control of all or part of Sharevision Ltd.
              Data provided by Users will, where it is relevant to any part of our business so
              transferred, be transferred along with that part and the new owner or newly
              controlling party will, under the terms of this privacy policy, be permitted to use
              the Data for the purposes for which it was originally supplied to us.
            </li>
          </ol>
          <ol start={28} role="list">
            <li>
              We may also disclose Data to a prospective purchaser of our business or any part of
              it.
            </li>
          </ol>
          <ol start={29} role="list">
            <li>
              In the above instances, we will take steps with the aim of ensuring your privacy is
              protected.
            </li>
          </ol>

          <h3>
            <strong>Cookies</strong>
          </h3>

          <ol start={30} role="list">
            <li>
              This Website may place and access certain Cookies on your computer. &nbsp;
              &nbsp;Sharevision Ltd uses Cookies to improve your experience of using the Website and
              to improve our range of products and services. &nbsp; Sharevision Ltd has carefully
              chosen these Cookies and has taken steps to ensure that your privacy is protected and
              respected at all times.
            </li>
          </ol>
          <ol start={31} role="list">
            <li>
              All Cookies used by this Website are used in accordance with current UK and EU Cookie
              Law.
            </li>
          </ol>
          <ol start={32} role="list">
            <li>
              Before the Website places Cookies on your computer, you will be presented with a
              message bar requesting your consent to set those Cookies. By giving your consent to
              the placing of Cookies, you are enabling Sharevision Ltd to provide a better
              experience and service to you. You may, if you wish, deny consent to the placing of
              Cookies; however certain features of the Website may not function fully or as
              intended.
            </li>
          </ol>
          <ol start={33} role="list">
            <li>
              This Website may place the following Cookies:
              <br />
            </li>
          </ol>
          <ul role="list">
            <li>
              <strong>Strictly necessary cookies</strong> – These are cookies that are required for
              the operation of our website. They include, for example, cookies that enable you to
              log into secure areas of our website, use a shopping cart or make use of e-billing
              services.
            </li>
            <li>
              <strong>Analytical/performance cookies</strong> – They allow us to recognise and count
              the number of visitors and to see how visitors move around our website when they are
              using it. This helps us to improve the way our website works, for example, by ensuring
              that users are finding what they are looking for easily.
            </li>
            <li>
              <strong>Functionality cookies</strong> – These are used to recognise you when you
              return to our website. This enables us to personalise our content for you, greet you
              by name and remember your preferences (for example, your choice of language or
              region).
            </li>
            <li>
              <strong>Targeting cookies</strong> – These cookies record your visit to our website,
              the pages you have visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on it more relevant to
              your interests. We may also share this information with third parties for this
              purpose.
            </li>
          </ul>
          <ol start={34} role="list">
            <li>You can find a list of Cookies that we use in the Cookies Schedule.</li>
          </ol>
          <ol start={35} role="list">
            <li>
              You can choose to enable or disable Cookies in your internet browser. By default, most
              internet browsers accept Cookies but this can be changed. For further details, please
              consult the help menu in your internet browser.
            </li>
          </ol>
          <ol start={36} role="list">
            <li>
              You can choose to delete Cookies at any time; however you may lose any information
              that enables you to access the Website more quickly and efficiently including, but not
              limited to, personalisation settings.
            </li>
          </ol>
          <ol start={37} role="list">
            <li>
              It is recommended that you ensure that your internet browser is up-to-date and that
              you consult the help and guidance provided by the developer of your internet browser
              if you are unsure about adjusting your privacy settings.
            </li>
            <li>
              For more information generally on cookies, including how to disable them, please refer
              to aboutcookies.org. You will also find details on how to delete cookies from your
              computer.
            </li>
          </ol>

          <h3>
            <strong>General</strong>
          </h3>

          <ol start={39} role="list">
            <li>
              You may not transfer any of your rights under this privacy policy to any other person.
              We may transfer our rights under this privacy policy where we reasonably believe your
              rights will not be affected.
            </li>
          </ol>
          <ol start={40} role="list">
            <li>
              If any court or competent authority finds that any provision of this privacy policy
              (or part of any provision) is invalid, illegal or unenforceable, that provision or
              part-provision will, to the extent required, be deemed to be deleted, and the validity
              and enforceability of the other provisions of this privacy policy will not be
              affected.
            </li>
          </ol>
          <ol start={41} role="list">
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in exercising any right
              or remedy will be deemed a waiver of that, or any other, right or remedy.
            </li>
          </ol>
          <ol start={42} role="list">
            <li>
              This Agreement will be governed by and interpreted according to the law of England and
              Wales. All disputes arising under the Agreement will be subject to the exclusive
              jurisdiction of the English and Welsh courts.
            </li>
          </ol>

          <h3>
            <strong>Changes to this privacy policy</strong>
          </h3>

          <ol start={43} role="list">
            <li>
              Sharevision Ltd reserves the right to change this privacy policy as we may deem
              necessary from time to time or as may be required by law. Any changes will be
              immediately posted on the Website and you are deemed to have accepted the terms of the
              privacy policy on your first use of the Website following the alterations. You may
              contact Sharevision Ltd by email at info@sharevision.com.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
