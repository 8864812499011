import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { useDispatch, useSelector } from "react-redux";
import { propEq, remove, update } from "ramda";
import { CommentModel } from "@Models/Video";
import { getAppAuthStatus } from "@Store/selectors/app";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { getAuthUser } from "@Store/selectors/user";

import {
  addVideoComment,
  getVideoComments,
  requestChangeCommentLikeStatus
} from "@Store/actions/video";

import CommentItem from "./CommentItem";
import CommentInput from "./CommentInput";
import "./style.scss";

type Props = {
  type: string;
  video_id: number;
  resetTrigger?: any;
  onlyUnanswered?: boolean;
  video_channel_id: number;
  onLoadSuccess?: () => void;
  onUpdateCommentsCount?: (commentsCount: number) => void;
  onCheckComment?: (comment_id: number) => void;
  checkedCommentsState?: any;
};

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

const defaultPaginationInfo = {
  totalResults: 0,
  resultsPerPage: 10,
  currentPage: 1,
  totalPages: 1
};

function Comments({
  type,
  video_id,
  resetTrigger,
  video_channel_id,
  onLoadSuccess,
  onlyUnanswered = false,
  onUpdateCommentsCount,
  checkedCommentsState,
  onCheckComment
}: Props) {
  const dispatch = useDispatch();
  const isAuthentificated = useSelector(getAppAuthStatus);
  const userDetails = useSelector(getAuthUser);

  const [commentsList, setCommentsList] = useState<CommentModel[]>([]);
  const [showCommentInput, setShowCommentInput] = useState(false);

  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [pageInfo, setPageinfo] = useState(defaultPaginationInfo);
  const [commentsMainData, setCommentsMainData] = useState({} as any);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    onChangeCommentsPage(1);
  }, [video_id, resetTrigger]);

  const onChangeCommentsPage = (
    nextPage: number,
    itemsPerPage: number = itemsPerPageCount.value
  ) => {
    dispatch(
      getVideoComments(
        {
          type,
          video_id,
          nextPage,
          itemsPerPage: itemsPerPage
        },
        {
          onSuccess: ({
            data,
            pageInfo
          }: {
            data: { comments: CommentModel[]; is_banned: boolean; owned: boolean };
            pageInfo: any;
          }) => {
            setActivePage(nextPage - 1);
            setCommentsList(data.comments);
            setCommentsMainData({ isBanned: data.is_banned, videoOwning: data.owned });
            setPageinfo(pageInfo);

            if (onLoadSuccess) {
              onLoadSuccess();
            }

            if (onUpdateCommentsCount) {
              onUpdateCommentsCount(pageInfo.totalResults);
            }
          },
          onError: () => {}
        }
      )
    );
  };

  const toggleShowCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleSubmitComment = (value: string) => {
    dispatch(
      addVideoComment(video_id, video_channel_id, 0, value, {
        onSuccess: (newComment: CommentModel) => {
          setCommentsList([newComment, ...commentsList]);
          setShowCommentInput(false);

          if (onUpdateCommentsCount) {
            onUpdateCommentsCount(pageInfo.totalResults + 1);

            setPageinfo({
              ...pageInfo,
              totalResults: pageInfo.totalResults + 1
            });
          }
        },
        onError: () => {}
      })
    );
  };

  const onUpdateCommentSuccess = (details: CommentModel) => {
    const commentIndex = commentsList.findIndex(propEq("comment_id", details.comment_id));

    if (commentIndex !== -1) {
      setCommentsList(update(commentIndex, details, commentsList));
    }
  };

  const onDeleteCommentSuccess = (comment_id: number) => {
    const commentIndex = commentsList.findIndex(propEq("comment_id", comment_id));

    if (commentIndex !== -1) {
      setCommentsList(remove(commentIndex, 1, commentsList));

      if (onUpdateCommentsCount) {
        onUpdateCommentsCount(pageInfo.totalResults - 1);

        setPageinfo({
          ...pageInfo,
          totalResults: pageInfo.totalResults - 1
        });
      }
    }
  };

  const onAddCommentReplySuccess = (comment_id: number) => {
    const commentIndex = commentsList.findIndex(propEq("comment_id", comment_id));

    if (commentIndex !== -1) {
      setCommentsList(
        update(
          commentIndex,
          {
            ...commentsList[commentIndex],
            replies_count: commentsList[commentIndex].replies_count + 1
          },
          commentsList
        )
      );
    }
  };

  const onDeleteCommentReplySuccess = (comment_id: number) => {
    const commentIndex = commentsList.findIndex(propEq("comment_id", comment_id));

    if (commentIndex !== -1) {
      setCommentsList(
        update(
          commentIndex,
          {
            ...commentsList[commentIndex],
            replies_count: commentsList[commentIndex].replies_count - 1
          },
          commentsList
        )
      );
    }
  };

  const toggleLikeStatus =
    (comment_id: number, prevStatus: "like" | "dislike", status: "like" | "dislike") => () => {
      if (isAuthentificated) {
        const next_status = prevStatus !== status ? status : `un${status}`;

        dispatch(
          requestChangeCommentLikeStatus(comment_id, next_status, {
            onSuccess: likesCountData => {
              const commentIndex = commentsList.findIndex(propEq("comment_id", comment_id));

              if (commentIndex !== -1) {
                setCommentsList(
                  update(
                    commentIndex,
                    {
                      ...commentsList[commentIndex],
                      ...likesCountData,
                      like_status: next_status
                    },
                    commentsList
                  )
                );
              }
            },
            onError: () => {}
          })
        );
      }
    };

  const onChangeItemsPerPageCount = (item: any) => {
    onChangeCommentsPage(1, item.value);
    setItemsPerPageCount(item);
  };

  return (
    <Row between="xs" className="comments-list">
      {(showCommentInput && (
        <CommentInput
          onCheck={!!onCheckComment}
          onSubmit={handleSubmitComment}
          onCancelShow={toggleShowCommentInput}
          placeholder="Add a comment..."
          actionLabel="Add"
        />
      )) ||
        (!commentsMainData.isBanned && isAuthentificated && (
          <Row>
            <Col
              className={
                !userDetails.email_confirmed
                  ? "comments-input-toggle disabled"
                  : "comments-input-toggle"
              }
              onClick={toggleShowCommentInput}
            >
              <button className="add-comment-btn">
                <i className="icon-rounded-plus" />
              </button>
              <span>Add new comment</span>
            </Col>
          </Row>
        ))}
      <Col xs={12} className={!!commentsList.length ? "" : "no-comments-coll"}>
        {(!!commentsList.length &&
          commentsList.map((comment: CommentModel) => (
            <CommentItem
              {...comment}
              videoOwning={commentsMainData.videoOwning}
              isBanned={commentsMainData.isBanned}
              video_id={video_id}
              key={comment.comment_id}
              onCheck={onCheckComment}
              checkedCommentsState={checkedCommentsState}
              onlyUnanswered={onlyUnanswered}
              video_channel_id={video_channel_id}
              isAuthentificated={isAuthentificated}
              toggleLikeStatus={toggleLikeStatus}
              parent_comment_id={comment.comment_id}
              onDeleteSuccess={onDeleteCommentSuccess}
              onUpdateSuccess={onUpdateCommentSuccess}
              onAddReplySuccess={onAddCommentReplySuccess}
              onDeleteReplySuccess={onDeleteCommentReplySuccess}
            />
          ))) || <span> There are no comments to display.</span>}
      </Col>

      <Row className="app-pagination">
        {pageInfo.totalResults > 10 && (
          <Col className="items-per-page-col">
            <span>Show</span>
            <Select
              classNamePrefix="app-select"
              menuPlacement="auto"
              placeholder="Assign Category"
              isSearchable={false}
              value={itemsPerPageCount}
              onChange={onChangeItemsPerPageCount}
              options={ITEMS_PER_PAGE_COUNT_OPTIONS}
            />
          </Col>
        )}

        {pageInfo.totalPages > 1 && pageInfo.totalResults > 10 && (
          <Col xs>
            <ReactPaginate
              forcePage={activePage}
              pageCount={pageInfo.totalPages}
              pageRangeDisplayed={6}
              marginPagesDisplayed={1}
              onPageChange={({ selected }) => onChangeCommentsPage(selected + 1)}
              activeClassName="active"
              pageClassName="page-number"
              containerClassName="pagination row"
              previousLabel={<i className="icon icon-chevron-left nav-icon" />}
              nextLabel={<i className="icon icon-chevron-right nav-icon" />}
            />
          </Col>
        )}
      </Row>
    </Row>
  );
}

export default Comments;
