import { useState } from "react";
import { Link } from "react-router-dom";
import UploadSource from "./Uploadsource";
import "./style.scss";

function UploadVideosModal({ onSetActiveModal }: { onSetActiveModal: Function }) {
  const [showArrowBack, setShowArrowBack] = useState(false);

  const onCloseModal = () => {
    onSetActiveModal("");
  };

  return (
    <article className="form-container upload-videos">
      {showArrowBack && (
        <i className="icon-chevron-left arrow-back" onClick={() => setShowArrowBack(false)} />
      )}
      <h2 className="heading">Upload Videos</h2>
      <p className="description">Your videos will be private until you publish them</p>

      <UploadSource
        setShowArrowBack={setShowArrowBack}
        showArrowBack={showArrowBack}
        onCloseModal={onCloseModal}
      />

      <p className="terms-conditions">
        By submitting your videos to ShareVision, you acknowledge that you agree to ShareVision's{" "}
        <Link to="/terms-conditions" onClick={onCloseModal}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link to="/community" onClick={onCloseModal}>
          Community Guidelines
        </Link>
        . Please be sure not to violate others' copyright or privacy rights.{" "}
        <Link to="/privacy-policy" onClick={onCloseModal}>
          Learn more
        </Link>
      </p>
    </article>
  );
}

export default UploadVideosModal;
