import { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { BecomeCreatorPlaceholder, BecomeLeft, BecomeRight } from "@Assets";
import { getAuthUser } from "@Store/selectors/user";
import AuthModule, { AuthModal } from "@Components/AuthModule";
import CreateChannelModal from "../../CreateChannelModal";

function BecomeStreamer() {
  const [createChannel, setCreateChannel] = useState(false);
  const [authModal, setAuthModal] = useState("" as AuthModal);
  const userDetails = useSelector(getAuthUser);

  const onBecomeStart = () => {
    userDetails.role !== "creator" && userDetails.role !== ""
      ? setCreateChannel(true)
      : setAuthModal("SIGN_UP");
  };

  return (
    <section className="become-streamer">
      <div className="overlay-images">
        <div className="left-side" style={{ backgroundImage: `url(${BecomeLeft})` }} />
        <div className="right-side" style={{ backgroundImage: `url(${BecomeRight})` }} />
      </div>
      <div className="full-container">
        <div className="content">
          <div className="title">Become a streamer</div>
          <Row className="creator-actions">
            <Col xs={12} md={6} className="cr-logo">
              <div className="background-logo" />
              <img src={BecomeCreatorPlaceholder} alt="Creator" />
            </Col>
            <Col xs={12} md={6}>
              <p className="description">
                To upload your video content to Sharevision and run live streams you should create a
                public channel.
              </p>
              <button className="main-button become-streamer-btn" onClick={onBecomeStart}>
                Start today
              </button>
            </Col>
          </Row>
        </div>
      </div>
      {createChannel && <CreateChannelModal setCreateChannelPopup={setCreateChannel} />}
      {!!authModal.length && <AuthModule modal={authModal} onModalClose={setAuthModal} />}
    </section>
  );
}

export default BecomeStreamer;
