import { abbreviateNumber } from "@Utils";

interface LikesRangeTypes {
  likesCount: number;
  dislikesCount: number;
}

function LikesRange({ likesCount = 0, dislikesCount = 0 }: LikesRangeTypes) {
  const likesPercentage = Math.ceil(likesCount / ((likesCount + dislikesCount) / 100)) || 0;

  if (!likesCount && !dislikesCount) {
    return (
      <div className="likes-range">
        <div className="likes-range-input">
          <div className="volume" />
        </div>
      </div>
    );
  }

  return (
    <div className="likes-range">
      <div className="reactions-label">
        <i className="icon icon-thumbs-up positive" />
        <i className="icon icon-thumbs-down negative" />
      </div>

      <div className="likes-range-input">
        <div className="volume positive" style={{ width: `${likesPercentage}%` }} />
        <div className="volume negative" style={{ width: `${100 - likesPercentage}%` }} />
      </div>
      <div className="reactions-label">
        <span className="likes-count">{abbreviateNumber(likesCount)}</span>
        <span className="likes-count">{abbreviateNumber(dislikesCount)}</span>
      </div>
    </div>
  );
}

export default LikesRange;
