import { VideosData, ChannelsData } from "@Models/Sidebar";
import { SIDEBAR } from "../actions/sidebar";

export type Directory = keyof IStateSidebar["data"];

export interface IStateSidebar {
  isVisible: boolean;
  isAnimated: boolean;
  isVisibleMobile: boolean;
  selectedDirectory: Directory;
  data: {
    videos: VideosData;
    channels: ChannelsData;
  };
}

const initialState: IStateSidebar = {
  isVisible: false,
  isAnimated: false,
  isVisibleMobile: false,
  selectedDirectory: "videos",
  data: {
    videos: {
      categories: [],
      tags: []
    },
    channels: {
      categories: [],
      tags: []
    }
  }
};

const filterEmpty = (list: any[], key: string) => list && list.filter(item => item[key] > 0);

export default function reducer(state: IStateSidebar = initialState, action: any): IStateSidebar {
  switch (action.type) {
    case SIDEBAR.SHOW:
      return { ...state, isVisible: true, isAnimated: true };
    case SIDEBAR.HIDE:
      return { ...state, isVisible: false, isAnimated: true };
    case SIDEBAR.TOGGLE:
      return { ...state, isVisible: !state.isVisible, isAnimated: true };
    case SIDEBAR.TOGGLE_MOBILE_MENU:
      return { ...state, isVisibleMobile: !state.isVisibleMobile };
    case SIDEBAR.SET_ANIMATED:
      return { ...state, isAnimated: action.payload };
    case SIDEBAR.SET_DIRECTORY:
      return { ...state, selectedDirectory: action.payload };
    case SIDEBAR.SET_VIDEOS: {
      const categories = filterEmpty(action.payload.categories, "videos");
      const tags = filterEmpty(action.payload.tags, "videos");

      return { ...state, data: { ...state.data, videos: { categories, tags } } };
    }
    case SIDEBAR.SET_CHANNELS: {
      const categories = filterEmpty(action.payload.categories, "channels");
      const tags = filterEmpty(action.payload.tags, "channels");

      return { ...state, data: { ...state.data, channels: { categories, tags } } };
    }
    default:
      return state;
  }
}
