import classes from "classnames";
import { useDebounce } from "@Hooks";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FilterToggle, ItemsListChooser } from "@Components/Filters";
import { FILTER_UPLOAD_DATE, SORT_VIDEOS_BY } from "@Constants";
import { FiltersDataType } from "@Views/CategoriesVideos";
import { equals, isEmpty, reject } from "ramda";

type FiltersProps = {
  filtersData: FiltersDataType;
  onFiltersUpdate: (filters: any) => void;
};

const CategoriesVideosFilters = ({ filtersData, onFiltersUpdate }: FiltersProps) => {
  const [filtersState, setFiltersState] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [outAnimate, setOutAnimate] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState("upload");
  const [selectedFilterUpload, setSelectedFilterUpload] = useState("");
  const [filtersReadyState, setFiltersReadyState] = useState(false);
  const debouncedAppliedFilters = useDebounce(appliedFilters, 1000);

  useEffect(() => {
    isEmpty(filtersData) && resetFilters();
  }, [filtersData]);

  useEffect(() => {
    const outputFilters = reject(equals([]))(debouncedAppliedFilters);
    filtersReadyState && onFiltersUpdate(outputFilters);
  }, [debouncedAppliedFilters]);

  const onSortRadioUpdate = (query: string, sortSlug: string) => {
    setFiltersReadyState(true);
    setAppliedFilters({
      ...appliedFilters,
      [query]: sortSlug
    });
  };

  const onUpdateTopicList = (slug: string, idsList: number[]) => {
    setFiltersReadyState(true);
    setAppliedFilters({
      ...appliedFilters,
      [slug]: idsList
    });
  };

  const resetFilters = () => {
    setSelectedSortBy("upload");
    setSelectedFilterUpload("");
    setFiltersState(false);
    setAppliedFilters({});
  };

  return (
    <>
      <FilterToggle
        status={filtersState}
        onStateChange={state => {
          setFiltersState(state);
          setOutAnimate(true);
        }}
      />
      <Row
        start="xs"
        className={classes("block-filters", {
          "filters-in": filtersState,
          "filters-out": !filtersState && outAnimate
        })}
      >
        <Col md={3} className="filter-coll">
          <h4 className="filter-title">Upload date</h4>
          <ul className="sort-list-chooser">
            {FILTER_UPLOAD_DATE.map((sort, i) => (
              <li key={i}>
                <input
                  type="radio"
                  id={sort.value}
                  value={sort.value}
                  checked={sort.value === selectedFilterUpload}
                  onChange={e => {
                    setSelectedFilterUpload(e.target.value);
                    onSortRadioUpdate("interval", e.target.value);
                  }}
                  name="upload-date"
                />
                <label htmlFor={sort.value}>{sort.label}</label>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={3} className="filter-coll">
          <h4 className="filter-title">Sort by</h4>
          <ul className="sort-list-chooser">
            {SORT_VIDEOS_BY.map((sort, i) => (
              <li key={i}>
                <input
                  type="radio"
                  id={sort.value}
                  value={sort.value}
                  checked={sort.value === selectedSortBy}
                  onChange={e => {
                    setSelectedSortBy(e.target.value);
                    onSortRadioUpdate("sortby", e.target.value);
                  }}
                  name="sort-videos"
                />
                <label htmlFor={sort.value}>{sort.label}</label>
              </li>
            ))}
          </ul>
        </Col>
        {!!filtersData &&
          Object.values(filtersData)
            .reverse()
            .map((filter, i) => (
              <Col xs className="filter-coll list-col" key={`filter-${i}`}>
                <h4 className="filter-title">{Object.keys(filtersData).reverse()[i]}</h4>
                <ItemsListChooser
                  list={filter}
                  slug={Object.keys(filtersData).reverse()[i]}
                  onUpdateList={onUpdateTopicList}
                />
              </Col>
            ))}
      </Row>
    </>
  );
};

export default CategoriesVideosFilters;
