import { Link } from "react-router-dom";
import "./style.scss";

function NotFoundPage() {
  return (
    <div className="page-content">
      <section className="page-not-found">
        <div className="page-not-found-main">
          <div className="page-not-found-main-big page-not-found-main-item">
            <span>404</span>
          </div>
          <div className="page-not-found-main-bold page-not-found-main-item">
            <span>Page could not be found</span>
          </div>
          <div className="page-not-found-main-text page-not-found-main-item">
            <span>The page you are looking for does not exist</span>
          </div>
          <div className="page-not-found-main-btn page-not-found-main-item">
            <Link to="/" className="page-not-found-main-btn-link">
              Go Back to Home
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NotFoundPage;
