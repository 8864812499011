import { Store } from "@Models/Store";

type Sidebar = Store["sidebar"];

export const selectSidebarVisibility = ({ sidebar }: Store): boolean => sidebar.isVisible;
export const selectSidebarAnimated = ({ sidebar }: Store): boolean => sidebar.isAnimated;
export const statusSidebarMobileVisible = ({ sidebar }: Store): boolean => sidebar.isVisibleMobile;
export const selectSidebarSelectedDirectory = ({ sidebar }: Store): Sidebar["selectedDirectory"] =>
  sidebar.selectedDirectory;
export const selectSidebarSelectedDirectoryData = ({
  sidebar
}: Store): Sidebar["data"][keyof Sidebar["data"]] => sidebar.data[sidebar.selectedDirectory];
