import { LiveChatMessage as LiveChatMessageModel } from "@Models/LiveChat";
import LiveChatMessage from "../Message";
import "./style.scss";

const mockMessage: LiveChatMessageModel[] = [
  {
    name: "John Doe",
    host: true,
    avatar: "",
    message:
      "Yes! What do you think the next year holds for US tech stocks? Thanks for joining the live stream today, any question?"
  },
  {
    name: "Freddy",
    host: false,
    avatar: "",
    message:
      "Thanks for joining the live stream today, did you have a further question? What do you think the next year holds for US tech stocks? Thanks for joining the live stream today, did a further question? "
  },
  {
    name: " Anthony Mathey Daytrader",
    host: false,
    avatar: "",
    message: "Yes! What do you think the next year holds for US tech stocks?"
  },
  {
    name: "Freddy",
    host: false,
    avatar: "",
    message: "Thanks for joining the live stream today, did you have a further question?"
  },
  {
    name: "Freddy",
    host: false,
    avatar: "",
    message: "Thanks for joining the live stream today, did you have a further question?"
  },
  {
    name: "John Doe",
    host: true,
    avatar: "",
    message:
      "Yes! What do you think the next year holds for US tech stocks? Thanks for joining the live stream today, any question?"
  },
  {
    name: "Freddy",
    host: false,
    avatar: "",
    message:
      "Thanks for joining the live stream today, did you have a further question? What do you think the next year holds for US tech stocks? Thanks for joining the live stream today, did a further question? "
  },
  {
    name: " Anthony Mathey Daytrader",
    host: false,
    avatar: "",
    message: "Yes! What do you think the next year holds for US tech stocks?"
  },
  {
    name: "Freddy",
    host: false,
    avatar: "",
    message:
      "Thanks for joining the live stream today, did you have a further question? What do you think the next year holds for US tech stocks? Thanks for joining the live stream today, did a further question? "
  },
  {
    name: " Anthony Mathey Daytrader",
    host: false,
    avatar: "",
    message: "Yes! What do you think the next year holds for US tech stocks?"
  }
];

const LiveChatContent: React.FC = () => {
  return (
    <div className="live-chat-content">
      {mockMessage.map((item: LiveChatMessageModel, key: number) => (
        <LiveChatMessage key={key} item={item} />
      ))}
    </div>
  );
};

export default LiveChatContent;
