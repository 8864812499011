import "./style.scss";

type Props = {
  rate: number;
};

const STARS_ARRAY = new Array(5).fill(null);

const StarsRate = ({ rate = 0 }: Props) => {
  const fillStarsCount = Math.floor(rate);

  return (
    <div className="stars-rating">
      {STARS_ARRAY.map((star: null, index: number) => {
        return (
          <i
            key={index}
            className={`icon ${index < fillStarsCount ? "icon-star" : "icon-star-o"}`}
          />
        );
      })}
      <p className="muted">{rate} points</p>
    </div>
  );
};

export default StarsRate;
