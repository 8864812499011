import { useState } from "react";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import { Col } from "react-flexbox-grid";
import { TagsLiveSearchInput, ToggleSwitch, RadioSelect } from "@Components";
import { SteamModalDefaultData } from "@Models/LiveStream";
import { AppSelect } from "@Models/AppSelect";
import { LIVE_TYPES, STREAM_PRIVACY_OPTIONS } from "./constants";

type DetailsFormTypes = {
  formData: SteamModalDefaultData;
  setFormData: () => void;
  setCustomKeyFormValue: Function;
  allCategories: AppSelect[];
};

function SetupForm({
  allCategories,
  setCustomKeyFormValue,
  setFormData,
  formData
}: DetailsFormTypes) {
  const [descriptionRows, setDescriptionRows] = useState(3);
  const handleOnDescriptionFocus = () => {
    setDescriptionRows(500);
  };

  const handleOnDescriptionBlur = () => {
    setDescriptionRows(3);
  };

  return (
    <Col xs={6} className="init-stream-form">
      <label>Title</label>
      <input
        type="text"
        className="main-input"
        placeholder="Stream title"
        autoComplete="off"
        name="title"
        value={formData.title}
        onChange={setFormData}
      />
      <label>Description</label>
      <div className="auto-size-description">
        <TextareaAutosize
          className="main-input input-description"
          placeholder="Description of your stream"
          autoComplete="off"
          name="description"
          onFocus={handleOnDescriptionFocus}
          onBlur={handleOnDescriptionBlur}
          value={formData.description}
          maxRows={descriptionRows}
          minRows={3}
          onChange={setFormData}
        />
      </div>
      <label>Visibility</label>
      <RadioSelect
        options={STREAM_PRIVACY_OPTIONS}
        value={formData.status}
        onChange={(nextStatus: number) => {
          setCustomKeyFormValue("status", nextStatus);
        }}
      />

      {!formData.stream_ended && (
        <label>
          <ToggleSwitch
            className="show-comments-switch"
            checked={formData.live_chat}
            onCheck={nextValue => {
              setCustomKeyFormValue("live_chat", nextValue);
            }}
          />
          <span className="label-text">Enable Live Chat</span>
        </label>
      )}

      <label>
        <ToggleSwitch
          className="show-comments-switch"
          checked={formData.autostart}
          onCheck={nextValue => {
            setCustomKeyFormValue("autostart", nextValue);
          }}
        />
        <span className="label-text">Autostart</span>
      </label>

      <label>Category and Tags</label>
      <p className="addition-description">Main Category</p>
      <Select
        classNamePrefix="app-select"
        menuPlacement="auto"
        placeholder="Assign Category"
        isSearchable={false}
        value={formData.category}
        options={allCategories}
        onChange={(nextValue: any) => {
          setCustomKeyFormValue("category", nextValue);
          setCustomKeyFormValue("category_id", nextValue.value);
        }}
      />

      {!formData.stream_ended && (
        <>
          <p className="addition-description">Chose your live duration</p>
          <Select
            classNamePrefix="app-select"
            menuPlacement="auto"
            placeholder="Live duration"
            isSearchable={false}
            value={formData.live_type}
            options={LIVE_TYPES}
            onChange={nextValue => {
              setCustomKeyFormValue("live_type", nextValue);
            }}
          />
        </>
      )}

      <p className="addition-description">More Tags</p>
      <TagsLiveSearchInput
        selectedValues={formData.tags}
        onValueChanges={nextTags => {
          setCustomKeyFormValue("tags", nextTags);
        }}
      />
      <p className="addition-description" />
      <label>Stream Comments</label>
      <label>
        <ToggleSwitch
          className="show-comments-switch"
          checked={formData.enable_comments}
          onCheck={nextValue => {
            setCustomKeyFormValue("enable_comments", nextValue);
          }}
        />
        <span className="label-text">
          Allow comments {!formData.stream_ended && "after stream stops"}
        </span>
      </label>
    </Col>
  );
}

export default SetupForm;
