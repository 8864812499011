import cn from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { hideSidebar } from "@Store/actions/sidebar";
import { Row } from "react-flexbox-grid";
import { MenuSubcategory } from "@Models/MenuSubcategory";
import Subcategory from "@Components/DiscoveryMenu/Subcategory";
import searchQueryParam from "@Utils/searchQueryParam";
import "./style.scss";

interface ICategoryProps {
  baseUrl: string;
  name: string;
  queryKey?: string;
  slug?: string;
  leftIconClassName?: string;
  subcategories?: MenuSubcategory[];
}

const Category = ({
  baseUrl,
  queryKey,
  slug,
  name,
  leftIconClassName,
  subcategories = []
}: ICategoryProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isToggle, setIsToggle] = useState(false);
  const handleClick = useCallback(() => dispatch(hideSidebar()), []);

  useEffect(() => {
    setIsToggle(false);
  }, [location]);

  const itemUrl = useMemo(
    () => (queryKey && slug ? `${baseUrl}?${queryKey}=${encodeURIComponent(slug)}` : baseUrl),
    [baseUrl, queryKey, slug]
  );

  const isCategorySelected = useMemo(
    () =>
      queryKey !== "custom"
        ? searchQueryParam(
            itemUrl.search("tag") !== -1 ? "tag" : "category",
            location.search,
            itemUrl.split("?")[1]
          )
        : false,
    [location, itemUrl]
  );

  const onToggleSubcategories = () => {
    history.push({
      search: `${location.search}`
    });
    setTimeout(() => {
      setIsToggle(!isToggle);
    });
  };

  return (
    <>
      {!!subcategories?.length ? (
        <>
          <Row
            middle="xs"
            data-selected={isCategorySelected || isToggle}
            className="dm-directory-row"
            onClick={onToggleSubcategories}
          >
            <a className="dm-directory-category-name">
              <span>{name}</span>
            </a>
          </Row>
          <ul className={cn("sub-items-list", { active: isCategorySelected || isToggle })}>
            {subcategories.map((subcategory, index) => (
              <li key={index} onClick={handleClick}>
                <Subcategory
                  category_id={subcategory.category_id}
                  name={subcategory.name}
                  baseCategorySlug={slug}
                  slug={subcategory.slug}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <Row middle="xs" data-selected={isCategorySelected} className="dm-directory-row">
          <Link to={itemUrl} className="dm-directory-category-name" onClick={handleClick}>
            {leftIconClassName && (
              <i className={cn(leftIconClassName, "dm-directory-category-icon")} />
            )}
            {name}
          </Link>
        </Row>
      )}
    </>
  );
};
export default Category;
