import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Select from "react-select";
import { toast } from "react-toastify";
import { ADMIN_ROLES } from "@Constants";
import { makeAdminAction } from "@Store/actions/admin";
import { getAuthUser } from "@Store/selectors/user";

type AdminMassEditTypes = {
  checkedItems: any[];
  actions: any;
  setCheckedVideos: Function;
};

function AdminMassEdit({ checkedItems, actions }: AdminMassEditTypes) {
  const dispatch = useDispatch();
  const userDetails = useSelector(getAuthUser);
  const [videoActions, setVideoActions] = useState({});
  useEffect(() => {
    userDetails.admin_role === ADMIN_ROLES.manager && delete actions.Custom;
    setVideoActions(actions);
  }, [actions]);
  const onActionChange = (selectedAction: any) => {
    if (checkedItems.length) {
      dispatch(
        makeAdminAction(checkedItems, selectedAction.label, {
          onSuccess: () => {
            toast.success(`${selectedAction.label} was applied`);
          },
          onError: () => null
        })
      );
    }
  };
  return (
    <Row className="video-library-header">
      <Col className="control-item selected-items-count">{checkedItems.length} SELECTED</Col>
      {Object.keys(videoActions).map((action: any, index: number) => (
        <Col className="control-item" key={index}>
          <Select
            classNamePrefix="app-select"
            className={"transparent-select"}
            menuPlacement="bottom"
            placeholder={action}
            isSearchable={false}
            onChange={onActionChange}
            options={Object.keys(actions[action]).map((key, i) => ({
              label: key,
              value: Object.values(actions[action])[i]
            }))}
          />
        </Col>
      ))}
    </Row>
  );
}

export default AdminMassEdit;
