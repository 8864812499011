import { useEffect, useState } from "react";
import "./style.scss";
import classes from "classnames";

type AdminAccordionTypes = {
  children?: any;
  title?: string;
  isLiked?: boolean;
  isDeletable: boolean;
  itemId: number;
  onDeleteItem: (deleteItemId: number) => void;
  isEditMode?: boolean;
};

function AdminAccordion({
  title,
  children,
  isLiked = false,
  isDeletable,
  itemId,
  isEditMode = true,
  onDeleteItem
}: AdminAccordionTypes) {
  const [expended, setExpended] = useState(false);

  useEffect(() => {
    isEditMode && setExpended(false);
  }, [isEditMode]);

  return (
    <div
      className={classes("admin-accordion", {
        ["opened"]: expended
      })}
    >
      <div className="accordion-header">
        <div
          className={classes("accordion-info", {
            ["active"]: expended
          })}
          onClick={() => {
            !isEditMode && setExpended(!expended);
          }}
        >
          <div className="accordion-title">
            <span>
              {title}
              {isLiked && <i className="icon-siblink" />}
            </span>
          </div>
          {isEditMode ? (
            <>
              {isDeletable && (
                <i
                  className="icon-remove"
                  onClick={() => {
                    onDeleteItem(itemId);
                  }}
                />
              )}
            </>
          ) : (
            <i className={`icon-${expended ? "dash-line-bold " : "rounded-plus"}`} />
          )}
        </div>
      </div>
      {expended && <div className="accordion-body">{children}</div>}
    </div>
  );
}

export default AdminAccordion;
