import classes from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { NavLink, Redirect, Route, useLocation } from "react-router-dom";
import { getAuthUser } from "@Store/selectors/user";
import { useEffect, useState } from "react";
import { AccountAvatar } from "@Components";
import { getChanelData, getChanelName } from "@Store/selectors/channel";
import { getUserNotifications } from "@Store/selectors/notifications";
import { getBanList } from "@Store/actions/video";
import { requestUserDetails, updateUserDetails, requestResendEmail } from "@Store/actions/user";
import { requestChannelDetails, setChannelDetails } from "@Store/actions/channel";
import CreateChannelModal from "../CreateChannelModal";
import RootSection from "./sections";
import { getNotifications } from "@Store/actions/notifications";
import useRootRef from "@Hooks/useRootRef";
import "react-datepicker/dist/react-datepicker.css";

import {
  USER_SECTIONS_LIST,
  EXTRA_USER_SECTIONS_LIST,
  SectionType,
  VIEWER_SECTIONS_LIST
} from "./constants";
import "./style.scss";

type ProfilePageTypes = {
  match: { url: string; path: string; params: any; isExact: boolean };
};

function ProfilePage({ match }: ProfilePageTypes) {
  const dispatch = useDispatch();
  const userDetails = useSelector(getAuthUser);
  const channelData = useSelector(getChanelData);
  const userNotifications = useSelector(getUserNotifications);
  const channelName: string = useSelector(getChanelName);
  const [profileInfo, setProfileInfo] = useState("user");
  const [createChannelModal, setCreateChannelModal] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [send, setSend] = useState(false);
  const location = useLocation();
  const rootRef = useRootRef();

  useEffect(() => {
    dispatch(
      requestUserDetails(userDetails.username, {
        onSuccess: (data: any) => {
          dispatch(
            updateUserDetails({
              about: data.about
            })
          );
        },
        onError: () => {}
      })
    );

    if (userDetails.channel_slug) {
      dispatch(
        requestChannelDetails(userDetails.channel_slug, 1, {
          onSuccess: (data: any) => {
            dispatch(setChannelDetails(data));
          },
          onError: () => {}
        })
      );
      dispatch(getBanList());
    }
    setChannelProfile();
    dispatch(getNotifications());
  }, []);

  const setChannelProfile = () => {
    if (userDetails.role == "creator" && location.pathname !== "/profile/settings") {
      setProfileInfo("channel");
    }
  };

  useEffect(() => {
    setChannelProfile();
    setIsAdminMode(location.pathname.indexOf("admin-settings") > -1);
    rootRef?.scrollTo(0, 0);
  }, [location]);

  const loadSectionList = (sectionsList: SectionType[]) => {
    return sectionsList.map((section: SectionType) => (
      <NavLink
        key={section.id}
        to={`${match.url}/${section.id}`}
        activeClassName="active"
        className={
          section.id == "comments" && !userDetails.email_confirmed
            ? "viewer-sections-item disabled"
            : "viewer-sections-item"
        }
        onClick={() => {
          section.id === "settings"
            ? setProfileInfo("user")
            : userDetails.role === "creator" && setProfileInfo("channel");
        }}
      >
        <i className={section.iconClass} />
        {section.label}
        {!!userNotifications[section.id] &&
          userNotifications[section.id].inbox + userNotifications[section.id].sent > 0 && (
            <span className="badge-counter">
              {userNotifications[section.id].inbox + userNotifications[section.id].sent}
            </span>
          )}
      </NavLink>
    ));
  };

  const onResendEmail = () => {
    const expectDate = (new Date().getTime() + 120000) * 1000;
    localStorage.setItem("DATE_SAVE", JSON.stringify(expectDate));
    dispatch(requestResendEmail());
    setSend(true);
  };

  useEffect(() => {
    if (send) {
      const dateSave = JSON.parse(String(localStorage.getItem("DATE_SAVE")));
      const interval = setInterval(() => {
        const now = new Date().getTime() * 1000;
        if (dateSave <= now) {
          setSend(false);
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [send]);

  return (
    <div className="profile page-content">
      <div className="full-container">
        {!userDetails.email_confirmed ? (
          <div className="confirm-notification">
            <div className="confirm-notification-block">
              <i className="icon-warning-alert" />
              <span className="text">
                Please confirm your email to unlock the full features of Sharevision
              </span>
            </div>
            {send ? (
              <div className="send">
                <i className="icon-check-mark" />
                <span className="send-text">Link was sent (resend again in 2 min)</span>
              </div>
            ) : (
              <button onClick={onResendEmail} className="resend">
                Resend confirmation link
              </button>
            )}
          </div>
        ) : null}
        <Row>
          <Col className="sidebar-container auto-column">
            <div className="profile-sidebar">
              <div className="header-info">
                {profileInfo === "user" ? (
                  <>
                    <AccountAvatar type="user" data={userDetails} onFileSelected={() => {}} />
                    <h3 className="name">{userDetails.full_name}</h3>
                    <p className="description">{channelName}</p>
                  </>
                ) : (
                  <>
                    <AccountAvatar type="channel" data={channelData} onFileSelected={() => {}} />
                    <h3 className="name">{channelName}</h3>
                    <p className="description">{userDetails.full_name}</p>
                  </>
                )}
              </div>
              <div
                className={classes("sections-list", {
                  ["viewer-sections"]: userDetails.role === "viewer"
                })}
              >
                {loadSectionList(
                  userDetails.role === "viewer" ? VIEWER_SECTIONS_LIST : USER_SECTIONS_LIST
                )}

                {!!userDetails.admin_role && (
                  <NavLink
                    key={"admin-settings"}
                    to={`${match.url}/admin-settings`}
                    activeClassName="active"
                    onClick={() => {
                      userDetails.role !== "viewer" && setProfileInfo("channel");
                    }}
                  >
                    <i className="icon-lock" />
                    Admin Settings
                  </NavLink>
                )}
              </div>
              <hr />
              {userDetails.role === "viewer" ? (
                <div
                  className={
                    !userDetails.email_confirmed
                      ? "create-channel-trigger disabled"
                      : "create-channel-trigger"
                  }
                  onClick={() => {
                    setCreateChannelModal(true);
                  }}
                >
                  <span className="icon-wrapper">
                    <i className="icon-rounded-plus" />
                  </span>
                  <span>Create a channel</span>
                </div>
              ) : (
                <div className="sections-list extra">
                  {loadSectionList(EXTRA_USER_SECTIONS_LIST)}
                </div>
              )}
            </div>
          </Col>
          <Col
            xs
            className={classes("main-profile-component", {
              ["admin-container"]: isAdminMode
            })}
          >
            {match.isExact && (
              <Redirect
                to={{
                  pathname: `${match.path}/${
                    userDetails.role === "viewer" ? "settings" : "dashboard"
                  }`
                }}
              />
            )}
            <Route
              path={`${match.path}/:sectionId`}
              render={(props: any) => <RootSection {...props} userRole={userDetails.role} />}
            />
          </Col>
        </Row>
      </div>
      {createChannelModal && <CreateChannelModal setCreateChannelPopup={setCreateChannelModal} />}
    </div>
  );
}

export default ProfilePage;
