import classes from "classnames";
import "./style.scss";

type Props = {
  className?: string;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
};

const ToggleSwitch = ({ className, checked = false, onCheck }: Props) => {
  const onToggleCheck = () => {
    if (onCheck) onCheck(!checked);
  };

  return (
    <label
      className={classes("toggle-switch", {
        [`${className}`]: className
      })}
    >
      <input type="checkbox" checked={checked} onChange={onToggleCheck} />
      <span className="slider round" />
    </label>
  );
};

export default ToggleSwitch;
