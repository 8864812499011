import cn from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSidebarAnimated } from "@Store/selectors/sidebar";
import { setSidebarAnimation, toggleSidebar } from "@Store/actions/sidebar";

interface IProps {
  offsetLeft?: number;
}

const TriggerMenuButton = ({ offsetLeft }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const isAnimated = useSelector(selectSidebarAnimated);
  const className = cn("dm-icon-button", { "animated-button": isAnimated });
  const style = useMemo<React.CSSProperties>(
    () => ({
      transform: offsetLeft ? `translateX(${offsetLeft + 25}px) rotate(-90deg)` : undefined
    }),
    [offsetLeft]
  );

  const handleClick = useCallback(() => dispatch(toggleSidebar()), []);
  const handleTransitionEnd = useCallback(() => dispatch(setSidebarAnimation(false)), []);

  return (
    <a
      className={className}
      onClick={handleClick}
      style={style}
      onTransitionEnd={handleTransitionEnd}
    >
      <i className={cn("icon-menu", "icon")} />
    </a>
  );
};

export default TriggerMenuButton;
