import { useState } from "react";
import { Modal } from "@Components";

import {
  WelcomeModal,
  SignInModal,
  SignUpModal,
  ForgotPasswordModal,
  SignUpSuccessModal,
  SignUpWithMailModal,
  ResetPassword
} from "./Modals";

import "./style.scss";

export type AuthModal =
  | "SIGN_IN"
  | "SIGN_UP"
  | "SIGN_UP_FORM"
  | "SIGN_UP_SUCCESS"
  | "FORGOT_PASSWORD"
  | "RESET_PASSWORD"
  | "WELCOME"
  | "";

function AuthModule({
  modal = "",
  onModalClose
}: {
  modal: AuthModal;
  onModalClose: (modalState: AuthModal) => void;
}) {
  const [activeModal, setActiveModal] = useState<AuthModal>(modal);

  const onCloseModal = () => {
    onModalClose("");
  };

  const setActiveModalHandler = (modal: any) => {
    modal.length ? setActiveModal(modal) : onCloseModal();
  };

  const MODAL_TYPES = {
    SIGN_IN: <SignInModal onSubmit={onCloseModal} onSetActiveModal={setActiveModalHandler} />,
    SIGN_UP: <SignUpModal onSetActiveModal={setActiveModalHandler} />,
    SIGN_UP_FORM: <SignUpWithMailModal onSetActiveModal={setActiveModalHandler} />,
    SIGN_UP_SUCCESS: (
      <SignUpSuccessModal onSetActiveModal={setActiveModalHandler} onCloseModal={onCloseModal} />
    ),
    FORGOT_PASSWORD: <ForgotPasswordModal onSetActiveModal={setActiveModalHandler} />,
    RESET_PASSWORD: <ResetPassword onSetActiveModal={setActiveModalHandler} />,
    WELCOME: <WelcomeModal onSetActiveModal={setActiveModalHandler} />,
    "": null
  };

  return (
    <>
      <Modal onClose={onCloseModal}>{MODAL_TYPES[activeModal]}</Modal>
    </>
  );
}

export default AuthModule;
