import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-flexbox-grid";
import TextareaAutosize from "react-textarea-autosize";
import { useDebounce, useForm } from "@Hooks";
import { toast } from "react-toastify";
import { getSelectCategories } from "@Store/selectors/category";
import { getChanelData } from "@Store/selectors/channel";
import { checkChannelSlug, updateChannelInfo } from "@Store/actions/channel";
import { TagsLiveSearchInput } from "@Components";
import { isEmpty } from "ramda";

import "./style.scss";

const defaultFormDataState = {
  channel_slug: "",
  name: "",
  description: "",
  slug: "",
  category: null,
  media_id: 0,
  homepage: "",
  twitter: "",
  facebook: "",
  instagram: "",
  tags: []
};

function ChannelSettings() {
  const dispatch = useDispatch();

  const channelData: { [key: string]: any } = useSelector(getChanelData);
  const allCategories = useSelector(getSelectCategories);
  const errorDebounce = useRef<any>();

  const [saveActions, setSaveActions] = useState(false);
  const [slugStatus, setSlugStatus] = useState(-1);
  const [formData, setFormData, setCustomKeyFormValue, setInitialState] =
    useForm(defaultFormDataState);
  const [descriptionRows, setDescriptionRows] = useState(3);

  const handleOnDescriptionFocus = () => {
    setDescriptionRows(500);
  };

  const handleOnDescriptionBlur = () => {
    setDescriptionRows(3);
  };

  const debouncedSlug = useDebounce(formData.slug, 1000);

  useEffect(() => {
    setInitialState(channelData);
  }, [JSON.stringify(channelData)]);

  useEffect(() => {
    if (
      !isEmpty(channelData) &&
      JSON.stringify(formData) !== JSON.stringify(defaultFormDataState)
    ) {
      setSaveActions(JSON.stringify(formData) !== JSON.stringify(channelData));
    }
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (debouncedSlug !== "") {
      debouncedSlug !== channelData.slug
        ? dispatch(
            checkChannelSlug(debouncedSlug, {
              onSuccess: () => {
                setSlugStatus(1);
              },
              onError: () => {
                setSlugStatus(2);
              }
            })
          )
        : setSlugStatus(1);
    } else {
      setSlugStatus(-1);
    }
  }, [debouncedSlug]);

  const validateSlugInput = (event: any) => {
    setSlugStatus(-1);
    clearTimeout(errorDebounce.current);

    errorDebounce.current = setTimeout(() => {
      if (event.getModifierState("CapsLock")) {
        toast.error(`Caps Lock is "on"`);
      }
    }, 600);
  };

  const onSlugChange = (event: any) => {
    if (event.target.value.match(/^([a-z0-9._]*)$/)) {
      setFormData(event);
    }
  };

  const onSaveChanges = () => {
    dispatch(
      updateChannelInfo(
        {
          name: formData.name,
          slug: formData.slug,
          description: formData.description,
          homepage: formData.homepage,
          twitter: formData.twitter,
          facebook: formData.facebook,
          instagram: formData.instagram,
          category_id: formData.category ? formData.category.value : formData.category_id,
          tags: formData.tags
        },
        channelData.channel_id,
        {
          onSuccess: () => {
            setSaveActions(false);
            toast.success("Channel settings updated!");
          },
          onError: () => null
        }
      )
    );
  };

  return (
    <form className="edit-channel-form">
      <Row className="section-heading">
        <Col sm={6}>
          <h1 className="main-heading">Channel Settings</h1>
        </Col>
        <Col sm={6}>
          {saveActions && (
            <div className="save-options">
              <span
                className="action-link"
                onClick={() => {
                  setInitialState(channelData);
                }}
              >
                Undo changes
              </span>
              <button
                className="main-button"
                type="button"
                onClick={onSaveChanges}
                disabled={slugStatus === -1 || slugStatus === 2}
              >
                Save changes
              </button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="channel-general-edit">
          <label htmlFor="channel-name">Channel name</label>
          <input
            type="text"
            className="main-input"
            placeholder="Your channel name"
            name="name"
            autoComplete={"off"}
            required
            value={formData.name}
            onChange={setFormData}
            id="channel-name"
          />
          <label htmlFor="channel-description">Description</label>

          <div className="auto-size-description">
            <TextareaAutosize
              className="main-input input-description"
              placeholder="Description of your channel"
              name="description"
              onFocus={handleOnDescriptionFocus}
              onBlur={handleOnDescriptionBlur}
              value={formData.description}
              maxRows={descriptionRows}
              minRows={3}
              onChange={setFormData}
              id="channel-description"
            />
          </div>
          <label htmlFor="url-slug">URL Slug</label>
          <div className="slug-input-container">
            <span className="slug-base">sharevision.com/</span>
            <input
              type="text"
              className="main-input"
              required
              placeholder="public.id"
              autoComplete={"off"}
              value={formData.slug}
              onChange={onSlugChange}
              onKeyUp={validateSlugInput}
              name="slug"
              id="url-slug"
            />
            {slugStatus !== -1 && (
              <i
                className={
                  slugStatus === 2
                    ? "icon-close-rounded slug-status error"
                    : "icon-check-mark slug-status"
                }
              />
            )}
          </div>
          <label htmlFor="category-select" className="category">
            Category
          </label>
          <p className="label-description addition-description">
            Main category your channel’s content will cover
          </p>
          <Select
            classNamePrefix={"app-select"}
            menuPlacement="auto"
            value={formData.category}
            placeholder="Assign Category"
            isSearchable={false}
            options={allCategories}
            onChange={nextCategory => {
              setCustomKeyFormValue("category", nextCategory);
            }}
          />
        </Col>
        <Col sm={6} className="channel-links">
          <label>Links</label>
          <div className="input-icon">
            <i className="icon-globe" />
            <input
              type="text"
              className="main-input"
              autoComplete={"off"}
              name="homepage"
              value={formData.homepage}
              onChange={setFormData}
              placeholder="Website Link"
            />
          </div>
          <div className="input-icon">
            <i className="icon-twitter" />
            <input
              type="text"
              className="main-input"
              autoComplete={"off"}
              name="twitter"
              value={formData.twitter}
              onChange={setFormData}
              placeholder="Twitter Link"
            />
          </div>
          <div className="input-icon">
            <i className="icon-facebook" />
            <input
              type="text"
              className="main-input"
              autoComplete="off"
              name="facebook"
              value={formData.facebook}
              onChange={setFormData}
              placeholder="Facebook Link"
            />
          </div>
          <div className="input-icon">
            <i className="icon-instagram" />
            <input
              type="text"
              className="main-input"
              autoComplete={"off"}
              name="instagram"
              value={formData.instagram}
              onChange={setFormData}
              placeholder="Instagram Link"
            />
          </div>
          <p className="label-description addition-description">More Tags</p>

          <TagsLiveSearchInput
            selectedValues={formData.tags}
            onValueChanges={nextTags => {
              setCustomKeyFormValue("tags", nextTags);
            }}
          />
        </Col>
      </Row>
    </form>
  );
}

export default ChannelSettings;
