import formatDate from "date-fns/format";
import classes from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAppAuthStatus } from "@Store/selectors/app";
import { getAuthUser } from "@Store/selectors/user";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import {
  getWatchersCount,
  requestChangeVideoLikeStatus,
  requestVideoById,
  subscribeLivePublicUpdates,
  unsubscribeLivePublicUpdates
} from "@Store/actions/video";
import { followChannel, unfollowChannel } from "@Store/actions/channel";
import { abbreviateNumber, getAvatar } from "@Utils";
import Linkify from "react-linkify";
import useRootRef from "@Hooks/useRootRef";
import VideoListItem from "./VideoListItem";
import { VideoPlayer, Comments, ToggleSwitch, LinkDecorator, LoadingSpinner } from "@Components";
import AuthModule, { AuthModal } from "@Components/AuthModule";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";
import "./style.scss";

let GTMInterval: any;

type SingleVideoPageTypes = {
  match: { params: { hash_id: string } };
};

type VideoDetailsTypes = {
  video: any;
  nextVideo: any;
  relatedVideo: any[];
};

function SingleVideoPage({ match: { params } }: SingleVideoPageTypes) {
  const isAuthenticated = useSelector(getAppAuthStatus);
  const userDetails = useSelector(getAuthUser);
  let [videoViewCount, setVideoViewCount] = useState<number>(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const [videoDetails, setVideoDetails] = useState<VideoDetailsTypes | null>(null);
  const [streamClose, setStreamClose] = useState(false);
  const [followIsPending, setFollowIsPending] = useState(false);
  const [autoplayMode, setAutoplayMode] = useState(true);
  const [expandDescription, setExpandDescription] = useState(false);
  const [watchersCount, setWatchersCount] = useState(0);
  const [expandInfo, setExpandInfo] = useState(false);
  const [expandComments, setExpandComments] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState(false);
  const [authModal, setAuthModal] = useState("" as AuthModal);
  const rootRef = useRootRef();

  useEffect(() => {
    if (!authModal.length) {
      setFollowIsPending(false);
      onPageLoadData(params.hash_id);
      sendGTMDataLayer({
        event: "pageview",
        page: {
          url: window.location.href,
          title: window.location.href
        }
      });
    }
  }, [authModal, params.hash_id]);

  useEffect(() => {
    setExpandDescription(false);
    setExpandInfo(false);
    rootRef?.scrollTo(0, 0);
    return () => {
      clearInterval(GTMInterval);
      subscriptionState && dispatch(unsubscribeLivePublicUpdates(params.hash_id));
      setVideoViewCount(0);
    };
  }, [params.hash_id, isAuthenticated]);

  useEffect(() => {
    if (videoDetails) {
      const targetTime = Math.round(videoDetails.video.duration / 4);
      if (videoViewCount >= targetTime) {
        UpdateViewInGTM(videoDetails.video.channel.name);
        clearInterval(GTMInterval);
        setVideoViewCount(0);
      }
    }
  }, [videoViewCount, videoDetails]);

  const onPageLoadData = (hash_id: string) => {
    dispatch(
      requestVideoById(hash_id, {
        onSuccess: data => {
          setVideoDetails(data);
          setSubscriptionState(data.video.is_stream && !data.video.stream_ended);
        },
        onError: () => {
          history.push("/");
        }
      })
    );
  };

  const UpdateViewInGTM = (channelName: string) => {
    sendGTMDataLayer({
      event: "Video View",
      eventCategory: "video views",
      eventAction: "watch video",
      eventValue: 5,
      channelName
    });
  };

  useEffect(() => {
    videoDetails && videoDetails.video.is_stream && loadStreamData(videoDetails.video);
  }, [videoDetails]);

  const videoCategoriesAndInterests = useMemo(() => {
    if (videoDetails && videoDetails.video.categories && videoDetails.video.tags) {
      const categories = videoDetails.video.categories.map((category: any) => ({
        label: category.name,
        value: category.slug,
        primary: true
      }));

      return [...categories, ...videoDetails.video.tags];
    }

    return [];
  }, [videoDetails]);
  const loadStreamData = (streamData: any) => {
    video.is_live &&
      dispatch(
        getWatchersCount(params.hash_id, (count: number) => {
          setWatchersCount(count + 1);
        })
      );
    dispatch(
      subscribeLivePublicUpdates(streamData.hash_id, (data: any) => {
        if (data.event) {
          if (data.event === "left" && data.user.name !== userDetails.username) {
            dispatch(
              getWatchersCount(params.hash_id, (count: number) => {
                setWatchersCount(count);
              })
            );
          }

          if (data.event === "joined" && data.user.name !== userDetails.username) {
            dispatch(
              getWatchersCount(params.hash_id, (count: number) => {
                setWatchersCount(count);
              })
            );
          }
        }
        if (data.message) {
          if (data.message.status === "started") {
            onPageLoadData(params.hash_id);
          }
          if (data.message.status === "stopped") {
            setStreamClose(true);
          }
        }
      })
    );
  };

  if (!videoDetails) {
    return (
      <div className="page-content">
        <Row between="xs" className="single-video-page full-container">
          <LoadingSpinner />
        </Row>
      </div>
    );
  }

  const {
    video,
    nextVideo: [next_video],
    relatedVideo
  } = videoDetails;

  const channelAvatarAlt = getAvatar(video.channel.name || "");

  const onUpdateCommentsCount = (comments_count: number) => {
    setVideoDetails({
      ...videoDetails,
      video: {
        ...videoDetails.video,
        comments_count
      }
    });
  };

  const onPlayVideoEnded = ({ autoplay }: { autoplay: boolean }) => {
    if (next_video && params.hash_id !== next_video.hash_id && autoplay) {
      history.push(`/video/${next_video.hash_id}`);
    }
    clearInterval(GTMInterval);
  };

  const toggleLikeStatus = (status: "like" | "dislike") => () => {
    if (isAuthenticated) {
      const next_status = video.like_status !== status ? status : `un${status}`;

      dispatch(
        requestChangeVideoLikeStatus(video.video_id, next_status, {
          onSuccess: likesCountData => {
            setVideoDetails({
              ...videoDetails,
              video: {
                ...videoDetails.video,
                ...likesCountData,
                like_status: next_status
              }
            });
          },
          onError: () => {}
        })
      );
    } else {
      setAuthModal("SIGN_IN");
    }
  };

  const toggleChannelFollowStatus = () => {
    if (video.channel.channel_id) {
      setFollowIsPending(true);

      if (video.channel.is_follow) {
        dispatch(
          unfollowChannel(video.channel.channel_id, {
            onSuccess: () => {
              setFollowIsPending(false);
              setVideoDetails({
                ...videoDetails,
                video: {
                  ...videoDetails.video,
                  channel: {
                    ...videoDetails.video.channel,
                    is_follow: false,
                    followers: videoDetails.video.channel.followers - 1
                  }
                }
              });
            },
            onError: () => {}
          })
        );
      } else {
        isAuthenticated
          ? dispatch(
              followChannel(video.channel.channel_id, {
                onSuccess: () => {
                  setFollowIsPending(false);
                  setVideoDetails({
                    ...videoDetails,
                    video: {
                      ...videoDetails.video,
                      channel: {
                        ...videoDetails.video.channel,
                        is_follow: true,
                        followers: videoDetails.video.channel.followers + 1
                      }
                    }
                  });
                },
                onError: () => {}
              })
            )
          : setAuthModal("SIGN_IN");
      }
    }
  };
  return (
    <div className="page-content">
      <Row between="xs" className="single-video-page full-container">
        <Col className="video-details">
          <Row className="video-header">
            {expandInfo ? (
              <div className="toggle-info">
                <Linkify componentDecorator={LinkDecorator}>
                  {video.description ? (
                    <span className="toggle-info-desc">{video.description}</span>
                  ) : null}
                </Linkify>
                {videoCategoriesAndInterests.map((category: any) => (
                  <Link
                    to={`/categories/videos?category=${category.label}`}
                    className={classes("video-tag", { default: category.primary })}
                    key={category.value}
                  >
                    {category.label}
                  </Link>
                ))}
              </div>
            ) : null}
            <Link className="owner" to={`/channel/${video.channel.slug}`}>
              {video.channel.image ? (
                <div
                  className="owner-avatar avatar"
                  style={{
                    backgroundImage: `url(${video.channel.image.replace("[SIZE]", "38x38")})`
                  }}
                />
              ) : (
                <div
                  className="owner-avatar avatar"
                  style={{
                    backgroundColor: channelAvatarAlt.color
                  }}
                >
                  <span className="name-letters">{channelAvatarAlt.shortLetters} </span>
                </div>
              )}
              <span>{video.channel.name}</span>
            </Link>
            <div className="owner-followers-count">
              <i className="icon icon-users-group" />
              <span>{abbreviateNumber(video.channel.followers)}</span>
            </div>

            {video.channel.channel_id != userDetails.channel_id && (
              <button
                className={classes("main-button blank small follow-btn", {
                  ["following"]: video.channel.is_follow
                })}
                onClick={toggleChannelFollowStatus}
                disabled={followIsPending}
              >
                {video.channel.is_follow ? (
                  <>
                    <i className="icon-check-mark" />
                    <span>Following</span>
                  </>
                ) : (
                  <span>Follow</span>
                )}
              </button>
            )}
          </Row>
          {streamClose ? (
            <div
              className="video-placeholder"
              style={{
                backgroundImage: `url(${video.thumb && video.thumb.replace("[SIZE]", "1000x1000")})`
              }}
            >
              <div className="content">
                <span className="close-label">thanks for watching</span>
                <h3 className="heading">The live stream has ended</h3>
                <p>
                  Check other videos of{" "}
                  <Link to={`/channel/${video.channel.slug}`} className="link">
                    {video.channel.name}
                  </Link>
                </p>
              </div>
            </div>
          ) : !video.is_stream || !!video.video_url ? (
            <VideoPlayer
              src={video.video_url}
              thumb={video.thumb}
              watchersCount={watchersCount}
              isLiveActive={video.is_live}
              liveType={video.live_type}
              livePublishStart={video.published_at}
              watchMode={true}
              onPause={() => {
                clearInterval(GTMInterval);
              }}
              onPlay={() => {
                GTMInterval = setInterval(() => {
                  setVideoViewCount((prevCount: number) => prevCount + 1);
                }, 1000);
              }}
              onPlayVideoEnded={onPlayVideoEnded}
              settings={{
                autoplay: autoplayMode,
                playbackRates: !video.is_live && !video.is_stream ? [0.5, 1, 1.5, 2] : []
              }}
            />
          ) : video.stream_ended ? (
            <div
              className="video-placeholder"
              style={{
                backgroundImage: `url(${video.thumb && video.thumb.replace("[SIZE]", "1000x1000")})`
              }}
            >
              <div className="content">
                <h3 className="heading">The live stream has ended</h3>
                <p>
                  Check other videos of{" "}
                  <Link to={`/channel/${video.channel.slug}`} className="link">
                    {video.channel.name}
                  </Link>
                </p>
              </div>
            </div>
          ) : (
            <div
              className="video-placeholder"
              style={{
                backgroundImage: `url(${video.thumb && video.thumb.replace("[SIZE]", "1000x1000")})`
              }}
            >
              <div className="content">
                <h3 className="heading">Starting soon</h3>
                <p>Please wait until {video.channel.name} will start streaming</p>
              </div>
            </div>
          )}
          <Row between="xs" className="video-details-published">
            <Col xs={12} sm={12} md={6} lg={6} className="created-date">
              {video.stream_ended ? "Streamed" : "Published"} on:{" "}
              {formatDate(video.createdAt * 1000, "MMMM d, yyyy")}
            </Col>
            <Col xs={12} sm={12} className="title hidden">
              {video.title}
              <Col className="expand-details">
                <div
                  className={classes("controls ", {
                    ["opened"]: expandInfo
                  })}
                  onClick={() => {
                    setExpandInfo(!expandInfo);
                  }}
                >
                  <i className="icon icon-chevron-left" />
                </div>
              </Col>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="controls-list">
              {!video.is_live && (
                <div className="controls">
                  <i className="icon icon-glasses" />
                  <span>{abbreviateNumber(video.views)}</span>
                </div>
              )}
              <div
                className={classes("controls likes", { active: video.like_status === "like" })}
                onClick={toggleLikeStatus("like")}
              >
                <i className="icon icon-thumbs-up" />
                <span>{abbreviateNumber(video.likes)}</span>
              </div>
              <div
                className={classes("controls likes", {
                  active: video.like_status === "dislike"
                })}
                onClick={toggleLikeStatus("dislike")}
              >
                <i className="icon icon-thumbs-down" />
                <span>{abbreviateNumber(video.dislikes)}</span>
              </div>
            </Col>
          </Row>
          <Row between="xs" className="video-details-title">
            <Col xs className="title">
              {video.title}
            </Col>
            <Col className="expand-details">
              {(video.description.length > 225 ||
                (video.description.match(/\n/g) || []).length >= 2) && (
                <div
                  className={classes("controls", {
                    ["opened"]: expandDescription
                  })}
                  onClick={() => {
                    setExpandDescription(!expandDescription);
                  }}
                >
                  <i className="icon icon-chevron-left" />
                </div>
              )}
            </Col>
          </Row>

          {video.description && (
            <Row className="description-block">
              <Col
                xs={12}
                className={classes("description ", {
                  ["full"]: expandDescription
                })}
              >
                <Linkify componentDecorator={LinkDecorator}>{video.description}</Linkify>
              </Col>
            </Row>
          )}

          <Row className="video-categories-tags">
            <Col xs={12} className="tags-list">
              {videoCategoriesAndInterests.map((category: any) => (
                <Link
                  to={`/categories/videos?tag=${
                    category.label && category.label.replace(" ", "-")
                  }`}
                  className={classes("video-tag", { default: category.primary })}
                  key={category.value}
                >
                  {category.label}
                </Link>
              ))}
            </Col>
          </Row>

          {video.enable_comments && (
            <Row className={!expandComments ? "comments hidden" : "comments"}>
              <Col xs={12} sm={12} md={12} lg={12} className="comments-block">
                <div className="comments-container">
                  <Row between="xs" className="comments-label">
                    <Col className="comments-count">
                      Comments <span>{video.comments_count || 0}</span>
                    </Col>
                    <Col className="expand-details">
                      <div
                        className={classes("controls ", {
                          ["opened"]: expandComments
                        })}
                        onClick={() => {
                          setExpandComments(!expandComments);
                        }}
                      >
                        <i className="icon icon-chevron-left" />
                      </div>
                    </Col>
                  </Row>

                  <Comments
                    type="comments"
                    video_id={video.video_id}
                    video_channel_id={video.channel.channel_id}
                    onUpdateCommentsCount={onUpdateCommentsCount}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>

        <Col xs className={expandComments ? "related-videos-list hidden" : "related-videos-list"}>
          {next_video && (
            <div className="videos-list-group videos-list-group-next">
              <Row className="app-next-heading">
                <Col xs className="app-next-heading-text">
                  <h3 className="label-txt">Up next</h3>
                </Col>
                <Col className="auto-play-switch">
                  <ToggleSwitch checked={autoplayMode} onCheck={setAutoplayMode} />
                  <span>AUTOPLAY</span>
                </Col>
              </Row>
              <div className="list">
                <VideoListItem {...next_video} />
              </div>
            </div>
          )}

          {!!relatedVideo && !!relatedVideo.length && (
            <div className="videos-list-group">
              <h3 className="videos-list-group-related">Related videos</h3>
              <div className="list">
                {relatedVideo.map((videoListItem, index) => (
                  <VideoListItem {...videoListItem} key={`${videoListItem.hash_id}-${index}`} />
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>
      {!!authModal.length && <AuthModule modal={authModal} onModalClose={setAuthModal} />}
    </div>
  );
}

export default SingleVideoPage;
