import { useCallback, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { LoadingSpinner } from "@Components";

const BACKGROUND_COLOR = "#1b1b28";
const IFRAME_URL = `https://app.darqube.com/widgets/news?background=${window.encodeURIComponent(
  BACKGROUND_COLOR
)}`;

const NewsContextMenu = (): JSX.Element => {
  // hasBeenOpened and isLoaded never changes after it was set to true
  // This is because we render the iframe after first open and keep it in the DOM
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const rootNode = useRef<HTMLDivElement>(null);

  const toggleShowNews = useCallback(() => {
    setShowNews(value => !value);
    setHasBeenOpened(true);
  }, []);
  const handleLoad = useCallback(() => setIsLoaded(true), []);
  const handleCloseMenu = useCallback(() => {
    setShowNews(false);
  }, []);

  useClickAway(rootNode, handleCloseMenu);

  return (
    <div ref={rootNode} className="news-container">
      <i className="icon-news" onClick={toggleShowNews} />

      {hasBeenOpened && (
        <div
          className="news-context-menu"
          style={{ background: BACKGROUND_COLOR }}
          data-visible={showNews}
        >
          {!isLoaded && <LoadingSpinner />}
          <iframe src={IFRAME_URL} onLoad={handleLoad} data-loaded={isLoaded} />
        </div>
      )}
    </div>
  );
};

export default NewsContextMenu;
