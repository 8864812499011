import Select from "react-select";
import { Row, Col } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { VIDEO_STATUS_OPTIONS } from "@Constants";
import { ConfirmModal, StatusOption, StatusSelected } from "@Components";

import {
  deleteVideosList,
  requestChangeVideosPlaylist,
  requestChangeVideosCategoryData,
  requestChangeVideosVisibility,
  requestUserStreams
} from "@Store/actions/video";
import { useState } from "react";

type LibraryHeaderTypes = {
  checkedItems: any[];
  categoriesList: any[];
  playlistsList: any[];
  setCheckedVideos: Function;
  perPage?: number;
};

const statusOptionsList = Object.values(VIDEO_STATUS_OPTIONS)
  .reverse()
  .filter(i => i.value);

function LibraryHeader({
  categoriesList,
  playlistsList,
  checkedItems,
  setCheckedVideos,
  perPage
}: LibraryHeaderTypes) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModalShow = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const onDeleteCheckedVideos = () => {
    if (checkedItems.length) {
      dispatch(
        deleteVideosList(checkedItems, true, {
          onSuccess: () => {
            dispatch(requestUserStreams(1, perPage));
          },
          onError: () => {}
        })
      );
      setCheckedVideos({});
    }
  };

  const onCategoryChange = (selectedOption: any) => {
    if (checkedItems.length) {
      dispatch(requestChangeVideosCategoryData(selectedOption.value, checkedItems, true));
    }
  };

  const onVisibilityChange = (selectedOption: any) => {
    if (checkedItems.length) {
      dispatch(requestChangeVideosVisibility(selectedOption.value, checkedItems, true));
    }
  };

  const onPlaylistChange = (selectedOption: any) => {
    if (checkedItems.length) {
      dispatch(requestChangeVideosPlaylist(selectedOption.value, checkedItems));
    }
  };

  return (
    <Row className="video-library-header">
      <Col className="control-item selected-items-count">{checkedItems.length} SELECTED</Col>
      <Col className="control-item visibility-item">
        <Select
          classNamePrefix="app-select"
          className="transparent-select"
          menuPlacement="auto"
          placeholder="Visibility"
          isSearchable={false}
          options={statusOptionsList}
          onChange={onVisibilityChange}
          components={{ Option: StatusOption, SingleValue: StatusSelected }}
        />
      </Col>
      <Col className="control-item">
        <Select
          classNamePrefix="app-select"
          className={"transparent-select"}
          menuPlacement="bottom"
          placeholder="Assign Category"
          isSearchable={false}
          onChange={onCategoryChange}
          options={categoriesList}
        />
      </Col>
      <Col className="control-item">
        <Select
          classNamePrefix="app-select"
          className={"transparent-select"}
          menuPlacement="bottom"
          placeholder="Add to playlist"
          isSearchable={false}
          onChange={onPlaylistChange}
          noOptionsMessage={() => "No playlists"}
          options={playlistsList}
        />
      </Col>
      <Col className="control-item delete-button" onClick={toggleDeleteModalShow}>
        <i className="icon icon-remove" />
        <span>Delete</span>
      </Col>
      {showDeleteModal && (
        <ConfirmModal
          onClose={toggleDeleteModalShow}
          onConfirm={onDeleteCheckedVideos}
          bodyText={`Are you sure you want to delete selected videos ?`}
        />
      )}
    </Row>
  );
}

export default LibraryHeader;
