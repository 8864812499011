import { useRef, useState } from "react";
import { FilterComponent } from "@Models/FilterComponent";
import { FilterCheckDropdown, FilterRange } from "@Components/Filters";
import { useOnClickOutside } from "@Hooks";

type Props = {
  filter: FilterComponent;
  onClickOutside: () => void;
  onFilterApply: (filterValue: FilterComponent) => void;
};

const FilterChooser = ({ onClickOutside, onFilterApply, filter }: Props) => {
  const chooserNode = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(chooserNode, onClickOutside);

  return (
    <div className="filter-chooser" ref={chooserNode}>
      <>
        {filter.componentType === "checkDropdown" && (
          <FilterCheckDropdown
            filter={filter}
            onCancel={onClickOutside}
            onSelectedItem={onFilterApply}
          />
        )}
        {filter.componentType === "range" && (
          <FilterRange filter={filter} onSelectedItem={onFilterApply} onCancel={onClickOutside} />
        )}
      </>
    </div>
  );
};
export default FilterChooser;
