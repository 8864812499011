import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { requestCreatePlaylist } from "@Store/actions/playlist";
import classes from "classnames";

function CreatePlaylistInput() {
  const dispatch = useDispatch();

  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null as any);

  const onInputChange = ({ target: { value } }: { target: { value: string } }) => {
    setInputValue(value);
  };

  const onAddInputType = (event: any) => {
    if (event.which === 13) {
      onSubmitAddPlaylist();
    }
  };

  const toggleShowInput = () => {
    if (showInput) {
      setInputValue("");
      if (inputRef.current) inputRef.current.focus();
    }

    setShowInput(!showInput);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  });

  const onSubmitAddPlaylist = () => {
    if (!!inputValue) {
      dispatch(requestCreatePlaylist(inputValue));
      toggleShowInput();
    }
  };

  return (
    <Row
      className={classes("playlist-heading-controls", {
        ["active-addition"]: showInput
      })}
    >
      <Col>
        <div className="toggle-button" onClick={toggleShowInput}>
          <span className="circle-button">
            <i className={showInput ? "icon-dash-line-bold" : "icon-rounded-plus"} />
          </span>
          {!showInput && <span className="label-button">Add new</span>}
        </div>
      </Col>
      <Col xs className="add-column-input">
        {showInput && (
          <div className="input-wrap">
            <input
              type="text"
              value={inputValue}
              ref={inputRef}
              onChange={onInputChange}
              onKeyUp={onAddInputType}
              placeholder="Add new"
            />

            <span className="submit-button" onClick={onSubmitAddPlaylist}>
              <i className="icon-check-mark" />
            </span>
            <span className="cancel-button" onClick={toggleShowInput}>
              <i className="icon-close-rounded" />
            </span>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default CreatePlaylistInput;
