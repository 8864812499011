import "./style.scss";
import { Col, Row } from "react-flexbox-grid";
import { SymbolLogo } from "@Assets";
import { useForm } from "@Hooks";
import { SupportForm } from "@Models/Support";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { sendSupportFormData } from "@Store/actions/pageSections";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
  targetRef: string;
};

const defaultFormDataState = {
  full_name: "",
  company_name: "",
  email: "",
  message: "",
  token: ""
};

function ContactSupportForm({ targetRef }: Props) {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData, _, setDefaultForm] = useForm(defaultFormDataState as SupportForm);

  const onFormSubmit = (event: FormEvent) => {
    setIsPending(true);
    executeRecaptcha &&
      executeRecaptcha("login").then((token: string) => {
        dispatch(
          sendSupportFormData(
            { ...formData, token },
            {
              onSuccess: () => {
                setIsPending(false);
                setDefaultForm(defaultFormDataState);
                toast.success("Support email has been successfully sent!");
              }
            }
          )
        );
      });
    event.preventDefault();
  };

  return (
    <section className="contact-us" ref={targetRef}>
      <div className="full-container contact-container">
        <Row between="xs">
          <Col xs className="contact-info">
            <div className="info-inner">
              <img src={SymbolLogo} alt="sv-logo" />
              <h2 className="title">Can’t find an answer to your question?</h2>
              <h2 className="blue-title">Then contact us.</h2>
            </div>
          </Col>
          <Col xs className="contact-form">
            <form className="form-inner" onSubmit={onFormSubmit}>
              <Row between="xs" className="form-heading">
                <Col>
                  <label htmlFor="full-name" className="form-label">
                    Full name*
                  </label>
                </Col>
                <Col className="explanation">*mandatory field</Col>
                <Col xs={12} className="input-container">
                  <input
                    required
                    id="full-name"
                    value={formData.full_name}
                    onChange={setFormData}
                    name="full_name"
                    placeholder="Name Surname"
                    type="text"
                    className="main-input"
                  />
                </Col>
              </Row>
              <label htmlFor="company" className="form-label">
                Company name
              </label>
              <div className="input-container">
                <input
                  id="company"
                  value={formData.company_name}
                  onChange={setFormData}
                  name="company_name"
                  placeholder="My Company"
                  type="text"
                  className="main-input"
                />
              </div>
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <div className="input-container">
                <input
                  required
                  id="email"
                  value={formData.email}
                  onChange={setFormData}
                  name="email"
                  placeholder="email@site.domain"
                  type="email"
                  className="main-input"
                />
              </div>
              <label htmlFor="email" className="form-label">
                Message*
              </label>
              <div className="input-container">
                <textarea
                  required
                  id="message"
                  value={formData.message}
                  onChange={setFormData}
                  name="message"
                  placeholder="Describe your enquiry"
                  className="main-input message"
                />
              </div>
              <button type="submit" className="main-button" disabled={isPending}>
                Send request
              </button>
              <p className="terms-conditions">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and{" "}
                <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">
                  Terms of Service
                </a>
              </p>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ContactSupportForm;
