import { call } from "redux-saga/effects";
import { RequestParams } from "@Models/RequestParams";
import { logoutUserSaga } from "@Store/sagas/user";
import { refreshAccessToken } from "@Store/sagas/app";
import fetchApiRequest from ".";

function* fetchAuthorizedApiRequest(url: string, options: RequestParams, isFullUrl?: boolean): any {
  const userAccessToken = localStorage.getItem("ACCESS_TOKEN");

  const payload = yield call(
    fetchApiRequest,
    url,
    {
      ...options,
      headers: userAccessToken && {
        Authorization: `Bearer ${userAccessToken}`
      }
    },
    isFullUrl
  );

  switch (payload.status) {
    case 410:
      yield call(refreshAccessToken);

      return yield call(fetchAuthorizedApiRequest, url, options);
    case 401:
      yield call(logoutUserSaga);

      return payload;

    default:
      return payload;
  }
}

export default fetchAuthorizedApiRequest;
