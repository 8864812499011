import { memo } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { VideoModel } from "@Models/Video";
import { abbreviateNumber, convertToTimeDuration, getAvatar, padNumber } from "@Utils";
import "./style.scss";

function PlaylistVideoItem({ thumb, title, duration, channel, views, hash_id, index }: VideoModel) {
  const ownerAvatarAlt = getAvatar(channel?.name);

  return (
    <Row className="playlist-video-item content">
      <Col className="auto-column nr-order">{index && padNumber(index)}</Col>
      <Col xs className="video-coll">
        <ul className="video-details-list">
          <li className="thumb">
            <Link className="thumb" to={`/video/${hash_id}`}>
              {thumb ? (
                <div
                  className="thumbnail-video"
                  style={{ backgroundImage: `url(${thumb.replace("[SIZE]", "140x85")})` }}
                />
              ) : (
                <div className="default-thumb">
                  <i className="icon-img-thumb" />
                </div>
              )}
            </Link>
            <span className="duration">{convertToTimeDuration(duration)}</span>
          </li>
          <li className="info">
            <h5 className="title">{title}</h5>
            <ul className="video-data">
              <li className="account-details">
                <Link to={`/channel/${channel?.slug}`} className="link-to-channel">
                  {channel?.iamge ? (
                    <div
                      className="channel-avatar"
                      style={{
                        backgroundImage: `url(${channel.iamge.replace("[SIZE]", "38x38")})`
                      }}
                    />
                  ) : (
                    <div
                      className="channel-avatar"
                      style={{
                        backgroundColor: ownerAvatarAlt.color
                      }}
                    >
                      <span className="name-letters">{ownerAvatarAlt.shortLetters}</span>
                    </div>
                  )}
                  <span> {channel?.name}</span>
                </Link>
              </li>
              <li className="nr-views">
                <div className="nr-views-item nr-views-galsses">
                  <i className="icon-glasses" />
                  {abbreviateNumber(views)}
                </div>
                <div className="nr-views-item nr-views-time">{convertToTimeDuration(duration)}</div>
              </li>
            </ul>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default memo(PlaylistVideoItem);
