import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import VideoPostItem from "@Components/VideoPostItem";
import { RecommendVideo } from "@Models/HomeData";

type Props = {
  recommendedVideos: RecommendVideo[];
};

function TopRecommended({ recommendedVideos }: Props) {
  const [limit, setLimit] = useState(3);
  const [showButton, setShowButton] = useState(true);

  const showMoreDocuments = () => {
    setLimit(limit + 99);
    setShowButton(!showButton);
  };

  return (
    <section className="top-recommended">
      <div className="full-container">
        <h2 className="section-title">
          <span className="colored">Top videos</span> recommended to you{" "}
        </h2>
        <Row className="recommended-post-list">
          {!!recommendedVideos &&
            recommendedVideos.map((video, i) => (
              <Col
                lg={3}
                md={4}
                sm={6}
                key={video.hash_id}
                className={
                  i > 2 && !!showButton ? "recommended-post-coll hidden" : "recommended-post-coll"
                }
              >
                <VideoPostItem {...video} />
              </Col>
            ))}
        </Row>
        {showButton ? (
          <button className="load-more" onClick={showMoreDocuments}>
            Load more
          </button>
        ) : null}
      </div>
    </section>
  );
}

export default TopRecommended;
