import { propEq } from "ramda";

const StatusOption = ({ innerRef, innerProps, children, isSelected, options, value }: any) => (
  <div
    className={
      isSelected ? "app-select__option app-select__option--is-selected" : "app-select__option"
    }
    ref={innerRef}
    {...innerProps}
  >
    <div className="custom-status-option">
      {children}
      <i className={options.find(propEq("value", value)).icon} />
    </div>
  </div>
);

export default StatusOption;
