import { useEffect, useRef, useState } from "react";
import { getAvatar } from "@Utils";
import { User } from "@Models/User";
import { Channel } from "@Models/Channel";
import { useDispatch, useSelector } from "react-redux";
import { deleteChannelAvatar, updateChannelImage } from "@Store/actions/channel";
import { getChanelData } from "@Store/selectors/channel";
import { deleteUserAvatar, updateUserImage } from "@Store/actions/user";
import { getAuthUser } from "@Store/selectors/user";
import "./style.scss";

type FileSelected = (file: File) => void;

type Props = {
  type: "user" | "channel" | "create-channel";
  data: User | Channel | any;
  onFileSelected: FileSelected;
};

type MainAvatarProps = {
  name: string;
  type: string;
  image: string;
  avatar_id: number | string;
};

const MainAvatar = ({ name, type, image, avatar_id }: MainAvatarProps) => {
  const avatar = getAvatar(name);
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [startAnimation, setStartAnimation] = useState(false);
  const updateImageInput: any = useRef(null);

  const onUpdateClick = () => {
    updateImageInput.current.click();
  };

  const handleUpdateChange = (event: any) => {
    if (event.target.files.length) {
      if (type === "channel") {
        dispatch(
          updateChannelImage({
            channel_id: avatar_id,
            file: event.target.files[0]
          })
        );
      } else {
        dispatch(
          updateUserImage({
            username: avatar_id,
            file: event.target.files[0]
          })
        );
      }
    }
  };

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      setStartAnimation(false);
      setTimeout(() => {
        setStartAnimation(true);
      }, 30);
    }
  }, [type]);

  const removeThumb = () => {
    if (type === "channel") {
      dispatch(deleteChannelAvatar(Number(avatar_id)));
    } else {
      dispatch(deleteUserAvatar(String(avatar_id)));
    }
  };
  return (
    <>
      {image ? (
        <aside
          className={
            startAnimation ? "account-avatar animate-flipInX" : "account-avatar first-state"
          }
        >
          <div
            className="thumbnail-container avatar"
            style={{
              backgroundImage: `url(${image.replace("[SIZE]", "500x500")})`
            }}
          >
            <div className="edit-controls-container">
              <i className="icon-edit" onClick={onUpdateClick} />
              <i className="icon-remove" onClick={removeThumb} />
            </div>
          </div>
        </aside>
      ) : (
        <aside
          className={
            startAnimation ? "account-avatar animate-flipInX" : "account-avatar first-state"
          }
        >
          <div
            className="thumbnail-container avatar"
            style={{
              backgroundColor: avatar.color
            }}
          >
            <span className="name-letters">{avatar.shortLetters}</span>
            <div className="edit-controls-container">
              <i className="icon-upload" onClick={onUpdateClick} />
            </div>
          </div>
        </aside>
      )}
      <input
        type="file"
        ref={updateImageInput}
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleUpdateChange}
        style={{ display: "none" }}
      />
    </>
  );
};

const CreateChannelAvatar = ({ onFileSelected }: { onFileSelected: FileSelected }) => {
  const hiddenFileInput: any = useRef(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    if (event.target.files.length) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      onFileSelected(event.target.files[0]);
    }
  };

  return (
    <aside className="account-avatar channel">
      <div className="thumbnail-container" style={{ backgroundImage: `url(${imageUrl})` }}>
        {!imageUrl && <i className="icon-l-user icon-placeholder" />}
      </div>
      <button type="button" className="main-button gray" onClick={handleClick}>
        <i className="icon-upload-cloud-fill" /> Upload
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </aside>
  );
};

function AccountAvatar({ type, data, onFileSelected }: Props) {
  const chanelData = useSelector(getChanelData);
  const userDetails = useSelector(getAuthUser);

  return type === "user" ? (
    <MainAvatar
      name={data.full_name}
      type={type}
      image={data.avatar}
      avatar_id={userDetails.username}
    />
  ) : type === "channel" ? (
    <MainAvatar
      name={data.name}
      type={type}
      image={data.image}
      avatar_id={chanelData.channel_id || 0}
    />
  ) : (
    <CreateChannelAvatar onFileSelected={onFileSelected} />
  );
}

export default AccountAvatar;
