import { useMemo, useEffect, useState, useCallback, forwardRef } from "react";
import cn from "classnames";
import { iframeResizer } from "iframe-resizer";

const WIDGET_URL = "https://app.darqube.com/widgets/markets_view";
const HEADER_BACKGROUND = "linear-gradient(90deg, #000428 0%, #004E92 100%)";
const MENU_BACKGROUND = "#050728";

const IFRAME_ID = "marketsIframe";

const MarketsViewIframe = forwardRef<HTMLIFrameElement>(({}, ref): JSX.Element => {
  const [showIframe, setShowIframe] = useState(false);
  const url = useMemo(() => {
    const queryParams =
      `?menuBackground=${window.encodeURIComponent(MENU_BACKGROUND)}` +
      `&headerBackground=${window.encodeURIComponent(HEADER_BACKGROUND)}`;

    return WIDGET_URL + queryParams;
  }, []);

  const handleLoad = useCallback(() => setShowIframe(true), []);

  useEffect(() => {
    setTimeout(() => {
      iframeResizer({}, `#${IFRAME_ID}`);
    });
  }, []);

  return (
    <iframe
      id={IFRAME_ID}
      ref={ref}
      src={url}
      className={cn("markets-view-iframe", !showIframe && "hidden")}
      onLoad={handleLoad}
    />
  );
});

export default MarketsViewIframe;
