import formatDate from "date-fns/format";
import classes from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { Link, NavLink, Route } from "react-router-dom";
import {
  followChannel,
  requestChannelDetails,
  unfollowChannel,
  requestChannelMarketAllocation,
  requestChannelSimilarChannels
} from "@Store/actions/channel";
import { abbreviateNumber, getAvatar, getCategoryChartColor } from "@Utils";
import { LinkDecorator } from "@Components";
import { LoadStatusTypes } from "@Models/RequestParams";
import { useHistory } from "react-router";
import { Channel } from "@Models/Channel";
import useRootRef from "@Hooks/useRootRef";
import { CHANNEL_SECTIONS_LIST } from "./constants";
import { getAuthUser } from "@Store/selectors/user";
import ChannelLatestUpdates from "./ChannelLatestUpdates";
import ChannelPagePlaceholder from "./PagePlaceholder";
import SimilarChannels from "./SimilarChannels";
import RootSection from "./sections";
import Linkify from "react-linkify";
import AuthModule, { AuthModal } from "@Components/AuthModule";
import { getAppAuthStatus } from "@Store/selectors/app";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

type PublicChannelPageTypes = {
  match: {
    url: string;
    path: string;
    params: { channel_slug: string; sectionId: string };
    isExact: boolean;
  };
};

interface ChannelPage extends Channel {
  is_follow: boolean;
  image: string | null;
  createdAt: number;
  instagram: string;
  facebook: string;
  twitter: string;
  homepage: string;
  streamer: { avatar: "string" | null; full_name: string; username: string };
  counts: { followers: number; videos: number; playlists: number; streams: number };
  market_allocation: { color: string; label: string; slug: string; value: number }[];
  similar_channels: { followers: any; image: string | null; name: string; slug: string }[];
}

function PublicChannelPage({ match }: PublicChannelPageTypes) {
  const dispatch = useDispatch();
  const userDetails = useSelector(getAuthUser);
  const isAuthenticated = useSelector(getAppAuthStatus);
  const [channelLoadStatus, setChannelLoadStatus] = useState<LoadStatusTypes>("PENDING");
  const [channelDetails, setChannelDetails] = useState<ChannelPage | any>(null);
  const [followIsPending, setFollowIsPending] = useState(false);
  const [authModal, setAuthModal] = useState("" as AuthModal);
  const [expandDescription, setExpandDescription] = useState(false);
  const rootRef = useRootRef();
  const history = useHistory();

  useEffect(() => {
    if (!authModal.length) {
      setFollowIsPending(false);
      initChannelDetails(match.params.channel_slug);
    }
  }, [authModal, match.params.channel_slug]);

  useEffect(() => {
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  const initChannelDetails = (channelSlug: string) => {
    dispatch(
      requestChannelDetails(channelSlug, 0, {
        onSuccess: data => {
          if (match.isExact) {
            history.replace({
              pathname: `${match.url}/${data.is_live ? "streams" : "videos"}`,
              state: { is_live: data.is_live }
            });
          }
          dispatch(
            requestChannelMarketAllocation(match.params.channel_slug, {
              onSuccess: allocation => {
                dispatch(
                  requestChannelSimilarChannels(match.params.channel_slug, {
                    onSuccess: similar_channels => {
                      setChannelDetails({
                        ...data,
                        similar_channels,
                        market_allocation: allocation.map((category: any) => ({
                          color: getCategoryChartColor(category.category.name),
                          label: category.category.name,
                          slug: category.category.slug,
                          value: category.count
                        }))
                      });
                      setChannelLoadStatus("SUCCESS");
                    },
                    onError: () => {}
                  })
                );
              },
              onError: () => {}
            })
          );
        },
        onError: error => {
          setChannelLoadStatus(error.code === 404 ? "NOT_FOUND" : "ERROR");
        }
      })
    );
  };

  useEffect(() => {
    if (channelDetails && match.isExact) {
      history.replace({
        pathname: `${match.url}/${channelDetails.is_live ? "streams" : "videos"}`,
        state: { is_live: channelDetails.is_live }
      });
    }
  }, [match]);

  if (!channelDetails) {
    return <ChannelPagePlaceholder channelLoadStatus={channelLoadStatus} />;
  }

  const channelAvatarAlt = getAvatar(channelDetails.name);
  const streamerAvatarAlt = getAvatar(channelDetails.streamer.full_name);

  const toggleChannelFollowStatus = () => {
    if (channelDetails.channel_id) {
      if (channelDetails.is_follow) {
        dispatch(
          unfollowChannel(channelDetails.channel_id, {
            onSuccess: () => {
              setFollowIsPending(false);
              setChannelDetails({
                ...channelDetails,
                is_follow: false,
                counts: {
                  ...channelDetails.counts,
                  followers: channelDetails.counts.followers - 1
                }
              });
            },
            onError: () => {}
          })
        );
      } else {
        isAuthenticated
          ? dispatch(
              followChannel(channelDetails.channel_id, {
                onSuccess: () => {
                  setFollowIsPending(false);
                  setChannelDetails({
                    ...channelDetails,
                    is_follow: true,
                    counts: {
                      ...channelDetails.counts,
                      followers: channelDetails.counts.followers + 1
                    }
                  });
                },
                onError: () => {}
              })
            )
          : setAuthModal("SIGN_IN");
      }
    }
  };
  return (
    <div className="page-content">
      <section className="full-container public-profile-page">
        <div className="mobile-block">
          <div className="breadcrumbs-block">
            <Link to={`/`} className="breadcrumbs-block-link">
              Home
            </Link>
            <span>/</span>
            <Link to={`/categories/channels?category=all`} className="breadcrumbs-block-link">
              Channel
            </Link>
          </div>
          <div className="mobile-block-item mobile-block-title">
            <span>{channelDetails.name}</span>
          </div>
          <div className="mobile-block-item mobile-block-info">
            <div className="mobile-block-info-avatar">
              {channelDetails.image ? (
                <div className="avatar-container">
                  {channelDetails.is_live && (
                    <div className="live-badge">
                      <div className="live-beep" />
                    </div>
                  )}
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundImage: `url(${channelDetails.image.replace("[SIZE]", "400x400")})`
                    }}
                  />
                </div>
              ) : (
                <div className="avatar-container">
                  {channelDetails.is_live && (
                    <div className="live-badge">
                      <div className="live-beep" />
                    </div>
                  )}
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundColor: channelAvatarAlt.color
                    }}
                  >
                    <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="mobile-block-info-follow">
              <div className="mobile-block-info-follow-item people">
                <i className="icon-users-group" />
                <span className="number">{abbreviateNumber(channelDetails.counts.followers)}</span>
              </div>
              <div className="mobile-block-info-follow-item">
                {channelDetails.channel_id != userDetails.channel_id && (
                  <button
                    className={classes("main-button blank small follow-btn", {
                      following: channelDetails.is_follow
                    })}
                    onClick={toggleChannelFollowStatus}
                    disabled={followIsPending}
                  >
                    {channelDetails.is_follow ? (
                      <>
                        <i className="icon-check-mark" />
                        <span>Following</span>
                      </>
                    ) : (
                      <span>Follow</span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mobile-block-item mobile-block-nav">
            <Row
              className={classes("nav-tabs", {
                ["is-live"]: channelDetails.is_live
              })}
            >
              {CHANNEL_SECTIONS_LIST.map(section => (
                <NavLink
                  key={section.id}
                  to={{
                    pathname: `${match.url}/${section.id}`,
                    state: { is_live: channelDetails.is_live }
                  }}
                  activeClassName="active"
                  className="nav-tab"
                >
                  {section.label}
                  <span className="badge-counter">{channelDetails.counts[section.id]}</span>
                </NavLink>
              ))}
            </Row>
          </div>
        </div>
        <Row className="account-heading">
          <Col className="left-side">
            <Row className="account-image">
              {channelDetails.image ? (
                <div className="avatar-container">
                  {channelDetails.is_live && (
                    <div className="live-badge">
                      <div className="live-beep" />
                    </div>
                  )}
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundImage: `url(${channelDetails.image.replace("[SIZE]", "400x400")})`
                    }}
                  />
                </div>
              ) : (
                <div className="avatar-container">
                  {channelDetails.is_live && (
                    <div className="live-badge">
                      <div className="live-beep" />
                    </div>
                  )}
                  <div
                    className="owner-avatar avatar"
                    style={{
                      backgroundColor: channelAvatarAlt.color
                    }}
                  >
                    <span className="name-letters">{channelAvatarAlt.shortLetters}</span>
                  </div>
                </div>
              )}
              <div className="streamer-details">
                <span className="muted">Owned by</span>
                <Link
                  to={`/streamer/${channelDetails.streamer.username}`}
                  className="link-to-streamer"
                >
                  {channelDetails.streamer.avatar ? (
                    <div
                      className="streamer-avatar avatar"
                      style={{
                        backgroundImage: `url(${channelDetails.streamer.avatar.replace(
                          "[SIZE]",
                          "32x32"
                        )})`
                      }}
                    />
                  ) : (
                    <div
                      className="streamer-avatar avatar"
                      style={{
                        backgroundColor: streamerAvatarAlt.color
                      }}
                    >
                      <span className="name-letters">{streamerAvatarAlt.shortLetters}</span>
                    </div>
                  )}
                  <span>{channelDetails.streamer.full_name}</span>
                </Link>
              </div>
            </Row>
            <ChannelLatestUpdates market_allocation={channelDetails.market_allocation} />
          </Col>
          <Col className="account-details" xs>
            <Row between="xs" className="account-details-top">
              <Col>
                <h2 className="account-name">{channelDetails.name}</h2>
              </Col>
              <Col className="media-icons">
                <div className="details-col">
                  <i className="icon-calendar" />
                  <p className="muted">
                    {`Since ${formatDate(channelDetails.createdAt * 1000, "MMM d, yyyy")}`}
                  </p>
                </div>
                {!!channelDetails.homepage.length && (
                  <a
                    href={channelDetails.homepage}
                    target="_blank"
                    rel="noreferrer"
                    className="details-col icon-globe"
                  />
                )}
                {!!channelDetails.twitter.length && (
                  <a
                    href={channelDetails.twitter}
                    target="_blank"
                    rel="noreferrer"
                    className="details-col icon-twitter"
                  />
                )}
                {!!channelDetails.facebook.length && (
                  <a
                    href={channelDetails.facebook}
                    target="_blank"
                    rel="noreferrer"
                    className="details-col icon-facebook"
                  />
                )}
                {!!channelDetails.instagram.length && (
                  <a
                    href={channelDetails.instagram}
                    target="_blank"
                    rel="noreferrer"
                    className="details-col icon-instagram"
                  />
                )}
              </Col>
            </Row>
            <Row between="xs" className="account-details-buttons">
              <Col>
                <div className="details-col">
                  <i className="icon-users-group subscriptions-icon" />
                  <p className="muted">{abbreviateNumber(channelDetails.counts.followers)}</p>

                  {channelDetails.channel_id != userDetails.channel_id && (
                    <button
                      className={classes("main-button blank small follow-btn", {
                        following: channelDetails.is_follow
                      })}
                      onClick={toggleChannelFollowStatus}
                      disabled={followIsPending}
                    >
                      {channelDetails.is_follow ? (
                        <>
                          <i className="icon-check-mark" />
                          <span>Following</span>
                        </>
                      ) : (
                        <span>Follow</span>
                      )}
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            {channelDetails.description && (
              <Row className="description">
                <i className="icon-info-o" />
                <p>
                  <Linkify componentDecorator={LinkDecorator}>
                    {((channelDetails.description.match(/\n/g) || []).length >= 3 ||
                      channelDetails.description.length > 375) &&
                    !expandDescription ? (
                      <>
                        {(channelDetails.description.match(/\n/g) || []).length >= 3 &&
                        channelDetails.description.split("\n")[0].length < 100 &&
                        channelDetails.description.split("\n")[1].length < 100
                          ? channelDetails.description.split("\n").splice(0, 3).join("\n")
                          : channelDetails.description.substring(0, 375)}{" "}
                        <span
                          className="desc-indicator"
                          onClick={() => {
                            setExpandDescription(true);
                          }}
                        >
                          More...
                        </span>
                      </>
                    ) : (
                      <>
                        {channelDetails.description}
                        {expandDescription && (
                          <span
                            className="desc-indicator"
                            onClick={() => {
                              setExpandDescription(false);
                            }}
                          >
                            {" "}
                            less
                          </span>
                        )}
                      </>
                    )}
                  </Linkify>
                </p>
              </Row>
            )}
            <Row
              className={classes("nav-tabs", {
                ["is-live"]: channelDetails.is_live
              })}
            >
              {CHANNEL_SECTIONS_LIST.map(section => (
                <NavLink
                  key={section.id}
                  to={{
                    pathname: `${match.url}/${section.id}`,
                    state: { is_live: channelDetails.is_live }
                  }}
                  activeClassName="active"
                  className="nav-tab"
                >
                  {section.label}
                  <span className="badge-counter">{channelDetails.counts[section.id]}</span>
                </NavLink>
              ))}
            </Row>
            <Row>
              <Route path={`${match.path}/:sectionId/:playlistId?`} render={RootSection as any} />
            </Row>
          </Col>
        </Row>
        {!!channelDetails.similar_channels.length && (
          <SimilarChannels
            market_allocation={channelDetails.market_allocation}
            similar_channels={channelDetails.similar_channels}
          />
        )}
      </section>
      {!!authModal.length && <AuthModule modal={authModal} onModalClose={setAuthModal} />}
    </div>
  );
}

export default PublicChannelPage;
