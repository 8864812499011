import TagManager from "react-gtm-module";

const sendGTMDataLayer = (dataLayer?: object) => {
  TagManager.dataLayer({
    dataLayer,
    dataLayerName: "PageDataLayer"
  });
};

export default sendGTMDataLayer;
