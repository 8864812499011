import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Col } from "react-flexbox-grid";
import { useDebounce, useOnClickOutside } from "@Hooks";
import { hideSidebar } from "@Store/actions/sidebar";
import { deleteSearchSuggestion, requestSearchSuggestions } from "@Store/actions/app";

function AppSearchInput() {
  const dispatch = useDispatch();
  const suggestionsContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchBlockRef = useRef(null);
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showSuggestionsMenu, setShowSuggestionsMenu] = useState(false);
  const history = useHistory();
  const debouncedSlug = useDebounce(searchQuery, 500);
  const handleCloseSidebar = useCallback(() => dispatch(hideSidebar()), []);

  useEffect(() => {
    if (showSuggestionsMenu) {
      dispatch(
        requestSearchSuggestions(!!debouncedSlug ? debouncedSlug : 1, {
          onSuccess: setSearchSuggestions,
          onError: () => {
            setSearchSuggestions([]);
          }
        })
      );
    }
  }, [debouncedSlug, showSuggestionsMenu]);

  useOnClickOutside(suggestionsContainerRef, () => {
    if (showSuggestionsMenu && document.activeElement !== searchInputRef.current) {
      setShowSuggestionsMenu(false);
    }
  });

  const onInputChange = ({ target: { value } }: any) => {
    setSearchQuery(value);
  };

  const onInputFocus = () => {
    setShowSuggestionsMenu(true);
  };

  const onSelectSuggestion = (query: string) => () => {
    setShowSuggestionsMenu(false);
    setSearchQuery(query);
    handleCloseSidebar();
    scrollTo(0, 0);
  };

  const handleRemove = (suggestionID: number) => {
    dispatch(
      deleteSearchSuggestion(suggestionID, {
        onSuccess: () => {
          setSearchSuggestions(
            searchSuggestions.filter((item: any) => item.del_id !== suggestionID)
          );
        },
        onError: () => null
      })
    );
  };

  const handleKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (searchQuery !== "") {
        history.push({
          pathname: "/search",
          search: `?query=${searchQuery}`
        });
        setShowSuggestionsMenu(false);
        event.target.blur();
        scrollTo(0, 0);
      }
    }
  };

  const handleClickSearch = (event: any) => {
    if (searchQuery !== "") {
      history.push({
        pathname: "/search",
        search: `?query=${searchQuery}`
      });
      setShowSuggestionsMenu(false);
      event.target.blur();
      handleCloseSidebar();
      scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (pathname == "/search") {
      setSearchQuery(history.location.search.replace("?query=", "").replace("%20", " "));
    } else {
      setSearchQuery("");
      setSearchSuggestions([]);
    }

    if (searchQuery == "") {
      setShowSuggestionsMenu(false);
    }
  }, [pathname]);

  const handleClickOutside = () => {
    setShowSuggestionsMenu(false);
  };

  useOnClickOutside(searchBlockRef, handleClickOutside);

  return (
    <Col xs className="search-container">
      <div className="search-container-item" ref={searchBlockRef}>
        <input
          type="text"
          className="main-input header-search"
          placeholder="Search..."
          onKeyUp={handleKeyUp}
          value={searchQuery}
          onChange={onInputChange}
          onFocus={onInputFocus}
          ref={searchInputRef}
        />
        <i className="icon-search" onClick={handleClickSearch} />

        {showSuggestionsMenu && !!searchSuggestions.length && (
          <div className="result-suggestions" ref={suggestionsContainerRef}>
            {searchSuggestions.map((suggestion: any, index: number) => (
              <div
                className={suggestion.del_id > 0 ? "suggestion-item blue" : "suggestion-item"}
                key={index}
              >
                <Link
                  onClick={onSelectSuggestion(suggestion.text)}
                  className="suggestion-item-link"
                  to={{
                    pathname: "/search",
                    search: `?query=${suggestion.text}`
                  }}
                >
                  {suggestion.text}
                </Link>
                {suggestion.del_id > 0 && (
                  <button
                    className="suggestion-item-btn"
                    onClick={() => {
                      handleRemove(suggestion.del_id);
                    }}
                  >
                    remove
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Col>
  );
}

export default AppSearchInput;
