import { createNamespace } from "@Utils";
import { RequestCallbacks } from "@Models/RequestParams";
import { SupportForm } from "@Models/Support";

export const HOME = createNamespace("HOME", ["GET_DATA"]);
export const SUPPORT = createNamespace("SUPPORT", ["SEND_SUPPORT_FORM"]);
export const DESHBOARD = createNamespace("DESHBOARD", [
  "GET_LIKES",
  "GET_TOP_VIDEOS",
  "GET_TOP_COMMENTS",
  "GET_CHANNEL_ANALYTICS",
  "GET_LAST_UPLOADED_VIDEO"
]);

export const requestHomePageData = (callbacks: RequestCallbacks) => ({
  type: HOME.GET_DATA,
  callbacks
});

export const requestDeshboardLikesData = (callbacks: RequestCallbacks) => ({
  type: DESHBOARD.GET_LIKES,
  callbacks
});

export const requestDeshboardTopVideos = (callbacks: RequestCallbacks) => ({
  type: DESHBOARD.GET_TOP_VIDEOS,
  callbacks
});

export const requestDeshboardLastUploadedVideo = (callbacks: RequestCallbacks) => ({
  type: DESHBOARD.GET_LAST_UPLOADED_VIDEO,
  callbacks
});

export const requestDeshboardChannelAnalytics = (callbacks: RequestCallbacks) => ({
  type: DESHBOARD.GET_CHANNEL_ANALYTICS,
  callbacks
});

export const requestDeshboardTopComments = (callbacks: RequestCallbacks) => ({
  type: DESHBOARD.GET_TOP_COMMENTS,
  callbacks
});

export const sendSupportFormData = (formData: SupportForm, callbacks: RequestCallbacks) => ({
  type: SUPPORT.SEND_SUPPORT_FORM,
  formData,
  callbacks
});
