import {
  UploadScreen1,
  UploadScreen2,
  UploadScreen3,
  UploadScreen4,
  UploadScreen5,
  UploadScreen6
} from "@Assets";
import "./style.scss";

function UploadVideoArticle() {
  return (
    <article className="help-article upload-video-article">
      <h2 className="article-title">How to upload or import videos to ShareVision?</h2>
      <p className="big-topic">
        Once you have successfully created your channel, you will be able to Live Stream, Upload or
        Import your existing content.
      </p>
      <p className="big-topic bold">Uploading content</p>
      <p className="description top">
        When on the home page of ShareVision.com, and when signed in, simply click the upload icon
        in the top right hand corner of the page.
      </p>
      <img src={UploadScreen1} alt="upload-1" />
      <p className="description">
        Here you can select files from your computer then click upload. You will be directed back to
        your channel dashboard where you will see the video file(s) uploading and then processing.
        Depending on the number of videos you have uploaded this shouldn’t take long.
      </p>
      <img src={UploadScreen2} alt="upload-2" />
      <p className="description">
        Once you have uploaded your video, it will default to draft. You can select the drop down
        next to draft in order to make it public, private or any other type of visibility mode.
      </p>
      <img src={UploadScreen3} alt="upload-3" />
      <p className="description">
        Whilst in your video library, if you hover over the video thumbnail, a small edit icon will
        appear. Clicking this will allow you to edit the title, description, thumbnail, category and
        tags for this video. It will also allow you to enable or disable comments from the
        ShareVision audience.
      </p>
      <img src={UploadScreen4} alt="upload-4" />
      <p className="description">
        On the next page, you have another chance to update the visibility of your video before
        saving.
      </p>
      <p className="big-topic bold">Importing content from YouTube</p>
      <p className="description top">
        When on the home page of ShareVision.com and when signed in, simply click on the upload icon
        in the top right corner of the page.
      </p>
      <img src={UploadScreen5} alt="upload-5" />
      <p className="description">
        Here you can use the YouTube import feature, where if you have existing content on YouTube
        you are able to import all of these videos, their titles, descriptions and thumbnails within
        a matter of seconds, and organise these videos before adding them to your ShareVision
        channel.
      </p>
      <p className="description">Now you must:</p>
      <ol>
        <li>Copy your YouTube channel URL (it must be the original URL, not a custom URL)</li>
        <li>Click Start</li>
        <li>
          You will receive a pop up notification, select the gmail address linked to your YouTube
          channel. This is the email you use to sign in to YouTube.
        </li>
        <li>Another pop-up will appear, here you must select your Brand Account.</li>
        <li>
          Here you can choose the videos and playlists from YouTube that you want to import to
          ShareVision.
        </li>
      </ol>
      <img src={UploadScreen6} alt="upload-6" />
      <p className="description">
        If you import many videos at once it might take some time to download and process them. You
        are able to exit and close ShareVision whilst this process is happening, or you can wait for
        it to finish.
      </p>
      <p className="description">
        If you need further support, you can send an email to{" "}
        <a href="mailto:support@sharevision.com">support@sharevision.com</a> where someone will
        assist you.
      </p>
    </article>
  );
}

export default UploadVideoArticle;
