import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch } from "react-redux";
import { requestSearchResults } from "@Store/actions/app";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { LoadingSpinner } from "@Components";
import SearchResultItem from "./SearchResultItem";
import useRootRef from "@Hooks/useRootRef";
import { getAvatar } from "@Utils";
import "./style.scss";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

type Props = {
  location: { search: string };
};

type ResultsTypes = {
  channel:
    | {
        slug: string;
        name: string;
        image: string;
        videos: string;
        followers: string;
        description: string;
      }
    | any;
  videos:
    | {
        thumb: string;
        views: number;
        title: string;
        hash_id: string;
        duration: number;
        description: string;
        channel: { name: string; slug: string; avatar: string | null };
      }[]
    | null;
};

const defaultPageInfo = {
  totalResults: 0,
  resultsPerPage: 0,
  currentPage: 1,
  totalPages: 0
};

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

function SearchResultsPage({ location }: Props) {
  const [searchResults, setSearchResults] = useState<ResultsTypes>({ channel: null, videos: [] });
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [activePage, setActivePage] = useState(0);
  const [isPending, setIsPending] = useState(true);
  const [pageRange, setPageRange] = useState(6);

  const dispatch = useDispatch();
  const rootRef = useRootRef();

  useEffect(() => {
    rootRef?.scrollTo(0, 0);

    const searchQuery =
      location && location.search ? new URLSearchParams(location.search).get("query") : null;

    if (searchQuery) {
      setSearchQuery(searchQuery);

      dispatch(
        requestSearchResults(searchQuery, 1, itemsPerPageCount.value, {
          onSuccess: ({ data, pageInfo }: { data: any; pageInfo: any }) => {
            setSearchResults(data);
            !!pageInfo ? setPageInfo(pageInfo) : setPageInfo(defaultPageInfo);
            setIsPending(false);
          },
          onError: () => {
            setSearchResults({ channel: null, videos: [] });
          }
        })
      );
    }
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, [location.search]);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setPageRange(3);
    }
    if (window.matchMedia("(max-width: 400px)").matches) {
      setPageRange(2);
    }
    if (window.matchMedia("(max-width: 355px)").matches) {
      setPageRange(1);
    }
  }, []);

  const onChangeItemsPerPageCount = (item: any) => {
    onChangeFollowersPage(1, item.value);
    setItemsPerPageCount(item);
  };

  const onChangeFollowersPage = useCallback(
    (nextPage: number, itemsPerPage: number = itemsPerPageCount.value) => {
      setIsPending(true);

      dispatch(
        requestSearchResults(searchQuery, nextPage, itemsPerPage, {
          onSuccess: ({ data, pageInfo }: { data: any; pageInfo: any }) => {
            setActivePage(nextPage - 1);
            setSearchResults(data);
            setPageInfo(pageInfo);
            setIsPending(false);
          },
          onError: () => {}
        })
      );
    },
    [itemsPerPageCount.value, searchQuery]
  );

  if (isPending) {
    return (
      <div className="page-content">
        <div className="full-container">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="full-container search-result-page">
        {searchQuery.length && (
          <h2 className="search-page-label">
            Results for <strong>{searchQuery}</strong>
          </h2>
        )}
        {searchResults.videos?.length || searchResults.channel.name ? (
          <>
            <div className="search-results-list">
              {searchResults.channel && searchResults.channel.name ? (
                <div className="search-result-channel">
                  <Link to={`/channel/${searchResults.channel.slug}`} className="link-to-channel">
                    {searchResults.channel.image ? (
                      <div
                        className="channel-avatar"
                        style={{
                          backgroundImage: `url(${searchResults.channel.image.replace(
                            "[SIZE]",
                            "136x136"
                          )})`
                        }}
                      />
                    ) : (
                      <div
                        className="channel-avatar"
                        style={{
                          backgroundColor: getAvatar(searchResults.channel.name).color
                        }}
                      >
                        <span className="name-letters">
                          {getAvatar(searchResults.channel.name).shortLetters}
                        </span>
                      </div>
                    )}
                  </Link>
                  <div className="item-details">
                    <div className="title">
                      <Link to={`/channel/${searchResults.channel.slug}`}>
                        <span>{searchResults.channel.name}</span>
                      </Link>
                    </div>
                    <div className="owner_details">
                      <div className="owner_details-item">
                        <span>{searchResults.channel.followers}</span>
                        <span> Followers</span>
                      </div>
                      <div className="owner_details-item">
                        <span>{searchResults.channel.videos}</span>
                        <span> Videos</span>
                      </div>
                      <div className="owner_details-desc">{searchResults.channel.description}</div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {!!searchResults.videos &&
                !!searchResults.videos.length &&
                searchResults.videos.map(videoItem => (
                  <SearchResultItem key={videoItem.hash_id} {...videoItem} />
                ))}
            </div>
            <Row className="app-pagination" between="xs">
              {pageInfo.totalResults > 10 && (
                <Col className="items-per-page-col">
                  <span>Show</span>
                  <Select
                    classNamePrefix="app-select"
                    menuPlacement="auto"
                    placeholder="Assign Category"
                    isSearchable={false}
                    value={itemsPerPageCount}
                    onChange={onChangeItemsPerPageCount}
                    options={ITEMS_PER_PAGE_COUNT_OPTIONS}
                  />
                </Col>
              )}

              {pageInfo.totalPages > 1 && (
                <>
                  <Col className="pagination-block">
                    <ReactPaginate
                      forcePage={activePage}
                      pageCount={pageInfo.totalPages}
                      pageRangeDisplayed={pageRange}
                      marginPagesDisplayed={1}
                      onPageChange={({ selected }) => onChangeFollowersPage(selected + 1)}
                      activeClassName="active"
                      pageClassName="page-number"
                      containerClassName="pagination row"
                      previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                      nextLabel={<i className="icon icon-chevron-right nav-icon" />}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        ) : (
          <div className="search-no-results">
            <div className="search-no-results-item">
              <i className="icon-search search-no-results-icon" />
              <span className="search-no-results-text">No results for your query</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchResultsPage;
