function EmptyFollowers() {
  return (
    <div className="empty-followers">
      <i className="icon-user-group" />
      <p className="empty-message">This channel has no followers for the moment</p>
    </div>
  );
}

export default EmptyFollowers;
