import { useState } from "react";
import { useDispatch } from "react-redux";
import { requestResetPassword } from "@Store/actions/user";
import { AuthModal } from "../index";
import { toast } from "react-toastify";

type ForgotPasswordModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
};

function ForgotPasswordModal({ onSetActiveModal }: ForgotPasswordModalTypes) {
  const dispatch = useDispatch();
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [isRequestPending, setRequestPending] = useState(false);

  const onChangeRecoveryEmail = (e: any) => {
    setRecoveryEmail(e.target.value);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setRequestPending(true);

    dispatch(
      requestResetPassword(recoveryEmail, {
        onSuccess: () => {
          onSetActiveModal("");
          toast.success("Reset password mail has been sent!");
        },
        onError: () => {
          setRequestPending(false);
        }
      })
    );
  };

  return (
    <div className="form-container forgot-password">
      <i
        className="icon-chevron-left arrow-back"
        onClick={() => {
          onSetActiveModal("SIGN_IN");
        }}
      />
      <h2 className="heading">Forgot your password?</h2>
      <p className="description">
        We will email you instructions to reset your password. Follow instructions from email to
        access your account
      </p>
      <form onSubmit={onSubmitForm} className="pop-content-container">
        <input
          value={recoveryEmail}
          className="main-input"
          onChange={onChangeRecoveryEmail}
          name="email"
          type="email"
          placeholder="Email"
          required
        />

        <button type="submit" className="main-button" disabled={isRequestPending}>
          Reset your password
        </button>
      </form>
    </div>
  );
}

export default ForgotPasswordModal;
