import { propEq } from "ramda";
import { Redirect } from "react-router";
import { HELP_ARTICLES_LIST } from "@Views/HelpCenter/constants";

type RootProfileSectionTypes = {
  match: { params: { articleId: string } };
};

function RootHelpArticles({ match }: RootProfileSectionTypes) {
  const {
    params: { articleId }
  } = match;

  const articleIndex = HELP_ARTICLES_LIST.findIndex(propEq("id", articleId));

  if (articleIndex !== -1) {
    return HELP_ARTICLES_LIST[articleIndex].component;
  }

  return (
    <Redirect
      to={{
        pathname: "/profile/library"
      }}
    />
  );
}

export default RootHelpArticles;
