import { useState } from "react";

const useForm = (initialState = {}) => {
  const [values, setValues] = useState<any>(initialState);

  const setFormData = (event: any) => {
    event.persist();

    setValues((values: Object) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };
  const setCustomKeyFormValue = (key: string, value: any) => {
    setValues((values: Object) => ({
      ...values,
      [key]: value
    }));
  };

  return [values, setFormData, setCustomKeyFormValue, setValues];
};

export default useForm;
