import { NavLink, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { getVideoLibraryCounts } from "@Store/selectors/channel";
import { VIDEO_LIBRARY_SECTIONS } from "../../constants";
import RootSection from "./sections";
import "./style.scss";

type VideoLibraryPageTypes = {
  match: {
    url: string;
    path: string;
    params: { channel_slug: string; sectionId: string };
    isExact: boolean;
  };
};

function VideoLibrary({ match }: VideoLibraryPageTypes) {
  const counts = useSelector(getVideoLibraryCounts);

  return (
    <section className="video-library">
      <Row className="section-heading">
        <Col xs className="auto-column">
          <h1 className="main-heading">Video Library</h1>
        </Col>
        <Col xs className="nav-tabs-wrap">
          <Row className="nav-tabs">
            {VIDEO_LIBRARY_SECTIONS.map(section => (
              <NavLink
                exact
                key={section.id}
                to={`${match.url}/${section.id}`}
                activeClassName="active"
                className="nav-tab"
              >
                {section.label}
                {!!counts && !!counts[section.id] && (
                  <span className="badge-counter">{counts[section.id]}</span>
                )}
              </NavLink>
            ))}
          </Row>
        </Col>
      </Row>
      {match.isExact && (
        <Redirect
          to={{
            pathname: `${match.url}/videos`
          }}
        />
      )}
      <Route path={`${match.path}/:sectionId`} render={RootSection as any} />
    </section>
  );
}

export default VideoLibrary;
