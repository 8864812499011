import { Col } from "react-flexbox-grid";
import { STREAM_PRIVACY_OPTIONS } from "./constants";

type Props = {
  formData: any;
  setFormData: () => void;
  allCategories: any[];
};

function PreviewForm({ formData, setFormData, allCategories }: Props) {
  const selectedCategory = allCategories.filter(c => c.value == formData.category_id)[0];
  return (
    <Col xs={6} className="init-stream-form">
      <label>Title</label>
      <input
        type="text"
        className="main-input"
        placeholder="Stream title"
        autoComplete="off"
        name="title"
        value={formData.title}
        onChange={setFormData}
        readOnly
      />

      <label>Visibility</label>
      <p className="addition-description">
        {STREAM_PRIVACY_OPTIONS.map(i => i.value === formData.status && i.label)}
      </p>

      {!!selectedCategory && (
        <>
          <label>Category</label>
          <p className="addition-description">{selectedCategory.label}</p>
        </>
      )}

      {!!formData.tags.length && (
        <>
          <label>Tags</label>
          <ul className="selected-tag-list">
            {formData.tags.map((tag: any, index: number) => (
              <li className="tag-item" key={index}>
                <span>{tag.label}</span>
              </li>
            ))}
          </ul>
        </>
      )}
    </Col>
  );
}

export default PreviewForm;
