import { Col, Row } from "react-flexbox-grid";
import { getAvatar } from "@Utils";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBanListData } from "@Store/selectors/video";
import { requestCommentsBan } from "@Store/actions/video";
import EmptyBanList from "./EmptyBanList";
import "./style.scss";

function BanList() {
  const banList = useSelector(getBanListData);
  const dispatch = useDispatch();

  const onBanUser = (userId: number) => {
    dispatch(requestCommentsBan(userId, false));
  };

  return (
    <section className="ban-list">
      <Row className="section-heading">
        <Col xs className="auto-column">
          <h1 className="main-heading">Ban List</h1>
        </Col>
      </Row>

      {!!Object.keys(banList).length ? (
        <>
          <Row className="heading-table">
            <Col xs className="heading-option name-coll">
              Name
            </Col>
            <Col className="heading-option date-coll">Date</Col>
            <Col className="heading-option action-coll">Action</Col>
          </Row>

          <div className="ban-all-list">
            {banList.map((user, index) => (
              <Row className="ban-item" key={index}>
                <Col xs className="name-coll">
                  <div className="user-item">
                    <Link to={`/streamer/${user.username}`} className="link-to-streamer">
                      {user.avatar ? (
                        <div
                          className="avatar"
                          style={{
                            backgroundImage: `url(${user.avatar.replace("[SIZE]", "32x32")})`
                          }}
                        />
                      ) : (
                        <div
                          className="avatar"
                          style={{
                            backgroundColor: getAvatar(user.full_name).color
                          }}
                        >
                          <span className="name-letters">
                            {getAvatar(user.full_name).shortLetters}
                          </span>
                        </div>
                      )}

                      <span>{user.full_name}</span>
                    </Link>
                  </div>
                </Col>
                <Col className="date-coll">
                  Jan 27, 2021
                  {/*{format(follower.created_at * 1000, "MMM d, yyyy")}*/}
                </Col>
                <Col className="action-coll">
                  <button
                    type="button"
                    className="round-action-button"
                    onClick={() => {
                      onBanUser(user.id);
                    }}
                  >
                    Unban
                  </button>
                </Col>
              </Row>
            ))}
          </div>
        </>
      ) : (
        <EmptyBanList />
      )}
    </section>
  );
}

export default BanList;
