import { propEq } from "ramda";
import { Redirect } from "react-router";

import { VIDEO_LIBRARY_SECTIONS } from "../../../constants";

type RootProfileSectionTypes = {
  match: { params: { sectionId: string } };
};

function RootVideoLibrarySection({
  match: {
    params: { sectionId }
  }
}: RootProfileSectionTypes) {
  const sectionIndex = VIDEO_LIBRARY_SECTIONS.findIndex(propEq("id", sectionId));

  if (sectionIndex !== -1) {
    return VIDEO_LIBRARY_SECTIONS[sectionIndex].component;
  }

  return (
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  );
}

export default RootVideoLibrarySection;
