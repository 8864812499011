import { Col, Row } from "react-flexbox-grid";
import { ExclusiveVideo } from "@Models/HomeData";
import VideoPostItem from "@Components/VideoPostItem";
import { ExclusiveBg } from "@Assets";

type Props = {
  exclusiveVideos: ExclusiveVideo[];
};

function Exclusive({ exclusiveVideos }: Props) {
  return (
    !!exclusiveVideos && (
      <section className="exclusive-content" style={{ backgroundImage: `url(${ExclusiveBg})` }}>
        <div className="full-container">
          <h2 className="section-title">Exclusive content </h2>
          <Row center="xs">
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="exclusive-description">
                <h4>Discover unique video content</h4>
                <p>
                  Get a taste of expert market analysis and insights by top finance professionals.
                  Watch first-class interviews and video content made exclusively on our platform.
                </p>
              </div>
            </Col>
            {exclusiveVideos.map(video => (
              <Col lg={3} md={3} sm={6} key={video.hash_id} className="exclusive-post-coll">
                <VideoPostItem {...video} />
              </Col>
            ))}
          </Row>
        </div>
      </section>
    )
  );
}

export default Exclusive;
