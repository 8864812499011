import authorizedApiRequest from "@Fetch/authorized";
import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { ServerResponse } from "@Models/RequestParams";
import { NOTIFICATIONS, setNotificationsState } from "@Store/actions/notifications";

export function* getUserNotifications() {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Notifications`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(setNotificationsState(data));
  }
}

export default function* notificationSagaWatcher() {
  yield all([takeEvery(NOTIFICATIONS.GET, getUserNotifications)]);
}
