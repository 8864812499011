type Props = {
  onUploadVideos: () => void;
};

function EmptyLibrary({ onUploadVideos }: Props) {
  return (
    <div className="empty-library">
      <i className="icon-folder-empty" />
      <p className="empty-message">You haven’t uploaded any videos</p>
      <button className="main-button" onClick={onUploadVideos}>
        <i className="icon-upload" />
        Upload
      </button>
    </div>
  );
}

export default EmptyLibrary;
