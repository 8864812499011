import { all, call, put, fork, take } from "redux-saga/effects";
import { AUTH_STATE, requestFetchInitialState } from "@Store/actions/app";

import appSaga from "./app";
import userSaga from "./user";
import videoSaga from "./video";
import adminSaga from "./admin";
import channelSaga from "./channel";
import playlistSaga from "./playlist";
import videoCommentsSaga from "./videoComments";
import notificationsSaga from "./notifications";
import pageSectionsSaga from "./pageSections";
import sidebarSaga from "./sidebar";
import searchSaga from "./search";
import socketSaga from "./socket";

function* rootSaga() {
  yield fork(socketSaga);

  yield put(requestFetchInitialState());

  yield take(AUTH_STATE.SOCKET_SUCCESS);

  yield fork(appSaga);

  // start watching modules
  yield all([
    call(userSaga),
    call(videoSaga),
    call(channelSaga),
    call(adminSaga),
    call(playlistSaga),
    call(videoCommentsSaga),
    call(notificationsSaga),
    call(pageSectionsSaga),
    call(sidebarSaga),
    call(searchSaga)
  ]);
}

export default rootSaga;
