import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useCallback, useEffect, useMemo, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { getAuthUserLoadedStreams, getAuthUserVideosPageInfo } from "@Store/selectors/video";
import { requestUserPlaylists } from "@Store/actions/playlist";
import { getSelectCategories } from "@Store/selectors/category";
import { getPlaylistsSelectList } from "@Store/selectors/playlist";
import { LoadingSpinner, VideoListItem } from "@Components";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import useRootRef from "@Hooks/useRootRef";
import { requestUserStreams } from "@Store/actions/video";

import LibraryHeader from "./LibraryHeader";
import EmptyStreams from "./EmptyStreams";
import classes from "classnames";

const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

function StreamsTab() {
  const dispatch = useDispatch();

  const streamsList = useSelector(getAuthUserLoadedStreams);
  const categoriesList = useSelector(getSelectCategories);
  const playlistsList = useSelector(getPlaylistsSelectList);
  const pageInfo = useSelector(getAuthUserVideosPageInfo);

  const [checkedVideos, setCheckedVideos] = useState<any>({});
  const [isPending, setIsPending] = useState(true);

  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);
  const [pageChooserInputValue, setPageChooserInputValue] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const rootRef = useRootRef();

  const checkedVideoIdsList = useMemo(
    () =>
      Object.keys(checkedVideos).reduce((acc: any, video_id) => {
        if (checkedVideos[video_id]) {
          return [...acc, Number(video_id)];
        }

        return acc;
      }, []),
    [checkedVideos]
  );

  const onChangeVideosPage = useCallback(
    (nextPAge: number, itemsPerPage = itemsPerPageCount.value) => {
      setIsPending(true);

      dispatch(
        requestUserStreams(nextPAge, itemsPerPage, {
          onSuccess: () => {
            setIsPending(false);
            rootRef?.scrollTo(0, 0);
            setActivePage(nextPAge - 1);
            setCheckedVideos({});
          },
          onError: () => {}
        })
      );
    },
    [itemsPerPageCount.value]
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(requestUserPlaylists());
      onChangeVideosPage(1);
    }, 200);
  }, []);

  useEffect(() => {
    setActivePage(pageInfo.currentPage - 1);
  }, [pageInfo.currentPage]);

  const onCheckVideo = (video_id: any) => () => {
    setCheckedVideos((prevstate: any) => ({
      ...prevstate,
      [video_id]: !prevstate[video_id]
    }));
  };

  const onCheckAllVideos = () => {
    const nextList = streamsList.reduce((acc, video) => {
      if (video.video_id) {
        return {
          ...acc,
          [video.video_id]: checkedVideoIdsList.length !== streamsList.length
        };
      }

      return acc;
    }, {});

    setCheckedVideos(nextList);
  };

  const onChangeItemsPerPageCount = (item: any) => {
    setItemsPerPageCount(item);
    onChangeVideosPage(1, item.value);
  };

  const onChangeItemsPage = () => {
    if (pageChooserInputValue <= pageInfo.totalPages && pageChooserInputValue >= 1) {
      setActivePage(pageChooserInputValue - 1);
      onChangeVideosPage(pageChooserInputValue);
    } else {
      setActivePage(pageInfo.totalPages - 1);
      onChangeVideosPage(pageInfo.totalPages);
      setPageChooserInputValue(pageInfo.totalPages);
    }
  };

  const onGoToPageType = (event: any) => {
    if (event.which === 13) {
      onChangeItemsPage();
    }
  };

  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {!!checkedVideoIdsList.length && (
        <LibraryHeader
          playlistsList={playlistsList.filter(i => i.value !== 0)}
          categoriesList={categoriesList}
          checkedItems={checkedVideoIdsList}
          perPage={itemsPerPageCount.value}
          setCheckedVideos={setCheckedVideos}
        />
      )}
      {!!streamsList.length ? (
        <Row className="heading">
          <Col className="check-option check-coll">
            <label
              className={classes("select-all-option", {
                ["partial-selected"]:
                  checkedVideoIdsList.length && checkedVideoIdsList.length !== streamsList.length
              })}
            >
              <input
                readOnly
                name="check"
                type="checkbox"
                checked={
                  !!checkedVideoIdsList.length && checkedVideoIdsList.length === streamsList.length
                }
              />
              <span className="label-text" onClick={onCheckAllVideos} />
            </label>
          </Col>
          <Col className="heading-option video-coll">Video</Col>
          <Col className="heading-option visibility-coll">Visibility</Col>
          <Col className="heading-option category-coll">Category</Col>
          <Col className="heading-option date-coll">Date</Col>
          <Col className="heading-option views-coll">Views</Col>
          <Col xs className="heading-option reactions-coll">
            Reactions
          </Col>
        </Row>
      ) : (
        <EmptyStreams />
      )}

      {streamsList.map((video: any, index: number) => (
        <VideoListItem
          {...video}
          key={index}
          isStreamEvent
          perPage={itemsPerPageCount.value}
          onCheck={onCheckVideo(video.video_id)}
          checked={!!checkedVideos[video.video_id]}
          editOnly={
            video.loadProgress ||
            (video?.converter?.processCodeUpdate && video.converter.processCodeUpdate !== 0)
          }
        />
      ))}

      <Row className="app-pagination">
        {pageInfo.totalResults > 10 && (
          <Col className="items-per-page-col">
            <span>Show</span>
            <Select
              classNamePrefix="app-select"
              menuPlacement="auto"
              placeholder="Assign Category"
              isSearchable={false}
              value={itemsPerPageCount}
              onChange={onChangeItemsPerPageCount}
              options={ITEMS_PER_PAGE_COUNT_OPTIONS}
            />
          </Col>
        )}

        {pageInfo.totalPages > 1 && (
          <>
            <Col xs>
              <ReactPaginate
                forcePage={activePage}
                pageCount={pageInfo.totalPages}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) => onChangeVideosPage(selected + 1)}
                activeClassName="active"
                pageClassName="page-number"
                containerClassName="pagination row"
                previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                nextLabel={<i className="icon icon-chevron-right nav-icon" />}
              />
            </Col>
            <Col className="go-to-page">
              <span>Go to page</span>
              <input
                type="number"
                onChange={({ target }: any) => {
                  setPageChooserInputValue(target.value || "");
                }}
                onKeyUp={onGoToPageType}
                value={pageChooserInputValue}
              />
              <button onClick={onChangeItemsPage}>
                <i className="icon-chevron-right" />
              </button>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default memo(StreamsTab);
