function EmptyStreams() {
  return (
    <div className="empty-library streams-empty">
      <i className="icon-stream" />
      <p className="empty-message">You haven’t stream any videos</p>
    </div>
  );
}

export default EmptyStreams;
