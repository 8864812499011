import ReactPaginate from "react-paginate";
import formatDate from "date-fns/format";
import { ITEMS_PER_PAGE_COUNT_OPTIONS } from "@Constants";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";
import { VideoModel } from "@Models/Video";
import { getAvatar } from "@Utils";
import { requestPlaylistVideos } from "@Store/actions/playlist";
import { PlaylistVideoItem, LoadingSpinner } from "@Components";
import Empty from "./Empty";
import "./style.scss";
import useRootRef from "@Hooks/useRootRef";

type Props = {
  playlist_id: number;
};

type PlaylistProps = {
  channel: {
    channel_id: number;
    slug: string;
    name: string;
    image: string;
  };
  created_at: number;
  updated_at: number;
  title: string;
  count: number;
  image: string | null;
  videos: VideoModel[];
};

const defaultPageInfo = {
  totalResults: 0,
  resultsPerPage: 0,
  currentPage: 1,
  totalPages: 0
};
const defaultItemsPerPage = ITEMS_PER_PAGE_COUNT_OPTIONS[0];

function PlaylistDetails({ playlist_id }: Props) {
  const dispatch = useDispatch();
  const rootRef = useRootRef();
  const [playlistDetails, setPlaylistDetails] = useState<PlaylistProps | null>(null);
  const channelAvatar = getAvatar(playlistDetails?.channel.name || "");
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPageCount, setItemsPerPageCount] = useState(defaultItemsPerPage);

  useEffect(() => {
    onChangePlaylistPage(1, itemsPerPageCount.value);
  }, []);

  const onChangePlaylistPage = useCallback(
    (nextPage: number, itemsPerPage: number) => {
      rootRef?.scrollTo(0, 0);
      dispatch(
        requestPlaylistVideos(
          playlist_id,
          nextPage,
          0,
          {
            onSuccess: ({ data, pageInfo }) => {
              setPlaylistDetails(data);
              setActivePage(nextPage - 1);
              setPageInfo(pageInfo);
            },
            onError: () => {}
          },
          itemsPerPage
        )
      );
    },
    [itemsPerPageCount.value]
  );

  if (!playlistDetails) {
    return (
      <section className="playlist-details">
        <LoadingSpinner />
      </section>
    );
  }

  const onChangeItemsPerPageCount = (item: any) => {
    onChangePlaylistPage(1, item.value);
    setItemsPerPageCount(item);
  };

  return (
    <section className="playlist-details">
      <Row className="playlist-main-heading">
        <Col xs={12} sm={12} md={4} className="playlist-main-heading-left">
          <div
            className="main-playlist-thumb"
            style={{
              backgroundImage: playlistDetails.image
                ? `url(${playlistDetails.image.replace("[SIZE]", "350x180")})`
                : ""
            }}
          >
            {!playlistDetails.image && <i className="icon-img-thumb" />}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} className="playlist-main-heading-right">
          <Row>
            <Col className="title-icon">
              <i className="icon-playlist-import" />
            </Col>
            <Col xs>
              <h4 className="title">{playlistDetails.title}</h4>
            </Col>
          </Row>
          <ul className="playlist-info">
            <li>
              <i className="icon-circle-play" /> {playlistDetails.count} videos
            </li>
            <li>
              <i className="icon-refresh-arrow" />
              {formatDate(playlistDetails.updated_at * 1000, "MMMM d, yyyy")}
            </li>
          </ul>

          <div className="account-details">
            <Link to={`/channel/${playlistDetails.channel.slug}`} className="link-to-channel">
              {playlistDetails.channel.image ? (
                <div
                  className="channel-avatar"
                  style={{
                    backgroundImage: `url(${playlistDetails.channel.image.replace(
                      "[SIZE]",
                      "70x70"
                    )})`
                  }}
                />
              ) : (
                <div
                  className="channel-avatar"
                  style={{
                    backgroundColor: channelAvatar.color
                  }}
                >
                  <span className="name-letters">{channelAvatar.shortLetters}</span>
                </div>
              )}
              <span>{playlistDetails.channel.name}</span>
            </Link>
          </div>
        </Col>
      </Row>

      <Row className="playlist-heading-table">
        <Col className="heading-option nr-order">#</Col>
        <Col xs className="heading-option video-coll">
          Video
        </Col>
      </Row>

      <Row className="videos-container videos-list">
        {(!!playlistDetails.videos.length && (
          <>
            {playlistDetails.videos.map((video: VideoModel, index: number) => (
              <PlaylistVideoItem {...video} index={++index} key={video.video_id} />
            ))}
            <Row className="app-pagination" between="xs">
              {pageInfo.totalResults > 10 && (
                <Col className="items-per-page-col">
                  <span>Show</span>
                  <Select
                    classNamePrefix="app-select"
                    menuPlacement="auto"
                    placeholder="Assign Category"
                    isSearchable={false}
                    value={itemsPerPageCount}
                    onChange={onChangeItemsPerPageCount}
                    options={ITEMS_PER_PAGE_COUNT_OPTIONS}
                  />
                </Col>
              )}

              {pageInfo.totalPages > 1 && (
                <>
                  <Col>
                    <ReactPaginate
                      forcePage={activePage}
                      pageCount={pageInfo.totalPages}
                      pageRangeDisplayed={6}
                      marginPagesDisplayed={1}
                      onPageChange={({ selected }) =>
                        onChangePlaylistPage(selected + 1, itemsPerPageCount.value)
                      }
                      activeClassName="active"
                      pageClassName="page-number"
                      containerClassName="pagination row"
                      previousLabel={<i className="icon icon-chevron-left nav-icon" />}
                      nextLabel={<i className="icon icon-chevron-right nav-icon" />}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        )) || <Empty />}
      </Row>
    </section>
  );
}

export default PlaylistDetails;
