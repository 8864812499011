import React, { useEffect, useState, Fragment } from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

interface IProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
}

type BreadcrumbItem = {
  label: string;
  link: string | null;
};

const PageWrapper = ({ title, children, className }: IProps): JSX.Element => {
  const sectionClassName = cn("full-container", className);
  const [breadcrumbMiddleware, setBreadcrumbMiddleware] = useState<BreadcrumbItem[]>([]);
  const { pathname, search } = useLocation();
  const filteredTitle = title ? title.replace("/", "").replace("-", " ") : "";

  useEffect(() => {
    setBreadcrumbMiddleware([]);
    const subcategory = new URLSearchParams(search).get("subcategory");
    const category = new URLSearchParams(search).get("category");

    if (pathname == "/categories/channels") {
      setBreadcrumbMiddleware([{ label: "Channels", link: "/categories/channels?category=all" }]);
    }

    if (subcategory) {
      setBreadcrumbMiddleware([
        {
          label: category ? category.replace("-", " ") : "",
          link: null
        }
      ]);
    }
  }, [pathname, search]);

  return (
    <div className="page-content">
      <section className={sectionClassName}>
        <div className="breadcrumbs-block">
          <Link to={`/`} className="breadcrumbs-block-link">
            Home
          </Link>
          <span>/</span>
          {breadcrumbMiddleware.map((item, i) => (
            <Fragment key={i}>
              {item.link ? (
                <Link to={item.link} className="breadcrumbs-block-link">
                  {item.label}
                </Link>
              ) : (
                <span key={i} className="breadcrumbs-block-link">
                  {item.label}
                </span>
              )}
              <span>/</span>
            </Fragment>
          ))}
          <Link to={`/categories/videos?category=${title}`} className="breadcrumbs-block-link">
            {filteredTitle.replace("-", " ")}
          </Link>
        </div>
        {title && <h2 className="page-title">{filteredTitle.replace("-", " ")}</h2>}
        {children}
      </section>
    </div>
  );
};

export default PageWrapper;
