import "./style.scss";

function ReportProblemArticle() {
  return (
    <article className="help-article">
      <h2 className="article-title">How to report a problem in ShareVision?</h2>
      <p className="big-topic">
        We work around the clock to improve ShareVision and we’re sorry if you have found a problem
        to report.
      </p>
      <p className="description">
        If you face any problems with using the platform, or if you want to report any content you
        have seen on the platform, you can get in touch directly with the team on
        <a href="mailto:support@sharevision.com"> support@sharevision.com</a>.
      </p>

      <p className="description top">
        When getting in touch, please elaborate on the problem experienced in as much detail as
        possible, providing screenshots if applicable. Doing this will enable us to fix your problem
        in the shortest possible time. We aim to get back to you within 24 hours of receiving your
        email.
      </p>

      <p className="description top">
        If you need further support, you can send an email to{" "}
        <a href="mailto:support@sharevision.com"> support@sharevision.com</a> where someone will
        assist you.
      </p>
    </article>
  );
}

export default ReportProblemArticle;
