import { Doughnut } from "react-chartjs-2";
import { hexToRgba } from "@Utils";
import { customTooltips } from "../tooltip";

type ProgressItemProps = {
  color: string;
  value: number;
  label: string;
};

type ChartProps = {
  data: ProgressItemProps[];
  allItems: number;
  isVideoMode: boolean;
  radius?: number;
};

function ChartDoughnut({ data, allItems, isVideoMode, radius = 45 }: ChartProps) {
  const chartData = {
    datasets: [
      {
        borderWidth: 0,
        data: data.map(i => i.value),
        backgroundColor: data.map(i => i.color),
        hoverBackgroundColor: data.map(i => hexToRgba(i.color, 0.7))
      }
    ],
    labels: data.map(i => i.label)
  };

  const options = {
    cutout: radius,
    plugins: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltip: {
        yPadding: 10,
        xPadding: 15,
        enabled: false,
        callbacks: {
          title: (tooltipItem: any) => tooltipItem[0].label,
          label: (tooltipItem: any) => {
            return ` ${tooltipItem.parsed} ${isVideoMode ? "Videos" : tooltipItem.label} ( ${(
              (tooltipItem.parsed * 100) /
              allItems
            ).toFixed(0)}% )`;
          }
        },
        external: customTooltips
      }
    }
  };

  return <Doughnut data={chartData} options={options} type="doughnut" />;
}

export default ChartDoughnut;
