function EmptyBanList() {
  return (
    <div className="empty-ban-list">
      <i className="icon-stop-hand" />
      <p className="empty-message">Your Ban-list is empty</p>
    </div>
  );
}

export default EmptyBanList;
