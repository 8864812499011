import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useDispatch, useSelector } from "react-redux";
import MainSlider from "./components/MainSlider";
import TopRecommended from "./components/TopRecomended";
import TopVideosWeek from "./components/TopVideosWeek";
import TopCreators from "./components/TopCreators";
import BecomeStreamer from "./components/BecomeStreamer";
import { getAuthUser } from "@Store/selectors/user";
import { requestHomePageData } from "@Store/actions/pageSections";
import { HomeData } from "@Models/HomeData";
import useRootRef from "@Hooks/useRootRef";
import Exclusive from "./components/Exclusive";
import { useHistory } from "react-router-dom";
import { updateUserDetails } from "@Store/actions/user";
import { updateAuthUserLocalStorage } from "@Utils";
import "./style.scss";
import Partners from "@Views/HomePage/components/Partners";
import AuthModule, { AuthModal } from "@Components/AuthModule";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function Homepage() {
  const userDetails = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState({} as HomeData);
  const [authModal, setAuthModal] = useState("" as AuthModal);
  const rootRef = useRootRef();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      requestHomePageData({
        onSuccess: (homeData: any) => {
          setHomeData(homeData);
        },
        onError: () => {}
      })
    );
    switch (history.location.search) {
      case "?activation=ok":
        toast.success("Your account has been activated!");
        dispatch(
          updateUserDetails({
            email_confirmed: true
          })
        );
        updateAuthUserLocalStorage({ email_confirmed: true });
        break;
      case "?activation=activated":
        toast.error("Account already activated!");
        break;
      case "?activation=error":
        toast.error("Activation link has expired!");
    }
    history.location.search.split("=")[0] === "?reset-password-token" &&
      setAuthModal("RESET_PASSWORD");
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  return (
    <div className="page-content home">
      <MainSlider />
      <TopRecommended recommendedVideos={homeData.recommended} />
      <div className="top-videos-creators-container">
        <div className="full-container">
          <Row className="top-videos-creators-container-block">
            <Col xs={12} sm={12} md lg className="top-videos-creators-container-block-item">
              <TopVideosWeek topVideos={homeData.topVideos} />
            </Col>
            <Col className="creators-coll">
              <TopCreators topCreators={homeData.topCreators} />
            </Col>
          </Row>
        </div>
      </div>
      {homeData.exclusive && !!homeData.exclusive.length && (
        <Exclusive exclusiveVideos={homeData.exclusive} />
      )}
      {userDetails.role !== "creator" && <BecomeStreamer />}
      <Partners />
      {!!authModal.length && <AuthModule modal={authModal} onModalClose={setAuthModal} />}
    </div>
  );
}

export default Homepage;
