import { createNamespace } from "@Utils";
import { RequestCallbacks } from "@Models/RequestParams";
import { Filters } from "@Models/Filters";
import { PublicLiveRoomMessage, StartLivePayload, StreamSetup } from "@Models/LiveStream";

export const VIDEO = createNamespace("VIDEO", [
  "GET_BY_ID",
  "GET_STREAM_BY_ID",
  "GET_STREAM_PREVIEW_SOURCE",
  "GET_DETAILS",
  "GET_COUNTRIES",
  "GET_SECTORS",
  "GET_CURRENCIES",
  "GET_CHILDREN_DROPDOWN",
  "SET_DETAILS",
  "SET_STREAM_DETAILS",
  "SET_STREAM_KEY",
  "LOAD_USER_VIDEOS",
  "LOAD_USER_STREAMS",
  "LOCAL_UPLOAD",
  "YOUTUBE_UPLOAD",
  "UPDATE_DETAILS",
  "UPDATE_STREAM_DETAILS",
  "MASS_DETAILS_UPDATE",
  "MASS_STREAMS_DETAILS_UPDATE",
  "CANCEL_UPLOAD",
  "DELETE_VIDEOS_LIST",
  "SEARCH_TAGS_BY_QUERY",
  "SUBSCRIBE_CONVERTER_UPDATES",
  "SUBSCRIBE_PUBLIC_LIVE_UPDATES",
  "SUBSCRIBE_RTC_UPDATES",
  "UNSUBSCRIBE_RTC_UPDATES",
  "RTC_UPDATES",
  "RTC_UPDATER",
  "RTC_LIVE_CREATE_ROOM",
  "RTC_LIVE_INIT_ROOM",
  "RTC_LIVE_REMOVE_ROOM",
  "PUBLIC_LIVE_STATUS_EMMIT",
  "REQUEST_START_LIVE",
  "REQUEST_STOP_LIVE",
  "UNSUBSCRIBE_CONVERTER_UPDATES",
  "UNSUBSCRIBE_PUBLIC_LIVE_UPDATES",
  "REQUEST_UPDATE_DETAILS",
  "REQUEST_USER_VIDEOS",
  "REQUEST_USER_STREAMS",
  "REQUEST_YOUTUBE_PLAYLISTS",
  "REQUEST_LIKE_STATUS_CHANGE",
  "REQUEST_UPDATE_VISIBILITY",
  "REQUEST_UPDATE_CATEGORY",
  "REQUEST_UPDATE_PLAYLIST",
  "REQUEST_CATEGORY_VIDEOS",
  "THUMBNAIL_UPLOAD",
  "PUBLIC_LIVE_WATCHERS",
  "CREATE_STREAM",
  "UPDATE_STREAM",
  "SAVE_STREAM"
]);

export const COMMENTS = createNamespace("COMMENTS", [
  "GET",
  "ADD",
  "UPDATE",
  "ADD_REPLY",
  "GET_REPLIES",
  "DELETE",
  "LIKE_STATUS_CHANGE",
  "MARK_AS_READ_BY_VIDEO",
  "GET_NEW_COMMENTED_VIDEOS",
  "REQUEST_BAN",
  "GET_BAN_LIST",
  "SET_BAN_LIST"
]);

export const VIDEO_SOCKET_RESPONSE: any = {
  GET_CONVERTER_UPDATES: "GET_VIDEO_SUBSCRIBE_CONVERTER_UPDATES",
  GET_RTC_UPDATES: "GET_VIDEO_SUBSCRIBE_RTC_UPDATES",
  GET_SUBSCRIBE_PUBLIC_LIVE_UPDATES: "GET_VIDEO_SUBSCRIBE_PUBLIC_LIVE_UPDATES"
};

export const requestVideoById = (video_id: string, callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_BY_ID,
  payload: { video_id },
  callbacks
});

export const requestStreamPreviewById = (video_id: string, callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_STREAM_BY_ID,
  payload: { video_id },
  callbacks
});

export const requestStreamPreviewRtcSource = (
  rtcAPI: string,
  data: any,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.GET_STREAM_PREVIEW_SOURCE,
  payload: { rtcAPI, data },
  callbacks
});

export const requestVideoDetails = (video_id: number, callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_DETAILS,
  payload: { video_id },
  callbacks
});

export const requestChildrenDropdown = (
  selectedIds: number[],
  type: string,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.GET_CHILDREN_DROPDOWN,
  payload: { selectedIds, type },
  callbacks
});

export const requestTopicCountries = (callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_COUNTRIES,
  callbacks
});

export const requestTopicSectors = (callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_SECTORS,
  callbacks
});

export const requestTopicCurrencies = (callbacks: RequestCallbacks) => ({
  type: VIDEO.GET_CURRENCIES,
  callbacks
});

export const setNewVideoDetails = (details: any) => ({
  type: VIDEO.SET_DETAILS,
  payload: details
});

export const setNewStreamDetails = (details: any) => ({
  type: VIDEO.SET_STREAM_DETAILS,
  payload: details
});

export const setBanListState = (payload: any) => ({
  type: COMMENTS.SET_BAN_LIST,
  payload
});

export const requestYoutubeVideos = (youtubeLink: string, callbacks: RequestCallbacks) => ({
  type: VIDEO.REQUEST_YOUTUBE_PLAYLISTS,
  payload: { youtubeLink },
  callbacks
});

export const requestUserVideos = (
  nextPage = 1,
  itemsPerPage = 10,
  callbacks?: RequestCallbacks,
  searchText?: string,
  filters?: Filters
) => ({
  type: VIDEO.REQUEST_USER_VIDEOS,
  payload: { nextPage, itemsPerPage, searchText, filters },
  callbacks
});

export const requestUserStreams = (
  nextPage = 1,
  itemsPerPage = 10,
  callbacks?: RequestCallbacks
) => ({
  type: VIDEO.REQUEST_USER_STREAMS,
  payload: { nextPage, itemsPerPage },
  callbacks
});

export const requestUploadVideoThumbnail = (
  file: File,
  video_id: number,
  typeVideo: "stream" | "video",
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.THUMBNAIL_UPLOAD,
  payload: { file, video_id, typeVideo },
  callbacks
});

export const loadUserVideos = (list: any[], pageInfo: any) => ({
  type: VIDEO.LOAD_USER_VIDEOS,
  payload: { list, pageInfo }
});

export const loadUserStreams = (list: any[], pageInfo: any) => ({
  type: VIDEO.LOAD_USER_STREAMS,
  payload: { list, pageInfo }
});

export const uploadYoutubeVideos = (list: any) => ({
  type: VIDEO.YOUTUBE_UPLOAD,
  payload: { list }
});

export const cancelUploadVideosList = (idsList: number[]) => ({
  type: VIDEO.CANCEL_UPLOAD,
  payload: { idsList }
});

export const deleteVideosList = (
  idsList: number[],
  isStream: boolean,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.DELETE_VIDEOS_LIST,
  payload: { idsList, isStream },
  callbacks
});

export const uploadLocalVideos = (list: any) => ({
  type: VIDEO.LOCAL_UPLOAD,
  payload: { list }
});

export const requestUpdateVideoDetails = (
  details: any,
  video_id: number,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.REQUEST_UPDATE_DETAILS,
  payload: { details, video_id },
  callbacks
});

export const updateVideoDetails = (
  details: any,
  video_id: number,
  typeVideo: "stream" | "video"
) => ({
  type: VIDEO.UPDATE_DETAILS,
  payload: { details, video_id, typeVideo }
});

export const updateVideoListDetails = (details: any, ids_list: number[]) => ({
  type: VIDEO.MASS_DETAILS_UPDATE,
  payload: { details, ids_list }
});
export const updateStreamsListDetails = (details: any, ids_list: number[]) => ({
  type: VIDEO.MASS_STREAMS_DETAILS_UPDATE,
  payload: { details, ids_list }
});

export const subscribeVideoConverterUpdates = () => ({
  type: VIDEO.SUBSCRIBE_CONVERTER_UPDATES,
  socket: {
    subscribe: true,
    topic: "converter"
  }
});

export const subscribeLivePublicUpdates = (
  video_hash: string,
  onUpdate: (socketData: any) => void
) => ({
  type: VIDEO.SUBSCRIBE_PUBLIC_LIVE_UPDATES,
  socket: {
    subscribe: true,
    topic: video_hash,
    onUpdate
  }
});

export const getWatchersCount = (video_hash: string, userCountUpdate: (count: number) => void) => ({
  type: VIDEO.PUBLIC_LIVE_WATCHERS,
  socket: {
    topic: video_hash,
    watchers: video_hash,
    userCountUpdate
  }
});

export const unsubscribeLivePublicUpdates = (video_hash: string) => ({
  type: VIDEO.UNSUBSCRIBE_PUBLIC_LIVE_UPDATES,
  socket: {
    subscribe: false,
    topic: video_hash
  }
});

export const subscribeRTCUpdates = () => ({
  type: VIDEO.SUBSCRIBE_RTC_UPDATES,
  socket: {
    subscribe: true,
    topic: "rtc"
  }
});

export const unsubscribeRTCUpdates = () => ({
  type: VIDEO.UNSUBSCRIBE_RTC_UPDATES,
  socket: {
    subscribe: false,
    topic: "rtc"
  }
});

export const sendRTCUpdater = (key: string) => ({
  type: VIDEO.RTC_UPDATER,
  socket: {
    topic: "rtc",
    updateRTCStatusKey: key
  }
});

export const setStreamManagerKey = (key: string) => ({
  type: VIDEO.SET_STREAM_KEY,
  payload: { key }
});
export const updateSaveStreamOption = (saveStream: boolean) => ({
  type: VIDEO.SAVE_STREAM,
  payload: { saveStream }
});

export const createLiveStreamRoom = (videoHashId: string) => ({
  type: VIDEO.RTC_LIVE_CREATE_ROOM,
  socket: {
    topic: "rtc",
    newRoomLive: videoHashId
  }
});

export const initLiveStreamRoom = (data: StartLivePayload) => ({
  type: VIDEO.RTC_LIVE_INIT_ROOM,
  socket: {
    topic: "rtc",
    startLiveRoomPayload: data
  }
});

export const emmitStatusPublicStreamRoom = (
  streamStatus: PublicLiveRoomMessage,
  hash_id: string
) => ({
  type: VIDEO.PUBLIC_LIVE_STATUS_EMMIT,
  socket: {
    topic: hash_id,
    streamStatus
  }
});

export const removeLiveStreamRoom = (videoHashId: string) => ({
  type: VIDEO.RTC_LIVE_REMOVE_ROOM,
  socket: {
    topic: "rtc",
    removeRoomLive: videoHashId
  }
});

export const updateRTCStatus = (status: boolean) => ({
  type: VIDEO.RTC_UPDATES,
  payload: { status }
});

export const unsubscribeVideoConverterUpdates = () => ({
  type: VIDEO.UNSUBSCRIBE_CONVERTER_UPDATES,
  socket: {
    subscribe: false,
    topic: "converter"
  }
});

export const requestChangeVideoLikeStatus = (
  video_id: number,
  next_status: string,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.REQUEST_LIKE_STATUS_CHANGE,
  payload: { video_id, next_status },
  callbacks
});

export const requestChangeVideosCategoryData = (
  categoriesManager: { [key: string]: number[] },
  ids_list: number[],
  isStream: boolean
) => ({
  type: VIDEO.REQUEST_UPDATE_CATEGORY,
  payload: { categoriesManager, ids_list, isStream }
});

export const requestChangeVideosVisibility = (
  status_id: number,
  ids_list: number[],
  isStream: boolean
) => ({
  type: VIDEO.REQUEST_UPDATE_VISIBILITY,
  payload: { status_id, ids_list, isStream }
});

export const requestChangeVideosPlaylist = (playlist_id: number, ids_list: number[]) => ({
  type: VIDEO.REQUEST_UPDATE_PLAYLIST,
  payload: { playlist_id, ids_list }
});

export const requestStartLive = (
  video_id: number,
  hash_id: string,
  channel_id: number,
  streamer_id: number,
  streamKey: number,
  liveType: number
) => ({
  type: VIDEO.REQUEST_START_LIVE,
  payload: { video_id, hash_id, channel_id, streamer_id, streamKey, liveType }
});

export const requestStopLive = (video_id: number, hash_id: string) => ({
  type: VIDEO.REQUEST_STOP_LIVE,
  payload: { video_id, hash_id }
});

export const requestCategoryVideos = (
  {
    category,
    subcategory,
    tag,
    appliedFilters
  }: { category?: string; tag?: string; subcategory?: string; appliedFilters?: any },
  page: number,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.REQUEST_CATEGORY_VIDEOS,
  payload: { category, subcategory, tag, appliedFilters, page },
  callbacks
});

export const searchVideoTagsByQuery = (query: string, callbacks: RequestCallbacks) => ({
  type: VIDEO.SEARCH_TAGS_BY_QUERY,
  payload: { query },
  callbacks
});

export const createStreamEvent = (streamPayload: any, callbacks: RequestCallbacks) => ({
  type: VIDEO.CREATE_STREAM,
  payload: { streamPayload },
  callbacks
});

export const updateStreamEvent = (
  streamPayload: StreamSetup,
  video_id: number,
  callbacks: RequestCallbacks
) => ({
  type: VIDEO.UPDATE_STREAM,
  payload: { streamPayload, video_id },
  callbacks
});

export const getVideoComments = (
  payload: { video_id: number; nextPage: number; itemsPerPage: number; type: string },
  callbacks: RequestCallbacks
) => ({
  type: COMMENTS.GET,
  payload,
  callbacks
});

export const getVideoCommentReplies = (
  video_id: number,
  nextPage: number,
  comment_id: number,
  callbacks: RequestCallbacks
) => ({
  type: COMMENTS.GET_REPLIES,
  payload: { video_id, nextPage, comment_id },
  callbacks
});

export const addVideoComment = (
  video_id: number,
  channel_id: number,
  depth: number,
  value: string,
  callbacks: RequestCallbacks
) => ({
  type: COMMENTS.ADD,
  payload: { video_id, channel_id, value, depth },
  callbacks
});

export const addVideoCommentReply = (
  video_id: number,
  channel_id: number,
  comment_id: number,
  depth: number,
  value: string,
  callbacks: RequestCallbacks
) => ({
  type: COMMENTS.ADD_REPLY,
  payload: { video_id, channel_id, comment_id, value, depth },
  callbacks
});

export const deleteVideoComments = (ids_list: number[], callbacks: RequestCallbacks) => ({
  type: COMMENTS.DELETE,
  payload: { ids_list },
  callbacks
});

export const updateComment = (comment_id: number, value: string, callbacks: RequestCallbacks) => ({
  type: COMMENTS.UPDATE,
  payload: { comment_id, value },
  callbacks
});

export const requestChangeCommentLikeStatus = (
  comment_id: number,
  next_status: string,
  callbacks: RequestCallbacks
) => ({
  type: COMMENTS.LIKE_STATUS_CHANGE,
  payload: { comment_id, next_status },
  callbacks
});

export const getVideosWithNewComments = (activeTab: string, callbacks: RequestCallbacks) => ({
  type: COMMENTS.GET_NEW_COMMENTED_VIDEOS,
  payload: { activeTab },
  callbacks
});

export const markAsReadVideoWithNewComments = (video_id: number, isOwn: boolean) => ({
  type: COMMENTS.MARK_AS_READ_BY_VIDEO,
  payload: { video_id, isOwn }
});

export const requestCommentsBan = (userId: number, isBan: boolean) => ({
  type: COMMENTS.REQUEST_BAN,
  userId,
  isBan
});

export const getBanList = () => ({
  type: COMMENTS.GET_BAN_LIST
});
