import { Link } from "react-router-dom";
import { Col } from "react-flexbox-grid";
import { StarsRate } from "@Components";
import { abbreviateNumber, getAvatar } from "@Utils";
import { BaseChannel } from "@Models/Channel";
import styles from "./index.module.scss";

type IProps = BaseChannel;

const ChannelCard = ({ name, slug, image, followers, is_live }: IProps): JSX.Element => {
  const channelAvatarAlt = getAvatar(name);

  return (
    <Col xs>
      <div className={styles.container}>
        <Link to={`/channel/${slug}`}>
          {(image && (
            <div className={styles.avatarContainer}>
              {is_live && (
                <div className={styles.liveBadge}>
                  <div className={`${styles.liveBeep} live-beep`} />
                </div>
              )}
              <div
                className={styles.thumb}
                style={{ backgroundImage: `url(${image.replace("[SIZE]", "200x200")})` }}
              />
            </div>
          )) || (
            <div className={styles.avatarContainer}>
              {is_live && (
                <div className={styles.liveBadge}>
                  <div className={`${styles.liveBeep} live-beep`} />
                </div>
              )}
              <div className={styles.thumb} style={{ backgroundColor: channelAvatarAlt.color }}>
                <span className={styles.nameLetters}>{channelAvatarAlt.shortLetters}</span>
              </div>
            </div>
          )}
        </Link>

        <h3 className={styles.title}>{name}</h3>
        <div className={styles.subscriptions}>
          <i className="icon-users-group" />
          <span>{abbreviateNumber(Number(followers))}</span>
        </div>
        <StarsRate rate={4.5} />
      </div>
    </Col>
  );
};

export default ChannelCard;
