import { all, takeEvery, put, call } from "redux-saga/effects";
import authorizedApiRequest from "@Fetch/authorized";
import { RequestCallbacks, ServerResponse } from "@Models/RequestParams";

import { COMMENTS, setBanListState } from "@Store/actions/video";

function* getVideoComments({
  payload: { video_id, nextPage, itemsPerPage, type },
  callbacks
}: {
  payload: { video_id: number; type: string; nextPage: number; itemsPerPage: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/${type}/${video_id}?page=${nextPage}&limit=${itemsPerPage}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* addCommentToVideo({
  payload: { video_id, channel_id, depth, value },
  callbacks
}: {
  payload: { video_id: number; channel_id: number; depth: number; value: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/comment/${video_id}`,
    {
      method: "POST",
      body: JSON.stringify({
        text: value,
        reply_to: 0,
        channel_id,
        depth
      })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* getCommentReplies({
  payload: { video_id, nextPage, comment_id },
  callbacks
}: {
  payload: { video_id: number; nextPage: number; comment_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/comments/${video_id}/${comment_id}?page=${nextPage}&limit=10`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* addReplyToComment({
  payload: { video_id, channel_id, comment_id, depth, value },
  callbacks
}: {
  payload: {
    video_id: number;
    channel_id: number;
    comment_id: number;
    depth: number;
    value: string;
  };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/comment/${video_id}`,
    {
      method: "POST",
      body: JSON.stringify({
        text: value,
        channel_id,
        reply_to: comment_id,
        depth
      })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* updateVideoComment({
  payload: { comment_id, value },
  callbacks
}: {
  payload: { comment_id: number; value: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/comment/${comment_id}`,
    {
      method: "PUT",
      body: JSON.stringify({
        text: value
      })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  }
}

function* deleteComment({
  payload: { ids_list: list },
  callbacks
}: {
  payload: { ids_list: number[] };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(authorizedApiRequest, `/streams/comment`, {
    method: "DELETE",
    body: JSON.stringify({ list })
  });

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  }
}

function* changeCommentLikeStatus({
  payload: { next_status, comment_id },
  callbacks
}: {
  payload: { next_status: string; comment_id: number };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/CommentsLikeDislike/${comment_id}`,
    {
      method: "PUT",
      body: JSON.stringify({ type: next_status })
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* getUserNewCommentedVideos({
  callbacks,
  payload: { activeTab }
}: {
  payload: { activeTab: string };
  callbacks: RequestCallbacks;
}) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/VideoComments/${activeTab}`,
    {
      method: "GET"
    }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* markAsReadVideoComments({
  payload: { video_id, isOwn }
}: {
  payload: { video_id: number; isOwn: boolean };
}) {
  yield call(
    authorizedApiRequest,
    `/streams/${isOwn ? "OwnMarkAsRead" : "markAsRead"}/${video_id}`,
    {
      method: "PUT"
    }
  );
}

function* getBanList() {
  const serverResponse = yield call(authorizedApiRequest, `/streams/MyBanList`, {
    method: "GET"
  });

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();
    yield put(setBanListState(data));
  }
}

function* requestCommentsBan({
  userId,
  isBan
}: {
  userId: number;
  isBan: boolean;
  onSuccess: () => void;
}) {
  const serverResponse = yield call(
    authorizedApiRequest,
    `/streams/${isBan ? "Ban" : "UnBan"}/${userId}`,
    {
      method: "PUT"
    }
  );

  if (serverResponse.status === 200) {
    yield call(getBanList);
  }
}

export default function* videoCommentsSagaWatcher() {
  yield all([
    takeEvery(COMMENTS.GET_REPLIES, getCommentReplies),
    takeEvery(COMMENTS.ADD_REPLY, addReplyToComment),
    takeEvery(COMMENTS.UPDATE, updateVideoComment),
    takeEvery(COMMENTS.GET, getVideoComments),
    takeEvery(COMMENTS.ADD, addCommentToVideo),
    takeEvery(COMMENTS.DELETE, deleteComment),
    takeEvery(COMMENTS.LIKE_STATUS_CHANGE, changeCommentLikeStatus),
    takeEvery(COMMENTS.GET_NEW_COMMENTED_VIDEOS, getUserNewCommentedVideos),
    takeEvery(COMMENTS.MARK_AS_READ_BY_VIDEO, markAsReadVideoComments),
    takeEvery(COMMENTS.REQUEST_BAN, requestCommentsBan),
    takeEvery(COMMENTS.GET_BAN_LIST, getBanList)
  ]);
}
