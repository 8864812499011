import formatDistance from "date-fns/formatDistance";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestDeshboardTopComments } from "@Store/actions/pageSections";
import { LoadingSpinner } from "@Components";
import { getAvatar } from "@Utils";

type CommentData = {
  created_at: number;
  text: string;
  video: { title: string; hash_id: string };
  streamer: { avatar: string | null; username: string; full_name: string };
};

function CommentsWidget() {
  const dispatch = useDispatch();

  const [data, setData] = useState<CommentData[] | null>(null);

  useEffect(() => {
    dispatch(
      requestDeshboardTopComments({
        onSuccess: setData,
        onError: () => {}
      })
    );
  }, []);

  if (!data) {
    return (
      <div className="widget comments">
        <div className="content">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="widget comments">
      <div className="content">
        {data.length ? (
          <>
            <Link className="all-option" to="/profile/comments">
              All
            </Link>
            <h3 className="widget-title">Comments</h3>
            <ul className="comments-list">
              {data.map((comment: CommentData, index: number) => {
                const ownerAvatarAlt = getAvatar(comment.streamer.full_name);

                return (
                  <li className="comment-item" key={index}>
                    <Link to={`/streamer/${comment.streamer.username}`} className="link-to-user">
                      {comment.streamer.avatar ? (
                        <div
                          className="avatar"
                          style={{
                            backgroundImage: `url(${comment.streamer.avatar.replace(
                              "[SIZE]",
                              "50x50"
                            )})`
                          }}
                        />
                      ) : (
                        <div
                          className="avatar"
                          style={{
                            backgroundColor: ownerAvatarAlt.color
                          }}
                        >
                          <span className="name-letters">{ownerAvatarAlt.shortLetters}</span>
                        </div>
                      )}
                    </Link>
                    <div className="user-inline-data">
                      <div className="top-data">
                        <span>{comment.streamer.full_name}</span>
                        <span>{formatDistance(comment.created_at * 1000, new Date())}</span>
                      </div>
                      <div className="comment-body">{comment.text}</div>
                      <Link className="video-title" to={`/video/${comment.video.hash_id}`}>
                        <i className="icon-return-arrow" />
                        {comment.video.title}
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <div className="no-data-comments">
            <i className="icon-chat-message-o" />
            <h3>No comments to display</h3>
            <p>Most recent comments left to your videos will be displayed here</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommentsWidget;
