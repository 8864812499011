import { Row } from "react-flexbox-grid";
import { Link, useLocation } from "react-router-dom";
import { MenuSubcategory } from "@Models/MenuSubcategory";
import classes from "classnames";
import { useMemo } from "react";
import searchQueryParam from "@Utils/searchQueryParam";

interface ISubcategory extends MenuSubcategory {
  baseCategorySlug: string | undefined;
}
const Subcategory = ({ name, slug, baseCategorySlug }: ISubcategory) => {
  const location = useLocation();
  const isSubcategorySelected = useMemo(
    () =>
      searchQueryParam(
        "subcategory",
        location.search,
        baseCategorySlug ? `?category=${baseCategorySlug}&subcategory=${slug}` : ""
      ),
    [location, baseCategorySlug]
  );

  return (
    <Row middle="xs" className="dm-directory-row">
      <Link
        to={`/categories/videos?category=${baseCategorySlug}&subcategory=${slug}`}
        className={classes("dm-directory-category-name", {
          ["active"]:
            new URLSearchParams(location.search).get("category") === baseCategorySlug &&
            isSubcategorySelected
        })}
        onClick={() => {}}
      >
        {name}
      </Link>
    </Row>
  );
};

export default Subcategory;
