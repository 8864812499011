import classes from "classnames";
import { useEffect, useState, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { propEq, prop } from "ramda";
import { useForm } from "@Hooks";
import {
  requestUpdateVideoDetails,
  requestVideoDetails,
  requestUploadVideoThumbnail
} from "@Store/actions/video";
import { getSelectCategories } from "@Store/selectors/category";
import { getPlaylistsSelectList } from "@Store/selectors/playlist";
import { ProgressBar, VideoPlayer } from "@Components";
import { VIDEO_PROCESSING_CODES } from "@Constants";
import { VIDEO_PLAYER_SETTINGS, VIDEO_PRIVACY_OPTIONS } from "./constants";
import { AppSelect } from "@Models/AppSelect";
import PermissionsForm from "./PermissionsForm";
import DetailsForm from "./DetailsForm";
import "./style.scss";

interface EditVideoModalTypes {
  title: string;
  thumb: string | null;
  filename: string | null;
  video_id?: number;
  video_url?: string;
  loadProgress?: number;
  onClose: Function;
  converter?: { processCodeUpdate?: 0 | 1 | 2 | 3; progress?: number };
}

type ACTIVE_FORM_TYPES = "DETAILS" | "PERMISSIONS";
type ThumbsPreviewTypes = { value: string; preview: string }[];

const defaultFormData = {
  title: "",
  description: "",
  tags: [],
  status: VIDEO_PRIVACY_OPTIONS[0].value,
  thumbsList: [],
  category: {},
  subcategories: [],
  countries: [],
  currency: [],
  sectors: [],
  loadedThumb: null,
  enable_comments: false
};

const formatThumbsForPreview = (thumbs: any, selectedThumb: string | null) => {
  const thumbKeys = Object.keys(thumbs);

  const defaultSelectedIndex = selectedThumb
    ? thumbKeys.findIndex(key => selectedThumb.includes(key))
    : -1;

  return thumbs
    ? thumbKeys.map((thumbKey: string, index: number) => ({
        value: thumbKey,
        preview: thumbs[thumbKey],
        selected: defaultSelectedIndex === index
      }))
    : [];
};

function EditVideoModal({
  title,
  video_id,
  video_url,
  thumb,
  filename,
  loadProgress,
  onClose,
  converter
}: EditVideoModalTypes) {
  const dispatch = useDispatch();
  const allCategories = useSelector(getSelectCategories);
  const playlistsList = useSelector(getPlaylistsSelectList);

  const [activeForm, setActiveForm] = useState<ACTIVE_FORM_TYPES>("DETAILS");
  const [mandatoryState, setMandatoryState] = useState(true);
  const [formData, setFormData, setCustomKeyFormValue, setFormState] = useForm(defaultFormData);

  useEffect(() => {
    if (video_id && allCategories.length) {
      dispatch(
        requestVideoDetails(video_id, {
          onSuccess: ({ thumbs, ...data }: any) => {
            const playlist = playlistsList.find(propEq("value", data.playlist_id));
            const thumbsList: ThumbsPreviewTypes = formatThumbsForPreview(thumbs, data.thumb);

            setFormState({
              ...data,
              playlist,
              thumbsList
            });
          },
          onError: () => {}
        })
      );
    }
  }, [video_id, allCategories.length]);

  const showPermissionsForm = () => {
    setActiveForm("PERMISSIONS");
  };

  const showDetailsForm = () => {
    setActiveForm("DETAILS");
  };

  const thumbnailBox = useMemo(() => {
    if (loadProgress && loadProgress !== 100) {
      return (
        <div className="progress-bar-wrap">
          <ProgressBar loadProgress={loadProgress} supressCancel />
        </div>
      );
    }

    if (converter && converter.progress && converter.processCodeUpdate && !loadProgress) {
      return (
        <div className="progress-bar-wrap">
          <ProgressBar
            label={VIDEO_PROCESSING_CODES[converter.processCodeUpdate].label}
            loadProgress={converter.progress}
            supressCancel
          />
        </div>
      );
    }

    if (converter && converter.processCodeUpdate === 3 && !loadProgress) {
      return (
        <div className="progress-bar-wrap">
          <div className="progress-bar-container">
            <span className="progress-label">Processing</span>
          </div>
        </div>
      );
    }

    if (video_url && thumb) {
      return (
        <VideoPlayer
          watchMode={false}
          src={video_url}
          thumb={thumb}
          settings={VIDEO_PLAYER_SETTINGS}
        />
      );
    }
  }, [video_url, thumb, loadProgress, converter]);

  const onPublishUpdates = () => {
    if (formData.video_id) {
      dispatch(
        requestUpdateVideoDetails(
          {
            title: formData.title,
            description: formData.description,
            category_id: formData.category?.value,
            subcategories: formData.subcategories?.map((i: AppSelect) => i.value) || [],
            countries: formData.countries?.map((i: AppSelect) => i.value) || [],
            currency: formData.currency?.map((i: AppSelect) => i.value) || [],
            sectors: formData.sectors?.map((i: AppSelect) => i.value) || [],
            playlist_id: formData.playlist?.value,
            tags: formData.tags.map(prop("value")),
            enable_comments: Number(formData.enable_comments),
            status: formData.status
          },
          formData.video_id,
          {
            onSuccess: () => {
              if (formData.loadedThumb) {
                if (typeof formData.loadedThumb.value === "string") {
                  dispatch(
                    requestUpdateVideoDetails(
                      {
                        thumb: formData.loadedThumb.value,
                        thumbPreview: formData.loadedThumb.preview
                      },
                      formData.video_id,
                      {
                        onSuccess: () => {
                          onClose(formData);
                        },
                        onError: () => {}
                      }
                    )
                  );
                } else {
                  dispatch(
                    requestUploadVideoThumbnail(
                      formData.loadedThumb.value,
                      formData.video_id,
                      "video",
                      {
                        onSuccess: () => {
                          onClose(formData);
                        },
                        onError: () => {}
                      }
                    )
                  );
                }
              } else {
                onClose(formData);
              }
            },
            onError: () => {}
          }
        )
      );
    }
  };

  return (
    <div className="edit-video-modal modal-container">
      <div className="modal-header">
        <Row>
          <Col xs className="auto-column">
            <h2 className="modal-heading">{title}</h2>
          </Col>
          <Col xs>
            {loadProgress && loadProgress !== 100 && (
              <h2 className="video-load-progress">
                <ProgressBar loadProgress={loadProgress} supressCancel />
              </h2>
            )}
          </Col>
        </Row>
      </div>

      <Row className="progress-tabs" center="xs">
        <Col
          className={classes("progress-tab", {
            active: activeForm === "DETAILS"
          })}
        >
          <span className="numerotation">1</span>
          <span>Video details</span>
        </Col>
        <Col
          className={classes("progress-tab", {
            active: activeForm === "PERMISSIONS"
          })}
        >
          <span className="numerotation">2</span>
          <span>Permissions</span>
        </Col>
      </Row>

      <Row className="edit-video modal-body">
        <Col xs={6} className="video-preview">
          <Row className="thumb">{thumbnailBox}</Row>
          {video_id && (
            <Row className="details-row">
              <span className="label">Link</span>
              <span className="value">{`${window.location.origin}/video/${
                formData.hash_id || video_id
              }`}</span>
            </Row>
          )}
          <Row className="details-row">
            <span className="label">Filename</span>
            <span className="value">{filename}</span>
          </Row>
        </Col>

        {(activeForm === "DETAILS" && (
          <DetailsForm
            formData={formData}
            setFormData={setFormData}
            onSecondMandatory={setMandatoryState}
            setCustomKeyFormValue={setCustomKeyFormValue}
            allCategories={allCategories}
            allPlaylists={playlistsList}
          />
        )) || (
          <PermissionsForm
            setFormData={setCustomKeyFormValue}
            formData={formData}
            privacyOptions={VIDEO_PRIVACY_OPTIONS}
          />
        )}
        <Col xs={6} className="edit-video-form" />
      </Row>

      <div className="modal-footer">
        <Row>
          <Col className="video-status" xs={6} />
          <Col className="submit-controls" xs={6}>
            {(activeForm === "DETAILS" && (
              <button
                className="next-button"
                disabled={mandatoryState}
                onClick={showPermissionsForm}
              >
                Next
              </button>
            )) || (
              <>
                <button className="back-button" onClick={showDetailsForm}>
                  Back
                </button>
                <button
                  className="main-button"
                  onClick={onPublishUpdates}
                  disabled={!formData.video_id}
                >
                  Update
                </button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default memo(EditVideoModal);
