import { createNamespace } from "@Utils";
import { Channel } from "@Models/Channel";
import { RequestCallbacks } from "@Models/RequestParams";
import { ChannelImageUpload } from "@Models/ChannelImageUpload";
import { Filters } from "@Models/Filters";

export const CHANNEL = createNamespace("CHANNEL", [
  "REQUEST_DETAILS",
  "REQUEST_VIDEOS",
  "REQUEST_STREAMS",
  "REQUEST_PLAYLISTS",
  "REQUEST_FOLLOWERS",
  "REQUEST_MARKET_ALLOCATION",
  "REQUEST_SIMILAR_CHANNELS",
  "SET_DETAILS",
  "UPDATE_DETAILS",
  "DELETE_AVATAR",
  "CHECK_SLUG",
  "CREATE",
  "UPDATE_IMAGE",
  "UPDATE_INFO",
  "UNFOLLOW",
  "FOLLOW",
  "REQUEST_CATEGORY_CHANNELS"
]);

export const requestChannelDetails = (
  channel_slug: string,
  full: number,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_DETAILS,
  payload: { channel_slug, full },
  callbacks
});

export const requestChannelVideos = (
  channel_slug: string,
  nextPage: number,
  callbacks: RequestCallbacks,
  filters?: Filters
) => ({
  type: CHANNEL.REQUEST_VIDEOS,
  payload: { channel_slug, nextPage, filters },
  callbacks
});

export const requestChannelStreams = (
  channel_slug: string,
  nextPage: number,
  limit: number,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_STREAMS,
  payload: { channel_slug, nextPage, limit },
  callbacks
});

export const requestChannelPlaylists = (channel_slug: string, callbacks: RequestCallbacks) => ({
  type: CHANNEL.REQUEST_PLAYLISTS,
  payload: { channel_slug },
  callbacks
});

export const requestChannelFollowers = (
  channel_slug: string,
  nextPage: number,
  itemsPerPage: number,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_FOLLOWERS,
  payload: { channel_slug, itemsPerPage, nextPage },
  callbacks
});

export const setChannelDetails = (payload: Channel) => ({
  type: CHANNEL.SET_DETAILS,
  payload
});

export const checkChannelSlug = (payload: string, callbacks: RequestCallbacks) => ({
  type: CHANNEL.CHECK_SLUG,
  payload,
  callbacks
});

export const createChannel = (payload: Channel, imageFile: File, callbacks: RequestCallbacks) => ({
  type: CHANNEL.CREATE,
  payload,
  imageFile,
  callbacks
});

export const updateChannelDetails = (payload: Object) => ({
  type: CHANNEL.UPDATE_DETAILS,
  payload
});

export const updateChannelImage = (payload: ChannelImageUpload) => ({
  type: CHANNEL.UPDATE_IMAGE,
  payload
});

export const updateChannelInfo = (
  payload: Channel,
  channel_id: number,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.UPDATE_INFO,
  payload,
  channel_id,
  callbacks
});

export const followChannel = (channel_id: number, callbacks: RequestCallbacks) => ({
  type: CHANNEL.FOLLOW,
  channel_id,
  callbacks
});

export const unfollowChannel = (channel_id: number, callbacks: RequestCallbacks) => ({
  type: CHANNEL.UNFOLLOW,
  channel_id,
  callbacks
});

export const deleteChannelAvatar = (channel_id: number) => ({
  type: CHANNEL.DELETE_AVATAR,
  channel_id
});

export const requestChannelMarketAllocation = (
  channel_slug: string,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_MARKET_ALLOCATION,
  payload: { channel_slug },
  callbacks
});

export const requestChannelSimilarChannels = (
  channel_slug: string,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_SIMILAR_CHANNELS,
  payload: { channel_slug },
  callbacks
});

export const requestCategoryChannels = (
  { category, tag, appliedFilters }: { category?: string; tag?: string; appliedFilters?: any },
  page: number,
  callbacks: RequestCallbacks
) => ({
  type: CHANNEL.REQUEST_CATEGORY_CHANNELS,
  payload: { category, tag, appliedFilters, page },
  callbacks
});
