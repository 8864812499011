import { components } from "react-select";
import "./style.scss";

const DropdownIndicator = ({ selectProps, ...props }: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className="icon-chevron-down arrow-dropdown-indicator"
        onClick={() => {
          selectProps.onToggleDropdown();
        }}
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
