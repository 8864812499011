import { LiveChatMessage } from "@Models/LiveChat";
import LiveChatAvatar from "../Avatar";
import "./style.scss";

type Props = {
  setShowParticipants: (item: boolean) => void;
};

const users: Omit<LiveChatMessage, "message">[] = [
  {
    avatar: "",
    name: "John Doe",
    host: true
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "Marquise",
    host: false
  },
  {
    avatar: "",
    name: "Jerry",
    host: false
  },
  {
    avatar: "",
    name: "Freddy",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "Dean Kearney",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "Tom",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  },
  {
    avatar: "",
    name: "James Thenighttrader",
    host: false
  },
  {
    avatar: "",
    name: "Anthony Mathey Daytrader",
    host: false
  }
];

const LiveChatParticipants: React.FC<Props> = ({ setShowParticipants }) => {
  return (
    <>
      <div className="live-chat-header live-chat-participants">
        <i className="icon-chevron-left" onClick={() => setShowParticipants(false)} />
        <span className="live-chat-header__title">Live Chat</span>
      </div>
      <div className="live-chat-participants-items">
        {users.map((item, key) => (
          <LiveChatAvatar
            key={key}
            item={{ avatar: item.avatar, name: item.name, host: item.host }}
          />
        ))}
      </div>
    </>
  );
};

export default LiveChatParticipants;
