import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { requestDeshboardLastUploadedVideo } from "@Store/actions/pageSections";
import { abbreviateNumber, convertToTimeDuration } from "@Utils";
import { LoadingSpinner, Modal } from "@Components";
import UploadVideos from "@Views/UploadVideos";

type LatestUploadData = {
  comments: number;
  duration: number;
  hash_id: string;
  image: string | null;
  reactions: number;
  title: string;
  views: number;
};

function LatestUploadWidget() {
  const dispatch = useDispatch();
  const [data, setData] = useState<LatestUploadData | null | any>(null);
  const [uploadModal, setUploadModal] = useState(false);

  useEffect(() => {
    dispatch(
      requestDeshboardLastUploadedVideo({
        onSuccess: setData,
        onError: () => {}
      })
    );
  }, []);

  if (!data) {
    return (
      <div className="widget latest-upload">
        <div className="content">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="widget latest-upload">
      <div className="content">
        {!!data.hash_id ? (
          <>
            <h3 className="widget-title">Your latest upload</h3>
            <div className="latest-post">
              <div
                className="thumb"
                style={{
                  backgroundImage: data.image
                    ? `url(${data.image.replace("[SIZE]", "300x190")})`
                    : ""
                }}
              >
                <Link className="play-button" to={`/video/${data.hash_id}`}>
                  <i className="icon-play" />
                </Link>
                <div className="overlay">
                  <div className="title">{data.title}</div>
                  <div className="duration">{convertToTimeDuration(data.duration)}</div>
                </div>
              </div>
            </div>
            <div className="table-heading">In last 7 days</div>
            <ul className="details-list">
              <li>
                <span>Views</span>
                <div className="direction-label">
                  <span>{abbreviateNumber(data.views)}</span>

                  {/*  <span className="label-value">*/}
                  {/*    <i className={`icon icon-caret-up`} />*/}
                  {/*    <span className={"increase"}>15%</span>*/}
                  {/*  </span>*/}
                </div>
              </li>
              <li>
                <span>Comments</span>
                <div className="direction-label">
                  <span>{abbreviateNumber(data.comments)}</span>

                  {/*  <span className="label-value">*/}
                  {/*    <i className={`icon icon-caret-up`} />*/}
                  {/*    <span className={"increase"}>15%</span>*/}
                  {/*  </span>*/}
                </div>
              </li>
              <li>
                <span>Reactions</span>
                <div className="direction-label">
                  <span>{abbreviateNumber(data.reactions)}</span>

                  {/*  <span className="label-value">*/}
                  {/*    <i className={`icon icon-caret-up`} />*/}
                  {/*    <span className={"increase"}>15%</span>*/}
                  {/*  </span>*/}
                </div>
              </li>
            </ul>
          </>
        ) : (
          <div className="no-data-upload">
            <i className="icon-multimedia-dash" />
            <h3>Want to see metrics on your recent video?</h3>
            <p>Upload and publish a video to get started.</p>
            <button
              className="main-button"
              onClick={() => {
                setUploadModal(true);
              }}
            >
              <i className="icon-upload" />
              Upload
            </button>
          </div>
        )}
      </div>
      {uploadModal && (
        <Modal
          onClose={() => {
            setUploadModal(false);
          }}
        >
          <UploadVideos
            onSetActiveModal={() => {
              setUploadModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}

export default LatestUploadWidget;
