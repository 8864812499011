import { AUTH_STATE } from "@Store/actions/app";
import { App } from "@Models/Store";
import { USER } from "@Store/actions/user";

const initialState: App = {
  isFetching: false,
  isAuthenticated: false
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case AUTH_STATE.SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case AUTH_STATE.REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case USER.SET_DETAILS:
      return {
        ...state,
        isAuthentificated: true
      };
    case USER.LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthentificated: false
      };
    default:
      return state;
  }
}
