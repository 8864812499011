import { createNamespace } from "@Utils";
import { RequestCallbacks } from "@Models/RequestParams";

export const AUTH_STATE = createNamespace("AUTH_STATE", [
  "REQUEST",
  "FAILURE",
  "SUCCESS",
  "SOCKET_SUCCESS",
  "SOCKET_FAILURE",
  "SOCKET_RECONNECT"
]);

export const TOKEN = createNamespace("TOKEN", ["REFRESH"]);
export const SEARCH = createNamespace("SEARCH", [
  "REQUEST_SUGGESTIONS",
  "DELETE_SUGGESTION",
  "REQUEST_RESULTS"
]);

export const requestFetchInitialState = () => ({
  type: AUTH_STATE.REQUEST
});

export const fetchInitialStateSuccess = () => ({
  type: AUTH_STATE.SUCCESS
});

export const fetchInitialStateFail = () => ({
  type: AUTH_STATE.FAILURE
});

export const reconnectUserSockets = () => ({
  type: AUTH_STATE.SOCKET_RECONNECT,
  socket: {
    reconnect: true
  }
});

export const requestSearchSuggestions = (query: string, callbacks: RequestCallbacks) => ({
  type: SEARCH.REQUEST_SUGGESTIONS,
  payload: { query },
  callbacks
});

export const deleteSearchSuggestion = (suggestId: number, callbacks: RequestCallbacks) => ({
  type: SEARCH.DELETE_SUGGESTION,
  payload: { suggestId },
  callbacks
});

export const requestSearchResults = (
  query: string,
  nextPage: number,
  itemsPerPage: number,
  callbacks: RequestCallbacks
) => ({
  type: SEARCH.REQUEST_RESULTS,
  payload: { query, nextPage, itemsPerPage },
  callbacks
});
