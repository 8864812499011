function EmptyComments() {
  return (
    <div className="empty-comments">
      <i className="icon-chat-message-o" />
      <p className="empty-message">No comments to display</p>
    </div>
  );
}

export default EmptyComments;
