import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Col } from "react-flexbox-grid";
import {
  selectSidebarSelectedDirectory,
  selectSidebarSelectedDirectoryData
} from "@Store/selectors/sidebar";
import { getAuthUser } from "@Store/selectors/user";
import Category from "@Components/DiscoveryMenu/Category";
import "./styles.scss";

export const Directory = (): JSX.Element => {
  const directoryData = useSelector(selectSidebarSelectedDirectoryData);
  const selectedDirectory = useSelector(selectSidebarSelectedDirectory);
  const userDetails = useSelector(getAuthUser);
  const baseUrl = useMemo(() => `/categories/${selectedDirectory}`, [selectedDirectory]);

  return (
    <div className="dm-directory-root">
      {selectedDirectory === "channels" && (
        <div className="dm-directory-category-group">
          <Col xs>
            <Category baseUrl={baseUrl} queryKey="category" slug="all" name="Full Directory" />
            <Category
              baseUrl={baseUrl}
              queryKey="category"
              slug="business-channels"
              name="Business Channels"
            />
            {!!userDetails.username && (
              <Category
                baseUrl={`/streamer/${userDetails.username}/following`}
                queryKey={"custom"}
                name="Following"
              />
            )}
          </Col>
        </div>
      )}

      {selectedDirectory === "videos" && (
        <div className={`dm-directory-category-group now-on-air`}>
          <Category
            baseUrl={baseUrl}
            queryKey="category"
            slug="streams"
            name="Now on air"
            leftIconClassName="icon-record"
          />
        </div>
      )}

      <div className="dm-directory-category-group">
        <h5 className="dm-directory-category-group-name">Categories</h5>
        <Col xs>
          {directoryData.categories.map(category => (
            <Category
              key={category.category_id}
              baseUrl={baseUrl}
              queryKey="category"
              slug={category.slug}
              name={category.name}
              subcategories={category.children}
            />
          ))}
        </Col>
      </div>
      <div className="dm-directory-category-group">
        <h5 className="dm-directory-category-group-name">Popular Tags</h5>
        <Col xs>
          {directoryData.tags.map(tag => (
            <Category
              key={tag.tag_id}
              baseUrl={baseUrl}
              queryKey="tag"
              slug={tag.slug}
              name={tag.name}
            />
          ))}
        </Col>
      </div>
    </div>
  );
};

export default Directory;
