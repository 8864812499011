import { VideoListItem } from "@Components";
import { useDrag, DragPreviewImage } from "react-dnd";
import { PlaylistVideoPlaceholder } from "@Assets";

const isInvalidVideo = (video: any) =>
  !video.loadProgress && !video.converter?.processCodeUpdate && !video.is_valid;

type Props = {
  videoListItem: any;
  onCheck: (checked: boolean) => void;
  checked: boolean;
  selectedVideos: any[];
  onUpdateVideoSuccess: (updatedDetails: any) => void;
  onDeleteVideoSuccess: () => void;
};

function PlaylistVideoListItem({
  videoListItem,
  onCheck,
  checked,
  selectedVideos,
  onDeleteVideoSuccess,
  onUpdateVideoSuccess
}: Props) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: "PLAYLIST_VIDEO_ITEM",
    item: {
      type: "PLAYLIST_VIDEO_ITEM",
      selectedVideos: selectedVideos.length ? selectedVideos : [videoListItem]
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <>
      {!!selectedVideos.length && (
        <DragPreviewImage connect={preview} src={PlaylistVideoPlaceholder} />
      )}
      <div ref={drag} style={{ opacity: isDragging ? 0.4 : 1 }}>
        <VideoListItem
          {...videoListItem}
          onCheck={onCheck}
          isStreamEvent={videoListItem.is_stream}
          checked={checked}
          onUpdateVideoSuccess={onUpdateVideoSuccess}
          onDeleteVideoSuccess={onDeleteVideoSuccess}
          editOnly={
            videoListItem.loadProgress ||
            (videoListItem?.converter?.processCodeUpdate &&
              videoListItem.converter.processCodeUpdate !== 0)
          }
          invalidVideo={isInvalidVideo(videoListItem)}
        />
      </div>
    </>
  );
}

export default PlaylistVideoListItem;
