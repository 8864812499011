import "./style.scss";
import { useState } from "react";
import { AppSelect } from "@Models/AppSelect";
import ListChooser from "@AdminComponents/ListChooser";

type AdminSelectedItemTypes = {
  title: string;
  multipleView?: boolean;
  selectedOptions?: AppSelect[];
  allEditOptions?: AppSelect[];
  onRemove?: () => void | null;
  onRemoveItem?: (id: number) => void | null;
  onUpdateList?: (idsList: number[]) => void | null;
};

function SelectedItem({
  title,
  multipleView = false,
  onRemove,
  onRemoveItem,
  onUpdateList,
  allEditOptions = [],
  selectedOptions
}: AdminSelectedItemTypes) {
  const [editMode, setEditMode] = useState(false);
  const [selectedList, setSelectedList] = useState<number[]>([]);

  return (
    <aside className="selected-admin-item">
      <div className="heading-selected">
        <span className={multipleView ? "multiple-head" : ""}>{title}</span>
        {onRemove && <i className="icon-remove" onClick={onRemove} />}
        {!editMode ? (
          <>
            {onUpdateList && (
              <i
                className="icon-edit"
                onClick={() => {
                  setEditMode(true);
                }}
              />
            )}
          </>
        ) : (
          <i
            className="icon-check-mark"
            onClick={() => {
              onUpdateList && onUpdateList(selectedList);
              setEditMode(false);
            }}
          />
        )}
      </div>
      {multipleView && (
        <div className="multiple-selected">
          {editMode ? (
            <ListChooser
              list={allEditOptions}
              selectedItems={selectedOptions?.map(i => i.value)}
              onUpdateList={idList => {
                setSelectedList(idList);
              }}
            />
          ) : (
            <ul className="list">
              {!!selectedOptions &&
                selectedOptions.map((option, index) => (
                  <li key={index}>
                    {option.label}
                    {onRemoveItem && (
                      <i
                        className="icon-close"
                        onClick={() => {
                          onRemoveItem(option.value);
                        }}
                      />
                    )}
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
    </aside>
  );
}

export default SelectedItem;
