import { cloneElement } from "react";
import { propEq } from "ramda";
import { Redirect } from "react-router";

import { COMMENTS_PAGE_SECTIONS } from "../../../constants";

type RootCommentsPageTypes = {
  match: { params: { sectionId: string } };
  location: { search: string };
};

function RootCommentsPageSection(props: RootCommentsPageTypes) {
  const {
    match: { params }
  } = props;

  const sectionIndex = COMMENTS_PAGE_SECTIONS.findIndex(propEq("id", params.sectionId));

  if (sectionIndex !== -1) {
    return cloneElement(COMMENTS_PAGE_SECTIONS[sectionIndex].component, props);
  }

  return (
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  );
}

export default RootCommentsPageSection;
