import { VIDEO_SOCKET_RESPONSE, VIDEO } from "@Store/actions/video";

type AllowedConverterEvents = "processCodeUpdate" | "progress" | "videoUpdated";

const getConverterEventMessageValue = (message: {
  event: AllowedConverterEvents;
  video_id: number;
  progress: { percent: number };
  process_code: number;
}) => {
  switch (message.event) {
    case "videoUpdated":
      return message.video_id;
    case "progress":
      return Math.round(message.progress.percent);
    case "processCodeUpdate":
      return message.process_code;
    default:
      return undefined;
  }
};

export default {
  [VIDEO_SOCKET_RESPONSE.GET_CONVERTER_UPDATES]: {
    interval: 600,
    buffer: (acc: any = {}, currentAction: any) => {
      if (currentAction.payload?.message?.event) {
        return {
          ...acc,
          [currentAction.payload.message.video_id]: {
            ...acc[currentAction.payload.message.video_id],
            [currentAction.payload.message.event]: getConverterEventMessageValue(
              currentAction.payload.message
            )
          }
        };
      }

      return acc;
    }
  },

  [VIDEO_SOCKET_RESPONSE.GET_SUBSCRIBE_PUBLIC_LIVE_UPDATES]: {
    interval: 200,
    buffer: (acc: any = {}, currentAction: any) => ({ ...acc, ...currentAction.payload })
  },
  [VIDEO.UPDATE_DETAILS]: {
    interval: 300,
    buffer: (acc: any = {}, currentAction: any) => ({
      ...acc,
      [currentAction.payload.video_id]: {
        ...acc[currentAction.payload.video_id],
        ...currentAction.payload.details
      },
      typeVideo: currentAction.payload.typeVideo
    })
  }
};
