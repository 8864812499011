import { VIDEO, VIDEO_SOCKET_RESPONSE } from "@Store/actions/video";

const initialState = {
  list: [],
  streamManager: {
    streamReceived: false,
    activeStreamKey: "",
    saveStream: true
  },
  streamsList: [],
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0,
    currentPage: 1,
    totalPages: 0
  }
};

const setUserVideoDetails = (state: any, action: any) => ({
  ...state,
  list: [{ ...action.payload, loadProgress: 1 }, ...state.list]
});

const setUserStreamEventDetails = (state: any, action: any) => ({
  ...state,
  streamsList: [action.payload, ...state.streamsList]
});

const setUserVideosList = (state: any, action: any) => ({
  ...state,
  pageInfo: action.payload.pageInfo,
  list: action.payload.list.map(({ process_code, ...video }: any) => ({
    ...video,
    converter: !!process_code
      ? {
          processCodeUpdate: process_code,
          progress: process_code ? 1 : 0
        }
      : null
  }))
});

const setUserStreamsList = (state: any, action: any) => ({
  ...state,
  pageInfo: action.payload.pageInfo,
  streamsList: action.payload.list.map(({ process_code, ...video }: any) => ({
    ...video,
    converter: !!process_code
      ? {
          processCodeUpdate: process_code,
          progress: process_code ? 1 : 0
        }
      : null
  }))
});

const updateVideoDetails = (state: any, action: any) => {
  const ids_list = Object.keys(action.payload);

  if (ids_list.length) {
    return action.payload.typeVideo == "video"
      ? {
          ...state,
          list: state.list.reduce((acc: any, videoItem: any) => {
            if (ids_list.includes(String(videoItem.video_id))) {
              return [
                ...acc,
                {
                  ...videoItem,
                  ...action.payload[videoItem.video_id]
                }
              ];
            }

            return [...acc, videoItem];
          }, [])
        }
      : {
          ...state,
          streamsList: state.streamsList.reduce((acc: any, videoItem: any) => {
            if (ids_list.includes(String(videoItem.video_id))) {
              return [
                ...acc,
                {
                  ...videoItem,
                  ...action.payload[videoItem.video_id]
                }
              ];
            }
            return [...acc, videoItem];
          }, [])
        };
  }

  return state;
};

const updateVideosListDetails = (state: any, action: any) => {
  return {
    ...state,
    list: state.list.reduce((acc: any, videoItem: any) => {
      if (action.payload.ids_list.includes(videoItem.video_id)) {
        return [...acc, { ...videoItem, ...action.payload.details }];
      }

      return [...acc, videoItem];
    }, [])
  };
};

const updateStreamsListDetails = (state: any, action: any) => {
  return {
    ...state,
    streamsList: state.streamsList.reduce((acc: any, videoItem: any) => {
      if (action.payload.ids_list.includes(videoItem.video_id)) {
        return [...acc, { ...videoItem, ...action.payload.details }];
      }

      return [...acc, videoItem];
    }, [])
  };
};

const updateRTCStreamStatus = (state: any, action: any) => {
  return {
    ...state,
    streamManager: {
      ...state.streamManager,
      streamReceived: action.payload.status
    }
  };
};
const setStreamManagerKey = (state: any, action: any) => {
  return {
    ...state,
    streamManager: {
      ...state.streamManager,
      activeStreamKey: action.payload.key
    }
  };
};

const updateStreamManagerSaveStream = (state: any, action: any) => {
  return {
    ...state,
    streamManager: {
      ...state.streamManager,
      saveStream: action.payload.saveStream
    }
  };
};

const updateConvertingVideoDetails = (state: any, action: any) => {
  const ids_list = Object.keys(action.payload);

  if (ids_list.length) {
    return {
      ...state,
      list: state.list.reduce((acc: any, videoItem: any) => {
        if (ids_list.includes(String(videoItem.video_id))) {
          return [
            ...acc,
            {
              ...videoItem,
              converter: {
                ...videoItem.converter,
                ...action.payload[videoItem.video_id]
              }
            }
          ];
        }

        return [...acc, videoItem];
      }, [])
    };
  }

  return state;
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case VIDEO.SET_DETAILS:
      return setUserVideoDetails(state, action);
    case VIDEO.SET_STREAM_DETAILS:
      return setUserStreamEventDetails(state, action);
    case VIDEO.LOAD_USER_VIDEOS:
      return setUserVideosList(state, action);
    case VIDEO.LOAD_USER_STREAMS:
      return setUserStreamsList(state, action);
    case VIDEO.UPDATE_DETAILS:
      return updateVideoDetails(state, action);
    case VIDEO.MASS_DETAILS_UPDATE:
      return updateVideosListDetails(state, action);
    case VIDEO.MASS_STREAMS_DETAILS_UPDATE:
      return updateStreamsListDetails(state, action);
    case VIDEO_SOCKET_RESPONSE.GET_CONVERTER_UPDATES:
      return updateConvertingVideoDetails(state, action);
    case VIDEO.RTC_UPDATES:
      return updateRTCStreamStatus(state, action);
    case VIDEO.SET_STREAM_KEY:
      return setStreamManagerKey(state, action);
    case VIDEO.SAVE_STREAM:
      return updateStreamManagerSaveStream(state, action);
    default:
      return state;
  }
}
