import { components } from "react-select";
import { useRef } from "react";
import { useOnClickOutside } from "@Hooks";

const CheckboxSelected = ({ children, selectProps, ...props }: any) => {
  const selected = props.getValue();
  const valueContainerNode = useRef<HTMLDivElement | null>(null);
  selectProps.onClickOutside && useOnClickOutside(valueContainerNode, selectProps.onClickOutside);
  const content = (length => {
    switch (length) {
      case 0:
        return children;
      case 1:
        return `${selected[0].label}`;
      default:
        return `${selected.length} ${selectProps.selectedLabel}`;
    }
  })(selected.length);

  return (
    <components.ValueContainer {...props}>
      <div
        ref={valueContainerNode}
        className="value-container-drop-item"
        onClick={() => {
          selectProps.onToggleDropdown && selectProps.onToggleDropdown();
        }}
      >
        {content}
      </div>
    </components.ValueContainer>
  );
};
export default CheckboxSelected;
