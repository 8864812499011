import Select from "react-select";
import { AppSelect } from "@Models/AppSelect";
import { useEffect, useState } from "react";
import { CheckboxOption, CheckboxSelected } from "@Components";
import "./style.scss";

type AdminDropdownTypes = {
  placeholder?: string;
  isSearchable?: boolean;
  checkboxSelect?: boolean;
  selectedValue?: any;
  onActionChange?: (option: any) => void;
  onApplyActionChange?: (option: any) => void;
  noOptionsMessage?: string;
  options: AppSelect[];
};

function AdminDropdown({
  placeholder = "",
  isSearchable = false,
  checkboxSelect = false,
  onActionChange,
  selectedValue = null,
  onApplyActionChange,
  noOptionsMessage = "No Options",
  options
}: AdminDropdownTypes) {
  const [selectedValues, setSelectedValues] = useState([] as AppSelect[]);
  const [dropDownValue, setDropDownValue] = useState(selectedValue);

  useEffect(() => {
    setDropDownValue(selectedValue);
  }, [selectedValue]);

  const onDropdownChange = (values: any) => {
    checkboxSelect && setSelectedValues(values);
    onActionChange && onActionChange(values);
    setDropDownValue(values);
  };

  return (
    <Select
      classNamePrefix="app-select"
      className="admin-select"
      value={dropDownValue}
      menuPlacement="bottom"
      placeholder={placeholder}
      onMenuClose={() => {
        onApplyActionChange && onApplyActionChange(selectedValues);
      }}
      isMulti={checkboxSelect}
      components={
        checkboxSelect ? { Option: CheckboxOption, ValueContainer: CheckboxSelected } : {}
      }
      closeMenuOnSelect={!checkboxSelect}
      hideSelectedOptions={false}
      isSearchable={isSearchable}
      onChange={onDropdownChange}
      noOptionsMessage={() => noOptionsMessage}
      options={options}
      selectedLabel="topics"
    />
  );
}

export default AdminDropdown;
