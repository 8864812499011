import useRootRef from "@Hooks/useRootRef";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

function TermsConditions() {
  const rootRef = useRootRef();

  useEffect(() => {
    rootRef?.scrollTo(0, 0);
    sendGTMDataLayer({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.href
      }
    });
  }, []);

  return (
    <div className="page-content policy-page">
      <div className="full-container">
        <h1 className="title">Terms & Conditions of use</h1>
        <div className="policy-content">
          <p>
            <strong>Introduction</strong>
          </p>
          <p>
            Thank you for using the Sharevision platform and the products, services and features we
            make available to you as part of the platform (collectively, the “Service”).
          </p>
          <p>
            These terms and conditions apply between you, the User of this Website (including any
            sub-domains, unless expressly excluded by their own terms and conditions), and
            &nbsp;Sharevision Ltd, &nbsp;the owner and operator of this Website. Please read these
            terms and conditions carefully, as they affect your legal rights. Your agreement to
            comply with and be bound by these terms and conditions is deemed to occur upon your
            first use of the Website. If you do not agree to be bound by these terms and conditions,
            you should stop using the Website immediately.
          </p>
          <p>
            In these terms and conditions, <strong>User</strong> or <strong>Users</strong> means any
            third party that accesses the Website and is not either (i) employed by
            &nbsp;Sharevision Ltd &nbsp;and acting in the course of their employment or (ii) engaged
            as a consultant or otherwise providing services to &nbsp;Sharevision Ltd &nbsp;and
            accessing the Website in connection with the provision of such services.
          </p>
          <p>
            You must be at least 18 years of age to use this Website. By using the Website and
            agreeing to these terms and conditions, you represent and warrant that you are at least
            18 years of age.
          </p>

          <p>
            <strong>Intellectual property and acceptable use</strong>
          </p>
          <ol role="list">
            <li>
              All Content included on the Website, unless uploaded by Users, is the property of
              &nbsp;Sharevision Ltd, &nbsp;our affiliates or other relevant third parties. In these
              terms and conditions, Content means any text, graphics, images, audio, video,
              software, data compilations, page layout, underlying code and software and any other
              form of information capable of being stored in a computer that appears on or forms
              part of this Website, including any such content uploaded by Users. By continuing to
              use the Website you acknowledge that such Content is protected by copyright,
              trademarks, database rights and other intellectual property rights. Nothing on this
              site shall be construed as granting, by implication, estoppel, or otherwise, any
              license or right to use any trademark, logo or service mark displayed on the site
              without the owner's prior written permission
            </li>
            <li>You may, for your own personal, non-commercial use only, do the following:</li>
          </ol>
          <ul role="list">
            <li>retrieve, display and view the Content on a computer screen</li>
          </ul>
          <ol start={3} role="list">
            <li>
              You must not otherwise reproduce, modify, copy, distribute or use for commercial
              purposes any Content without the written permission of &nbsp;Sharevision Ltd.
            </li>
            <li>
              You acknowledge that you are responsible for any Content you may submit via the
              Website, including the legality, reliability, appropriateness, originality and
              copyright of any such Content. You may not upload to, distribute or otherwise publish
              through the Website any Content that (i) is confidential, proprietary, false,
              fraudulent, libellous, defamatory, obscene, threatening, invasive of privacy or
              publicity rights, infringing on intellectual property rights, abusive, illegal or
              otherwise objectionable; (ii) may constitute or encourage a criminal offence, violate
              the rights of any party or otherwise give rise to liability or violate any law; or
              (iii) may contain software viruses, political campaigning, chain letters, mass
              mailings, or any form of "spam." You may not use a false email address or other
              identifying information, impersonate any person or entity or otherwise mislead as to
              the origin of any content. You may not upload commercial content onto the Website.
            </li>
            <li>
              You represent and warrant that you own or otherwise control all the rights to the
              Content you post; that the Content is accurate; that use of the Content you supply
              does not violate any provision of these terms and conditions and will not cause injury
              to any person; and that you will indemnify &nbsp;Sharevision Ltd &nbsp;for all claims
              resulting from Content you supply.
            </li>
          </ol>

          <p>
            <strong>Prohibited use</strong>
          </p>
          <ol start={6} role="list">
            <li>You may not use the Website for any of the following purposes:</li>
          </ol>
          <ul role="list">
            <li>
              in any way which causes, or may cause, damage to the Website or interferes with any
              other person's use or enjoyment of the Website;
            </li>
            <li>
              in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or
              otherwise objectionable or in breach of any applicable law, regulation, governmental
              order;
            </li>
            <li>
              making, transmitting or storing electronic copies of Content protected by copyright
              without the permission of the owner.
            </li>
          </ul>

          <p>
            <strong>Registration</strong>
          </p>
          <ol start={7} role="list">
            <li>
              You must ensure that the details provided by you on registration or at any time are
              correct and complete.
            </li>
          </ol>
          <ol start={8} role="list">
            <li>
              You must inform us immediately of any changes to the information that you provide when
              registering by updating your personal details to ensure we can communicate with you
              effectively.
            </li>
          </ol>
          <ol start={9} role="list">
            <li>
              We may suspend or cancel your registration with immediate effect for any reasonable
              purposes or if you breach these terms and conditions.
            </li>
          </ol>
          <ol start={10} role="list">
            <li>
              You may cancel your registration at any time by informing us in writing to the address
              at the end of these terms and conditions. If you do so, you must immediately stop
              using the Website. Cancellation or suspension of your registration does not affect any
              statutory rights.
            </li>
          </ol>

          <p>
            <strong>Password and security</strong>
          </p>
          <ol start={11} role="list">
            <li>
              When you register on this Website, you will be asked to create a password, which you
              should keep confidential and not disclose or share with anyone.
            </li>
          </ol>
          <ol start={12} role="list">
            <li>
              If we have reason to believe that there is or is likely to be any misuse of the
              Website or breach of security, we may require you to change your password or suspend
              your account.
            </li>
          </ol>

          <p>
            <strong>Links to other websites</strong>
          </p>
          <ol start={13} role="list">
            <li>
              This Website may contain links to other sites. Unless expressly stated, these sites
              are not under the control of &nbsp;Sharevision Ltd &nbsp;or that of our affiliates.
            </li>
          </ol>
          <ol start={14} role="list">
            <li>
              We assume no responsibility for the content of such Websites and disclaim liability
              for any and all forms of loss or damage arising out of the use of them.
            </li>
          </ol>
          <ol start={15} role="list">
            <li>
              The inclusion of a link to another site on this Website does not imply any endorsement
              of the sites themselves or of those in control of them.
            </li>
          </ol>

          <p>
            <strong>Privacy Policy and Cookies Policy</strong>
          </p>
          <ol start={16} role="list">
            <li>
              Use of the Website is also governed by our Privacy Policy and Cookies Policy, which
              are incorporated into these terms and conditions by this reference. To view the
              Privacy Policy and Cookies Policy, please click on the following:{" "}
              <Link to="/privacy-policy">sharevision.com/privacy-policy</Link> and{" "}
              <Link to="/cookie-policy">sharevision.com/cookie-policy</Link>.
            </li>
          </ol>

          <p>
            <strong>Availability of the Website and disclaimers</strong>
          </p>
          <ol start={17} role="list">
            <li>
              Any online facilities, tools, services or information that Sharevision Ltd makes
              available through the Website (the <strong>Service</strong>) is provided "as is" and
              on an "as available" basis. We give no warranty that the Service will be free of
              defects and/or faults. To the maximum extent permitted by the law, we provide no
              warranties (express or implied) of fitness for a particular purpose, accuracy of
              information, compatibility and satisfactory quality. Sharevision Ltd is under no
              obligation to update information on the Website.
            </li>
          </ol>
          <ol start={18} role="list">
            <li>
              Whilst Sharevision Ltd &nbsp;uses reasonable endeavours to ensure that the Website is
              secure and free of errors, viruses and other malware, we give no warranty or guaranty
              in that regard and all Users take responsibility for their own security, that of their
              personal details and their computers.
            </li>
          </ol>
          <ol start={19} role="list">
            <li>
              Sharevision Ltd accepts no liability for any disruption or non-availability of the
              Website.
            </li>
          </ol>
          <ol start={20} role="list">
            <li>
              Sharevision Ltd reserves the right to alter, suspend or discontinue any part (or the
              whole of) the Website including, but not Ltd to, any products and/or services
              available. These terms and conditions shall continue to apply to any modified version
              of the Website unless it is expressly stated otherwise.
            </li>
          </ol>

          <p>
            <strong>Limitation of liability</strong>
          </p>
          <ol start={21} role="list">
            <li>
              Nothing in these terms and conditions will: (a) limit or exclude our or your liability
              for death or personal injury resulting from our or your negligence, as applicable; (b)
              limit or exclude our or your liability for fraud or fraudulent misrepresentation; or
              (c) limit or exclude any of our or your liabilities in any way that is not permitted
              under applicable law.
            </li>
          </ol>
          <ol start={22} role="list">
            <li>
              To the extent that the Website and Content are provided free of charge, we will not be
              liable to you for any loss or damage of any kind.
            </li>
          </ol>
          <ol start={23} role="list">
            <li>
              We will not be liable to you in respect of any losses arising out of events beyond our
              reasonable control.
            </li>
          </ol>
          <ol start={24} role="list">
            <li>
              To the maximum extent permitted by law, &nbsp;Sharevision Ltd &nbsp;accepts no
              liability for any of the following:
            </li>
          </ol>
          <ul role="list">
            <li>
              any business losses, such as loss of profits, income, revenue, anticipated savings,
              business, contracts, goodwill or commercial opportunities;
            </li>
          </ul>
          <ul role="list">
            <li>loss or corruption of any data, database or software;</li>
            <li>any special, indirect or consequential loss or damage.</li>
          </ul>

          <p>
            <strong>General</strong>
          </p>
          <ol start={25} role="list">
            <li>
              You may not transfer any of your rights under these terms and conditions to any other
              person. We may transfer our rights under these terms and conditions where we
              reasonably believe your rights will not be affected.
            </li>
          </ol>
          <ol start={26} role="list">
            <li>
              These terms and conditions may be varied by us from time to time. Such revised terms
              will apply to the Website from the date of publication. Users should check the terms
              and conditions regularly to ensure familiarity with the then current version.
            </li>
          </ol>
          <ol start={27} role="list">
            <li>
              These terms and conditions &nbsp; &nbsp;together with the Privacy Policy and Cookies
              Policy &nbsp;contain the whole agreement between the parties relating to its subject
              matter and supersede all prior discussions, arrangements or agreements that might have
              taken place in relation to the terms and conditions.
            </li>
          </ol>
          <ol start={28} role="list">
            <li>
              The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and
              conditions and no third party will have any right to enforce or rely on any provision
              of these terms and conditions.
            </li>
          </ol>
          <ol start={29} role="list">
            <li>
              If any court or competent authority finds that any provision of these terms and
              conditions (or part of any provision) is invalid, illegal or unenforceable, that
              provision or part-provision will, to the extent required, be deemed to be deleted, and
              the validity and enforceability of the other provisions of these terms and conditions
              will not be affected.
            </li>
          </ol>
          <ol start={30} role="list">
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in exercising any right
              or remedy will be deemed a waiver of that, or any other, right or remedy.
            </li>
          </ol>
          <ol start={31} role="list">
            <li>
              This Agreement shall be governed by and interpreted according to the law of England
              and Wales and all disputes arising under the Agreement (including non-contractual
              disputes or claims) shall be subject to the exclusive jurisdiction of the English and
              Welsh courts.
            </li>
          </ol>

          <p>
            <strong>Our Service</strong>
          </p>
          <p>
            The Service allows you to discover, watch and share videos and other content, provides a
            forum for people to connect, inform, and inspire others across the globe, and acts as a
            distribution platform for original content creators and advertisers large and small.
          </p>
          <p>
            ‍<br />
          </p>
          <p>
            <strong>Your Service Provider</strong>
          </p>
          <p>
            The entity providing the Service in the United Kingdom is Sharevision Ltd, having it’s
            registered office at Suite 2 125 - 135 Staines Road, Hounslow, United Kingdom TW3 3JB
            (referred to as “Sharevision”, “we”, “us”, or “our”).
          </p>

          <p>
            <strong>Applicable Terms</strong>
          </p>
          <p>
            Your use of the Service is subject to these terms, the Sharevision Community Guidelines
            and the Policy, Safety and Copyright Policies (together, the "Agreement"). Your
            Agreement with us will also include the Advertising on Sharevision Policies if you
            provide advertising or sponsorships to the Service or incorporate paid promotions in
            your Content.
          </p>
          <p>
            Please read this Agreement carefully and make sure you understand it. If you do not
            understand the Agreement, or do not accept any part of it, then you may not use the
            Service.
          </p>

          <p>
            <strong>Who may use the Service?</strong>
          </p>
          <p>
            <strong>‍</strong>
            <br />
          </p>
          <p>
            <strong>Age Requirements</strong>
          </p>
          <p>You may use the Service if you are at least 16 years old.</p>

          <p>
            <strong>Permission by Parent or Guardian</strong>
          </p>
          <p>
            If you are under 18, you must have your parent or legal guardian’s permission to use the
            Service.&nbsp;
            <br />
          </p>
          <p>
            Please have them read this Agreement with you.
            <br />
          </p>
          <p>
            If you are a parent or legal guardian of a user under the age of 18, by allowing your
            child to use the Service, you are subject to the terms of this Agreement and responsible
            for your child’s activity on the Service.&nbsp;<strong>‍</strong>
          </p>

          <p>
            <strong>Businesses</strong>
            <br />
          </p>
          <p>
            If you are using the Service on behalf of a company or organisation, you confirm to us
            that you have authority to act on behalf of that entity, and that entity accepts this
            Agreement.
          </p>

          <p>
            <strong>Your Use of the Service</strong>
          </p>

          <p>
            <strong>Content on the Service</strong>
          </p>
          <p>
            The content on the Service includes videos, audio (for example music and other sounds),
            graphics, photos, text (such as comments and scripts), branding (including trade names,
            trademarks, service marks, or logos), interactive features, software, metrics, and other
            materials (collectively, "Content”). Content may be provided to the Service and
            distributed by our users and Sharevision is a provider of hosting services for such
            Content. Content is the responsibility of the person or entity that provides it to the
            Service. If you see any Content you believe does not comply with these terms, such as by
            violating the Community Guidelines or the law, you can report it to us.
          </p>

          <p>
            <strong>Sharevision Accounts and Sharevision Channels</strong>
          </p>
          <p>
            You can use parts of the Service, such as browsing and searching for Content, without
            having a Sharevision account. However, you do need a Sharevision account to use some
            features. With a Sharevision account, you may be able to like videos, subscribe to
            channels, create your own Sharevision channel, and more.
          </p>

          <p>
            Creating a Sharevision channel will give you access to additional features and
            functions, such as uploading videos, making comments or creating playlists.
          </p>

          <p>
            To protect your Sharevision account, keep your password confidential. You should not
            reuse your Sharevision account password on third-party applications.
          </p>

          <p>
            <strong>Your Information</strong>
          </p>

          <p>
            Our Privacy Policy explains how we treat your personal data and protect your privacy
            when you use the Service.
            <br />
          </p>
          <p>
            We will process any audio or audiovisual content uploaded by you to the Service in
            accordance with the Sharevision Data Processing Terms.
          </p>

          <p>
            <strong>Permissions and Restrictions</strong>
          </p>

          <p>
            You may access and use the Service as made available to you, as long as you comply with
            this Agreement and the law. You may view or listen to Content for your personal,
            non-commercial use. You may also show Sharevision videos through the embeddable
            Sharevision player.
            <br />
          </p>
          <p>
            The following restrictions apply to your use of the Service. You are not allowed to:
            <br />
          </p>
          <ol role="list">
            <li>
              access, reproduce, download, distribute, transmit, broadcast, display, sell, license,
              alter, modify or otherwise use any part of the Service or any Content except: (a) as
              specifically permitted by the Service; &nbsp;(b) with prior written permission from
              Sharevision and, if applicable, the respective rights holders; or (c) as permitted by
              applicable law;
            </li>
            <li>
              circumvent, disable, fraudulently engage, or otherwise interfere with the Service (or
              attempt to do any of these things), including security-related features or features
              that: (a) prevent or restrict the copying or other use of Content; or (b) limit the
              use of the Service or Content;
            </li>
            <li>
              access the Service using any automated means (such as robots, botnets or scrapers)
              except: (a) in the case of public search engines, in accordance with Sharevision’s
              robots.txt file; (b) with Sharevision’s prior written permission; or (c) as permitted
              by applicable law;
            </li>
            <li>
              collect or use any information that might identify a person (for example, harvesting
              usernames), unless permitted by that person or allowed under section 3 above;
            </li>
            <li>
              use the Service to distribute unsolicited promotional or commercial content or other
              unwanted or mass solicitations (spam);
            </li>
            <li>
              cause or encourage any inaccurate measurements of genuine user engagement with the
              Service, including by paying people or providing them with incentives to increase a
              video’s views, likes, or dislikes, or to increase a channel’s subscribers, or
              otherwise manipulate metrics;
            </li>
            <li>
              misuse any reporting, flagging, complaint, dispute, or appeals process, including by
              making groundless, vexatious, or frivolous submissions;
            </li>
            <li>
              run contests on or through the Service that do not comply with Sharevision’s contest
              policies and guidelines;
            </li>
            <li>
              use the Service to view or listen to Content other than for personal, non-commercial
              use (for example, you may not publicly screen videos or stream music from the
              Service); or
            </li>
            <li>
              use the Service to: (a) sell any advertising, sponsorships, or promotions placed on,
              around, or within the Service or Content, other than those allowed in the Advertising
              on Sharevision policies (such as compliant product placements); or (b) sell
              advertising, sponsorships, or promotions on any page of any website or application
              that only contains Content from the Service or where Content from the Service is the
              primary basis for such sales (for example, selling ads on a webpage where Sharevision
              videos are the only content of value).
            </li>
          </ol>

          <p>
            <strong>Reservation</strong>
            <br />
          </p>
          <p>
            Any right not expressly granted to you in this Agreement remains the right of
            Sharevision or the respective rights holders. This means, for example, that using the
            Service does not give you ownership of any intellectual property rights in the Content
            you access (including any branding used on or displayed in the Service).
          </p>

          <p>
            <strong>Changes to the Service</strong>
            <br />
          </p>
          <p>
            Sharevision is constantly changing and improving the Service. We may also need to alter
            or discontinue the Service, or any part of it, in order to make performance or security
            improvements, change functionality and features, make changes to comply with law, or
            prevent illegal activities on or abuse of our systems. We will always consider and
            balance the impact of such changes on the use of the Service. Whenever reasonably
            possible, we will provide notice when we discontinue or make material changes to our
            Service that will have an adverse impact on the use of our Service. However, there will
            be times when we need to make such changes without notice, such as where we need to take
            action to improve the security and operability of our Service, prevent abuse, or meet
            our legal requirements.
          </p>
          <p />
          <p>
            <strong>Your Content and Conduct</strong>
          </p>

          <p>
            <strong>Uploading Content</strong>
          </p>
          <p>
            If you have a Sharevision channel, you may be able to upload Content to the Service. You
            may use your Content to promote your business or artistic enterprise. If you choose to
            upload Content, you must not submit to the Service any Content that does not comply with
            this Agreement or the law. For example, the Content you submit must not include
            third-party intellectual property (such as copyrighted material) unless you have
            permission from that party or are otherwise legally entitled to do so. You are legally
            responsible for the Content you submit to the Service. We may use automated systems that
            analyze your Content to help detect infringement and abuse, such as spam, malware, and
            illegal content.
          </p>

          <p>
            <strong>Rights you Grant</strong>
          </p>
          <p>
            You retain all of your ownership rights in your Content. In short, what belongs to you
            stays yours. However, we do require you to grant certain rights to Sharevision and other
            users of the Service, as described below.
          </p>

          <p>
            <strong>Licence to Sharevision</strong>
          </p>
          <p>
            By providing Content to the Service, you grant to Sharevision a worldwide,
            non-exclusive, royalty-free, transferable, sublicensable licence to use that Content
            (including to reproduce, distribute, modify, display and perform it) for the purpose of
            operating, promoting, and improving the Service.&nbsp; This is inclusive but not limited
            to your name, image, biography and other such publicly available information that might
            be used for the promotion of Sharevision.
          </p>

          <p>
            <strong>Licence to Other Users</strong>
          </p>
          <p>
            You also grant each other user of the Service a worldwide, non-exclusive, royalty-free
            licence to access your Content through the Service, and to use that Content (including
            to reproduce, distribute, modify, display, and perform it) only as enabled by a feature
            of the Service.
          </p>

          <p>
            <strong>Duration of Licence</strong>
          </p>
          <p>
            The licences granted by you continue until the Content is removed as described below.
            Once removed, the licences will terminate, except where the operation of the Service,
            use of Content permitted before your removal, or the law requires otherwise. &nbsp;For
            example, removal of Content by you does not require Sharevision to: (a) recall Content
            that is being used by other users within any limited offline viewing functionality of
            the Service; or (b) delete copies we reasonably need to keep for legal purposes.
          </p>

          <p>
            <strong>Removing Your Content</strong>
          </p>
          <p>
            You may remove your Content from the Service at any time. You also have the option to
            make a copy of your Content before removing it. You must remove your Content if you no
            longer have the rights required by these terms.
          </p>

          <p>
            <strong>Removal of Content By Sharevision</strong>
          </p>
          <p>
            If we reasonably believe that any Content is in breach of this Agreement or may cause
            harm to Sharevision, our users, or third parties, we may remove or take down some or all
            of such Content. We will notify you with the reason for our action unless we reasonably
            believe that to do so: (a) would violate the law or the direction of a legal enforcement
            authority, or would otherwise risk legal liability for Sharevision or our Affiliates;
            (b) would compromise an investigation or the integrity or operation of the Service; or
            (c) would cause harm to any user, other third party, Sharevision or our Affiliates.
          </p>

          <p>
            <strong>Copyright Protection</strong>
          </p>
          <p>
            If you believe your copyright has been infringed on the Service, please send us a
            notice.
          </p>

          <p>
            We respond to notices of alleged copyright infringement, Sharevision's policies provide
            for the termination, in appropriate circumstances, of repeat infringers’ access to the
            Service.
          </p>

          <p>
            <strong>Account Suspension and Termination</strong>
          </p>

          <p>
            <strong>Terminations by You</strong>
          </p>
          <p>You may stop using the Service at any time.</p>

          <p>
            <strong>Terminations and Suspensions by Sharevision for Cause</strong>
          </p>
          <p>
            Sharevision may suspend or terminate your access, your Sharevision account, or your
            Sharevision&nbsp; account’s access to all or part of the Service if: (a) you materially
            or repeatedly breach this Agreement; (b) we are required to do so to comply with a legal
            requirement or a court order; or (c) we reasonably believe there has been conduct that
            creates liability or harm to any user, other third party, Sharevision or our Affiliates.
            <br />
            <br />
          </p>
          <p>
            <strong>Terminations by Sharevision for Service Changes</strong>
          </p>
          <p>
            Sharevision may terminate your access, or your Sharevision account’s access to all or
            part of the Service if Sharevision reasonably believes that its provision of the Service
            to you is no longer commercially viable.
          </p>

          <p>
            <strong>Notice for Termination or Suspension</strong>
          </p>
          <p>
            We will notify you with the reason for termination or suspension by Sharevision unless
            we reasonably believe that to do so: (a) would violate the law or the direction of a
            legal enforcement authority, or would otherwise risk legal liability for Sharevision or
            our Affiliates; (b) would compromise an investigation or the integrity or operation of
            the Service; or (c) would cause harm to any user, other third party, Sharevision or our
            Affiliates. Where Sharevision is terminating your access for Service Changes, where
            reasonably possible, you will be provided with sufficient time to export your Content
            from the Service.
          </p>

          <p>
            <strong>Effect of Account Suspension or Termination</strong>
          </p>
          <p>
            If your Sharevision account is terminated or your Sharevision account’s access to the
            Service is restricted, you may continue using certain aspects of the Service (such as
            viewing only) without an account, and this Agreement will continue to apply to such use.
            If you believe your Sharevision account has been terminated in error, you can contact
            us.
          </p>

          <p>
            <strong>
              About Software in our Service
              <br />
              <br />
            </strong>
          </p>
          <p>
            <strong>Downloadable Software</strong>
          </p>

          <p>
            When the Service requires or includes downloadable software, you give permission for
            that software to update automatically on your device once a new version or feature is
            available, subject to your device settings. Unless that software is governed by
            additional terms which provide a licence, Sharevision gives you a personal, worldwide,
            royalty-free, non-assignable and non-exclusive licence to use the software provided to
            you by Sharevision as part of the Service. This licence is for the sole purpose of
            enabling you to use and enjoy the benefit of the Service as provided by Sharevision, in
            the manner permitted by this Agreement. You are not allowed to copy, modify, distribute,
            sell, or lease any part of the software, or to reverse-engineer or attempt to extract
            the source code of that software, unless laws prohibit these restrictions or you have
            Sharevision’s written permission.
          </p>

          <p>
            <strong>Open Source</strong>
          </p>
          <p>
            Some software used in our Service may be offered under an open source licence. There may
            be provisions in an open source licence that expressly override some of these terms.
            &nbsp;If so, we will make that open source licence available to you.
          </p>

          <p>
            <strong>Other Legal Terms</strong>
          </p>

          <p>
            <strong>Warranty</strong>
          </p>
          <p>We provide the Service with reasonable care and skill.</p>

          <p>
            <strong>Disclaimer</strong>
          </p>
          <p>
            By law, consumers have certain rights that cannot be excluded or altered by a contract.
            Nothing in this Agreement affects those rights you may have as a consumer. Other than as
            expressly stated in this Agreement or as required by law, Sharevision does not make any
            specific promises about the Service. For example, we don’t make any promises about: the
            Content provided through the Service; the specific features of the Service, or its
            accuracy, reliability, availability, or ability to meet your needs; or that any Content
            you submit will be accessible or stored on the Service.
          </p>

          <p>
            <strong>Limitation of Liability</strong>
          </p>
          <p>
            All users: Nothing in this Agreement is intended to exclude or limit any party’s
            liability for: death or personal injury; fraud; fraudulent misrepresentation; or any
            liability that cannot be excluded or limited by law.
          </p>
          <p>
            To the extent permitted by applicable law, Sharevision and its Affiliates will not be
            responsible for:
          </p>
          <ol role="list">
            <li>
              losses that were not caused by Sharevision or its Affiliates’ breach of this
              Agreement;
            </li>
            <li>
              any loss or damage that was not, at the time that this Agreement was formed between
              you and Sharevision, a reasonably foreseeable consequence of Sharevision or its
              Affiliates breaching this Agreement; or
            </li>
            <li>
              the Content submitted by any user, or for the defamatory, offensive, or illegal
              conduct of any user.
            </li>
          </ol>

          <p>
            Business Users only: If you are using the Service for the purpose of your trade,
            business, craft or profession, (a “Business User”), to the extent permitted by
            applicable law, the following limitations of liability will also apply:
            <br />
          </p>
          <ol role="list">
            <li>
              Sharevision and its Affiliates will not be responsible for lost profits, revenues, or
              data; loss of opportunity or anticipated savings; indirect or consequential losses, or
              punitive damages (in all cases whether such losses were foreseeable or not); and
            </li>
            <li>
              Sharevision and its Affiliates’ total liability for any claims arising from or
              relating to the Service is limited to the greater of: (a) the amount of revenue that
              Sharevision has paid to you from your use of the Service in the 1 months before the
              date of your notice, in writing to Sharevision, of the claim and (b) £500, whichever
              is higher.
            </li>
          </ol>

          <p>
            <strong>Third-Party Links</strong>
          </p>
          <p>
            The Service may contain links to third-party websites and online services that are not
            owned or controlled by Sharevision. Sharevision has no control over, and assumes no
            responsibility for, such websites and online services. Be aware when you leave the
            Service; we suggest you read the terms and privacy policy of each third-party website
            and online service that you visit.
          </p>

          <p>
            <strong>About this Agreement</strong>
          </p>

          <p>
            <strong>Modifying this Agreement</strong>
          </p>
          <p>
            We may modify this Agreement, for example, to reflect changes to our Service or for
            legal, regulatory, or security reasons. Sharevision will provide reasonable advance
            notice of any material modifications to this Agreement and the opportunity to review
            these changes. However, changes addressing newly available features of the Service or
            changes made for legal reasons may be effective immediately. Changes will only apply
            going forward. If you do not agree to the modified terms, you should remove any Content
            you uploaded and discontinue your use of the Service.
          </p>

          <p>
            <strong>Continuation of this Agreement</strong>
          </p>
          <p>
            If your use of the Service ends, the following terms of this Agreement will continue to
            apply to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by
            you will continue in limited cases as described under “Duration of License”.
          </p>

          <p>
            <strong>Severance</strong>
          </p>
          <p>
            If it turns out that a particular term of this Agreement is not enforceable for any
            reason, this will not affect any other terms.
          </p>

          <p>
            <strong>Assignment</strong>
          </p>
          <p>
            Sharevision may transfer all or part of this Agreement to an Affiliate or, if
            Sharevision is sold, to a third party.
          </p>

          <p>
            <strong>No Waiver</strong>
          </p>
          <p>
            If you do not comply with this Agreement and we do not take action immediately, this
            doesn’t mean that we are giving up any rights that we may have (such as the right to
            take action in the future).
          </p>

          <p>
            <strong>Governing Law</strong>
          </p>
          <p>
            If you live in the United Kingdom this Agreement, and your relationship with Sharevision
            under this Agreement will be governed by the laws of your country of residence, and
            legal proceedings may be brought in your local courts.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
