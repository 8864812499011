import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestSignIn } from "@Store/actions/user";
import { LoadingSpinner } from "@Components";

const getTokenParam = () => {
  const url = new URL(window.location.href);

  return url.searchParams.get("token");
};

function GetAuthTokenRedirect() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const tokenParam = getTokenParam();

    if (!!tokenParam) {
      dispatch(
        requestSignIn(tokenParam, {
          onSuccess: () => {
            history.push("/");
          },
          onError: () => {
            history.push("/");
          }
        })
      );
    }
  }, []);

  return <LoadingSpinner />;
}

export default GetAuthTokenRedirect;
