import { cloneElement, createElement } from "react";
import { propEq } from "ramda";
import { EXTRA_USER_SECTIONS_LIST, USER_SECTIONS_LIST, VIEWER_SECTIONS_LIST } from "../constants";
import { Redirect } from "react-router";
import AdminSettings from "./AdminSettings";

type RootProfileSectionTypes = {
  match: { params: { sectionId: string } };
  userRole: string;
};

function RootProfileSection({ match, userRole }: RootProfileSectionTypes) {
  const {
    params: { sectionId }
  } = match;

  const adminSection = { component: createElement(AdminSettings), id: "admin-settings" };

  const allSections =
    userRole === "viewer"
      ? [...VIEWER_SECTIONS_LIST, adminSection]
      : [...USER_SECTIONS_LIST, ...EXTRA_USER_SECTIONS_LIST, adminSection];

  const sectionIndex = allSections.findIndex(propEq("id", sectionId));

  if (sectionIndex !== -1) {
    return cloneElement(allSections[sectionIndex].component, { match });
  }

  return (
    <Redirect
      to={{
        pathname: "/profile/library"
      }}
    />
  );
}

export default RootProfileSection;
