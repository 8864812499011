import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signInWithFacebook, signInWithGoogle } from "@Store/actions/user";
import { FacebookLogo, GoogleLogo, MailLogo } from "@Assets";
import { AuthModal } from "../index";
import sendGTMDataLayer from "@Utils/sendGTMDataLayer";

type SignUpModalTypes = {
  onSetActiveModal: (modal: AuthModal) => void;
};

function SignUpModal({ onSetActiveModal }: SignUpModalTypes) {
  const dispatch = useDispatch();

  const handleSignWithFacebook = () => {
    sendGTMDataLayer({
      event: "SignUp",
      eventCategory: "user signup",
      eventAction: "account creation",
      eventValue: 1,
      eventLabel: "user signup"
    });
    dispatch(signInWithFacebook());
  };

  const handleSignWithGoogle = () => {
    sendGTMDataLayer({
      event: "SignUp",
      eventCategory: "user signup",
      eventAction: "account creation",
      eventValue: 1,
      eventLabel: "user signup"
    });
    dispatch(signInWithGoogle());
  };

  return (
    <article className="form-container sign-up">
      <h2 className="heading">Create a Free account</h2>
      <div className="pop-content-container">
        <p className="description">
          Create an account to subscribe to Streams, like videos, follow streamers, create
          Watchlists and much more.
        </p>
        <ul className="sign-up-options">
          <li>
            <button className="icon-button" onClick={handleSignWithFacebook}>
              <span className="icon-side">
                <img src={FacebookLogo} alt="FB Logo" />
              </span>
              <span className="text-side">Sign up via Facebook</span>
            </button>
          </li>
          <li>
            <button className="icon-button" onClick={handleSignWithGoogle}>
              <span className="icon-side">
                <img src={GoogleLogo} alt="Google Logo" />
              </span>
              <span className="text-side">Sign up via Gmail</span>
            </button>
          </li>
          <li>
            <button className="icon-button">
              <span className="icon-side">
                <img src={MailLogo} alt="FB Logo" />
              </span>
              <span
                className="text-side"
                onClick={() => {
                  onSetActiveModal("SIGN_UP_FORM");
                }}
              >
                Sign up with Email
              </span>
            </button>
          </li>
        </ul>
        <h4 className="login-register-action">
          Already have account?{" "}
          <span
            onClick={() => {
              onSetActiveModal("SIGN_IN");
            }}
          >
            Sign in
          </span>
        </h4>
        <p className="terms-conditions">
          By continuing, you agree to our{" "}
          <Link
            to="/terms-conditions"
            onClick={() => {
              onSetActiveModal("");
            }}
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy-policy"
            onClick={() => {
              onSetActiveModal("");
            }}
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </article>
  );
}

export default SignUpModal;
