import Select from "react-select";
import classes from "classnames";
import { isEmpty } from "ramda";
import { DeviceInputsType } from "@Views/StreamModal/WebStream";
import AudioMetric from "@Views/StreamModal/AudioMetric";
import "./style.scss";

type Props = {
  deviceInputs: DeviceInputsType;
  stream: MediaStream;
  selectedAudio: any;
  selectedVideo: any;
  onAudioInputChange: (selected: any) => void;
  onVideoInputChange: (selected: any) => void;
};

const WebcamInputs = ({
  deviceInputs,
  selectedAudio,
  selectedVideo,
  onAudioInputChange,
  onVideoInputChange,
  stream
}: Props) => {
  return (
    <aside className="webCam-inputs">
      <label htmlFor="camera" className="labels-select">
        Video input
      </label>
      <div
        className={classes("input-container", {
          ["disabled"]: !!(selectedVideo && !selectedVideo.value)
        })}
      >
        <i className="icon-camera" />
        <Select
          classNamePrefix="app-select"
          className="inputs-select"
          menuPlacement="top"
          isDisabled={!!(selectedVideo && !selectedVideo.value)}
          placeholder="Select Camera"
          isSearchable={false}
          value={selectedVideo && !!selectedVideo.value ? selectedVideo : null}
          onChange={onVideoInputChange}
          options={deviceInputs.videoInputs}
        />
      </div>
      <label htmlFor="microphone" className="labels-select">
        Audio input
      </label>
      <div
        className={classes("input-container", {
          ["disabled"]: !!(selectedVideo && !selectedVideo.value)
        })}
      >
        <i className="icon-mic" />
        <Select
          classNamePrefix="app-select"
          className="inputs-select"
          id="microphone"
          isDisabled={!!(selectedAudio && !selectedAudio.value)}
          menuPlacement="bottom"
          placeholder="Select microphone"
          isSearchable={false}
          value={selectedAudio && !!selectedAudio.value ? selectedAudio : null}
          onChange={onAudioInputChange}
          options={deviceInputs.audioInputs}
        />
      </div>
      <AudioMetric stream={stream} />
    </aside>
  );
};

export default WebcamInputs;
