import { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { requestUserPlaylists } from "@Store/actions/playlist";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CreatePlaylistInput from "./CreatePlaylistInput";
import PlaylistsContent from "./PlaylistsContent";
import "./style.scss";

function PlaylistsTab() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestUserPlaylists());
  }, []);

  return (
    <Fragment>
      <CreatePlaylistInput />
      <DndProvider backend={HTML5Backend}>
        <PlaylistsContent />
      </DndProvider>
    </Fragment>
  );
}

export default PlaylistsTab;
