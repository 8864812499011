import { call, takeEvery, all } from "@redux-saga/core/effects";
import { SEARCH } from "../actions/app";
import { ServerResponse } from "@Models/RequestParams";
import authorizedApiRequest from "@Fetch/authorized";

function* getAppSearchSuggestions({ payload, callbacks }: any) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/search?suggest=${payload.query}`,
    { method: "GET" }
  );

  if (serverResponse.status === 200) {
    const { data } = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* getAppSearchResults({ payload, callbacks }: any) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/search?query=${payload.query}&page=${payload.nextPage}&limit=${payload.itemsPerPage}`,
    { method: "GET" }
  );

  if (serverResponse.status === 200) {
    const data = yield serverResponse.json();

    yield call(callbacks.onSuccess, data);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

function* deleteUserSearchSuggestion({ payload, callbacks }: any) {
  const serverResponse: ServerResponse = yield call(
    authorizedApiRequest,
    `/streams/Suggest/${payload.suggestId}`,
    { method: "DELETE" }
  );

  if (serverResponse.status === 200) {
    yield call(callbacks.onSuccess);
  } else {
    if (callbacks.onError) yield call(callbacks.onError);
  }
}

export default function* sidebarSagaWatcher() {
  yield all([
    takeEvery(SEARCH.REQUEST_RESULTS, getAppSearchResults),
    takeEvery(SEARCH.REQUEST_SUGGESTIONS, getAppSearchSuggestions),
    takeEvery(SEARCH.DELETE_SUGGESTION, deleteUserSearchSuggestion)
  ]);
}
