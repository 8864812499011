import { CreateChannel1, CreateChannel2 } from "@Assets";
import "./style.scss";

function CreateChannelArticle() {
  return (
    <article className="help-article">
      <h2 className="article-title">How to create a channel in ShareVision?</h2>
      <p className="big-topic">
        This topic will guide you through the process of setting up your own channel on ShareVision
        where you can share your own content.
      </p>
      <p className="description">
        First, you need to sign up to ShareVision in the top right hand corner of the screen.
      </p>
      <img src={CreateChannel1} alt="channel-1" />
      <p className="description">
        Once signed up, in the top right corner again you can click your profile image / icon and
        select Create Channel.
      </p>
      <img src={CreateChannel2} alt="channel-2" />
      <p className="description">
        Fill out all the required information for your channel, here are some tips:
      </p>
      <ol>
        <li>Choose a standout profile picture</li>
        <li>
          Ensure the description is filled out and describes the type of Creator you are and the
          sort of content your followers will see.
        </li>
      </ol>
      <p className="description">
        If you need further support, you can send an email to{" "}
        <a href="mailto:support@sharevision.com">support@sharevision.com</a> where someone will
        assist you.
      </p>
    </article>
  );
}

export default CreateChannelArticle;
