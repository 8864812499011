import { createElement } from "react";
import BanListSection from "./sections/BanList";
import CommentsSection from "./sections/Comments";
import DashboardSection from "./sections/Dashboard";
import ChannelSettings from "./sections/ChannelSettings";
import ProfileSettings from "./sections/ProfileSettings";
import VideoLibrary from "./sections/VideoLibrary";

import VideoSection from "./sections/VideoLibrary/sections/Videos";
import StreamSection from "./sections/VideoLibrary/sections/Streams";
import PlaylistsSection from "./sections/VideoLibrary/sections/Playlists";
import CommentsInboxSection from "./sections/Comments/sections/Inbox";
import SentCommentsSection from "./sections/Comments/sections/Sent";

export type SectionType = {
  id: string;
  iconClass: string;
  label: string;
  component: JSX.Element;
};

type VideoLibraryOptionsType = {
  id: "videos" | "streams" | "playlists";
  label: "Videos" | "Streams" | "Playlists";
  component: any;
};

type CommentsPageOptionsType = {
  id: "inbox" | "sent";
  label: "Inbox" | "Sent";
  component: any;
};

export const USER_SECTIONS_LIST: SectionType[] = [
  {
    id: "dashboard",
    iconClass: "icon-dashboard",
    label: "Dashboard",
    component: createElement(DashboardSection)
  },
  {
    id: "library",
    iconClass: "icon-circle-play",
    label: "Video library",
    component: createElement(VideoLibrary)
  },
  {
    id: "comments",
    iconClass: "icon-chat-message-o",
    label: "Comments",
    component: createElement(CommentsSection)
  },
  {
    id: "ban-list",
    iconClass: "icon-stop-hand",
    label: "Ban-list",
    component: createElement(BanListSection)
  }
];

export const EXTRA_USER_SECTIONS_LIST: SectionType[] = [
  {
    id: "channel-settings",
    iconClass: "icon-settings",
    label: "Channel Settings",
    component: createElement(ChannelSettings)
  },
  {
    id: "settings",
    iconClass: "icon-l-user",
    label: "Profile Settings",
    component: createElement(ProfileSettings)
  }
];

export const VIEWER_SECTIONS_LIST: SectionType[] = [
  {
    id: "comments",
    iconClass: "icon-chat-message-o",
    label: "Comments",
    component: createElement(CommentsSection)
  },
  {
    id: "settings",
    iconClass: "icon-l-user",
    label: "Profile Settings",
    component: createElement(ProfileSettings)
  }
];

export const VIDEO_LIBRARY_SECTIONS: VideoLibraryOptionsType[] = [
  {
    id: "videos",
    label: "Videos",
    component: createElement(VideoSection)
  },
  {
    id: "streams",
    label: "Streams",
    component: createElement(StreamSection)
  },
  {
    id: "playlists",
    label: "Playlists",
    component: createElement(PlaylistsSection)
  }
];

export const COMMENTS_PAGE_SECTIONS: CommentsPageOptionsType[] = [
  {
    id: "inbox",
    label: "Inbox",
    component: createElement(CommentsInboxSection)
  },
  {
    id: "sent",
    label: "Sent",
    component: createElement(SentCommentsSection)
  }
];
